import React from 'react'
import { format, render, cancel } from 'timeago.js'
import styled from 'styled-components'
import { colors } from '../core/theme'

const TimeAgo = ({
  small,
  datetime,
  live,
  locale,
  className,
  style,
  ...others
}) => {
  const renderTimeAgo = (ref) => {
    cancel(ref)

    if (live !== false && ref) {
      const dateString =
        datetime instanceof Date ? datetime.getTime() : datetime
      ref.setAttribute('datetime', dateString)

      render(ref, locale)
    }
  }

  return (
    <Time
      ref={renderTimeAgo}
      className={className || ''}
      style={style}
      small={small}
      {...others}
    >
      {format(datetime, locale)}
    </Time>
  )
}

const Time = styled.time`
  color: ${(props) => props.color || colors.primary};
  font-size: ${(props) => (props.small ? '14px' : '15px')};
  font-weight: 400;
`

TimeAgo.defaultProps = {
  live: true,
  locale: 'en',
}

export default TimeAgo
