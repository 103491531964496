import React from 'react'
import styled from 'styled-components'
import { ErrorIcon } from '../assets/icons'
import Button from './Button'
import { Subheading, Label } from './Typography'

const LoadingError = ({ error, logout }) => (
  <Container>
    <ErrorIcon />
    <Subheading padding="16px 0 8px 0">Ooops</Subheading>
    <ErrorWrapper>
      <Label align="center" lineHeight="medium">
        {error}
      </Label>
    </ErrorWrapper>
    {logout && (
      <Button style={{ marginTop: 16 }} outline onClick={logout} small>
        Logout
      </Button>
    )}
  </Container>
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 250px;
`

export default LoadingError
