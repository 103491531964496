/* eslint-disable no-alert */
import React, { useState } from 'react'
import styled from 'styled-components'
import KonectCard from 'konect-react'
import { colors } from '../core/theme'
import {
  DuplicateIcon,
  LightingIcon,
  MobileMenuIcon,
  NewTabIcon,
  SaveIcon,
} from '../assets/icons'
import Button from './Button'
import { useActions, useStore } from '../store'
import { TopBar } from './TopBar'
import { Dropdown, DropdownList } from './Dropdown'
import { config } from '../core/config'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { removePremiumFeaturesFromCard } from '../helpers/removePremiumFeaturesFromCard'
import { usePermission } from '../hooks/usePermission'

const CardPreview = ({
  hideSend,
  editMode,
  showSave,
  saveChanges,
  card,
  isFreeCard,
  isCardActive,
  isTemplate,
}) => {
  const { showModal } = useActions().modals
  const { license } = useStore()
  const { canEdit, showPermissionAlert } = usePermission()
  const [isMenuVisible, setMenuVisibility] = useState(false)

  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <Container>
      {isTemplate ? (
        <TopBar style={{ justifyContent: 'flex-end' }}>
          <Button
            small
            outline
            borderColor={colors.main}
            className="compact-button"
            onClick={() => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              showModal({ name: 'CREATE_CARD', template: card })
            }}
          >
            Use Template
          </Button>
        </TopBar>
      ) : (
        <TopBar style={{ justifyContent: 'flex-end' }}>
          {!isCardActive && (
            <Button
              small
              outline
              borderColor={colors.main}
              style={{ marginRight: 12 }}
              className="compact-button-outline"
              leftIcon={<LightingIcon />}
              onClick={async () => {
                showModal({ name: 'TRIAL_ENDED' })
              }}
            >
              Upgrade
            </Button>
          )}
          {!hideSend && (
            <Button
              small
              outline
              borderColor={colors.main}
              className="compact-button"
              onClick={async () => {
                if (showSave) {
                  const confirmation = window.confirm(
                    'You have some unsaved work. Do you want to save it before continuing?'
                  )
                  if (confirmation) {
                    await saveChanges(card)
                  }
                }
                showModal({ name: 'SHARE_CARD', card })
              }}
            >
              Share
            </Button>
          )}
          <Dropdown
            isVisible={isMenuVisible}
            placement="bottom-end"
            onClose={hideMenu}
            zIndex={9999999}
            triggerRenderer={({ ref }) => (
              <MenuButton
                onClick={showMenu}
                ref={ref}
                style={{ cursor: 'pointer' }}
              >
                <MobileMenuIcon />
              </MenuButton>
            )}
          >
            <DropdownList
              onClose={hideMenu}
              items={[
                {
                  content: 'Open page in new tab',
                  onItemSelect: () => {
                    const cardURL = card.slug
                      ? `${config.CARDS_BASE_URL}/${card.slug}`
                      : card.cardURL
                    window.open(cardURL, '_blank')
                  },
                  icon: <NewTabIcon color={colors.primary} size={18} />,
                },
                {
                  content: 'Duplicate page',
                  onItemSelect: () => {
                    if (!canEdit) {
                      showPermissionAlert()
                      return
                    }
                    showModal({ name: 'DUPLICATE_CARD', card })
                  },
                  icon: <DuplicateIcon color={colors.primary} size={18} />,
                },
                {
                  content: 'Create template from page',
                  onItemSelect: () => {
                    showModal({ name: 'CREATE_TEMPLATE_MODAL', card })
                  },
                  icon: <SaveIcon color={colors.primary} size={18} />,
                },
              ]}
            />
          </Dropdown>
        </TopBar>
      )}
      <OverflowContainer
        onScroll={() => {
          if (window.ks_trigger_page_scroll) {
            window.ks_trigger_page_scroll()
          }
        }}
      >
        <CardContainer>
          <KonectCard
            card={
              isFreeCard
                ? removePremiumFeaturesFromCard(
                    deepCloneObject({
                      ...card,
                      affiliateRef: license.affiliateRef,
                    })
                  )
                : deepCloneObject({
                    ...card,
                    affiliateRef: license.affiliateRef,
                  })
            }
            editMode={editMode}
          />
        </CardContainer>
      </OverflowContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 10;
  border-left: 1px solid ${colors.border};
  position: relative;
  @media (max-width: 767px) {
    border-left: none;
  }
`

const OverflowContainer = styled.div`
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const MenuButton = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`

export default CardPreview
