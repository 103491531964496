const INITIAL_STATE = []

const showModal = ({ state }, { name, ...props }) => {
  state.modals = [
    ...state.modals,
    {
      key: Math.random().toString(36),
      name,
      props,
    },
  ]
}

const closeModal = ({ state }, key) => {
  state.modals = state.modals.filter((modal) => modal.key !== key)
}

const clearModals = ({ state }) => {
  state.modals = []
}

export default {
  state: INITIAL_STATE,
  actions: {
    showModal,
    closeModal,
    clearModals,
  },
}
