import { returnPodcastPlatformInfo } from 'konect-react'
import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import { DoneIcon, DraggableIcon, EditTextIcon } from '../assets/icons'
import { colors } from '../core/theme'
import FloatingInput from './FloatingInput'

export default function PodcastListItem({
  platform,
  index,
  link,
  platforms,
  podcast,
  updateCard,
  card,
  i,
}) {
  const [error, setError] = useState()
  const [editing, setEditing] = useState(false)

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.keyCode === 13) {
        if (editing) {
          setEditing(false)
        }
      }
    }
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [editing])

  return (
    <Draggable key={platform.name} draggableId={platform.name} index={index}>
      {(provided, snapshot) => (
        <ItemContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <IconContainer style={{ marginRight: 6 }}>
            <DraggableIcon />
          </IconContainer>
          {editing ? (
            <FloatingInput
              autoFocus
              error={error}
              value={link}
              label={`Link to ${platform.name}`}
              style={{ marginBottom: 0, marginRight: 8 }}
              onChange={(e) => {
                const foundPlatform = returnPodcastPlatformInfo(e.target.value)
                if (foundPlatform.icon !== platform.icon) {
                  setError(`Invalid link to ${platform.name}.`)
                } else {
                  setError('')
                }
                for (let x = 0; x < i; x++) {
                  if (!platforms[x]) platforms[x] = {}
                }
                platforms[i] = {
                  ...platforms[i],
                  link: e.target.value,
                }
                podcast.platforms = platforms
                updateCard(card)
              }}
            />
          ) : (
            <>
              <IconContainer style={{ marginRight: 4 }}>
                <PlatformIcon alt={platform.name} src={platform.icon} />
              </IconContainer>
              <SectionTitle>{platform.name}</SectionTitle>
            </>
          )}
          <IconContainer
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setError(false)
              if (editing) setEditing(false)
              else setEditing(true)
            }}
          >
            {editing ? (
              <DoneIcon size={20} color={colors.success} />
            ) : (
              <EditTextIcon size={17} />
            )}
          </IconContainer>
        </ItemContainer>
      )}
    </Draggable>
  )
}

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  padding: 12px;
  margin: 0 0 16px 0;
  @media (max-width: 767px) {
    padding: 12px 10px;
  }
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
`

const SectionTitle = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const PlatformIcon = styled.img`
  width: 24px;
  height: 24px;
`

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})
