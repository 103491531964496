import React, { useState } from 'react'
import styled from 'styled-components'
import TemplatesList from './TemplatesList'
import { CARDS_TEMPLATES } from '../../core/templates/cards-templates'
import { cloneArrayDeep } from '../../helpers/deepCloneObject'
import { colors } from '../../core/theme'
import { useStore, useActions } from '../../store'
import { Row } from '../../components/Layout'
import { AddIcon, SearchIcon } from '../../assets/icons'
import Input from '../../components/Input'
import Button from '../../components/Button'

const Templates = () => {
  const { showModal } = useActions().modals
  const { license } = useStore()
  const { templates = [] } = license
  const allTemplates = cloneArrayDeep(templates).concat(CARDS_TEMPLATES)
  const [search, setSearch] = useState('')

  return (
    <Container>
      <Row align="center" style={{ width: '100%', paddingBottom: 20 }}>
        <Row
          align="center"
          style={{
            width: '100%',
            border: `1px solid ${colors.border}`,
            borderTopLeftRadius: 8,
            borderBottomLeftRadius: 8,
            boxSizing: 'border-box',
            paddingLeft: 16,
          }}
        >
          <SearchIcon size={18} />
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            inputClassName="search-icons-input"
            placeholder="Search..."
            style={{
              margin: 0,
              width: '100%',
            }}
            inputStyle={{
              fontSize: 13,
              padding: '0',
            }}
          />
        </Row>
        <Button
          color={colors.black}
          style={{
            height: 46,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
          leftIcon={<AddIcon color={colors.white} />}
          onClick={() => {
            showModal({ name: 'CREATE_TEMPLATE_MODAL', showTypePicker: true })
          }}
        >
          Add Template
        </Button>
      </Row>
      <TemplatesList templates={allTemplates} search={search} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 36px 40px;
  position: relative;
  overflow: auto;
  background-color: ${colors.background};
  @media (max-width: 767px) {
    padding: 20px;
    padding-bottom: 100px;
    overflow: visible;
  }
`

export default Templates
