/* eslint-disable no-alert */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import Tag from './Tag'
import { Row } from './Layout'
import { Label } from './Typography'

const UserRow = ({ tag, removeTag, editTag }) => {
  const { label, description, color } = tag

  return (
    <TagContainer>
      <Row flex="1">
        <Tag color={color} label={label} />
      </Row>
      <Row flex="1">
        <Label lineHeight="medium" variant="secondary">
          {description}
        </Label>
      </Row>
      <ActionsWrapper>
        <ActionButton onClick={editTag}>Edit</ActionButton>
        <ActionButton
          onClick={() => {
            const result = window.confirm(
              `You are about to delete "${label}" tag. Are you sure you want to continue?`
            )
            if (result) removeTag(tag)
          }}
        >
          Delete
        </ActionButton>
      </ActionsWrapper>
    </TagContainer>
  )
}

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.lightBorder};
  background-color: white;
  align-items: center;
  padding: 0 16px;
  min-height: 64px;
  max-height: 64px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-width: 320px;
  padding: 4px;
  box-sizing: border-box;
`

const ActionButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${colors.primary};
  padding: 0 8px;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.6;
    text-decoration: underline;
  }
`

export default UserRow
