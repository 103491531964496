import React, { useState } from 'react'
import styled from 'styled-components'
import FloatingInput from './FloatingInput'
import { Row } from './Layout'
import Button from './Button'
import { updateAffiliateUser } from '../api/database-api'
import { usePermission } from '../hooks/usePermission'
import { useActions } from '../store'
import { validateEmail } from '../helpers/validators/validateEmail'

export default function AffiliatePayPal({ affiliatePayPal = '', affiliateID }) {
  const { canEdit, showPermissionAlert } = usePermission()
  const { showToast } = useActions().toasts
  const { updateLicenseData } = useActions().license
  const [value, setValue] = useState(affiliatePayPal)
  const [hasChanges, setHasChanges] = useState()
  const [loading, setLoading] = useState()
  const [error, setError] = useState('')

  const updatePayPal = async () => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (loading) return

    if (!validateEmail(value)) {
      setError('Invalid email format.')
      return
    }

    setLoading(true)
    setError('')

    try {
      await updateAffiliateUser(
        {
          payment_details: {
            paypal_email: value,
          },
        },
        affiliateID
      )
      await updateLicenseData({
        affiliatePayPal: value,
      })
      showToast({
        content: 'PayPal updated successfully.',
        variant: 'success',
      })
      setHasChanges(false)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Something went wrong. Please contact your support.',
      })
    }
    setLoading(false)
  }

  return (
    <>
      <PayPalIcon />
      <Row style={{ width: '100%', marginTop: 20 }}>
        <FloatingInput
          error={error}
          placeholder="PayPal address"
          name="email"
          type="email"
          autoComplete="email"
          value={value}
          onChange={(e) => {
            if (e.target.value === value) {
              setHasChanges(false)
            } else {
              setHasChanges(true)
            }
            setValue(e.target.value)
          }}
          style={{ marginTop: 0, marginBottom: 0 }}
          extraSX={{
            '& fieldset': {
              border: 'none',
              borderTopRightRadius: hasChanges ? `0px` : '8px',
              borderBottomRightRadius: hasChanges ? `0px` : '8px',
            },
          }}
          inputProps={{
            className: 'grey-input',
            style: {
              borderRadius: '8px',
              borderTopRightRadius: hasChanges ? `0px` : '8px',
              borderBottomRightRadius: hasChanges ? `0px` : '8px',
            },
          }}
        />
        {hasChanges && (
          <Button
            loading={loading}
            onClick={updatePayPal}
            style={{
              margin: '0',
              height: 50,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          >
            Save
          </Button>
        )}
      </Row>
      <Description>
        Enter your PayPal email address to receive your commissions on the 1st
        of every month.
      </Description>
    </>
  )
}

const Description = styled.span`
  font-size: 15.5px;
  color: #545454;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 16px;
  @media (max-width: 767px) {
    font-size: 14.5px;
  }
`

const PayPalIcon = () => (
  <svg
    width="152"
    height="40"
    fill="none"
    viewBox="0 0 152 40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#axx)">
      <path
        d="M56.5 8.2H48a1.2 1.2 0 0 0-1.2 1l-3.3 21.2a.7.7 0 0 0 .4.8h4.3a1.2 1.2 0 0 0 1.1-1l1-5.7a1.1 1.1 0 0 1 1-1h2.7c5.5 0 8.7-2.6 9.5-7.8.4-2.3 0-4.1-1-5.4-1.2-1.4-3.3-2.1-6.1-2.1Zm1 7.7c-.5 3-2.8 3-5 3h-1.3l.9-5.5a.7.7 0 0 1 .7-.6h.5c1.5 0 3 0 3.7.8.4.5.6 1.3.4 2.3Zm24 0h-4a.7.7 0 0 0-.8.5l-.1 1.1-.3-.4c-.9-1.2-2.8-1.6-4.7-1.6a9.2 9.2 0 0 0-9 8 7.4 7.4 0 0 0 1.5 6c1.2 1.5 3 2 5 2 3.6 0 5.6-2.2 5.6-2.2l-.2 1.1a.7.7 0 0 0 .4.8h3.9a1.2 1.2 0 0 0 1.1-1l2.2-13.6a.7.7 0 0 0-.4-.7h-.3Zm-5.6 7.7a4.4 4.4 0 0 1-4.6 3.8c-1.1 0-2-.4-2.7-1.1a3.4 3.4 0 0 1-.6-2.8 4.4 4.4 0 0 1 4.5-3.8c1.1 0 2 .3 2.7 1 .6.8.8 1.8.7 2.9Zm26.8-7.8h-4a1.2 1.2 0 0 0-1 .6l-5.5 8-2.3-7.7a1.2 1.2 0 0 0-1.1-.9h-4a.7.7 0 0 0-.7.6v.4l4.4 12.9-4.1 5.8a.7.7 0 0 0 .6 1h4a1.2 1.2 0 0 0 1-.4l13.3-19.2a.7.7 0 0 0-.2-1 .7.7 0 0 0-.4 0Z"
        fill="#253B80"
      />
      <path
        d="M116 8.2h-8.3a1.2 1.2 0 0 0-1.2 1l-3.3 21.2a.7.7 0 0 0 .4.8h4.5a.8.8 0 0 0 .8-.7l1-6a1.2 1.2 0 0 1 1.2-1h2.6c5.5 0 8.7-2.6 9.5-7.8.4-2.3 0-4.1-1-5.4-1.3-1.4-3.4-2.1-6.2-2.1Zm1 7.7c-.4 3-2.7 3-5 3h-1.2l.9-5.5a.7.7 0 0 1 .7-.6h.5c1.5 0 3 0 3.7.8.4.5.6 1.3.4 2.3Zm24 0h-4a.7.7 0 0 0-.7.5l-.1 1.1-.3-.4c-.9-1.2-2.8-1.6-4.7-1.6a9.2 9.2 0 0 0-9 8 7.4 7.4 0 0 0 1.5 6c1.2 1.5 3 2 5 2 3.6 0 5.6-2.2 5.6-2.2l-.2 1.1a.7.7 0 0 0 .4.7l.3.1h3.6a1.2 1.2 0 0 0 1.2-1l2.1-13.6a.7.7 0 0 0-.2-.5.7.7 0 0 0-.5-.3Zm-5.6 7.7a4.4 4.4 0 0 1-4.5 3.8c-1.1 0-2-.4-2.7-1.1a3.4 3.4 0 0 1-.6-2.8 4.4 4.4 0 0 1 4.5-3.8c1.1 0 2 .3 2.7 1 .6.8.8 1.8.6 2.9Zm10.3-14.8-3.4 21.6a.7.7 0 0 0 .4.7l.3.1h3.4c.6 0 1.1-.4 1.2-1L151 9a.7.7 0 0 0-.2-.6.7.7 0 0 0-.5-.2h-3.9a.7.7 0 0 0-.7.6Z"
        fill="#179BD7"
      />
      <path
        d="m8.9 35.3.6-4H1.3L6 1.6a.4.4 0 0 1 .4-.4h11.5c3.8 0 6.4.8 7.8 2.4.6.7 1 1.5 1.2 2.3.2.9.2 2 0 3.2v1l.6.3c.5.2 1 .6 1.3 1 .6.6 1 1.4 1.1 2.3a12 12 0 0 1-1.6 7.3 8 8 0 0 1-5.2 3.7c-1 .3-2.4.5-3.7.5h-1c-.6 0-1.2.2-1.7.6-.5.4-.8 1-.9 1.6v.4l-1.2 7v.3l-.1.2H8.9Z"
        fill="#253B80"
      />
      <path
        d="m28.2 9.3-.2.7c-1.5 7.7-6.6 10.3-13.2 10.3h-3.4c-.8 0-1.5.6-1.6 1.4L8 32.5l-.5 3a.8.8 0 0 0 .5 1h6.3c.7 0 1.3-.4 1.4-1.1V35l1.2-7v-.5c.2-.7.8-1.2 1.5-1.2h.9c5.7 0 10.3-2.3 11.6-9 .5-2.9.3-5.2-1.2-6.9-.5-.5-1-.9-1.6-1.2Z"
        fill="#179BD7"
      />
      <path
        d="M26.6 8.7 25 8.3l-3-.2h-9a1.4 1.4 0 0 0-1.4 1.2l-1.9 12v.4a1.6 1.6 0 0 1 1.6-1.4h3.4c6.6 0 11.7-2.7 13.2-10.3l.2-.7a8 8 0 0 0-1.6-.6Z"
        fill="#222D65"
      />
      <path
        d="M11.7 9.3a1.4 1.4 0 0 1 1.5-1.2h9a20.2 20.2 0 0 1 4.7.7l1.3.5c.4-2.9 0-4.8-1.6-6.5C25 .8 21.8 0 18 0H6.4c-.8 0-1.5.6-1.6 1.4L0 31.4a1 1 0 0 0 .6 1l.4.1h7l1.8-11.2 2-12Z"
        fill="#253B80"
      />
    </g>
    <defs>
      <clipPath id="axx">
        <path fill="#fff" d="M0 0h151.5v40H0z" />
      </clipPath>
    </defs>
  </svg>
)
