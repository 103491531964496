import { createStateHook, createActionsHook } from 'overmind-react'
import toasts from './toasts'
import modals from './modals'
import profile from './profile'
import license from './license'
import invitation from './invitation'
import cards from './cards'
import inbox from './inbox'
import reports from './reports'
import accessories from './accessories'

export const store = {
  state: {
    toasts: toasts.state,
    modals: modals.state,
    profile: profile.state,
    license: license.state,
    cards: cards.state,
    inbox: inbox.state,
    invitation: invitation.state,
    accessories: accessories.state,
  },
  actions: {
    toasts: toasts.actions,
    modals: modals.actions,
    profile: profile.actions,
    license: license.actions,
    cards: cards.actions,
    inbox: inbox.actions,
    invitation: invitation.actions,
    reports: reports.actions,
    accessories: accessories.actions,
  },
}

export const useStore = createStateHook()
export const useActions = createActionsHook()
