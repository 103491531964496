import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import CardsList from './CardsList'
import { colors } from '../../core/theme'
import { useStore, useActions } from '../../store'
import CardSection from './CardSection'
import { getLastMonthTime, getLastWeekTime } from '../../helpers/days'

export default function Cards() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const { setTagsFilter, updateFilters, updateDateFilter } = useActions().inbox
  const { changeFolder, setTab } = useActions().cards
  const { license } = useStore()
  const { cards, selectedFolder, cardsFilter } = useStore().cards
  const { inboxCards, dateFilter, tagsFilter, cardDateFilter } =
    useStore().inbox
  const [selectedCard, selectCard] = useState({})
  const [report, updateReport] = useState()

  const onCardClicked = (card) => () => {
    if (Object.keys(tagsFilter).length > 0) {
      setTagsFilter({})
    }

    if (cardDateFilter.label !== 'Last week') {
      updateDateFilter({
        label: 'Last week',
        from: getLastWeekTime(),
      })
    }

    if (dateFilter.label !== 'Last month') {
      updateFilters({
        label: 'Last month',
        from: getLastMonthTime(),
      })
    }

    setTab(0)

    if (isMobile) {
      history.push('/' + card.cardID + '/report')
      return
    }

    history.push('/' + card.cardID)
    selectCard(card)
  }

  useEffect(() => {
    const cardID = window.location.pathname.split('/')[1]
    const currentCard = cards.filter((f) => f.cardID === cardID)[0]

    if (license.folders) {
      license.folders.forEach((folder) => {
        if (folder.cards.includes(cardID)) {
          changeFolder(folder)
        }
      })
    }

    if (currentCard) {
      selectCard(currentCard)
    } else if (cards[0] && cards[0].cardID) {
      history.push('/' + cards[0].cardID)
      selectCard(cards[0])
      if (license.folders) {
        license.folders.forEach((folder) => {
          if (folder.cards.includes(cards[0].cardID)) {
            changeFolder(folder)
          }
        })
      }
    }
  }, [cards, history])

  return (
    <Container>
      <CardsList
        cards={cards}
        license={license}
        inboxCards={inboxCards}
        selectedFolder={selectedFolder}
        selectedCard={selectedCard}
        onCardClicked={onCardClicked}
        dateFilter={dateFilter}
        cardsFilter={cardsFilter}
        report={report}
        isMobile={isMobile}
      />
      {!isMobile && (
        <CardSection
          selectedCard={selectedCard}
          report={report}
          updateReport={updateReport}
          inboxCards={inboxCards}
          // dateFilter={cardDateFilter}
          // updateDateFilter={updateDateFilter}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100vh;
  overflow: hidden;
  background-color: ${colors.menu};
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`
