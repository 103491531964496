import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Switch, Route, useHistory } from 'react-router-dom'
import SideMenu from './components/SideMenu'
import Cards from './pages/cards'
import EditCard from './pages/edit-card'
import Templates from './pages/templates'
import InboxCardDetails from './pages/inbox-card-details'
import TemplatePreview from './pages/templates/TemplatePreview'
import PageLoader from './components/PageLoader'
import Company from './pages/company'
import TagsManager from './pages/tags-manager'
import { useActions, useStore } from './store'
import {
  startListeningForCards,
  startListeningForInboxChanges,
} from './api/database-api'
import AccessoriesPage from './pages/accessories'
import CardSection from './pages/cards/CardSection'
import Billing from './pages/billing'
import { licenseStatus } from './helpers/licenseStatus'
import { DESKTOP_BREAKPOINT } from './core/constants'
import Referrals from './pages/referrals'

export default function App() {
  const history = useHistory()
  const { invitation } = useStore()
  const { cards, inbox } = useActions()
  const { deleteInvitation } = useActions().invitation
  const { updateLicenseData } = useActions().license
  const profile = useStore().profile
  const license = useStore().license
  const { updateAccessory } = useActions().accessories
  const { showModal } = useActions().modals
  const { isActive, isTrialActive } = licenseStatus(license)

  useEffect(() => {
    if (!isActive && !isTrialActive && !license.hideTrialEndedModal) {
      updateLicenseData({
        hideTrialEndedModal: true,
      })
      showModal({
        name: 'TRIAL_ENDED',
        title:
          'Your complimentary 7-day full access to the Premium Plan has ended. Upgrade your pages to maintain access to these features and more.',
      })
    }
    if (invitation) {
      deleteInvitation(invitation)
    }
    startListeningForCards({
      profile,
      license,
      updateCards: cards.updateCards,
      addCard: cards.addCardToAccount,
      updateAccessory,
      history,
    })
    startListeningForInboxChanges({
      profile,
      updateInboxCards: inbox.updateInboxCards,
      license,
    })
  }, [])

  if (!license) return <PageLoader />

  return (
    <>
      <SideMenu />
      <Container id="app-container">
        <Switch>
          <Route path="/templates/:template_id" component={TemplatePreview} />
          <Route path="/templates" component={Templates} />
          <Route path="/company" component={Company} />
          <Route path="/billing" component={Billing} />
          <Route path="/referrals" component={Referrals} />
          <Route path="/tags-manager" component={TagsManager} />
          <Route path="/accessories" component={AccessoriesPage} />
          <Route
            path="/contacts/:card_id/:response_id"
            component={InboxCardDetails}
          />
          <Route path="/:card_id/edit" component={EditCard} />
          <Route path="/:template_id/edit/template" component={EditCard} />
          <Route path="/:card_id/report" component={CardSection} />
          <Route path="/" component={Cards} />
        </Switch>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  margin-left: 200px;
  height: 100%;
  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    margin-left: 64px;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin-left: 0;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`
