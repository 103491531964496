import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../../core/theme'
import Checkbox from '../Checkbox'
import Button from '../Button'
import { validateEmail } from '../../helpers/validators/validateEmail'
import { Column, Row } from '../Layout'
import { Label } from '../Typography'
import FloatingInput from '../FloatingInput'

const EmailNotifications = ({ card, updateCard, section }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [recipientInput, setInput] = useState('')
  const [error, setError] = useState('')
  const targetObject = section || card
  const {
    email = {},
    sendEmailToUser,
    customSubjectEmailToUser,
    customMessageEmailToUser,
  } = targetObject

  const hasCustomUserEmail = customMessageEmailToUser !== undefined

  const isSending = email.sendEmail !== false
  const recipients = email.recipients
    ? email.recipients
    : card.creatorEmail
    ? [card.creatorEmail]
    : []

  const changeSendingStatus = (value) => () => {
    if (value !== isSending) {
      const emailConfig = targetObject.email || {}
      emailConfig.sendEmail = value
      targetObject.email = emailConfig
      updateCard(card)
    }
  }

  const changeSendingToUserStatus = (value) => () => {
    targetObject.sendEmailToUser = value

    if (!value) {
      delete targetObject.customSubjectEmailToUser
      delete targetObject.customMessageEmailToUser
      delete targetObject.customEmailFrom
    }

    updateCard(card)
  }

  const changeCustomUserEmailStatus = (value) => () => {
    if (value) {
      targetObject.customMessageEmailToUser =
        'Hi, thank you for reaching out to me.'
    } else {
      delete targetObject.customSubjectEmailToUser
      delete targetObject.customMessageEmailToUser
      delete targetObject.customEmailFrom
    }

    updateCard(card)
  }

  const removeRecipient = (recipient) => () => {
    const newRecipients = recipients.filter((r) => r !== recipient)
    const emailConfig = targetObject.email || {}
    emailConfig.recipients = newRecipients
    targetObject.email = emailConfig
    updateCard(card)
  }

  const addRecipient = () => {
    if (!recipientInput) {
      setError('Please enter recipient.')
      return
    }

    const recipient = recipientInput.replace(/\s/g, '')

    if (!validateEmail(recipient)) {
      setError('Invalid email format.')
      return
    }

    if (recipients.includes(recipient)) {
      setError('Recipient already added.')
      return
    }

    if (recipients.length >= 10) {
      setError('Recipients limit reached.')
      return
    }

    const newRecipients = [...recipients, recipient]
    const emailConfig = targetObject.email || {}
    emailConfig.recipients = newRecipients
    targetObject.email = emailConfig
    updateCard(card)
    setInput('')
  }

  const updateInput = (e) => {
    if (error) setError('')
    setInput(e.target.value)
  }

  return (
    <Container>
      <Label padding="8px 0" weight="medium">
        Send email notifications
      </Label>

      <Checkbox
        value={!isSending}
        onChange={changeSendingStatus(false)}
        label="No"
        radio
      />
      <Checkbox
        value={isSending}
        onChange={changeSendingStatus(true)}
        label="Yes"
        radio
      />

      {isSending && (
        <Wrapper>
          <Label
            weight="medium"
            lineHeight="medium"
            style={{ marginBottom: 8, padding: '0 4px' }}
          >
            Recipients
          </Label>
          <Row align="flex-start" style={{ marginBottom: 8 }}>
            <FloatingInput
              value={recipientInput}
              onChange={updateInput}
              error={error}
              placeholder="Enter new recipient"
              style={{ marginBottom: 0 }}
            />
            <Button
              onClick={addRecipient}
              style={{ margin: '0 0 0 12px', height: 46 }}
            >
              {isMobile ? '+' : 'Add Recipient'}
            </Button>
          </Row>
          <Column>
            {recipients.map((rec) => (
              <RecipientContainer key={rec}>
                <Label>{rec}</Label>
                <Remove onClick={removeRecipient(rec)}>remove</Remove>
              </RecipientContainer>
            ))}
          </Column>
        </Wrapper>
      )}

      {isSending && (
        <FloatingInput
          label="Email subject"
          placeholder={'Contact Form Completed: ' + card.label}
          value={targetObject.customEmailSubject}
          style={{ marginTop: 20, marginBottom: 8 }}
          onChange={(e) => {
            targetObject.customEmailSubject = e.target.value
            updateCard(card)
          }}
        />
      )}

      <Divider />

      <Label padding="8px 0" weight="medium">
        Send an email the to person that completed the contact form
      </Label>
      <CheckboxContainer>
        <Checkbox
          value={sendEmailToUser !== true}
          onChange={changeSendingToUserStatus(false)}
          label="No"
          radio
        />
        <Checkbox
          value={sendEmailToUser === true}
          onChange={changeSendingToUserStatus(true)}
          label="Yes"
          radio
        />
      </CheckboxContainer>

      {sendEmailToUser && (
        <>
          <Label margin="16px 0 0 0" padding="8px 0" weight="medium">
            What should the email include?
          </Label>
          <CheckboxContainer>
            <Checkbox
              value={!hasCustomUserEmail}
              onChange={changeCustomUserEmailStatus(false)}
              label="All answered questions"
              radio
            />
            <Checkbox
              value={hasCustomUserEmail}
              onChange={changeCustomUserEmailStatus(true)}
              label="Custom message"
              radio
            />
          </CheckboxContainer>
          <FloatingInput
            label="Email subject"
            placeholder={'You completed: ' + card.label}
            value={customSubjectEmailToUser}
            style={{ marginTop: 20 }}
            onChange={(e) => {
              targetObject.customSubjectEmailToUser = e.target.value
              updateCard(card)
            }}
          />
        </>
      )}
      {hasCustomUserEmail && (
        <>
          <FloatingInput
            multiline
            label="Email message *"
            description="Can be plain text or HTML."
            value={customMessageEmailToUser}
            style={{ marginTop: 8 }}
            onChange={(e) => {
              targetObject.customMessageEmailToUser = e.target.value
              updateCard(card)
            }}
          />
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 12px 24px;
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 0 20px 12px 20px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`

const RecipientContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.border};
  padding: 4px;
  margin-top: 12px;
  margin-bottom: 8px;
  box-sizing: border-box;
  line-height: 2;
`

const Remove = styled.span`
  color: ${colors.secondary};
  font-size: 13px;
  cursor: pointer;
  :hover {
    color: ${colors.red};
  }
  :active {
    opacity: 0.7;
  }
`

const Divider = styled.div`
  width: 100%;
  background-color: ${colors.border};
  height: 1px;
  margin: 16px 0;
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export default EmailNotifications
