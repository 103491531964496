import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { Dropdown, DropdownList } from './Dropdown'
import DatePicker from './DatePicker'
import { EditIcon, ArrowDown } from '../assets/icons'
import { Caption, Label } from './Typography'
import { TopBar } from './TopBar'

const FiltersHeader = ({ dateFilter, updateDateFilter, total, filters }) => {
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  const onCustomRangeSelected = ({ from, to, fromLabel, toLabel }) => {
    const fromDate = new Date(from)
    const toDate = new Date(to)
    fromDate.setHours(0)
    toDate.setHours(23)
    updateDateFilter({
      label: fromLabel + ' - ' + toLabel,
      from: fromDate.getTime(),
      to: toDate.getTime(),
    })
  }

  return (
    <TopBar
      style={{
        justifyContent: 'space-between',
        minHeight: 38,
        maxHeight: 38,
        fontWeight: '400',
      }}
    >
      <Dropdown
        isVisible={isMenuVisible}
        placement="bottom-start"
        onClose={hideMenu}
        triggerRenderer={({ ref }) => (
          <Filter onClick={showMenu} ref={ref}>
            {dateFilter.label} <ArrowDown color={colors.darkgrey} size={16} />
          </Filter>
        )}
      >
        <DropdownList
          onClose={hideMenu}
          items={filters.map((filter) => ({
            content: filter.label,
            isChecked: dateFilter.label === filter.label,
            radio: true,
            onClick: () => {
              if (dateFilter.label !== filter.label) {
                updateDateFilter({
                  label: filter.label,
                  from: filter.getValue(),
                })
              }
            },
          }))}
        />
        <DatePicker
          onRangeSelect={onCustomRangeSelected}
          disabledDays={{ after: new Date() }}
          triggerRenderer={
            <CustomRange>
              <EditIcon size={14} />
              <Label padding="0 15px">Custom range</Label>
            </CustomRange>
          }
        />
      </Dropdown>
      {total >= 0 && (
        <Caption>
          Total: <strong style={{ color: colors.main }}>{total}</strong>
        </Caption>
      )}
    </TopBar>
  )
}

const Filter = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${colors.darkgrey};
  font-size: 13px;
  font-weight: 400;
`

const CustomRange = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 12px 12px 18px 20px;
  :hover {
    background-color: ${colors.hover};
  }
  @media (max-width: 767px) {
    display: none;
  }
`

export default FiltersHeader
