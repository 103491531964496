import React from 'react'
import styled from 'styled-components'
import DropdownListItem from './DropdownListItem'

const DropdownList = ({
  items,
  onItemSelect,
  onClose,
  multiSelect,
  style,
  className,
}) => (
  <Container style={style} className={className}>
    {items.map(
      ({ content, item, ...itemRestProps }, index) =>
        item || (
          <DropdownListItem
            multiSelect={multiSelect}
            content={content}
            key={`${content}-${index}`}
            onItemSelect={onItemSelect}
            onClose={onClose}
            itemId={index}
            {...itemRestProps}
          />
        )
    )}
  </Container>
)

const Container = styled.ul`
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  &:focus {
    outline: none;
  }
`

export default DropdownList
