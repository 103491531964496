export function sortCountsInArray(array) {
  const length = array.length
  for (let i = 0; i < length; i++) {
    for (let j = 0; j < length - i - 1; j++) {
      const number = array[j].count || 0
      const number2 = array[j + 1].count || 0
      if (number < number2) {
        const tmp = Object.assign(array[j])
        array[j] = array[j + 1]
        array[j + 1] = tmp
      }
    }
  }
  return array
}
