import {
  getLicense,
  updateLicense,
  findUser,
  updateUserProfile,
  updateConnectedPageData,
  markTemplateAsDeleted,
} from '../api/database-api'
import {
  cancelLicenseSubscription,
  updateLicenseSubscription,
} from '../api/billing-api'
import { generateRandomString } from '../helpers/generateRandomString'
import { INITIAL_FOLDERS } from '../core/constants'
import { CARDS_TEMPLATES } from '../core/templates/cards-templates'

const INITIAL_STATE = null

const updateLicenseData = async ({ state }, updatedLicense) => {
  const { license } = state
  if (!updatedLicense.license) {
    updatedLicense.license = license.license
  }
  await updateLicense(updatedLicense)
  state.license = { ...license, ...updatedLicense }
}

const getLicenseData = async ({ state, actions }) => {
  const profile = state.profile
  const licenseData = await getLicense(profile)
  if (!licenseData.folders) {
    licenseData.folders = INITIAL_FOLDERS
    actions.license.updateLicenseData({
      folders: licenseData.folders,
      license: licenseData.license,
    })
  }
  state.license = licenseData
}

const addFolderToLicense = async ({ state, actions }, folder) => {
  const { license } = state
  const newFolder = {
    name: folder,
    cards: [],
    createdTimestamp: new Date().getTime(),
  }

  if (license.folders.filter((g) => g.name === folder).length > 0) {
    throw new Error('Folder already exists.')
  }

  const updatedLicense = {
    ...license,
    folders: [...license.folders, newFolder],
  }

  await actions.license.updateLicenseData({ folders: updatedLicense.folders })
  return newFolder
}

const removeFolder = async ({ state, actions }, folder) => {
  const { license } = state
  const updatedLicense = {
    ...license,
    folders: license.folders.filter((f) => f.name !== folder.name),
  }
  await actions.license.updateLicenseData({ folders: updatedLicense.folders })
}

const updateFolder = async (
  { state, actions },
  { updatedFolderName, oldFolderName }
) => {
  const { license } = state
  const updatedLicense = {
    ...license,
    folders: license.folders.map((folder) => {
      if (folder.name === oldFolderName) {
        folder.name = updatedFolderName
        return folder
      }
      return folder
    }),
  }
  await actions.license.updateLicenseData({ folders: updatedLicense.folders })
  actions.cards.changeFolder(
    updatedLicense.folders.filter((f) => f.name === updatedFolderName)[0]
  )
}

const removeEmployee = async ({ state, actions }, employee) => {
  const { license } = state
  const { email, uid } = employee
  const users = { ...license.users } || {}
  delete users[email]

  employee.removedFromLicense = employee.license
  employee.removedTimestamp = new Date().getTime()
  employee.permission = null
  if (employee.allowedCards) {
    employee.allowedCards = null
  }
  if (employee.allowedFolders) {
    employee.allowedFolders = null
  }

  if (employee.oldLicense) {
    employee.license = employee.oldLicense
  } else {
    employee.license = generateRandomString(6)
  }

  if (!uid) {
    const foundUser = await findUser(email)
    if (foundUser && foundUser.uid) {
      employee.uid = foundUser.uid
    }
  }

  updateUserProfile(employee)
  await actions.license.updateLicenseData({ users })
}

const changeEmployeePermission = async (
  { state, actions },
  { employee, permission, allowedCards = null, allowedFolders = null }
) => {
  const { license } = state
  const { email } = employee
  const updatedLicense = {
    ...license,
    users: {
      ...license.users,
      [email]: {
        ...license.users[email],
        permission,
        allowedCards,
        allowedFolders,
      },
    },
  }
  return actions.license.updateLicenseData({ users: updatedLicense.users })
}

const updateSubscription = async ({ state, actions }, name) => {
  const { license, profile } = state
  await updateLicenseSubscription({
    license: profile.license,
    customerEmail: profile.email,
    customerId: license.customerId,
    subscriptionId: license.subscriptionId,
    subscriptionName: name,
    subscriptionCreatedTimestamp: license.subscriptionCreatedTimestamp,
  })
  await actions.license.getLicenseData()
}

const cancelSubscription = async ({ state, actions }) => {
  const { license, profile } = state
  const { email } = profile
  const { customerId, customerEmail, subscriptionName, subscriptionId } =
    license
  const subscription = {
    customerId,
    customerEmail,
    subscriptionName,
    subscriptionId,
  }
  await cancelLicenseSubscription(subscription, profile.license, email)
  await actions.license.getLicenseData()
}

const removeTagFromLicense = ({ state, actions }, tag) => {
  const { license } = state
  const { label } = tag
  const licenseTags = license.tags || []
  const updatedLicense = {
    ...license,
    tags: licenseTags.filter((t) => t.label !== label),
  }
  return actions.license.updateLicenseData({ tags: updatedLicense.tags })
}

const updateLicenseTags = ({ state, actions }, { tag, isEditing }) => {
  const { license } = state
  const tagNames = license.tags.map((t) => t.label)
  const { label, description, color } = tag
  const updatedLicense = { ...license }

  if (isEditing) {
    const foundIndex = tagNames.indexOf(tag.oldLabel)
    updatedLicense.tags[foundIndex] = {
      label,
      description,
      color,
    }
  } else {
    updatedLicense.tags.push({
      label,
      description,
      color,
    })
  }

  return actions.license.updateLicenseData({ tags: updatedLicense.tags })
}

function createTemplateFromCard({ state, actions }, template) {
  const { license } = state
  const { customTemplatesLimit = 50 } = license
  const updatedLicense = { ...license }
  const templates = updatedLicense.templates || []
  if (templates.length > customTemplatesLimit) {
    throw new Error(
      'Custom templates limit reached. Please contact our support.'
    )
  }
  if (templates.some((t) => t.label === template.label)) {
    throw new Error('Template with given name already exists.')
  }
  if (CARDS_TEMPLATES.some((t) => t.label === template.label)) {
    throw new Error('Template with given name already exists.')
  }
  templates.unshift(template)
  updatedLicense.templates = templates
  return actions.license.updateLicenseData({
    templates: updatedLicense.templates,
  })
}

function updateTemplate({ state, actions }, template) {
  const { license } = state
  const updatedLicense = { ...license }
  const templates = updatedLicense.templates || []
  updatedLicense.templates = templates.map((t) => {
    if (t.label === template.label) {
      return template
    }
    return t
  })
  return actions.license.updateLicenseData({
    templates: updatedLicense.templates,
  })
}

function updateConnectedPages({ state }, { templateId, difference }) {
  const { cards } = state
  if (!templateId) return

  if (Object.keys(difference).length === 0) return

  const connectedCards = cards.cards.filter((c) => c.templateId === templateId)
  if (connectedCards.length === 0) return

  connectedCards.forEach(({ cardID, license }) => {
    updateConnectedPageData({ ...difference, cardID, license })
  })
}

function renameTemplate({ state, actions }, { template, value }) {
  const { license } = state
  const updatedLicense = { ...license }
  const templates = updatedLicense.templates || []
  if (!value) {
    throw new Error("Template name can't be empty.")
  }
  if (templates.some((t) => t.label === value)) {
    throw new Error('Template with given name already exists.')
  }
  if (CARDS_TEMPLATES.some((t) => t.label === value)) {
    throw new Error('Template with given name already exists.')
  }
  updatedLicense.templates = templates.map((t) => {
    if (t.label === template.label) {
      t.label = value
      return t
    }
    return t
  })
  updatedLicense.templates = templates
  return actions.license.updateLicenseData({
    templates: updatedLicense.templates,
  })
}

async function deleteTemplate({ state, actions }, template) {
  const { license } = state
  const updatedLicense = { ...license }
  const templates = updatedLicense.templates || []
  updatedLicense.templates = templates.filter((t) => t.label !== template.label)
  await actions.license.updateLicenseData({
    templates: updatedLicense.templates,
  })
  markTemplateAsDeleted(template, license.license)
  return updatedLicense.templates
}

async function setCardForSlot({ state, actions }, { card, slot }) {
  const { license } = state
  const updatedLicense = { ...license }
  const cardSlots = updatedLicense.cardSlots || []
  const foundSlot = cardSlots.find((s) => s.id === slot.id)
  if (!foundSlot) {
    throw new Error('Slot was not found. Please contact our support.')
  }
  if (foundSlot) {
    foundSlot.cardID = card.cardID
  }
  updatedLicense.cardSlots = cardSlots
  await actions.license.updateLicenseData({
    cardSlots: updatedLicense.cardSlots,
  })
}

const clear = ({ state }) => {
  state.license = INITIAL_STATE
}

export default {
  state: INITIAL_STATE,
  actions: {
    getLicenseData,
    updateLicenseData,
    addFolderToLicense,
    removeFolder,
    removeEmployee,
    changeEmployeePermission,
    updateSubscription,
    cancelSubscription,
    removeTagFromLicense,
    updateLicenseTags,
    updateFolder,
    createTemplateFromCard,
    deleteTemplate,
    setCardForSlot,
    clear,
    renameTemplate,
    updateTemplate,
    updateConnectedPages,
  },
}
