import { getLastMonthTime, getLastWeekTime } from '../helpers/days'
import { updateInboxCardData } from '../api/database-api'
import { deepCloneObject } from '../helpers/deepCloneObject'

const INITIAL_STATE = {
  initialLoading: true,
  inboxCards: {},
  dateFilter: {
    label: 'Last month',
    from: getLastMonthTime(),
  },
  cardDateFilter: {
    label: 'Last week',
    from: getLastWeekTime(),
  },
  tagsFilter: {},
}

const updateInboxCards = ({ state }, { inboxCards, dateFilter }) => {
  state.inbox = {
    ...state.inbox,
    inboxCards,
    dateFilter,
    initialLoading: false,
  }
}

const updateDateFilter = ({ state }, filters) => {
  state.inbox.cardDateFilter = filters
}

const updateFilters = ({ state }, filters) => {
  state.inbox = {
    ...state.inbox,
    dateFilter: filters,
  }
}

const setTagsFilter = ({ state }, filters) => {
  state.inbox = {
    ...state.inbox,
    tagsFilter: filters,
  }
}

const markAllContactsAsRead = ({ state }, cards) => {
  const { profile, inbox } = state
  const unseenCards = cards.filter((f) => !f.seen && f.completed)
  const inboxCards = inbox.inboxCards
  for (let i = 0; i < unseenCards.length; i++) {
    const card = inboxCards[unseenCards[i].cardID].filter(
      (f) => f.id === unseenCards[i].id
    )[0]
    if (card) {
      card.seen = true
      card.lastSeenUpdate = {
        updatedBy: profile.name || '',
        email: profile.email || '',
        timestamp: new Date().getTime(),
      }
      updateInboxCardData(card)
    }
  }
  state.inbox.inboxCards = { ...inboxCards }
}

const updateCardSeenStatus = ({ state }, { cardID, id }) => {
  const { profile, inbox } = state

  if (!inbox.inboxCards[cardID]) return false

  const card = inbox.inboxCards[cardID].filter((f) => f.id === id)[0]

  card.seen = true
  card.lastSeenUpdate = {
    updatedBy: profile.name || '',
    email: profile.email || '',
    timestamp: new Date().getTime(),
  }

  return updateInboxCardData(card)
}

const updateCardTags = ({ state }, { card, tags }) => {
  const { inboxCards } = state.inbox
  updateInboxCardData({ ...card, tags })
  const currentCards = inboxCards[card.cardID].map((f) => {
    if (f.id === card.id) {
      return {
        ...f,
        tags,
      }
    }
    return f
  })

  state.inbox = {
    ...state.inbox,
    inboxCards: { ...inboxCards, [card.cardID]: currentCards },
  }
}

const clear = ({ state }) => {
  state.inbox = deepCloneObject(INITIAL_STATE)
}

export default {
  state: INITIAL_STATE,
  actions: {
    updateInboxCards,
    updateFilters,
    updateDateFilter,
    updateCardSeenStatus,
    markAllContactsAsRead,
    updateCardTags,
    setTagsFilter,
    clear,
  },
}
