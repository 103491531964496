import React from 'react'
import styled from 'styled-components'
import { ArrowRight } from '../assets/icons'
import Button from '../components/Button'
import { colors } from '../core/theme'
import BaseModal from './BaseModal'

export default function HowToVideoModal({ onClose, title, video, onNext }) {
  const onModalClose = () => {
    if (onNext) onNext()
    onClose()
  }

  return (
    <BaseModal
      title={title}
      onClose={onModalClose}
      actions={
        <>
          <Button
            onClick={onModalClose}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Continue
          </Button>
        </>
      }
    >
      <Container>
        <Video
          width="100%"
          src={video}
          title={title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 24px 32px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 20px 16px;
  }
`

const Video = styled.iframe`
  width: 100%;
  aspect-ratio: 16 / 9;
`
