import React from 'react'
import styled from 'styled-components'
import SummaryCard from '../../components/ReportCards/SummaryCard'
import ActivityCard from '../../components/ReportCards/ActivityCard'
import DevicesCard from '../../components/ReportCards/DevicesCard'
import PlatformsCard from '../../components/ReportCards/PlatformsCard'
import BrowsersCard from '../../components/ReportCards/BrowsersCard'
import LocationsCard from '../../components/ReportCards/LocationsCard'
import InteractionsCard from '../../components/ReportCards/InteractionsCard'
import { Label } from '../../components/Typography'
import PageLoader from '../../components/PageLoader'
import {
  getLastWeekTime,
  getLastMonthTime,
  getLastQuarterTime,
} from '../../helpers/days'
import FiltersHeader from '../../components/FiltersHeader'

const Insights = ({ report, dateFilter, updateDateFilter, error }) => {
  return (
    <>
      <FiltersHeader
        dateFilter={dateFilter}
        updateDateFilter={updateDateFilter}
        filters={[
          {
            label: 'Last week',
            getValue: getLastWeekTime,
          },
          {
            label: 'Last month',
            getValue: getLastMonthTime,
          },
          {
            label: 'Last quarter',
            getValue: getLastQuarterTime,
          },
        ]}
      />
      {error && (
        <Label
          align="center"
          padding="16px"
          lineHeight="medium"
          variant="error"
        >
          {error}
        </Label>
      )}
      {!report && !error && <PageLoader />}
      {report && (
        <Container>
          <Wrapper id="report-content">
            <SummaryCard data={report.insights.summary} />
            <CardsContainer>
              <ActivityCard data={report.insights.activity} />
              <InteractionsCard data={report.insights.interactions} />
              <DevicesCard data={report.insights.devices} />
              <PlatformsCard data={report.insights.platforms} />
              <BrowsersCard data={report.insights.browsers} />
              <LocationsCard data={report.insights.locations} />
            </CardsContainer>
          </Wrapper>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  box-sizing: border-box;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    padding: 16px;
  }
  @media (max-width: 767px) {
    padding: 8px 16px;
    width: 100%;
  }
`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
  }
`

export default Insights
