import React from 'react'
import { AreaChart, Area, ResponsiveContainer, Tooltip, YAxis } from 'recharts'
import styled from 'styled-components'
import { PIE_CHART_COLORS } from '../core/constants'
import { colors } from '../core/theme'

const EMPTY_CHART_DATA = [
  { value: 2 },
  { value: 3 },
  { value: 2 },
  { value: 5 },
  { value: 3 },
  { value: 3 },
  { value: 1 },
]

const CardChart = ({ height, data, card }) => {
  const highestRecord = Math.max(...data.map((i) => i.count || 0))
  const isChartEmpty = highestRecord <= 0

  if (isChartEmpty) {
    return (
      <ResponsiveContainer width="100%" height={height}>
        <AreaChart
          data={EMPTY_CHART_DATA}
          margin={{ top: 5, right: 6, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient
              id={`areaFill-${card.cardID}`}
              x1="0"
              y1="0"
              x2="0"
              y2="1"
            >
              <stop offset="10%" stopColor={colors.border} stopOpacity={0.7} />
              <stop offset="90%" stopColor={colors.border} stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <YAxis hide type="number" domain={[0, 6]} />
          <Area
            type="monotone"
            dataKey="value"
            name="Received"
            stroke={colors.border}
            fill={`url(#areaFill-${card.cardID})`}
          />
          <Tooltip
            content={() => <EmptyTooltip>No data to show, yet.</EmptyTooltip>}
          />
        </AreaChart>
      </ResponsiveContainer>
    )
  }

  return (
    <ResponsiveContainer width="100%" height={height}>
      <AreaChart data={data} margin={{ top: 5, right: 6, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="areaFill-Visits" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="10%"
              stopColor={PIE_CHART_COLORS[0]}
              stopOpacity={0.8}
            />
            <stop
              offset="90%"
              stopColor={PIE_CHART_COLORS[0]}
              stopOpacity={0.5}
            />
          </linearGradient>
        </defs>
        <defs>
          <linearGradient
            id="areaFill-Interactions"
            x1="0"
            y1="0"
            x2="0"
            y2="1"
          >
            <stop
              offset="10%"
              stopColor={PIE_CHART_COLORS[1]}
              stopOpacity={0.8}
            />
            <stop
              offset="90%"
              stopColor={PIE_CHART_COLORS[1]}
              stopOpacity={0.5}
            />
          </linearGradient>
        </defs>
        <YAxis hide type="number" domain={[0, highestRecord * 1.25]} />
        <Area
          type="monotone"
          dataKey="Visits"
          name="Visits"
          stroke={PIE_CHART_COLORS[0]}
          fill="url(#areaFill-Visits)"
        />
        <Area
          type="monotone"
          dataKey="Interactions"
          name="Interactions"
          stroke={PIE_CHART_COLORS[1]}
          fill="url(#areaFill-Interactions)"
        />
        <Tooltip labelFormatter={(x) => (data[x] ? data[x].name : '')} />
      </AreaChart>
    </ResponsiveContainer>
  )
}

const EmptyTooltip = styled.span`
  font-size: 14px;
  color: ${colors.secondary};
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  padding: 8px;
`

export default CardChart
