import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors } from '../../core/theme'
import Checkbox from '../Checkbox'
import { CheckIcon } from '../../assets/icons'

function isBoolean(val) {
  return val === false || val === true
}

const DropdownListItem = (props) => {
  const {
    icon,
    content,
    isSelected,
    isMarked,
    isChecked,
    isDisabled,
    onItemSelect,
    onClose,
    onClick,
    multiSelect,
    color,
    divider,
    radio,
    href,
  } = props

  const handleClick = (e) => {
    if (!onItemSelect && href) {
      onClose()
      return
    }
    e.preventDefault()
    e.stopPropagation()

    if (!isDisabled && onItemSelect) {
      onItemSelect(props)
    }
    if (onClick) {
      onClick(e)
    }
    if (onClose && !multiSelect) {
      onClose()
    }
  }

  const Children = (
    <Container
      isDisabled={isDisabled}
      isMarked={isMarked}
      onClick={handleClick}
      color={color}
      divider={divider}
    >
      {isBoolean(isChecked) && (
        <Checkbox
          name={content}
          label={content}
          value={isChecked}
          style={{ margin: '0' }}
          boxStyle={{ marginRight: 14 }}
          radio={radio}
        />
      )}
      {icon && <Icon>{icon}</Icon>}
      {!isBoolean(isChecked) && <Title>{content}</Title>}
      {isSelected && <SelectedIcon color={colors.main} />}
    </Container>
  )

  if (href) {
    return <NavLink to={href}>{Children}</NavLink>
  }

  return Children
}

const Container = styled.li`
  list-style: none;
  padding: 17px 20px;
  flex-shrink: 0;
  width: 100%;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  line-height: 1.3;
  font-size: 13.5px;
  color: ${(props) => props.color || colors.primary};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-radius: 6px;
  border-bottom: ${(props) =>
    props.divider ? `1px solid ${colors.border}` : 'none'};
  background-color: ${(props) =>
    props.isMarked ? `${colors.blue}55` : 'transparent'};
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
  :hover {
    background-color: ${colors.hover};
  }
`

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
  font-weight: 500;
`

const Icon = styled.div`
  margin-right: 14px;
  height: 18px;
`

const SelectedIcon = styled(CheckIcon)`
  transition: all 0.15s ease-in-out;
  margin-left: auto;
  padding-left: 5px;
`

export default DropdownListItem
