import styled from 'styled-components'
import { colors } from '../../core/theme'

const Tab = styled.button`
  position: relative;
  display: inline-block;
  flex-direction: row;
  align-items: center;
  padding: 0 8px;
  margin: 0 12px;
  height: 50px;
  border: none;
  box-sizing: border-box;
  background: none;
  outline: none;
  color: ${(props) => (props.isSelected ? colors.primary : colors.secondary)};
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  white-space: nowrap;
  font-weight: ${({ isSelected }) => (isSelected ? '500' : '400')};

  &::before {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    content: '';
    transition: background-color 100ms ease-out;
    background-color: ${(props) =>
      props.isSelected ? colors.main : 'transparent'};
    border-radius: 6px;
    height: 3px;
  }
`

export default Tab
