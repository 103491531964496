import { getUserInvitation, sendInvitationToUser } from '../api/database-api'
import { generateRandomString } from '../helpers/generateRandomString'

const INITIAL_STATE = null

const getInvitation = async ({ state }, id) => {
  const invitation = await getUserInvitation(id)
  state.invitation = invitation
  return invitation
}

const createInvitation = async (
  { state, actions },
  { email, permission, allowedCards, allowedFolders }
) => {
  const { license, profile } = state
  const invitationID = window
    .btoa(profile.license + '-' + generateRandomString(8))
    .replace(/=/g, '')

  const invitation = {
    email,
    permission,
    invitedBy: profile,
    license: profile.license,
    invitedTimestamp: new Date().getTime(),
    id: invitationID,
  }

  if (allowedCards) {
    invitation.allowedCards = allowedCards
  }

  if (allowedFolders) {
    invitation.allowedFolders = allowedFolders
  }

  await sendInvitationToUser(invitation)
  actions.license.updateLicenseData({
    ...license,
    invitations: { ...license.invitations, [invitationID]: invitation },
  })
}

const deleteInvitation = ({ state, actions }, invitation) => {
  const { license } = state
  const invitations = license.invitations || {}
  delete invitations[invitation.id]
  actions.license.updateLicenseData({ ...license, invitations })
  state.invitation = INITIAL_STATE
}

const clear = ({ state }) => {
  state.invitation = INITIAL_STATE
}

export default {
  state: INITIAL_STATE,
  actions: {
    getInvitation,
    createInvitation,
    deleteInvitation,
    clear,
  },
}
