import { colors } from '../../core/theme'

export function returnFontColor(color) {
  if (color === 'secondary') return colors.secondary
  if (color === 'tertiary') return colors.tertiary
  if (color === 'disabled') return colors.disabled
  if (color === 'light') return colors.white
  if (color === 'error') return colors.error
  if (color === 'success') return colors.success
  if (color === 'warning') return colors.warning
  if (color === 'primary') return colors.main
  if (color === 'grey') return colors.grey
  return colors.primary
}

export function returnFontWeight(weight) {
  if (weight === 'bold') return 'bold'
  if (weight === '600') return '600'
  if (weight === 'medium') return '500'
  return 'initial'
}

export function returnLineHeight(lineHeight) {
  if (lineHeight === 'big') return 1.75
  if (lineHeight === 'medium') return 1.5
  if (lineHeight === 'small') return 1.25
  return 'initial'
}
