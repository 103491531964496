import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions, useStore } from '../store'
import { DeleteIcon } from '../assets/icons'
import { updateLicenseSubscription } from '../api/billing-api'

export default function DeleteSlotModal({ onClose, slot }) {
  const { showToast } = useActions().toasts
  const { getLicenseData } = useActions().license
  const { license, profile } = useStore()
  const [isCanceling, setCancelStatus] = useState(false)

  const deleteSlot = async () => {
    const cardSlots = license.cardSlots || []
    if (cardSlots.length <= 1) {
      showToast({
        content: 'You must have at least one active premium page.',
        duration: 4000,
      })
      return
    }

    if (isCanceling) return
    setCancelStatus(true)
    try {
      const newCardSlots = license.cardSlots.filter((s) => s.id !== slot.id)
      await updateLicenseSubscription({
        customerEmail: profile.email,
        license: profile.license,
        customerId: license.customerId,
        subscriptionName: license.subscriptionName,
        subscriptionType: license.subscriptionType,
        subscriptionId: license.subscriptionId,
        cardSlots: newCardSlots,
      })
      await getLicenseData()
      showToast({
        content: 'Premium page deleted successfully.',
        variant: 'success',
      })
    } catch (error) {
      console.error(error)
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
    setCancelStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DeleteIcon size={60} color={colors.error} />}
      header="Delete premium page?"
      description="You're about to delete this premium page. You will no longer be charged for it but the page assigned to it will be moved to a free plan. Are you sure you want to continue?"
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={deleteSlot}
            loading={isCanceling}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, delete
          </Button>
        </>
      }
    />
  )
}
