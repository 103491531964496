import React, { useState } from 'react'
import styled from 'styled-components'
import {
  DeleteIcon,
  EditTextIcon,
  MenuIcon,
  YouTubeIcon,
} from '../assets/icons'
import { config } from '../core/config'
import { ACCESSORIES_STYLES } from '../core/constants'
import { colors } from '../core/theme'
import EditAccessoryModal from '../modals/EditAccessoryModal'
import { useActions, useStore } from '../store'
import { Dropdown, DropdownList } from './Dropdown'
import { Row } from './Layout'

export default function AccessoryBlock({
  accessory,
  cards,
  allowedCards,
  isMobile,
}) {
  const {
    color = 'dark',
    type = 'card',
    activatedTimestamp,
    cardID,
    uid,
  } = accessory
  const { license } = useStore()
  const licenseUsers = Object.values(license.users)
  const { showModal } = useActions().modals
  const { image } = ACCESSORIES_STYLES[type][color]
  const foundCard = cards.find((c) => c.cardID === cardID) || {}
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  if (allowedCards && !accessory.id) {
    return null
  }

  if (!foundCard.cardID && accessory.cardID && allowedCards) {
    return null
  }

  const foundUser = licenseUsers.find((u) => u.uid === uid) || {
    name: '-',
  }

  window[`konectAccessoryShowModal-${accessory.id}`] = setShowEdit

  return (
    <>
      <Container>
        <Row flex="1" align="center">
          <AccessoryImage src={image} alt="" />
        </Row>
        {!isMobile && (
          <Row flex="1" align="center">
            <AccessoryLabel>{foundUser.name || foundUser.email}</AccessoryLabel>
          </Row>
        )}
        <Row flex="1" align="center">
          <AccessoryLabel>{foundCard.label || '-'}</AccessoryLabel>
        </Row>
        {!isMobile && (
          <Row flex="1" align="center">
            <AccessoryLabel>
              {activatedTimestamp
                ? new Date(activatedTimestamp).toLocaleDateString()
                : 'Not activated'}
            </AccessoryLabel>
          </Row>
        )}
        <Dropdown
          isVisible={isMenuVisible}
          placement="bottom-end"
          onClose={hideMenu}
          triggerRenderer={({ ref }) => (
            <MenuContainer onClick={showMenu} ref={ref}>
              <MenuIcon size={24} />
            </MenuContainer>
          )}
        >
          <DropdownList
            onClose={hideMenu}
            items={[
              {
                content: `Edit`,
                onItemSelect: () => {
                  setShowEdit(true)
                  hideMenu()
                },
                icon: <EditTextIcon size={18} />,
              },
              {
                content: `How to use?`,
                onItemSelect: () => {
                  if (accessory.type === 'card') {
                    showModal({
                      name: 'HOW_TO_VIDEO',
                      title: 'How to share your Konect Card',
                      video: config.HOW_TO_SHARE_CARD,
                    })
                  }
                  if (accessory.type === 'tag') {
                    showModal({
                      name: 'HOW_TO_VIDEO',
                      title: 'How to share your Konect Tag',
                      video: config.HOW_TO_SHARE_TAG,
                    })
                  }
                  hideMenu()
                },
                icon: <YouTubeIcon size={18} />,
              },
              {
                content: `Delete`,
                color: colors.error,
                onItemSelect: () => {
                  showModal({ name: 'DELETE_ACCESSORY_MODAL', accessory })
                  hideMenu()
                },
                icon: <DeleteIcon color={colors.error} size={18} />,
              },
            ]}
          />
        </Dropdown>
      </Container>
      {showEdit && (
        <EditAccessoryModal
          accessory={accessory}
          onClose={() => setShowEdit(false)}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid #c3cdd3;
  background-color: #ededed;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;
`

const MenuContainer = styled.div`
  position: absolute;
  right: 12px;
  cursor: pointer;
  z-index: 1;
  flex-shrink: 0;
  @media (max-width: 767px) {
    right: 12px;
  }
`

const AccessoryImage = styled.img`
  width: 70px;
  height: 70px;
  box-sizing: border-box;
  background-color: transparent;
  object-fit: contain;
  @media (max-width: 767px) {
    width: 60px;
    height: 60px;
  }
`

const AccessoryLabel = styled.span`
  font-size: 14px;
  color: ${colors.primary};
  padding-left: 2px;
`
