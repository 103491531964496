import React from 'react'
import styled from 'styled-components'
import { colors, animations } from '../core/theme'

export default function PageLoader() {
  return (
    <Container>
      <Spinner />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

const Spinner = styled.div`
  border: 2px solid ${colors.border};
  border-top: 2px solid ${colors.main};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${animations.spin} 0.75s linear infinite;
  box-sizing: border-box;
`
