import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export default function Logo({ iconOnly, style }) {
  return iconOnly ? (
    <NavLink to="/" style={style}>
      <Image src={require('../assets/icons/logo.svg').default} alt="logo" />
    </NavLink>
  ) : (
    <NavLink to="/" style={style}>
      <Banner
        src={require('../assets/icons/banner.svg').default}
        alt="banner"
      />
    </NavLink>
  )
}

const Image = styled.img`
  height: 32px;
`

const Banner = styled.img`
  height: 24px;
`
