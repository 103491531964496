import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown } from './Dropdown'
import Checkbox from './Checkbox'
import { ArrowRight } from '../assets/icons'
import { colors, animations } from '../core/theme'
import { Label } from './Typography'

const FolderPicker = ({ folders, setFolder, label, card }) => {
  const [showFolders, setFoldersVisibility] = useState(false)
  const hideMenu = () => setFoldersVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (showFolders) setFoldersVisibility(false)
    else setFoldersVisibility(true)
  }

  return (
    <Dropdown
      isVisible={showFolders}
      placement="bottom-start"
      onClose={hideMenu}
      triggerRenderer={({ ref }) => (
        <MenuItem onClick={showMenu} ref={ref}>
          <Icon>
            <ArrowRight color={colors.primary} size={18} />
          </Icon>
          <Title>Move to folder</Title>
        </MenuItem>
      )}
    >
      <FoldersContainer>
        {label && (
          <Label weight="medium" padding="16px">
            {label}
          </Label>
        )}

        {folders.length === 0 && (
          <Label variant="secondary" padding="16px">
            No folders created, yet.
          </Label>
        )}

        {folders.map((folder) => {
          const cardFolder =
            folders.filter(({ cards = [] }) =>
              cards.includes(card.cardID)
            )[0] || folders[0]
          const isSelected = cardFolder.name === folder.name

          return (
            <FolderItem
              key={folder.name}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                if (!isSelected) {
                  setFolder(folder)
                }
                setFoldersVisibility(false)
              }}
            >
              <Checkbox
                radio
                name={folder.name}
                label={folder.name}
                value={isSelected}
              />
            </FolderItem>
          )
        })}
      </FoldersContainer>
    </Dropdown>
  )
}

const FoldersContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 12px 0 rgba(66, 77, 87, 0.1);
  box-sizing: border-box;
  border-radius: 6px;
  width: 240px;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  &:focus {
    outline: none;
  }
`

const FolderItem = styled.span`
  list-style: none;
  padding: 12px;
  flex-shrink: 0;
  max-height: 50px;
  cursor: pointer;
  line-height: 1.3;
  font-size: 14px;
  color: ${colors.primary};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  fill: transparent;
  :hover {
    background-color: ${colors.hover};
    fill: ${colors.red};
  }
`

const MenuItem = styled.li`
  list-style: none;
  padding: 16px 20px;
  flex-shrink: 0;
  width: 100%;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
  line-height: 1.3;
  font-size: 13.5px;
  color: ${(props) => props.color || colors.primary};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${(props) =>
    props.divider ? `1px solid ${colors.border}` : 'none'};
  background-color: ${(props) =>
    props.isMarked ? `${colors.blue}55` : 'transparent'};
  opacity: ${(props) => (props.isDisabled ? 0.4 : 1)};
  :hover {
    background-color: ${colors.hover};
  }
`

const Title = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 auto;
  font-weight: 500;
`

const Icon = styled.div`
  margin-right: 14px;
  height: 18px;
`

export default FolderPicker
