import React from 'react'
import styled from 'styled-components'
import Attribute from '../components/Attribute'
import Avatar from '../components/Avatar'
import CardDetails from '../components/CardDetails'
import InboxCardDetailsMenu from '../components/InboxCardDetailsMenu'
import { Row } from '../components/Layout'
import TimeAgo from '../components/TimeAgo'
import { Paragraph, Title } from '../components/Typography'
import BaseModal from './BaseModal'

export default function ContactDetailsModal({ onClose, contact }) {
  const { label, completedTimestamp, customer = {} } = contact

  return (
    <BaseModal
      title={
        <InboxCardDetailsMenu card={contact} style={{ margin: '-6px 0' }} />
      }
      maxWidth="560px"
      onClose={onClose}
      fullscreen
    >
      <DetailsWrapper>
        <Row align="center">
          <div>
            <Avatar size={56} profile={contact.customer} />
          </div>
          <DataContainer>
            <Title padding="4px 0" weight="medium">
              {customer.name || label}
            </Title>
            {customer.email && (
              <Attribute label="Email" value={customer.email} />
            )}
            {completedTimestamp && (
              <Paragraph variant="secondary">
                {contact.completed === false ? 'Abandoned' : 'Completed'}
                : <TimeAgo datetime={completedTimestamp} />
              </Paragraph>
            )}
          </DataContainer>
        </Row>
        <CardDetails card={contact} onClose={onClose} />
      </DetailsWrapper>
    </BaseModal>
  )
}

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 16px 28px;
  @media (max-width: 767px) {
    padding: 16px 20px;
  }
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding: 0 0 0 20px;
`
