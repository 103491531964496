import { generateUniqueID } from './generateUniqueID'

export function generateSectionsIDs(card = {}) {
  const sections = card.sections || []
  sections.forEach((section) => {
    if (!section) return
    if (section.id) return
    if (section.type === 'social-links') {
      const links = section.links || []
      links.forEach((link) => {
        link.id = generateUniqueID()
      })
    }
    if (section.type === 'buttons') {
      const buttons = section.buttons || []
      buttons.forEach((item) => {
        item.id = generateUniqueID()
      })
    }
    if (section.type === 'media') {
      const media = section.media || []
      media.forEach((item) => {
        item.id = generateUniqueID()
      })
    }
    if (section.type === 'contact') {
      const fields = section.value || []
      fields.forEach((field) => {
        field.id = generateUniqueID()
      })
    }
    section.id = generateUniqueID()
  })
}
