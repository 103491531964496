/* eslint-disable */
export function deepCloneObject(val) {
  if (Array.isArray(val)) {
    return cloneArrayDeep(val)
  }
  if (typeof val === 'object') {
    return cloneObjectDeep(val)
  }
  return val
}

function cloneObjectDeep(val) {
  if (!val) return null
  const res = {}
  for (let key in val) {
    res[key] = deepCloneObject(val[key])
  }
  return res
}

export function cloneArrayDeep(val) {
  const res = new Array(val.length)
  for (let i = 0; i < val.length; i++) {
    res[i] = deepCloneObject(val[i])
  }
  return res
}
