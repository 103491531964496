import React, { useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import {
  DeleteIcon,
  DoneIcon,
  DraggableIcon,
  EditTextIcon,
} from '../assets/icons'
import { colors } from '../core/theme'
import FloatingInput from './FloatingInput'

export default function TagListItem({
  item,
  onRemove,
  index,
  onChange,
  isDragDisabled,
}) {
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(false)
  window.ks_editor_tags_item_setEditing[index] = setEditing

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.keyCode === 13) {
        if (editing) {
          setEditing(false)
        }
      }
    }
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [editing])

  return (
    <Draggable
      key={item.createdTimestamp.toString()}
      draggableId={item.createdTimestamp.toString()}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided, snapshot) => (
        <ItemContainer
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          {!isDragDisabled && (
            <ActionContainer style={{ marginRight: 6 }}>
              <DraggableIcon />
            </ActionContainer>
          )}
          {editing ? (
            <FloatingInput
              value={item.label}
              style={{ marginBottom: 0, marginRight: 8 }}
              onChange={(e) => {
                setError(false)
                onChange(e)
              }}
              autoFocus
              error={error}
            />
          ) : (
            <SectionTitle>{item.label}</SectionTitle>
          )}
          {!isDragDisabled && (
            <>
              <ActionContainer
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  if (editing) {
                    if (!item.label) {
                      setError("Tag can't be empty.")
                      return
                    }
                    setEditing(false)
                  } else setEditing(true)
                }}
              >
                {editing ? (
                  <DoneIcon size={20} color={colors.success} />
                ) : (
                  <EditTextIcon size={17} />
                )}
              </ActionContainer>
              <ActionContainer onClick={onRemove}>
                <DeleteIcon size={20} />
              </ActionContainer>
            </>
          )}
        </ItemContainer>
      )}
    </Draggable>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: #f5f5f5;
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  padding: 6px 12px;
  min-height: 54px;
  margin: 0 0 16px 0;
  @media (max-width: 767px) {
    padding: 0 10px;
  }
`

const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
`

const SectionTitle = styled.div`
  word-break: break-all;
  font-size: 13.5px;
  font-weight: 500;
  width: 100%;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
`
