export function calculatePercentageInArray(array, decimals) {
  const sum = array
    .map((d) => d.count)
    .reduce((accumulator, currentValue = 0) => accumulator + currentValue, 0)
  array.forEach((item) => {
    if (!item.count) item.percent = 0
    else
      item.percent = Number.parseFloat((item.count / sum) * 100).toFixed(
        decimals
      )
  })
  return array
}
