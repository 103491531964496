import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import { colors } from '../core/theme'
import ActionModal from '../modals/ActionModal'
import { DeleteIcon, YouTubeIcon } from '../assets/icons'
import { connectGoogleSheets } from '../helpers/connectGoogleSheets'
import { usePermission } from '../hooks/usePermission'
import { useActions, useStore } from '../store'
import { Column, Row } from './Layout'
import { Label } from './Typography'
import { InfoTooltip } from './InfoTooltip'
import { config } from '../core/config'
import HubSpotIntegrationDetails from './HubSpotIntegrationDetails'
import { revokePipedriveToken } from '../api/auth-api'
import PipedriveIntegrationDetails from './PipedriveIntegrationDetails'
import ZendeskIntegrationDetails from './ZendeskIntegrationDetails'
import MailchimpIntegrationDetails from './MailchimpIntegrationDetails'

const Integration = ({
  card,
  updateCard,
  integration,
  canUseIntegrations,
  section,
  isLocked,
  showLockMessage = () => {},
}) => {
  const { profile } = useStore()
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const { canEdit, showPermissionAlert } = usePermission(card)
  const [hasDelete, showDelete] = useState(false)
  const [connecting, setConnectingStatus] = useState(false)
  const { id, name, icon, description, tutorial, href } = integration
  const integrationData = {}
  const targetObject = section || card
  const sectionID = section ? section.id : ''

  if (id === 'google') {
    integrationData.isConnected = !!targetObject.google_sheets
    integrationData.url = targetObject.google_sheets
      ? targetObject.google_sheets.url
      : ''
    integrationData.link = 'View your Google Sheet'
  }

  if (id === 'hubspot') {
    integrationData.isConnected = !!targetObject.hubspot
    integrationData.url = targetObject.hubspot
      ? 'https://app.hubspot.com/contacts'
      : ''
    integrationData.link = 'Open HubSpot dashboard'
  }

  if (id === 'pipedrive') {
    integrationData.isConnected = !!targetObject.pipedrive
    integrationData.url = targetObject.pipedrive
      ? targetObject.pipedrive.api_domain
      : ''
    integrationData.link = 'Open Pipedrive dashboard'
  }

  if (id === 'zendesk-sell') {
    integrationData.isConnected = !!targetObject.zendesk
    integrationData.url = targetObject.zendesk
      ? 'https://app.futuresimple.com/'
      : ''
    integrationData.link = 'Open Zendesk dashboard'
  }

  if (id === 'slack') {
    integrationData.isConnected = !!targetObject.slack
    integrationData.url = targetObject.slack
      ? targetObject.slack.channel.url
      : ''
    integrationData.link = 'Open connected Slack channel'
  }

  if (id === 'discord') {
    integrationData.isConnected = !!targetObject.discord
    integrationData.url = targetObject.discord
      ? targetObject.discord.channel_url
      : ''
    integrationData.link = 'Open Discord channel'
  }

  if (id === 'mailchimp') {
    integrationData.isConnected = !!targetObject.mailchimp
    integrationData.url = targetObject.mailchimp
      ? targetObject.mailchimp.api_endpoint.replace('api', 'admin')
      : ''
    integrationData.link = 'Open Mailchimp dashboard'
  }

  if (id === 'analytics') {
    integrationData.isConnected = !!targetObject.google_analytics
    integrationData.url = targetObject.google_analytics
      ? 'https://analytics.google.com/'
      : ''
    integrationData.link = 'Open Google Analytics'
  }

  if (id === 'pixel') {
    integrationData.isConnected = !!targetObject.facebook_pixel
    integrationData.url = targetObject.facebook_pixel
      ? 'https://www.facebook.com/business/tools/meta-pixel'
      : ''
    integrationData.link = 'Open Meta Pixel'
  }

  const connectIntegration = async () => {
    if (isLocked) {
      showLockMessage()
      return
    }
    if (!canUseIntegrations) {
      showModal({ name: 'TRIAL_ENDED' })
      return
    }

    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (connecting) return

    setConnectingStatus(true)

    try {
      if (id === 'google') {
        const updatedCard = await connectGoogleSheets({
          card,
          profile,
          section,
        })
        updateCard(updatedCard, true)
        showToast({
          content: 'Integration connected successfully.',
          variant: 'success',
        })
      }

      if (id === 'hubspot') {
        const { license, cardID } = card
        const width = 760
        const height = 760
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2
        window.open(
          `${config.SERVER_URL}/hubspot/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'pipedrive') {
        const { license, cardID } = card
        const width = 600
        const height = 700
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2
        window.open(
          `${config.SERVER_URL}/pipedrive/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'zendesk-sell') {
        const { license, cardID } = card
        const width = 1200
        const height = 820
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2
        window.open(
          `${config.SERVER_URL}/zendesk/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'slack') {
        const { license, cardID } = card
        const width = 600
        const height = 700
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2
        window.open(
          `${config.SERVER_URL}/slack/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'discord') {
        const { license, cardID } = card
        const width = 600
        const height = 740
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2
        window.open(
          `${config.SERVER_URL}/discord/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'mailchimp') {
        const { license, cardID } = card

        const width = 800
        const height = 800
        const left = window.innerWidth / 2 - width / 2
        const top = window.innerHeight / 2 - height / 2

        window.open(
          `${config.SERVER_URL}/mailchimp/start?license=${license}&cardID=${cardID}&sectionID=${sectionID}`,
          '_blank',
          `toolbar=yes,scrollbars=yes,resizable=yes,top=${top},left=${left},width=${width},height=${height}`
        )
      }

      if (id === 'activecampaign') {
        window.open(href, '_blank')
      }

      if (id === 'analytics') {
        showModal({ name: 'GOOGLE_ANALYTICS', card, updateCard })
      }

      if (id === 'pixel') {
        showModal({ name: 'FACEBOOK_PIXEL', card, updateCard })
      }
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Failed to connect integration. Please contact our support.',
      })
    }

    setConnectingStatus(false)
  }

  const disconnectIntegration = async () => {
    if (isLocked) {
      showLockMessage()
      return
    }
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (connecting) return
    setConnectingStatus(true)

    try {
      if (id === 'google') {
        delete targetObject.google_sheets
        updateCard(card, true)
      }

      if (id === 'hubspot') {
        delete targetObject.hubspot
        updateCard(card, true)
      }

      if (id === 'pipedrive') {
        revokePipedriveToken(targetObject.pipedrive.token)
        delete targetObject.pipedrive
        updateCard(card, true)
      }

      if (id === 'zendesk-sell') {
        delete targetObject.zendesk
        updateCard(card, true)
      }

      if (id === 'slack') {
        delete targetObject.slack
        updateCard(card, true)
      }

      if (id === 'discord') {
        delete targetObject.discord
        updateCard(card, true)
      }

      if (id === 'mailchimp') {
        delete targetObject.mailchimp
        updateCard(card, true)
      }

      if (id === 'analytics') {
        delete targetObject.google_analytics
        updateCard(card, true)
      }

      if (id === 'pixel') {
        delete targetObject.facebook_pixel
        updateCard(card, true)
      }
    } catch (e) {
      showToast({
        content:
          'Failed to disconnect integration. Please contact our support.',
      })
    }

    setConnectingStatus(false)
    showDelete(false)
  }

  return (
    <>
      <Container>
        <Icon src={icon} alt="img" />
        <Column flex="1">
          <Row align="center">
            <Label lineHeight="medium" weight="medium" style={{ fontSize: 14 }}>
              {name}
            </Label>
            {tutorial && (
              <a href={tutorial} rel="noopener noreferrer" target="_blank">
                <InfoTooltip
                  style={{ marginLeft: 8 }}
                  icon={<YouTubeIcon size={14} color={colors.youtube} />}
                  description="Watch Tutorial"
                  direction="bottom"
                  arrowPosition="top"
                  onClick={() => {}}
                />
              </a>
            )}
          </Row>
          {description && (
            <Label variant="secondary" lineHeight="medium" padding="4px 0">
              {description}
            </Label>
          )}
          {integrationData.url && (
            <Link
              rel="noopener noreferrer"
              target="_blank"
              href={integrationData.url}
            >
              {integrationData.link}
            </Link>
          )}
        </Column>

        <ButtonContainer>
          {integrationData.isConnected ? (
            <Button
              onClick={() => showDelete(true)}
              color={colors.error}
              loading={connecting}
              small
            >
              x
            </Button>
          ) : (
            <Button
              onClick={connectIntegration}
              color={colors.primary}
              loading={connecting}
              small
            >
              +
            </Button>
          )}
        </ButtonContainer>
      </Container>

      {id === 'hubspot' && (
        <HubSpotIntegrationDetails
          targetObject={targetObject}
          card={card}
          updateCard={updateCard}
        />
      )}

      {id === 'pipedrive' && (
        <PipedriveIntegrationDetails
          targetObject={targetObject}
          card={card}
          updateCard={updateCard}
        />
      )}

      {id === 'zendesk-sell' && (
        <ZendeskIntegrationDetails
          targetObject={targetObject}
          card={card}
          updateCard={updateCard}
        />
      )}

      {id === 'mailchimp' && (
        <MailchimpIntegrationDetails
          targetObject={targetObject}
          card={card}
          updateCard={updateCard}
        />
      )}

      {hasDelete && (
        <ActionModal
          onClose={() => showDelete(false)}
          icon={<DeleteIcon size={64} color={colors.error} />}
          actions={
            <>
              <Button
                color={colors.darkgrey}
                onClick={() => showDelete(false)}
                style={{ margin: '12px 6px 0 6px' }}
                outline
              >
                Wait, go back
              </Button>
              <Button
                onClick={disconnectIntegration}
                loading={connecting}
                color={colors.error}
                style={{ margin: '12px 6px 0 6px' }}
              >
                Yes, disconnect
              </Button>
            </>
          }
          header="Disconnect Integration?"
          description="You're about to disconnect one of your integrations. Are you sure you want to continue?"
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 12px 0 24px 0;
  width: 100%;
`

const Icon = styled.img`
  align-self: center;
  padding-right: 20px;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
  @media (max-width: 767px) {
    padding-right: 16px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 12px;
`

const Link = styled.a`
  margin-top: 5px;
  font-size: 13.5px;
`

export default Integration
