import React from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { colors } from '../core/theme'
import { Label } from './Typography'
import { arrayMoveElement } from '../helpers/arrayMoveElement'
import TagListItem from './TagListItem'
import TemplateLock from './TemplateLock'

export default function TagsEditor({
  card,
  updateCard,
  showLock,
  isLocked = () => {},
  onLock,
  showLockMessage,
}) {
  const { personalDetails = {} } = card
  const { tags = [] } = personalDetails
  window.ks_editor_tags_item_setEditing = []

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return
    const sourceIndex = source.index
    const destinationIndex = destination.index
    if (sourceIndex === destinationIndex) return
    card.personalDetails.tags = arrayMoveElement(
      [...card.personalDetails.tags],
      sourceIndex,
      destinationIndex
    )
    updateCard(card)
  }

  return (
    <Container>
      <Option>
        <Label weight="medium">Tags</Label>
        <AddTag
          onClick={() => {
            if (isLocked('add-new-tag')) {
              showLockMessage()
              return
            }
            tags.push({
              createdTimestamp: new Date().getTime(),
            })
            personalDetails.tags = tags
            card.personalDetails = personalDetails
            updateCard(card)
            setTimeout(() => {
              if (window.ks_editor_tags_item_setEditing[tags.length - 1]) {
                window.ks_editor_tags_item_setEditing[tags.length - 1](true)
              }
            }, 50)
          }}
        >
          Add New
          {(showLock || (card.cardID && isLocked('add-new-tag'))) && (
            <TemplateLock
              onLock={onLock}
              label="add-new-tag"
              isLocked={isLocked}
              style={{
                right: '-15px',
                top: 'calc(50% - 14px)',
              }}
            />
          )}
        </AddTag>
      </Option>
      {tags.length > 0 && (
        <TagsWrapper>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(listProvided) => (
                <List
                  {...listProvided.droppableProps}
                  ref={listProvided.innerRef}
                >
                  {tags.map((tag, index) => (
                    <TagListItem
                      isDragDisabled={isLocked('add-new-tag')}
                      item={tag}
                      index={index}
                      onChange={(e) => {
                        card.personalDetails.tags[index] = {
                          ...card.personalDetails.tags[index],
                          label: e.target.value,
                        }
                        updateCard(card)
                      }}
                      onRemove={async () => {
                        const result = window.confirm(
                          `You are about to delete this tag. Are you sure you want to continue?`
                        )
                        if (result) {
                          tags.splice(index, 1)
                          personalDetails.tags = tags
                          card.personalDetails = personalDetails
                          updateCard(card)
                        }
                      }}
                    />
                  ))}
                  {listProvided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </TagsWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 18px;
`

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 14px;
  box-sizing: border-box;
`

const AddTag = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  cursor: pointer;
  background-color: #f5f5f5;
  color: ${colors.primary};
  height: 50px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 13.5px;
  font-weight: 500;
  border-left: 1px solid ${colors.border};
  padding: 0 18px;
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 6px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.border};
  @media (max-width: 767px) {
    padding: 20px 16px;
    padding-bottom: 4px;
  }
`

const List = styled.div`
  width: 100%;
`
