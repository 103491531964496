import { returnSocialLinkIcon } from 'konect-react'
import React from 'react'
import styled from 'styled-components'
import { cardColors, colors } from '../core/theme'
import { lightOrDark } from '../helpers/lightOrDark'
import { Row } from './Layout'

export function returnSocialIcon({
  type,
  style,
  colored,
  layout,
  customSize,
  changeIconStyle,
  selected,
  isMobile,
  socialIconColor,
  icon,
}) {
  const size =
    customSize ||
    (layout === 'minimal' ? (isMobile ? 30 : 31) : isMobile ? 40 : 44)
  const linkIconStyle = {
    borderRadius:
      style === 'round' ? '50%' : layout === 'minimal' ? '9px' : '13px',
  }
  const socialIconFill =
    lightOrDark(socialIconColor) === 'dark' ? colors.white : colors.black
  const socialIconBackgroundColor = colored ? 'transparent' : socialIconColor

  if (icon) {
    return (
      <IconContainer
        style={{
          width: size,
          height: isMobile ? 40 : 44,
        }}
      >
        <img
          style={{
            ...linkIconStyle,
            width: size,
            height: size,
            objectFit: 'cover',
          }}
          src={icon}
          alt={type}
        />
      </IconContainer>
    )
  }

  return (
    <IconContainer
      style={{
        width: size,
        height: isMobile ? 40 : 44,
      }}
    >
      <SocialIcon
        onClick={(e) => {
          if (changeIconStyle) {
            e.preventDefault()
            e.stopPropagation()
            changeIconStyle()
          }
        }}
        style={{
          ...linkIconStyle,
          opacity: selected ? 1 : 0.4,
          backgroundColor: socialIconBackgroundColor,
          width: size,
          height: size,
        }}
      >
        {returnSocialLinkIcon(
          { type: type || 'twitter' },
          socialIconFill,
          layout,
          colored,
          linkIconStyle,
          size
        )}
      </SocialIcon>
    </IconContainer>
  )
}

export default function IconStylePicker({
  section,
  updateCard,
  card,
  isMobile,
  templateLockProps,
  label,
}) {
  const { style = 'round', layout = 'classic', colored } = section
  const { socialIconColor = cardColors.socialIconColor } = card
  const isContentBlocked = templateLockProps.isLocked('Add Content')
  const isItemBlocked = isContentBlocked || templateLockProps.isLocked(label)

  return (
    <Container>
      <Wrapper>
        <IconLabel>Style</IconLabel>
        <Row>
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style,
            layout,
            colored: true,
            selected: colored === true,
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (!section.colored) {
                section.colored = true
                updateCard(card)
              }
            },
          })}
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style,
            layout,
            colored: false,
            selected: !colored,
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (section.colored) {
                section.colored = false
                updateCard(card)
              }
            },
          })}
        </Row>
      </Wrapper>
      <Divider />
      <Wrapper>
        <IconLabel>Size</IconLabel>
        <Row align="center">
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style,
            layout: 'classic',
            selected: layout === 'classic',
            colored,
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (section.layout !== 'classic') {
                section.layout = 'classic'
                updateCard(card)
              }
            },
          })}
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style,
            layout: 'minimal',
            selected: layout === 'minimal',
            colored,
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (section.layout !== 'minimal') {
                section.layout = 'minimal'
                updateCard(card)
              }
            },
          })}
        </Row>
      </Wrapper>
      <Divider />
      <Wrapper>
        <IconLabel>Shape</IconLabel>
        <Row>
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style: 'round',
            layout,
            colored,
            selected: style === 'round',
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (section.style !== 'round') {
                section.style = 'round'
                updateCard(card)
              }
            },
          })}
          {returnSocialIcon({
            type: 'email',
            isMobile,
            style: 'square',
            layout,
            colored,
            selected: style === 'square',
            socialIconColor,
            changeIconStyle: () => {
              if (isItemBlocked) return
              if (section.style !== 'square') {
                section.style = 'square'
                updateCard(card)
              }
            },
          })}
        </Row>
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 0 16px 12px 16px;
  box-sizing: border-box;
  border-top: 1px solid #c3cdd3;
  @media (max-width: 767px) {
    padding: 0 4px 12px 4px;
  }
`

const IconLabel = styled.span`
  font-size: 13px;
  font-weight: 500;
  color: ${colors.primary};
  text-align: center;
  margin: 12px 0 6px 0;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SocialIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  box-sizing: border-box;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
`

const Divider = styled.div`
  width: 1px;
  height: 70px;
  background-color: ${colors.border};
  margin: 0 12px;
  flex-shrink: 0;
  @media (max-width: 767px) {
    margin: 0 8px;
  }
`
