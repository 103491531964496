import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import ContactForm from '../components/SectionSettings/ContactForm'
import { DEFAULT_EXCHANGE_FORM } from '../core/constants'
import { generateUniqueID } from '../helpers/generateUniqueID'
import SwitchInput from '../components/SwitchInput'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { useActions, useStore } from '../store'
import { licenseStatus } from '../helpers/licenseStatus'

export default function ExchangeFormModal({
  onClose,
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  templateLockProps,
}) {
  const { license } = useStore()
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const { showModal } = useActions().modals
  const canUseFeature = isCardActive || isTrialActive
  const popupForm = canUseFeature && card.sections.find((s) => s.enablePopup)

  return (
    <BaseModal
      title={
        showSave ? (
          <Button
            small
            loading={isSaving}
            onClick={() => saveChanges(card)}
            className="save-button"
          >
            Save
          </Button>
        ) : null
      }
      forceFullscreen
      onClose={() => {
        if (window.ks_show_exchangeModal) {
          window.ks_show_exchangeModal(null)
        }
        onClose()
      }}
    >
      <Container>
        <SwitchInput
          {...templateLockProps}
          premium
          label="Contact Exchange Form"
          value={popupForm}
          style={{ marginBottom: 0 }}
          onClick={(e) => {
            if (!canUseFeature) {
              e.stopPropagation()
              e.preventDefault()
              showModal({ name: 'TRIAL_ENDED' })
              return
            }

            if (popupForm) {
              card.sections = card.sections.filter((s) => !s.enablePopup)
              if (window.ks_show_exchangeModal) {
                window.ks_show_exchangeModal(null)
              }
            } else {
              const sectionToAdd = deepCloneObject(DEFAULT_EXCHANGE_FORM)
              const fields = sectionToAdd.value || []
              fields.forEach((field) => {
                field.id = generateUniqueID()
                delete field.value
                delete field.error
              })
              sectionToAdd.value = fields
              card.sections.push({
                ...sectionToAdd,
                question: `Connect with ${card.label}`,
                id: generateUniqueID(),
              })
            }

            updateCard(card)
            if (canUseFeature) {
              window.ks_show_exchangeModal(
                card.sections.find((s) => s.enablePopup)
              )
            }
          }}
        />
        {popupForm && (
          <ContactForm
            label="Contact Exchange Form"
            templateLockProps={templateLockProps}
            section={popupForm}
            card={card}
            updateCard={updateCard}
            showSave={showSave}
            saveChanges={saveChanges}
            isSaving={isSaving}
          />
        )}
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px 24px 20px 24px;
  @media (max-width: 767px) {
    padding: 20px;
  }
`
