import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { InfoTooltip } from './InfoTooltip'
import { LockIcon, UnLockIcon } from '../assets/icons'
import { colors } from '../core/theme'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'

export default function TemplateLock({
  onLock,
  label,
  isLocked,
  style,
  direction = 'right',
  arrowPosition = 'left',
  type = 'field',
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <LockContainer
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onLock(label)
      }}
      style={style}
    >
      <InfoTooltip
        hideMobile={isMobile}
        zIndex={10}
        icon={
          isLocked(label) ? (
            <LockIcon size={12} />
          ) : (
            <UnLockIcon fill={colors.border} size={12} />
          )
        }
        description={
          isLocked(label)
            ? `${firstLetterToUpper(type)} is locked and can't be edited.`
            : `Prevent this ${type} from being edited.`
        }
        direction={direction}
        arrowPosition={arrowPosition}
        style={{
          cursor: 'pointer',
          borderRadius: '50%',
          border: `1px solid ${colors.lightBorder}`,
          width: 26,
          height: 26,
          boxShadow: '0 1px 3px rgba(50, 50, 93, 0.05)',
          backgroundColor: colors.white,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      />
    </LockContainer>
  )
}

const LockContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: -13px;
  top: calc(50% - 15px);
`
