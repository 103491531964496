import React from 'react'
import styled from 'styled-components'
import { colors } from '../../core/theme'
import Button from '../../components/Button'
import CardListItem from '../../components/CardListItem'
import Folders from '../../components/Folders'
import { usePermission } from '../../hooks/usePermission'
import { useActions } from '../../store'
import CardsFilters from '../../components/CardsFilters'

const CardsList = ({
  cards,
  selectedFolder,
  selectedCard,
  onCardClicked,
  license,
  inboxCards,
  dateFilter,
  cardsFilter,
  report,
  isMobile,
}) => {
  const { showModal } = useActions().modals
  const { changeFolder } = useActions().cards
  const { canEdit, showPermissionAlert } = usePermission()
  const folders = license.folders

  const filteredCards = cards.filter((card) => {
    if (!folders[0]) return false
    if (selectedFolder.cards.includes(card.cardID)) return true
    if (folders[0].name === selectedFolder.name) {
      let alreadyTaken = false
      folders.forEach((folder) => {
        if (alreadyTaken) return
        if (folder.cards.includes(card.cardID)) {
          alreadyTaken = true
        }
      })
      if (!alreadyTaken) {
        return true
      }
    }
    return false
  })

  cardsFilter.sortFunction(filteredCards, inboxCards)

  return (
    <Container>
      <Top>
        <Button
          small
          className="primary-compact-button"
          onClick={() => {
            if (!canEdit) {
              showPermissionAlert()
              return
            }
            showModal({ name: 'CREATE_CARD' })
          }}
        >
          + Add New Page
        </Button>
      </Top>

      {license.folders && (
        <FiltersContainer>
          <Folders
            selectedFolder={selectedFolder}
            setFolder={changeFolder}
            folders={license.folders}
          />
          <CardsFilters cardsFilter={cardsFilter} />
        </FiltersContainer>
      )}

      {cards.length > 0 && (
        <Wrapper>
          {filteredCards.length === 0 && (
            <EmptyFolder>This folder has no cards, yet.</EmptyFolder>
          )}
          {Object.keys(license).length > 0 &&
            filteredCards.map((card) => {
              const receivedCards = inboxCards[card.cardID] || []
              const unseenCards = receivedCards.filter(
                (f) => !f.seen && f.completed
              ).length

              return (
                <CardListItem
                  key={card.cardID}
                  card={card}
                  onCardClicked={onCardClicked}
                  selectedCard={selectedCard}
                  selectedFolder={selectedFolder}
                  folders={license.folders}
                  dateFilter={dateFilter}
                  report={report}
                  isMobile={isMobile}
                  unseenCards={unseenCards}
                />
              )
            })}
        </Wrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.menu};
  flex: 1 1 auto;
  min-width: 320px;
  overflow: hidden;
  @media (min-width: 500px) {
    max-width: 320px;
  }
  @media (min-width: 800px) {
    max-width: 360px;
  }
  @media (min-width: 1300px) {
    max-width: 410px;
  }
  @media (max-width: 500px) {
    max-width: 100%;
    min-width: auto;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
  overflow: auto;
  min-height: 300px;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding-bottom: 100px;
  }
`

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border-top: 1px solid ${colors.border};
  border-bottom: 1px solid ${colors.border};
  background-color: ${colors.white};
  align-items: center;
  padding: 8px;
  z-index: 999;
`

const Top = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;
  min-height: 60px;
  max-height: 60px;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`

const EmptyFolder = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 18px;
  box-sizing: border-box;
  font-size: 14px;
  color: ${colors.secondary};
`

export default CardsList
