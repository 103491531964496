import React, { useState } from 'react'
import styled from 'styled-components'
import { animations, colors } from '../core/theme'
import Button from '../components/Button'
import { useActions } from '../store'
import { emailValidator } from '../helpers/validators/emailValidator'
import { sendEmailWithPassword } from '../api/auth-api'
import Modal from './Modal'
import { CloseIcon } from '../assets/icons'
import FloatingInput from '../components/FloatingInput'

const ResetPasswordModal = ({ onClose }) => {
  const { showToast } = useActions().toasts
  const [sending, setSendingStatus] = useState()
  const [email, setEmail] = useState({ value: '', error: '' })

  const sendRestPasswordEmail = async () => {
    if (sending) return
    const emailError = emailValidator(email.value)
    if (emailError) {
      setEmail({ ...email, error: emailError })
      return
    }
    setSendingStatus(true)
    const { error } = await sendEmailWithPassword(email.value)
    if (error) {
      showToast({ content: error })
    } else {
      showToast({
        content: 'Email with password has been sent.',
        variant: 'success',
      })
    }
    setSendingStatus(false)
    setEmail({ value: '', error: '' })
    onClose()
  }

  return (
    <Modal onClose={onClose}>
      <Container>
        <CloseButton onClick={onClose}>
          <CloseIcon color={colors.darkgrey} />
        </CloseButton>
        <FloatingInput
          label="Email"
          name="email"
          type="email"
          value={email.value}
          error={email.error}
          onChange={(e) => setEmail({ value: e.target.value, error: '' })}
          description="You will receive an email with a link to reset your password. If you don't see it, check your spam folder in case it landed there."
        />
        <Button
          loading={sending}
          onClick={sendRestPasswordEmail}
          style={{ marginTop: 16, height: 48 }}
        >
          Reset Password
        </Button>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: 420px;
  padding: 56px 28px 28px 28px;
  border-radius: 8px;
  background-color: #f9f9fc;
  animation: ${animations.dialogScaleIn} 200ms ease-out;
  @media (max-width: 767px) {
    width: 96%;
    margin: 0;
    padding: 56px 22px 28px 22px;
  }
`

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

export default ResetPasswordModal
