import React from 'react'
import styled from 'styled-components'
import { useStore } from '../store'
import AddFolder from './AddFolder'
import Folder from './Folder'

const Folders = ({ folders, selectedFolder, setFolder }) => {
  const { profile, license } = useStore()
  const users = license.users || {}
  const foundUser = users[profile.email] || {}
  const allowedCards = foundUser.allowedCards || []
  const allowedFolders = foundUser.allowedFolders || []

  return (
    <Container>
      {folders.map((folder) => {
        if (foundUser.permission === 'Selected pages' && folders.length > 0) {
          let isUsingFolder = false
          if (folder.cards) {
            folder.cards.forEach((card) => {
              if (allowedCards.includes(card)) {
                isUsingFolder = true
              }
            })
          }
          if (!isUsingFolder) return null
        }

        if (foundUser.permission === 'Selected folders' && folders.length > 0) {
          if (allowedFolders) {
            let isUsingFolder = false
            if (allowedFolders.includes(folder.createdTimestamp)) {
              isUsingFolder = true
            }
            if (!isUsingFolder) return null
          }
        }

        return (
          <Folder
            key={folder.name}
            folder={folder}
            isSelected={selectedFolder.name === folder.name}
            setFolder={setFolder}
          />
        )
      })}
      <AddFolder setFolder={setFolder} />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  align-items: center;
`

export default Folders
