import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Avatar from './Avatar'
import { colors } from '../core/theme'
import TimeAgo from './TimeAgo'
import Tag from './Tag'
import {
  WindowsLogo,
  AndroidLogo,
  AppleIcon,
  UnixIcon,
  LinuxIcon,
  EyeIcon,
  EyeOffIcon,
  WarningIcon,
} from '../assets/icons'
import { Tooltip, TooltipContent } from './Tooltip'
import { InfoTooltip } from './InfoTooltip'
import AddTagButton from './AddTagButton'
import { useActions } from '../store'
import { Column, Row } from './Layout'
import { Label, Caption } from './Typography'

const InboxCardRow = ({ card, onInboxCardSelect, style, hideTooltip }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const {
    completedTimestamp,
    lastSeenUpdate = {},
    customer = {},
    platform = '',
    tags = [],
  } = card

  const [showAddTag, setAddTagStatus] = useState()
  const { updateCardTags } = useActions().inbox
  const { name, email } = customer

  const addTag = (tag) => {
    const newTags = [...tags, tag]
    updateCardTags({ card, tags: newTags })
  }

  const removeTag = (tag) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    const newTags = tags.filter((t) => t.label !== tag.label)
    updateCardTags({ card, tags: newTags })
  }

  const renderTags = () => {
    return tags.map((tag, i) => (
      <Tag
        removeTag={removeTag(tag)}
        key={tag.label}
        color={tag.color}
        label={tag.label}
        index={i}
      />
    ))
  }

  const renderVisibilityStatus = () => {
    if (hideTooltip) {
      return (
        <Row margin="0 4px">
          {lastSeenUpdate && lastSeenUpdate.timestamp ? (
            <EyeIcon size={20} color={colors.success} />
          ) : (
            <EyeOffIcon size={20} color={colors.error} />
          )}
        </Row>
      )
    }

    return (
      <InfoTooltip
        zIndex={1}
        icon={
          <Row margin="0 4px">
            {lastSeenUpdate && lastSeenUpdate.timestamp ? (
              <EyeIcon size={20} color={colors.success} />
            ) : (
              <EyeOffIcon size={20} color={colors.error} />
            )}
          </Row>
        }
        description={
          lastSeenUpdate && lastSeenUpdate.timestamp ? 'Seen' : 'Not seen'
        }
        direction="left"
        arrowPosition="right"
      />
    )
  }

  const returnLogo = () => {
    if (!platform) return '?'
    if (platform.toLowerCase().includes('window')) {
      return <WindowsLogo />
    }
    if (platform.toLowerCase().includes('android')) {
      return <AndroidLogo />
    }
    if (
      platform.toLowerCase().includes('ios') ||
      platform.toLowerCase().includes('mac')
    ) {
      return <AppleIcon />
    }
    if (platform.toLowerCase().includes('linux')) {
      return <LinuxIcon />
    }
    if (platform.toLowerCase().includes('unix')) {
      return <UnixIcon />
    }

    return '?'
  }

  const returnPlatform = () => {
    if (hideTooltip) {
      return <PlatformContainer>{returnLogo()}</PlatformContainer>
    }

    return (
      <Tooltip
        offset={1}
        zIndex={1}
        align="center"
        trigger="hover"
        direction="right"
        content={
          <TooltipContent arrowPosition="left">
            {platform || 'Unknown'}
          </TooltipContent>
        }
      >
        <PlatformContainer>{returnLogo()}</PlatformContainer>
      </Tooltip>
    )
  }

  return (
    <InboxCardContainer
      key={card.id}
      onClick={onInboxCardSelect(card)}
      onMouseEnter={() => {
        setAddTagStatus(true)
      }}
      onMouseLeave={() => {
        setAddTagStatus(false)
      }}
      style={style}
    >
      <RecipientRow>
        <div>
          <Avatar size={32} profile={customer} />
        </div>
        {!isMobile && (
          <Column padding="0 12px" style={{ maxWidth: 400 }}>
            {name && (
              <Label lineHeight="medium" className="clip-text">
                {name} <br />
              </Label>
            )}
            <Caption
              lineHeight="medium"
              className="clip-text"
              variant={name ? 'secondary' : email ? 'default' : 'secondary'}
            >
              {email || 'Email not provided'}
            </Caption>
          </Column>
        )}
      </RecipientRow>
      <Row flex="1">{returnPlatform()}</Row>
      <Row flex="1">
        <TimeAgo color={colors.secondary} small datetime={completedTimestamp} />
      </Row>
      <StatusWrapper>
        <TagsWrapper>{renderTags()}</TagsWrapper>
        {showAddTag && <AddTagButton onTagAdd={addTag} card={card} />}
        {renderVisibilityStatus()}
        {card.completed === false && (
          <InfoTooltip
            icon={
              <Row margin="0 4px">
                <WarningIcon size={20} color={colors.orange} />
              </Row>
            }
            description="Not completed"
            direction="left"
            arrowPosition="right"
          />
        )}
      </StatusWrapper>
    </InboxCardContainer>
  )
}

const InboxCardContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.lightBorder};
  align-items: center;
  padding: 0 16px;
  min-height: 64px;
  max-height: 64px;
  background-color: white;
  transition: background-color 0.2s ease-out;
  :hover {
    background-color: ${colors.hover};
  }
`

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-width: 320px;
  padding: 4px;
  box-sizing: border-box;
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 0 4px;
  z-index: 0;
  @media (max-width: 767px) {
    display: none;
  }
`

const RecipientRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
  }
`

const PlatformContainer = styled.div`
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
`

export default InboxCardRow
