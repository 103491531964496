import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Avatar from './Avatar'
import { colors } from '../core/theme'
import { MenuIcon, DeleteIcon, EditTextIcon } from '../assets/icons'
import { Dropdown, DropdownList } from './Dropdown'
import { usePermission } from '../hooks/usePermission'
import { useActions, useStore } from '../store'
import { Column, Row } from './Layout'
import { Label, Caption } from './Typography'

const UserRow = ({ user, license }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { profile } = useStore()
  const { canEdit, showPermissionAlert, allowedFolders } = usePermission()
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const { name, email, createdTimestamp } = user
  const isOwner = license.createdBy === email
  const permission = isOwner ? 'Owner' : user.permission || 'Full access'

  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <UserContainer>
      <Profile>
        <Avatar size={36} profile={user} />
        {!isMobile && (
          <Column padding="0 12px">
            {name && (
              <Label lineHeight="medium" className="clip-text">
                {name} <br />
              </Label>
            )}
            <Caption lineHeight="medium" className="clip-text">
              {email || 'Email not provided'}
            </Caption>
          </Column>
        )}
      </Profile>
      <Row flex="1">
        <Caption lineHeight="medium" className="clip-text">
          {new Date(createdTimestamp).toLocaleDateString()}
        </Caption>
      </Row>
      <Row flex="1">
        <Label lineHeight="medium" className="clip-text">
          {permission}
        </Label>
      </Row>
      <ActionsWrapper>
        <Dropdown
          isVisible={isMenuVisible}
          placement="bottom-end"
          onClose={hideMenu}
          triggerRenderer={({ ref }) => (
            <MenuButton onClick={showMenu} ref={ref}>
              <MenuIcon size={18} color={colors.primary} />
            </MenuButton>
          )}
        >
          <DropdownList
            onClose={hideMenu}
            items={[
              {
                content: 'Change permission',
                onItemSelect: () => {
                  if (email === profile.email) {
                    showToast({
                      content: "You can't change your own permission.",
                    })
                    return
                  }

                  if (allowedFolders) {
                    showPermissionAlert()
                    return
                  }

                  if (canEdit) {
                    if (isOwner) {
                      showToast({
                        content: "Owner can't be edited.",
                      })

                      return
                    }

                    showModal({
                      name: 'CHANGE_USER_PERMISSION',
                      employee: user,
                    })
                  } else {
                    showPermissionAlert()
                  }
                },
                icon: <EditTextIcon color={colors.primary} size={18} />,
              },
              {
                content: 'Remove employee',
                color: colors.error,
                onItemSelect: () => {
                  if (email === profile.email) {
                    showToast({
                      content: "You can't remove yourself.",
                    })

                    return
                  }

                  if (allowedFolders) {
                    showPermissionAlert()
                    return
                  }

                  if (canEdit) {
                    if (isOwner) {
                      showToast({
                        content: "Owner can't be removed.",
                      })

                      return
                    }

                    showModal({ name: 'REMOVE_EMPLOYEE', employee: user })
                  } else {
                    showPermissionAlert()
                  }
                },
                icon: <DeleteIcon color={colors.error} size={18} />,
              },
            ]}
          />
        </Dropdown>
      </ActionsWrapper>
    </UserContainer>
  )
}

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.lightBorder};
  align-items: center;
  padding: 0 16px;
  min-height: 64px;
  max-height: 64px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-width: 320px;
  padding: 4px;
  box-sizing: border-box;
`

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
  }
`

const MenuButton = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  transition: opacity 0.2s ease-out;
  :active {
    opacity: 0.5;
  }
`

export default UserRow
