import { PODCAST_PLATFORMS, returnPodcastPlatformInfo } from 'konect-react'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { Label } from '../Typography'
import PodcastListItem from '../PodcastListItem'
import { arrayMoveElement } from '../../helpers/arrayMoveElement'

export default function PodcastButtonSettings({ button, updateCard, card }) {
  const { podcast = {} } = button
  let { platforms = [] } = podcast
  const [podcastPlatforms, updatePodcastPlatforms] = useState([])

  useEffect(() => {
    const usedPlatforms = []
    platforms.forEach((p, index) => {
      if (!p) return
      const foundPlatform = returnPodcastPlatformInfo(p.link)
      if (foundPlatform.icon) {
        usedPlatforms[index] = foundPlatform
      }
    })
    const freePlatforms = []
    Object.values(PODCAST_PLATFORMS).forEach((p, index) => {
      if (
        !usedPlatforms.find((plat) => {
          if (!plat) return false
          return plat.name === p.name
        })
      ) {
        freePlatforms.push(p)
      }
      if (!usedPlatforms[index]) {
        usedPlatforms[index] = null
      }
    })
    let counter = 0
    for (let x = 0; x < usedPlatforms.length; x++) {
      if (!usedPlatforms[x]) {
        usedPlatforms[x] = freePlatforms[counter]
        counter++
      }
    }
    updatePodcastPlatforms(usedPlatforms)
  }, [])

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return
    const sourceIndex = source.index
    const destinationIndex = destination.index
    if (sourceIndex === destinationIndex) return
    platforms = arrayMoveElement(platforms, sourceIndex, destinationIndex)
    for (let x = 0; x < platforms.length; x++) {
      if (!platforms[x]) platforms[x] = {}
    }
    podcast.platforms = platforms
    updatePodcastPlatforms(
      arrayMoveElement(podcastPlatforms, sourceIndex, destinationIndex)
    )
    updateCard(card)
  }

  return (
    <>
      <Label padding="8px 6px 12px 6px" weight="medium">
        Podcast Platforms:
      </Label>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(listProvided) => (
            <PlatformsWrapper
              {...listProvided.droppableProps}
              ref={listProvided.innerRef}
            >
              {podcastPlatforms.map((platform, i) => {
                const link = platforms[i] ? platforms[i].link : ''
                return (
                  <PodcastListItem
                    key={platform.name}
                    platform={platform}
                    link={link}
                    index={i}
                    platforms={platforms}
                    podcast={podcast}
                    updateCard={updateCard}
                    card={card}
                    i={i}
                  />
                )
              })}
              {listProvided.placeholder}
            </PlatformsWrapper>
          )}
        </Droppable>
      </DragDropContext>
    </>
  )
}

const PlatformsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
`
