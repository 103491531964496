import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { config } from '../../core/config'
import { INTEGRATIONS } from '../../core/constants'
import { licenseStatus } from '../../helpers/licenseStatus'
import { useStore } from '../../store'
import Integration from '../Integration'
import { Caption } from '../Typography'

export default function Integrations({ updateCard, card, section }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { license } = useStore()
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const canUseIntegrations = isCardActive || isTrialActive

  return (
    <Container>
      {INTEGRATIONS.map((integration) => (
        <Integration
          key={integration.id}
          integration={integration}
          card={card}
          updateCard={updateCard}
          canUseIntegrations={canUseIntegrations}
          isMobile={isMobile}
          section={section}
        />
      ))}
      <Caption padding="8px 0" variant="secondary" lineHeight="medium">
        Can't find what you are looking for?{' '}
        <a
          href={config.REQUEST_INTEGRATION_FORM}
          rel="noopener noreferrer"
          target="_blank"
          className="clickable-element"
        >
          Request an integration.
        </a>
      </Caption>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 16px 28px 20px 28px;
  padding-bottom: 0;
  @media (max-width: 767px) {
    padding: 20px;
    padding-top: 8px;
  }
`
