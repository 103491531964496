import React, { useRef } from 'react'
import styled from 'styled-components'
import Modal from './Modal'
import { colors, animations } from '../core/theme'
import { CloseIcon } from '../assets/icons'
import { Paragraph, Subheading } from '../components/Typography'

const ActionModal = ({ onClose, icon, header, description, actions, body }) => {
  const ref = useRef()
  const onModalClose = () => {
    if (ref && ref.current) {
      ref.current.classList.add('modal-out-animation')
    }
    setTimeout(onClose, 180)
  }

  return (
    <Modal onClose={onModalClose} forwardRef={ref}>
      <Container>
        <CloseButton onClick={onModalClose}>
          <CloseIcon color={colors.darkgrey} />
        </CloseButton>
        {icon && icon}
        <Subheading
          align="center"
          padding="20px 0"
          weight="bold"
          lineHeight="medium"
        >
          {header}
        </Subheading>
        <Paragraph variant="secondary" align="center" lineHeight="medium">
          {description}
        </Paragraph>
        {body}
        <ActionsContainer>{actions}</ActionsContainer>
      </Container>
    </Modal>
  )
}
const Container = styled.div`
  margin: 10px;
  max-width: 420px;
  min-width: 340px;
  border-radius: 8px;
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 28px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${colors.white};
  animation: ${animations.dialogScaleIn} 200ms ease-out;
  @media (max-width: 767px) {
    max-width: 340px;
    padding: 30px 16px;
  }
  @media (max-width: 340px) {
    min-width: 300px;
    max-width: 300px;
    padding: 20px 4px;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: center;
  margin-top: 12px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

export default ActionModal
