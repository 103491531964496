import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { Dropdown, DropdownList } from './Dropdown'
import { FilterIcon } from '../assets/icons'
import { CARDS_FILTERS } from '../helpers/filters'
import { Label } from './Typography'
import { useActions } from '../store'

export default function CardsFilters({ cardsFilter }) {
  const { changeFilter } = useActions().cards
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <Dropdown
      isVisible={isMenuVisible}
      placement="bottom-end"
      onClose={hideMenu}
      triggerRenderer={({ ref }) => (
        <Filter onClick={showMenu} ref={ref}>
          <FilterIcon color={colors.darkgrey} size={16} />
        </Filter>
      )}
    >
      <Label padding="16px 16px 12px 16px" weight="medium">
        Sort pages by
      </Label>
      <DropdownList
        onClose={hideMenu}
        items={CARDS_FILTERS.map((filter) => ({
          content: filter.label,
          isChecked: cardsFilter.label === filter.label,
          radio: true,
          onClick: () => {
            if (cardsFilter.label !== filter.label) {
              changeFilter(filter)
            }
          },
        }))}
      />
    </Dropdown>
  )
}

const Filter = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  color: ${colors.darkgrey};
  font-size: 13px;
  font-weight: 400;
`
