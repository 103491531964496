/* eslint-disable */

const AVATAR_COLORS = [
  '#4CAF50',
  '#0186c0',
  '#bb3355',
  '#e2445c',
  '#ff652e',
  '#579bfc',
]

export function getColorForAvatar(str) {
  if (!str) return '#34a095'
  let hash = 0
  if (str.length === 0) return hash
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
    hash &= hash
  }
  hash =
    ((hash % AVATAR_COLORS.length) + AVATAR_COLORS.length) %
    AVATAR_COLORS.length
  return AVATAR_COLORS[hash]
}
