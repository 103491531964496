import React from 'react'
import styled from 'styled-components'
import { Chart } from 'react-google-charts'
import { useMediaQuery } from 'react-responsive'
import CardBase from './CardBase'
import Flag from '../Flag'
import { Column, Row } from '../Layout'
import { Paragraph, Caption, Label } from '../Typography'
import { PIE_CHART_COLORS } from '../../core/constants'

const LocationsCard = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isMediumDevice = useMediaQuery({ minWidth: 767, maxWidth: 1440 })

  return (
    <CardBase
      title="Top Locations"
      body={
        <Container>
          <Column flex="1">
            {data.length === 0 && isMobile && (
              <Paragraph variant="secondary">No data to show</Paragraph>
            )}
            {data.map(({ country, count, percent, code }) => (
              <Location padding="12px 0" align="center" key={country}>
                <Flag code={code} />
                <Country>{country}</Country>
                <Count weight="medium">{count}</Count>
                <Percent>{percent}%</Percent>
              </Location>
            ))}
          </Column>
          {!isMobile && (
            <Chart
              width={isMediumDevice ? '400px' : '500px'}
              height="100%"
              chartType="GeoChart"
              data={[
                ['Country', 'Visits'],
                ...data.map((c) => [c.country, c.count]),
              ]}
              mapsApiKey="AIzaSyDztF_0SHo606yMZlsBcfBrsZvmQJfACbQ"
              options={{
                colorAxis: { colors: [PIE_CHART_COLORS[0]] },
              }}
            />
          )}
        </Container>
      }
    />
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 16px;
`

const Location = styled(Row)`
  width: 100%;
  width: 400px;
  margin-right: 8px;
  @media (max-width: 1440px) {
    width: 300px;
  }
  @media (max-width: 767px) {
    width: 100%;
    flex: 1;
  }
`

const Country = styled(Label)`
  flex: 3;
  white-space: nowrap;
  padding: 0 16px;
`

const Count = styled(Label)`
  flex: 1;
  text-align: right;
  padding: 0 12px;
`

const Percent = styled(Caption)`
  flex: 1;
  text-align: right;
  padding: 0 12px;
`

export default LocationsCard
