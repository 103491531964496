import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import SketchPicker from 'react-color/lib/components/sketch/Sketch'
import { ReloadIcon } from '../assets/icons'
import { colors } from '../core/theme'
import { InfoTooltip } from './InfoTooltip'
import PremiumBadge from './PremiumBadge'

export default function ColorInput({
  label,
  description,
  value,
  required,
  error,
  style,
  inputStyle,
  labelStyle,
  pickerStyle,
  className,
  prefix,
  onChangeComplete,
  color = '',
  changeToDefault,
  premium,
}) {
  const [showPicker, setPickerState] = useState(false)
  const pickerRef = useRef()

  const changePickerState = () => {
    setPickerState(!showPicker)
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (pickerRef.current) {
        if (!pickerRef.current.contains(event.target) && showPicker) {
          setPickerState(false)
        }
      }
    }
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [pickerRef.current, showPicker])

  return (
    <Container style={style} className={className}>
      {label && (
        <InputLabel style={labelStyle}>
          {label}{' '}
          {required && <span style={{ color: colors.required }}>*</span>}
        </InputLabel>
      )}
      <InputContainer onClick={changePickerState}>
        {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
        <InputField style={inputStyle} error={error} hasPrefix={prefix}>
          {value}
          {premium && (
            <PremiumContainer>
              <PremiumBadge
                type="feature"
                direction="bottom"
                arrowPosition="top"
                size={14}
              />
            </PremiumContainer>
          )}
        </InputField>
        <ColorWrapper>
          {changeToDefault && (
            <InfoTooltip
              icon={<ReloadIcon size={18} />}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                changeToDefault()
              }}
              direction="bottom"
              arrowPosition="top"
              style={{ margin: '0 0 0 8px' }}
              description="Reset to default"
            />
          )}
          <ColorContainer color={color} />
        </ColorWrapper>
        {showPicker && (
          <PickerContainer
            style={pickerStyle}
            ref={pickerRef}
            onMouseLeave={() => setPickerState(false)}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
            }}
          >
            <SketchPicker
              color={color}
              disableAlpha
              onChangeComplete={onChangeComplete}
            />
          </PickerContainer>
        )}
      </InputContainer>
      {error && <InputError>{error}</InputError>}
      {description && !error && (
        <InputDescription>{description}</InputDescription>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
`

const InputLabel = styled.label`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.primary};
  line-height: 1.5;
  text-align: left;
  margin-bottom: 8px;
  padding: 0 4px;
`

const InputDescription = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.secondary};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const InputError = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.error};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px 14px;
  height: 50px;
  line-height: 1.5;
  color: ${colors.darkgrey};
  background-color: ${colors.prefix};
  border: 1px solid ${colors.border};
  border-right: none;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
`

const InputField = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 14px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  ${(props) =>
    props.hasPrefix
      ? `
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  `
      : ''}
`

const ColorWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: 12px;
  top: 14px;
`

const ColorContainer = styled.div`
  cursor: pointer;
  width: 21px;
  height: 21px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  border: 1px solid ${colors.border};
  margin-left: 10px;
  box-sizing: border-box;
`

const PickerContainer = styled.div`
  position: absolute;
  z-index: 9999999;
  top: -100px;
  right: 0;
`

const PremiumContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`
