import React from 'react'
import styled from 'styled-components'
import { ARROW_POSITION, getArrowOffsetStyle } from './helpers'
import { colors } from '../../core/theme'

const TooltipContent = ({
  children,
  arrowPosition,
  arrowOffset,
  arrowAlign,
}) => (
  <Tooltip>
    <Arrow
      arrowPosition={arrowPosition}
      style={getArrowOffsetStyle(arrowOffset, arrowPosition, arrowAlign)}
    />
    {children}
  </Tooltip>
)

const Tooltip = styled.div`
  position: relative;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);
  margin: 4px;
  font-size: 14px;
  border-radius: 2px;
  padding: 8px 12px;
  max-width: 320px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #000000;
  border-color: #000000;
  color: ${colors.white};
`

const Arrow = styled.div`
  content: ' ';
  position: absolute;
  border-width: 4px;
  border-style: solid;
  border-color: transparent;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => {
    switch (props.arrowPosition) {
      case 'none':
        return `display: none;`
      case 'left':
        return `border-right-color: inherit; left: -8px;`
      case 'right':
        return `border-left-color: inherit; right: -8px;`
      case 'top':
        return `border-bottom-color: inherit; top: -4px; left: calc(50% - 4px);`
      case 'bottom':
        return `border-top-color: inherit; bottom: -12px; top: auto; left: calc(50% - 4px);`
      default:
        return `border-right-color: inherit; left: -8px;`
    }
  }}
`

TooltipContent.defaultProps = {
  arrowPosition: ARROW_POSITION.Left,
  className: '',
}

export default TooltipContent
