import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors, animations } from '../core/theme'
import CardChart from './CardChart'
import { useActions } from '../store'
import { Row } from './Layout'
import { Paragraph } from './Typography'
import CardMenu from './CardMenu'
import { PIE_CHART_COLORS } from '../core/constants'
import Avatar from './Avatar'

const CardListItem = ({
  card,
  onCardClicked,
  selectedCard,
  selectedFolder,
  folders,
  report,
  isMobile,
  unseenCards,
}) => {
  const isMediumDevice = useMediaQuery({ maxWidth: 1320 })
  const { showToast } = useActions().toasts
  const { addCardToFolder } = useActions().cards
  const isSelected = selectedCard.cardID === card.cardID
  const usageData = report ? report.insights.activity : []
  const { avatar = {} } = card

  const setFolder = (folder) => {
    try {
      addCardToFolder({ card, folder })
      onCardClicked(card)()
    } catch (e) {
      showToast({
        content: 'Failed to add card to folder. Please reload app.',
      })
    }
  }

  return (
    <CardContainer onClick={onCardClicked(card)} isSelected={isSelected}>
      <Row
        padding={isSelected && !isMobile ? '14px 12px' : '18px 12px'}
        align="center"
      >
        <div style={{ marginLeft: 8 }}>
          <Avatar
            size={28}
            profile={{
              avatar:
                avatar.url ||
                require('../assets/images/default-avatar.png').default,
              name: avatar.name,
            }}
          />
        </div>
        <Paragraph
          padding="0 14px"
          weight="medium"
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '100%',
            fontSize: '14.5px',
          }}
        >
          {card.label}
        </Paragraph>
        {isMobile && (
          <CardMenu
            card={card}
            setFolder={setFolder}
            folders={folders}
            selectedFolder={selectedFolder}
          />
        )}
      </Row>
      {unseenCards > 0 && (
        <BadgeContainer isSelected={isSelected}>
          <Badge>{unseenCards} NEW</Badge>
        </BadgeContainer>
      )}
      {isSelected && !isMobile && (
        <FadeInView>
          <CardChart
            card={card}
            data={usageData}
            height={isMediumDevice ? 170 : 200}
          />
        </FadeInView>
      )}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  cursor: pointer;
  position: relative;
  background-color: ${colors.white};
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  :after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 6px;
    height: 100%;
    background-color: ${(props) =>
      props.isSelected ? PIE_CHART_COLORS[0] : colors.border};
    content: '';
    @media (max-width: 767px) {
      background-color: transparent;
    }
  }
  :hover {
    background-color: ${colors.hover};
  }
`
const FadeInView = styled.div`
  width: 100%;
  animation: ${animations.fadeIn} 1000ms linear;
`

const BadgeContainer = styled.div`
  position: absolute;
  right: 16px;
  top: ${(props) => (props.isSelected ? '16px' : '20px')};
`

const Badge = styled.span`
  font-size: 11px;
  color: ${colors.white};
  background-color: ${colors.red};
  border-radius: 20px;
  white-space: nowrap;
  font-weight: bold;
  padding: 4px 8px;
  text-transform: uppercase;
`

export default CardListItem
