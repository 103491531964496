import { useActions, useStore } from '../store'

export const usePermission = (card, isTemplate) => {
  const { license, profile } = useStore()
  const { showToast } = useActions().toasts

  if (!license) return {}

  const users = license.users || {}
  const licenseUser = users[profile.email] || {}
  const isOwner = license.createdBy === profile.email

  const permission = isOwner ? 'Owner' : licenseUser.permission || 'Full access'
  let canEdit = permission === 'Owner' || permission === 'Full access'

  const allowedCards = licenseUser.allowedCards
  if (allowedCards && card && !isTemplate) {
    if (allowedCards.includes(card.cardID)) {
      canEdit = true
    }
  }

  const allowedFolders = licenseUser.allowedFolders
  if (allowedFolders) {
    canEdit = true
  }

  const showPermissionAlert = () => {
    showToast({
      content: `You don't have permission to perform this action. Contact your license owner and ask for permission.`,
      duration: 5000,
    })
  }

  return {
    canEdit,
    isOwner,
    showPermissionAlert,
    permission,
    allowedFolders: licenseUser.allowedFolders,
  }
}
