import styled from 'styled-components'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { clearCardValues } from 'konect-react'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { colors } from '../core/theme'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { ArrowRight } from '../assets/icons'
import { useActions } from '../store'
import { Caption } from '../components/Typography'
import FloatingInput from '../components/FloatingInput'
import { ReactComponent as LinkInBio } from '../assets/icons/link_in_bio_type.svg'
import { ReactComponent as BusinessCard } from '../assets/icons/business_card_type.svg'
import {
  BLANK_DIGITAL_CARD_TEMPLATE,
  BLANK_LINK_IN_BIO_TEMPLATE,
} from '../core/templates/cards-templates'
import { generateUniqueID } from '../helpers/generateUniqueID'
import { generateSectionsIDs } from '../helpers/generateSectionsIDs'

const CreateTemplateModal = ({ onClose, card, showTypePicker }) => {
  const history = useHistory()
  const { showToast } = useActions().toasts
  const { createTemplateFromCard } = useActions().license
  const [error, setError] = useState(false)
  const [name, updateName] = useState('')
  const [isCreating, setCreatingStatus] = useState(false)
  const [screen, setScreen] = useState(showTypePicker ? 'type' : 'details') // type, details
  const [type, setType] = useState('')
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const updateNameValue = (e) => {
    if (error) setError(false)
    updateName(e.target.value)
  }

  const createTemplate = async () => {
    if (!name) {
      setError("Template name can't be empty.")
      return
    }

    if (isCreating) return
    setCreatingStatus(true)

    try {
      const newTemplate = showTypePicker
        ? deepCloneObject(
            type === 'Modern'
              ? BLANK_LINK_IN_BIO_TEMPLATE
              : BLANK_DIGITAL_CARD_TEMPLATE
          )
        : deepCloneObject(card)
      clearCardValues(newTemplate)
      newTemplate.label = name
      newTemplate.templateId = generateUniqueID()

      if (showTypePicker) {
        newTemplate.pageType = type
      }
      // if (newTemplate.avatar) {
      //   delete newTemplate.avatar.url
      // }
      // newTemplate.personalDetails.name = 'Full Name'
      // newTemplate.personalDetails.title = 'Title'
      // delete newTemplate.personalDetails.phone
      // delete newTemplate.personalDetails.birthday
      delete newTemplate.slug
      delete newTemplate.id
      delete newTemplate.cardID
      delete newTemplate.synchronizeChanges
      // INTEGRATIONS.forEach((integration) => {
      //   if (newTemplate[integration.property]) {
      //     delete newTemplate[integration.property]
      //   }
      // })
      if (card) {
        newTemplate.createdFromCard = card.cardID
      } else {
        generateSectionsIDs(newTemplate)
      }
      await createTemplateFromCard(newTemplate)
      if (showTypePicker) {
        history.push(
          `/${newTemplate.templateId || newTemplate.label}/edit/template`
        )
      } else {
        history.push('/templates')
      }
      showToast({
        content: 'Template created successfully.',
        variant: 'success',
      })
      setCreatingStatus(false)
      onClose()
    } catch (e) {
      console.error(e)
      showToast({ content: e.message })
      setCreatingStatus(false)
    }
  }

  useEffect(() => {
    const onKeyUp = (event) => event.keyCode === 13 && createTemplate()
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [createTemplate])

  return (
    <BaseModal
      title="New Template"
      maxWidth="660px"
      onClose={onClose}
      actions={
        screen === 'type' ? (
          type ? (
            <Button
              key="Next"
              onClick={() => setScreen('details')}
              icon={<ArrowRight size={20} color={colors.white} />}
            >
              Next
            </Button>
          ) : null
        ) : (
          <Button
            onClick={createTemplate}
            loading={isCreating}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Create Template
          </Button>
        )
      }
    >
      <Container>
        {screen === 'type' ? (
          <>
            <InfoHeader>
              What type of template would you like to create?
            </InfoHeader>
            <PagesContainer>
              <PageType
                selected={type === 'Modern'}
                onClick={() => setType('Modern')}
              >
                <PageTitle>Modern</PageTitle>
                <LinkInBio width={!isMobile ? 180 : '100%'} height="100%" />
              </PageType>
              <PageType
                selected={type === 'Classic'}
                onClick={() => setType('Classic')}
              >
                <PageTitle>Classic</PageTitle>
                <BusinessCard width={!isMobile ? 180 : '100%'} height="100%" />
              </PageType>
            </PagesContainer>
          </>
        ) : (
          <>
            <FloatingInput
              value={name}
              onChange={updateNameValue}
              label="Template name"
              description="This name will be used as a title for your template."
              error={error}
              autoFocus
              style={{ marginTop: 12 }}
            />
            <Caption lineHeight="big" padding="4px">
              The template will be added to the "Templates" list and will be
              used as a default one next time you create a new page. Templates
              will have all the properties saved, as: colors, links, banner,
              etc.
            </Caption>
          </>
        )}
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 26px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const InfoHeader = styled.h2`
  width: 100%;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 20px 0;
  text-align: center;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
`

const PagesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const PageType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 24px 16px 24px;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  background-color: ${(props) =>
    props.selected ? colors.mainLight : 'transparent'};
  border: ${(props) =>
    props.selected ? `1px solid ${colors.main}` : '1px solid transparent'};
  border-radius: 12px;
  box-sizing: border-box;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    padding: 6px 12px 16px 12px;
  }
`

const PageTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin: 4px 0 16px 0;
  text-align: center;
`

export default CreateTemplateModal
