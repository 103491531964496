import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { colors, animations } from '../core/theme'

const Modal = ({
  children,
  forwardRef,
  onClose,
  forceFullscreen,
  ...restProps
}) => {
  const [modalContainer] = useState(document.createElement('div'))

  const onOverlayClick = (event) =>
    event.target === event.currentTarget && onClose()

  useEffect(() => {
    const onKeyUp = (event) => event.keyCode === 27 && onClose()
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [onClose])

  useEffect(() => {
    let root = document.getElementById('root')
    if (forceFullscreen) {
      if (document.getElementById('desktop-editor')) {
        root = document.getElementById('desktop-editor')
      }
    }
    root.appendChild(modalContainer)
    return () => {
      root.removeChild(modalContainer)
    }
  }, [modalContainer])

  return ReactDOM.createPortal(
    <Overlay
      onClick={onOverlayClick}
      forceFullscreen={forceFullscreen}
      {...restProps}
      ref={forwardRef}
    >
      {children}
    </Overlay>,
    modalContainer
  )
}

const Overlay = styled.div`
  position: ${(props) => (props.forceFullscreen ? 'absolute' : 'fixed')};
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'center'};
  background-color: ${colors.overlay};
  animation: ${animations.fadeIn} 200ms ease-out;
  z-index: 90000999;
`

export default Modal
