import React, { memo } from 'react'
import { colors } from '../core/theme'
import { returnDivider } from '../helpers/returnDivider'
import { NewTabIcon } from '../assets/icons'
import { Paragraph } from './Typography'

const Attribute = ({ label, value, href, multiline, color, valueHref }) => {
  const divider = returnDivider(label)
  const text =
    typeof value === 'object'
      ? React.isValidElement(value)
        ? value
        : JSON.stringify(value)
      : value

  return (
    <Paragraph variant="secondary" padding="6px 0" lineHeight="medium">
      {label}
      {divider} {multiline && <br />}
      <span style={{ color: colors.primary }}>{text}</span>
      {href && (
        <a href={href} rel="noopener noreferrer" target="_blank">
          <NewTabIcon
            size={16}
            translate="translate(5 5)"
            color={color || colors.main}
          />
        </a>
      )}
      {valueHref && (
        <a href={valueHref} rel="noopener noreferrer" target="_blank">
          {valueHref}
        </a>
      )}
    </Paragraph>
  )
}

export default memo(Attribute)
