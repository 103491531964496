import React from 'react'
import styled from 'styled-components'
import { Row } from '../Layout'
import { Caption, Label } from '../Typography'

const CardLegend = ({ legend, colors = [] }) => {
  return (
    <Container>
      {legend.map(({ name, count = 0, percent = 0, title }, i) =>
        percent === 0 ? null : (
          <Row key={name || title} align="center" margin="8px">
            <Color style={{ backgroundColor: colors[i % colors.length] }} />
            <Name>{name || title}</Name>
            <Count weight="medium">{count}</Count>
            <Percent>{percent}%</Percent>
          </Row>
        )
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0px 8px 16px;
  @media (max-width: 767px) {
    padding: 8px;
  }
`

const Color = styled.div`
  width: 18px;
  height: 18px;
  flex-shrink: 0;
`

const Name = styled(Label)`
  width: 100%;
  box-sizing: border-box;
  line-height: 1.5;
  padding: 0 8px 0 12px;
`

const Count = styled(Label)`
  width: 50px;
  text-align: right;
  padding: 0 4px;
`

const Percent = styled(Caption)`
  width: 50px;
  text-align: right;
  padding: 0 4px;
`

export default CardLegend
