import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import ActionModal from './ActionModal'
import { DuplicateIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { useActions } from '../store'

const DuplicateCardModal = ({ card, onClose }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { showToast } = useActions().toasts
  const { addCardToAccount } = useActions().cards
  const [loading, setLoadingStatus] = useState(false)

  const duplicateCard = async () => {
    setLoadingStatus(true)

    try {
      const duplicatedCard = deepCloneObject(card)
      duplicatedCard.label += ' Copy'
      delete duplicatedCard.slug
      const addedCard = await addCardToAccount({
        card: duplicatedCard,
        duplicate: true,
      })
      if (!isMobile) {
        if (window.location.pathname.includes('edit')) {
          history.push(`/${addedCard.cardID}`)
        } else {
          history.push(`/${addedCard.cardID}/edit`)
        }
      }
    } catch (e) {
      showToast({ content: 'Unexpected error occurred. Please try again.' })
    }

    setLoadingStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DuplicateIcon size={50} color={colors.main} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={duplicateCard}
            loading={loading}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, duplicate
          </Button>
        </>
      }
      header={`Duplicate ${card.label}?`}
      description="You’re about to duplicate one of your pages. Are you sure you want to continue?"
    />
  )
}

export default DuplicateCardModal
