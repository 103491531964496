import React from 'react'
import styled from 'styled-components'
import { colors } from '../../core/theme'

const IconTab = ({ onClick, isSelected, icon, label, color }) => (
  <Container onClick={onClick} isSelected={isSelected}>
    <IconWrapper>{icon}</IconWrapper>
    {label && (
      <TabLabel color={color} isSelected={isSelected}>
        {label}
      </TabLabel>
    )}
  </Container>
)

const Container = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 80px;
  height: 56px;
  box-sizing: border-box;
  outline: none;

  border-left: 1px solid #fcfcfc;
  border-right: 1px solid #fcfcfc;
  margin-left: -1px;

  background-color: ${(props) =>
    props.isSelected ? `${colors.main}08` : 'transparent'};

  :active {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.5)};
  }

  :hover {
    opacity: ${({ isSelected }) => (isSelected ? 1 : 0.8)};
    background-color: ${colors.main}08;
  }

  @media (max-width: 767px) {
    width: 68px;
  }
  @media (max-width: 320px) {
    width: 60px;
  }
`

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
`

const TabLabel = styled.span`
  white-space: nowrap;
  font-size: 10px;
  text-decoration: none;
  color: ${(props) =>
    props.isSelected ? props.color || colors.main : colors.primary};
  font-weight: ${(props) => (props.isSelected ? 'bold' : '500')};
`

export default IconTab
