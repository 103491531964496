import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { clearCardValues } from 'konect-react'
import { useMediaQuery } from 'react-responsive'
import EditCardPanel from '../components/EditCardPanel'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { updateCardData } from '../api/database-api'
import { usePermission } from '../hooks/usePermission'
import CardPreview from '../components/CardPreview'
import { useActions, useStore } from '../store'
import LoadingError from '../components/LoadingError'
import { licenseStatus } from '../helpers/licenseStatus'
import { generateDifferenceObject } from '../helpers/generateDifferenceObject'

const EditCard = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { cards } = useStore().cards
  const { profile, license } = useStore()
  const { templates = [] } = license
  const { showToast } = useActions().toasts
  const { updateTemplate, updateConnectedPages } = useActions().license
  const [card, setCard] = useState({})
  const [originalTemplate, setOriginalTemplate] = useState({})
  const [currentPage, setCurrentPage] = useState(0)
  const [error, setError] = useState(false)
  const [showSave, setSaveStatus] = useState(false)
  const [isSaving, setSavingStatus] = useState(false)
  const {
    subscriptionName,
    isActive,
    isTrialActive,
    isCustom,
    cardSlots = [],
  } = licenseStatus(license)
  let isCardActive = cardSlots.find((s) => s.cardID === card.cardID)
  let isFreeCard = subscriptionName === 'free' || (isActive && !isCardActive)
  const isTemplate = window.location.pathname.includes('template')
  if (isCustom || isTemplate) {
    isCardActive = true
    isFreeCard = false
  }
  const { canEdit, showPermissionAlert } = usePermission(card, isTemplate)
  if (!isCardActive) {
    delete card.poweredBy
    if (card.personalDetails) {
      delete card.personalDetails.verified
    }
  }

  const templateLockProps = {
    card,
    showLock: isTemplate && card.templateId,
    isLocked: (name, type) => {
      const { lockedFields = {} } = card
      if (type === 'content') {
        // check for isLocked inside object
      }
      return lockedFields[name]
    },
    onLock: (name, type) => {
      if (card.cardID) return
      const { lockedFields = {} } = card
      if (type === 'content') {
        // check for isLocked inside object
      }
      lockedFields[name] = !lockedFields[name]
      card.lockedFields = lockedFields
      updateCard(card, true)
    },
    showLockMessage: (customMessage) => {
      showToast({
        content: customMessage || `Field is locked and can't be edited.`,
      })
    },
  }

  useEffect(() => {
    if (isTemplate) {
      const templateId = window.location.pathname.split('/')[1]
      const selectedTemplate = templates.find((t) => {
        if (t.templateId) {
          return t.templateId === templateId
        }
        return t.label === decodeURIComponent(templateId)
      })
      if (!selectedTemplate) {
        setError(true)
      }
      setCard(deepCloneObject(selectedTemplate))
      setOriginalTemplate(deepCloneObject(selectedTemplate))
      return
    }

    const cardID = window.location.pathname.split('/')[1]
    const currentCard = cards.filter((f) => f.cardID === cardID)[0]

    if (currentCard) {
      let lockedFields = {}
      if (currentCard.templateId) {
        const foundTemplate = templates.find(
          (t) => t.templateId === currentCard.templateId
        )
        if (foundTemplate) {
          lockedFields = foundTemplate.lockedFields || {}
        }
      }
      setCard(deepCloneObject({ ...currentCard, lockedFields }))
      setError(false)
    }

    if (cards.length > 0 && !currentCard) {
      setError(true)
    }
  }, [cards])

  useEffect(() => {
    const warnUserAboutUnsavedChanges = (e) => {
      if (!showSave) return ''

      const confirmationMessage =
        'It looks like you have been editing your card. ' +
        'If you leave before saving, your changes will be lost.'

      ;(e || window.event).returnValue = confirmationMessage
      return confirmationMessage
    }

    window.addEventListener('beforeunload', warnUserAboutUnsavedChanges)
    return () => {
      window.removeEventListener('beforeunload', warnUserAboutUnsavedChanges)
    }
  }, [showSave])

  const updateCard = (newCard, forceSave) => {
    setCard(deepCloneObject(newCard))
    if (forceSave) {
      saveChanges(newCard)
    } else {
      setSaveStatus(true)
    }
  }

  const saveChanges = async (newCard) => {
    if (isSaving) return

    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (!newCard.license) {
      newCard.license = profile.license || ''
    }
    if (!newCard.label) {
      window.alert("Page name can't be empty.")
      return
    }

    setSavingStatus(true)

    try {
      clearCardValues(newCard)
      if (isTemplate) {
        await updateTemplate(newCard)
        if (card.synchronizeChanges) {
          setOriginalTemplate(deepCloneObject(newCard))
          updateConnectedPages({
            templateId: newCard.templateId,
            difference: generateDifferenceObject(originalTemplate, newCard),
          })
        }
      } else {
        await updateCardData(newCard)
      }
    } catch (e) {
      showToast({ content: e.message })
    }

    setSaveStatus(false)
    setSavingStatus(false)
  }

  if (error) {
    return (
      <LoadingError
        error={
          <span>
            Page does not exist or
            <br />
            the link is broken
          </span>
        }
      />
    )
  }

  if (!card.cardID && !isTemplate) {
    return <Container />
  }

  return (
    <Container>
      <EditCardPanel
        card={card}
        updateCard={updateCard}
        saveChanges={saveChanges}
        showSave={showSave}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        isSaving={isSaving}
        isCardActive={isCardActive}
        isActive={isActive}
        isTrialActive={isTrialActive}
        isFreeCard={isFreeCard}
        isTemplate={isTemplate}
        templateLockProps={templateLockProps}
      />
      {!isMobile && (
        <CardPreview
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          updateCard={updateCard}
          card={card}
          showSave={showSave}
          saveChanges={saveChanges}
          editMode
          isFreeCard={isFreeCard}
          isCardActive={isCardActive}
          isTemplate={isTemplate}
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100vh;
  position: relative;
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`

export default EditCard
