import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { colors } from '../core/theme'
import { DropImageIcon } from '../assets/icons'
import { useActions } from '../store'
import { uploadButtonImage } from '../api/storage-api'
import Spinner from './Spinner'
import { BUTTON_STYLE_TYPES } from '../core/constants'

const ButtonImageUpload = ({ card, button, updateCard }) => {
  const url = button.image
  const buttonType = button.buttonType || BUTTON_STYLE_TYPES[0].value
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const [error, setError] = useState()
  const [uploading, setUploading] = useState(false)
  let aspectRatio = 1 / 1
  if (
    buttonType === 'image-top' ||
    buttonType === 'carousel' ||
    buttonType === 'grid'
  ) {
    aspectRatio = 16 / 9
  }

  const saveCroppedImage = async (uploadedImage, fileExtension) => {
    try {
      const imageUrl = await uploadButtonImage(
        uploadedImage,
        fileExtension,
        card
      )
      button.image = imageUrl
      updateCard(card)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (uploading) return
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('File must be an image.')
      } else {
        setError('File exceeded max 5mb size.')
      }
      return
    }

    setError(false)
    setUploading(true)

    const uploadedImage = acceptedFiles[0]
    if (!uploadedImage) return

    const imageDataUrl = URL.createObjectURL(uploadedImage)
    const parts = uploadedImage.name.split('.')
    const fileExtension = parts[parts.length - 1] || ''

    if (fileExtension.toLowerCase() === 'gif') {
      try {
        const logoUrl = await uploadButtonImage(
          uploadedImage,
          fileExtension,
          card
        )
        button.image = logoUrl
        updateCard(card)
      } catch (e) {
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
      setUploading(false)
      return
    }

    showModal({
      name: 'CROP_IMAGE',
      image: imageDataUrl,
      fileExtension,
      aspect: aspectRatio,
      onSave: saveCroppedImage,
    })

    setUploading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    maxSize: 5000000,
  })

  const extraProps = {}
  if (url) {
    extraProps.onClick = () => {
      const result = window.confirm(
        `You are about to delete this image. Are you sure you want to continue?`
      )
      if (result) {
        delete button.image
        updateCard(card)
      }
    }
  }

  return (
    <Container>
      <InputContainer {...getRootProps()} {...extraProps}>
        <EmptyInput {...getInputProps()} />
        <InputField error={error}>Image</InputField>
        <SuffixContainer>
          {url ? (
            <SuffixImage src={url} alt="logo" />
          ) : uploading ? (
            <Spinner />
          ) : (
            <DropImageIcon />
          )}
        </SuffixContainer>
      </InputContainer>
      {error && <Error>{error}</Error>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
`

const EmptyInput = styled.input`
  display: none;
  position: relative;
  width: 0;
  height: 0;
`

const Error = styled.span`
  font-size: 14px;
  color: ${colors.error};
  margin-top: 8px;
  margin-left: 6px;
  margin-bottom: 4px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
`

const InputField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 14px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  :hover {
    border: 1px solid
      ${(props) => (props.error ? colors.error : 'rgba(0, 0, 0, 0.87)')};
  }
  @media (max-width: 767px) {
    :hover {
      border: 1px solid
        ${(props) => (props.error ? colors.error : colors.border)};
    }
  }
`

const SuffixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  height: 50px;
  width: 50px;
  line-height: 1.5;
  color: ${colors.darkgrey};
  background-color: ${colors.prefix};
  border: 1px solid ${colors.border};
  border-left: none;
  box-sizing: border-box;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
`

const SuffixImage = styled.img`
  width: 40px;
  height: 40px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
`

export default ButtonImageUpload
