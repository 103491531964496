import React from 'react'
import styled from 'styled-components'
import FloatingInput from '../FloatingInput'

export default function Video({ section, card, updateCard }) {
  const { question, description } = section

  const onQuestionChange = (e) => {
    section.question = e.target.value
    updateCard(card)
  }

  const onDescriptionChange = (e) => {
    section.description = e.target.value
    updateCard(card)
  }

  return (
    <EditSectionContainer>
      <FloatingInput
        label="Video URL"
        placeholder="e.g. https://www.youtube.com/watch?v=zEtYTEUOPWI"
        value={section.video || ''}
        description="You can use YouTube, Vimeo or any video file link."
        onChange={(e) => {
          section.video = e.target.value
          updateCard(card)
        }}
      />
      <FloatingInput
        onChange={onQuestionChange}
        label="Header"
        value={question}
        style={{ marginTop: 8 }}
      />
      <FloatingInput
        onChange={onDescriptionChange}
        label="Description"
        value={description}
        style={{ marginTop: 8 }}
      />
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;
`
