import React, { useState } from 'react'
import styled from 'styled-components'
import { clearCardValues } from 'konect-react'
import Button from '../../components/Button'
import { cloneArrayDeep, deepCloneObject } from '../../helpers/deepCloneObject'
import { colors, shadows } from '../../core/theme'
import { usePermission } from '../../hooks/usePermission'
import CardPreview from '../../components/CardPreview'
import { useActions, useStore } from '../../store'
import { CARDS_TEMPLATES } from '../../core/templates/cards-templates'

const TemplatePreview = ({
  selectedTemplate,
  history,
  location,
  selectTemplate,
}) => {
  const { license } = useStore()
  const { templates = [] } = license
  const allTemplates = cloneArrayDeep(templates).concat(CARDS_TEMPLATES)
  const { canEdit, showPermissionAlert } = usePermission()
  const { addCardToAccount } = useActions().cards
  const [addingTemplate, setAddingStatus] = useState(false)
  const { showToast } = useActions().toasts
  const template_id = window.location.pathname.split('/')[2]
  let template = selectedTemplate
  if (!template) {
    template =
      allTemplates.filter((temp) => temp.label === template_id)[0] || {}
  }

  const hideMobile = !location

  const addTemplateToAccount = async () => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (addingTemplate) return

    setAddingStatus(true)

    try {
      const clearTemplate = deepCloneObject(template)
      clearCardValues(clearTemplate)
      const card = await addCardToAccount({ card: clearTemplate })
      if (card) {
        history.push('/' + card.cardID)
      }
    } catch (e) {
      showToast({ content: 'Unexpected error occurred. Please try again.' })
    }

    setAddingStatus(false)
  }

  if (!template || !template.label) {
    return <Container hideMobile={hideMobile} />
  }

  return (
    <Container hideMobile={hideMobile}>
      <UseTemplate
        loading={addingTemplate}
        onClick={addTemplateToAccount}
        small
        className="primary-compact-button"
      >
        Use Template
      </UseTemplate>
      <CardPreview
        card={template}
        editMode
        isTemplate
        hideSend
        showDelete={template.createdFromCard}
        selectTemplate={selectTemplate}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  flex: 3;
  position: relative;
  background-color: ${colors.white};
  box-shadow: ${shadows.menu};
  border-top-left-radius: 10px;
  @media (min-width: 768px) {
    min-width: 600px;
  }
  @media (max-width: 767px) {
    display: ${(props) => (props.hideMobile ? 'none' : 'flex')};
    flex-direction: column;
    height: auto;
    padding-bottom: 64px;
  }
`

const UseTemplate = styled(Button)`
  position: absolute;
  left: 16px;
  margin: 11px 0;
  z-index: 4;
`

export default TemplatePreview
