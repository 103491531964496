import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions } from '../store'

const RemoveEmployeeModal = ({ employee, onClose }) => {
  const { showToast } = useActions().toasts
  const { removeEmployee } = useActions().license
  const [removing, setRemovingStatus] = useState(false)

  const { name, email } = employee

  const onRemoveClicked = async () => {
    if (removing) return

    setRemovingStatus(true)
    try {
      await removeEmployee(employee)
    } catch (e) {
      showToast({ content: e.message })
    }
    setRemovingStatus(false)
    onClose()
  }

  return (
    <ActionModal
      onClose={onClose}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={onRemoveClicked}
            loading={removing}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, remove
          </Button>
        </>
      }
      header={`Remove ${name || email}?`}
      description={
        <span>
          You’re about to remove employee from your account. Are you sure you
          want to?
        </span>
      }
    />
  )
}

export default RemoveEmployeeModal
