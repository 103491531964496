import styled from 'styled-components'
import { colors } from '../core/theme'

export const TopBar = styled.div`
  position: ${(props) => props.position || 'relative'};
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  background-color: ${colors.white};
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  height: 58px;
  max-height: 58px;
  font-size: 15px;
  font-weight: 500;
`
