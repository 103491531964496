import React from 'react'
import FloatingInput from '../FloatingInput'

export default function AdminSettings({ card, updateCard }) {
  return (
    <>
      <FloatingInput
        label="Custom JavaScript code"
        rows={8}
        multiline
        style={{ marginTop: 16 }}
        placeholder={`<script>
...
</script>`}
        value={card.customJavaScript}
        onChange={(e) => {
          card.customJavaScript = e.target.value
          updateCard(card)
        }}
        description="Code will be added to the <head/> tag."
      />
      <FloatingInput
        label="Custom CSS code"
        rows={8}
        multiline
        placeholder={`.ks-contact-button {
font-size: 18px;
}`}
        value={card.customCSS}
        onChange={(e) => {
          card.customCSS = e.target.value
          updateCard(card)
        }}
        description="Inspect page elements to find class names."
      />
    </>
  )
}
