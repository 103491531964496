import React from 'react'
import styled from 'styled-components'

export default function PricingPlan({
  name,
  description,
  price,
  button,
  features,
}) {
  return (
    <Container>
      <Header>{name}</Header>
      <Description>{description}</Description>
      <Price>{price}</Price>
      {button}
      <FeaturesContainer>{features}</FeaturesContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid #c3cdd3;
  background-color: #ededed;
  padding: 24px 36px;
  border-radius: 12px;
  height: 100%;
  box-sizing: border-box;
  margin: 12px 8px;
  @media (max-width: 1000px) {
    padding: 16px 24px;
    margin: 10px 0;
  }
`

const Header = styled.span`
  font-size: 21px;
  font-weight: 700;
  margin: 8px 0;
  line-height: 1.4;
`

const Description = styled.span`
  font-size: 15px;
  line-height: 1.6;
  margin: 8px 0;
`

const Price = styled.span`
  font-size: 24px;
  font-weight: 700;
  margin: 12px 0;
  line-height: 1.2;
  margin-bottom: 32px;
  height: 50px;
  @media (max-width: 1000px) {
    height: auto;
  }
`

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`
