import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { ArrowRight } from '../assets/icons'
import { useActions, useStore } from '../store'
import { Label } from '../components/Typography'
import { generateBillingSession } from '../api/billing-api'
import FloatingSelect from '../components/FloatingSelect'

const SLOTS = new Array(500).fill().map((v, i) => i + 1)

export default function CreateSubscriptionModal({
  onClose,
  subscriptionName,
  subscriptionType,
  price,
}) {
  const { profile, license } = useStore()
  const { showToast } = useActions().toasts
  const [slots, setSlots] = useState(1)
  const [loading, setLoading] = useState(false)

  const startCheckout = async () => {
    if (loading) return
    setLoading(true)
    try {
      const { data } = await generateBillingSession({
        customerEmail: profile.email,
        license: profile.license,
        customerId: license.customerId,
        subscriptionName,
        subscriptionType,
        quantity: parseInt(slots, 10),
      })
      const { url } = data
      if (!url) {
        throw new Error('Something went wrong. Please contact our support.')
      }
      window.location.href = url
    } catch (e) {
      console.error(e)
      showToast({ content: e.message })
    }
    setLoading(false)
  }

  return (
    <BaseModal
      title="Start Subscription"
      maxWidth="640px"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} outline style={{ margin: '0 8px' }}>
            Cancel
          </Button>
          <Button
            onClick={startCheckout}
            loading={loading}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Checkout
          </Button>
        </>
      }
    >
      <BodyWrapper>
        <FloatingSelect
          onChange={(e) => {
            setSlots(parseInt(e.target.value, 10))
          }}
          label="Number of premium pages"
          description="Pick the number of premium pages that will be added to your account."
          value={slots}
          style={{ marginTop: 8 }}
          options={SLOTS.map((i) => {
            return (
              <option key={i} value={i}>
                {i}
              </option>
            )
          })}
        />
        <Label padding="4px" lineHeight="big">
          In Checkout you will be asked to pay for your subscription.
        </Label>
        <Label padding="4px" lineHeight="big">
          Subscription will cost:{' '}
          <strong>
            ${slots * price} / {subscriptionType}.
          </strong>
        </Label>
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 28px 16px 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`
