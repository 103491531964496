import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import firebase from 'firebase/app'
import 'firebase/auth'
import { config } from '../core/config'
import { queryStringToJSON } from '../helpers/queryStringToJSON'
import PageLoader from '../components/PageLoader'
import LoadingError from '../components/LoadingError'
import {
  getCannyToken,
  logoutUser,
  updateAccountCreatedData,
} from '../api/auth-api'
import { useActions } from '../store'
import { addQueryToURL } from '../helpers/addQueryToURL'
import { cannyGetRedirectURL } from '../helpers/cannyGetRedirectURL'
import { sleep } from '../helpers/sleep'

firebase.initializeApp(config.FIREBASE_CONFIG)

export const AuthProvider = ({ children }) => {
  const history = useHistory()
  const { profile, license, inbox, cards, accessories, invitation } =
    useActions()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      profile.clear()
      license.clear()
      inbox.clear()
      cards.clear()
      setLoading(true)
      const parameters = queryStringToJSON()

      if (parameters.ref) {
        window.konectAffiliateRef = parameters.ref
      }
      if (parameters.REF) {
        window.konectAffiliateRef = parameters.REF
      }

      if (parameters.invitation) {
        if (!window.failedInvitation) {
          try {
            await invitation.getInvitation(parameters.invitation)
          } catch (e) {
            console.error(e)
            window.failedInvitation = true
            setError('Invitation no longer exists.')
          }
        }
      }

      try {
        if (user) {
          if (!user.displayName && window.userName) {
            await user.updateProfile({
              displayName: window.userName,
            })
          }
          const konectProfile = await profile.getUserProfile({
            user,
          })
          window.ks_konectProfile = konectProfile
          await license.getLicenseData()
          if (window.nativeForms) {
            window.nativeForms.name = user.displayName
            window.nativeForms.email = user.email
          }
          updateAccountCreatedData({
            email: konectProfile.email,
          })
          if (parameters.client_id) {
            const clientID = parameters.client_id
            if (clientID === config.ACTIVE_CAMPAIGN_CLIENT_ID) {
              const redirectURL = parameters.redirect_uri
              const extraData = parameters.redirect_uri ? parameters : {}
              const code = konectProfile.license
              window.location.href = addQueryToURL(redirectURL, {
                ...extraData,
                code,
              })
              return
            }
          }
          if (parameters.canny) {
            const cannyToken = await getCannyToken(konectProfile)
            const ssoToken = cannyToken.data
            const redirectURL = cannyGetRedirectURL(ssoToken)
            if (redirectURL) {
              window.location.assign(redirectURL)
              await sleep(2000)
            }
          }
          if (window.Beacon) {
            window.Beacon('identify', {
              name: user.displayName,
              email: user.email,
              avatar: user.photoURL,
            })
            window.Beacon('config', { hideFABOnMobile: true })
          }
        }
      } catch (e) {
        console.error(e)
        setError(e.message)
      }
      setLoading(false)
    })
  }, [])

  if (error) {
    return (
      <LoadingError
        error={error}
        logout={() => {
          if (history) history.push('/')
          inbox.clear()
          cards.clear()
          license.clear()
          profile.clear()
          accessories.clear()
          invitation.clear()
          logoutUser()
          setError(false)
          setLoading(false)
        }}
      />
    )
  }
  if (loading) return <PageLoader />
  return children
}
