/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import PremiumBadge from './PremiumBadge'
import TemplateLock from './TemplateLock'
import { SettingsIcon } from '../assets/icons'

export default function SwitchInput({
  label,
  nodeLabel,
  description,
  value,
  onChange,
  error,
  style,
  inputStyle,
  className,
  prefix,
  onClick,
  premium,
  showLock,
  isLocked = () => {},
  onLock,
  card = {},
  showLockMessage,
  readOnly,
  extraChild,
  settings,
}) {
  return (
    <Container style={style} className={className}>
      <InputContainer
        onClick={(e) => {
          if (onClick && settings) {
            onClick(e)
          }
        }}
      >
        {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
        <InputField style={inputStyle} error={error} hasPrefix={prefix}>
          {nodeLabel || label}
          {premium && (
            <PremiumContainer>
              <PremiumBadge
                type="feature"
                direction="bottom"
                arrowPosition="top"
                size={14}
              />
            </PremiumContainer>
          )}
          {extraChild}
        </InputField>
        {settings ? (
          <SettingsContainer
            showLock={showLock || (card.cardID && isLocked(label))}
            onClick={(e) => {
              if (onClick) {
                onClick(e)
              }
            }}
          >
            <SettingsIcon size={20} />
          </SettingsContainer>
        ) : (
          <SwitchWrapper
            showLock={showLock || (card.cardID && isLocked(label))}
            onClick={() => {
              if (isLocked(label)) {
                showLockMessage()
              }
            }}
          >
            <label className="switch-small">
              <input
                checked={value}
                type="checkbox"
                disabled={isLocked(label) || readOnly}
                onClick={(e) => {
                  if (onClick) {
                    onClick(e)
                  }
                }}
                onChange={() => {
                  if (onChange) {
                    onChange(!value)
                  }
                }}
              />
              <span className="slider-small round" />
            </label>
          </SwitchWrapper>
        )}
        {(showLock || (card.cardID && isLocked(label))) && (
          <TemplateLock onLock={onLock} label={label} isLocked={isLocked} />
        )}
      </InputContainer>
      {error && <InputError>{error}</InputError>}
      {description && !error && (
        <InputDescription>{description}</InputDescription>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
`

const InputDescription = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.secondary};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const InputError = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.error};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px 14px;
  height: 50px;
  line-height: 1.5;
  color: ${colors.darkgrey};
  background-color: ${colors.prefix};
  border: 1px solid ${colors.border};
  border-right: none;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
`

const InputField = styled.div`
  display: flex;
  align-items: center;
  cursor: default;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 14px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  ${(props) =>
    props.hasPrefix
      ? `
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  `
      : ''}
`

const SwitchWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  right: ${(props) => (props.showLock ? '22px' : '12px')};
  top: 12px;
`

const PremiumContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: ${(props) => (props.showLock ? '22px' : '15px')};
  top: 15px;
`
