import React, { useEffect, useRef, useState } from 'react'
import SketchPicker from 'react-color/lib/components/sketch/Sketch'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { InfoTooltip } from './InfoTooltip'
import { Paragraph } from './Typography'

const ColorPicker = ({
  style,
  onChangeComplete,
  color = '',
  changeToDefault,
}) => {
  const [showPicker, setPickerState] = useState(false)
  const pickerRef = useRef()

  const changePickerState = () => {
    setPickerState(!showPicker)
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (pickerRef.current) {
        if (!pickerRef.current.contains(event.target) && showPicker) {
          setPickerState(false)
        }
      }
    }
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [pickerRef.current, showPicker])

  return (
    <Container style={style}>
      <Wrapper onClick={changePickerState}>
        <Paragraph>{color.toUpperCase()}</Paragraph>
        <ColorContainer color={color} />
      </Wrapper>
      {changeToDefault && (
        <InfoTooltip
          icon={<ReloadIcon size={18} />}
          onClick={changeToDefault}
          direction="bottom"
          arrowPosition="top"
          style={{ margin: '0 0 0 8px' }}
          description="Reset to default"
        />
      )}
      {showPicker && (
        <PickerContainer
          ref={pickerRef}
          onMouseLeave={() => setPickerState(false)}
        >
          <SketchPicker
            color={color}
            disableAlpha
            onChangeComplete={onChangeComplete}
          />
        </PickerContainer>
      )}
    </Container>
  )
}

const ReloadIcon = ({ size = 16, fill = colors.lightgrey }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={fill}
    viewBox="0 0 24 24"
  >
    <path d="M12 6v3l4-4-4-4v3c-4.42 0-8 3.58-8 8 0 1.57.46 3.03 1.24 4.26L6.7 14.8c-.45-.83-.7-1.79-.7-2.8 0-3.31 2.69-6 6-6zm6.76 1.74L17.3 9.2c.44.84.7 1.79.7 2.8 0 3.31-2.69 6-6 6v-3l-4 4 4 4v-3c4.42 0 8-3.58 8-8 0-1.57-.46-3.03-1.24-4.26z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </svg>
)

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 8px;
  margin: ${(props) => props.margin || 0};
  background-color: ${colors.white};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${colors.border};
  padding: 8px;
  border-radius: 8px;
  align-items: center;
`

const ColorContainer = styled.div`
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-color: ${(props) => props.color};
  margin-left: 8px;
  border-radius: 50%;
  border: 1px solid ${colors.border};
`

const PickerContainer = styled.div`
  position: absolute;
  z-index: 9999999;
  top: 28px;
  left: 0;
`

export default ColorPicker
