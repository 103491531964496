import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import FloatingInput from '../components/FloatingInput'

export default function AdvancedContactDetailsModal({
  onClose,
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  templateLockProps,
}) {
  const { personalDetails = {} } = card
  const { phone, birthday } = personalDetails

  return (
    <BaseModal
      title={
        showSave ? (
          <Button
            small
            loading={isSaving}
            onClick={() => saveChanges(card)}
            className="save-button"
          >
            Save
          </Button>
        ) : null
      }
      forceFullscreen
      onClose={onClose}
    >
      <Container>
        <FloatingInput
          {...templateLockProps}
          label="Phone Number"
          value={phone}
          style={{ marginTop: 8 }}
          onChange={(e) => {
            personalDetails.phone = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
        <FloatingInput
          {...templateLockProps}
          shrink
          type="date"
          id="birthday"
          name="birthday"
          label="Birth Date"
          style={{ marginTop: 8 }}
          inputProps={{ style: { display: 'flex' } }}
          value={birthday}
          onChange={(e) => {
            personalDetails.birthday = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 24px 24px 20px 24px;
  @media (max-width: 767px) {
    padding: 20px;
  }
`
