import React, { memo, useState } from 'react'
import styled from 'styled-components'

const Flag = ({ code = '' }) => {
  const [error, setError] = useState()

  if (error)
    return (
      <Container
        alt={code}
        src={require('../assets/icons/eye_off.svg').default}
      />
    )

  return (
    <Container
      alt={code}
      src={`https://flagcdn.com/${code.toLowerCase()}.svg`}
      onError={() => setError(true)}
    />
  )
}

const Container = styled.img`
  width: 28px;
  height: 20px;
  border-radius: 2px;
`

export default memo(Flag)
