import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions } from '../store'

const DeleteCardModal = ({ card, onClose }) => {
  const { cards, toasts } = useActions()
  const [isDeleting, setDeleteStatus] = useState(false)

  const deleteCard = async () => {
    if (isDeleting) return
    setDeleteStatus(true)
    try {
      await cards.deleteCard(card)
    } catch (e) {
      console.error(e)
      toasts.showToast({
        content: 'Unexpected error occurred. Please try again.',
      })
    }
    setDeleteStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DeleteIcon size={60} color={colors.error} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={deleteCard}
            loading={isDeleting}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, delete
          </Button>
        </>
      }
      header={`Delete ${card.label}?`}
      description="You're about to delete one of your pages. Are you sure you want to continue?"
    />
  )
}

export default DeleteCardModal
