import React, { useState, createRef } from 'react'
import styled from 'styled-components'
import { colors, shadows } from '../../core/theme'
import Button from '../../components/Button'
import InboxCardRow from '../../components/InboxCardRow'
import FiltersHeader from '../../components/FiltersHeader'
import { getCompletedCards } from '../../api/database-api'
import { EyeOffIcon, SendIcon } from '../../assets/icons'
import PageLoader from '../../components/PageLoader'
import { useActions, useStore } from '../../store'
import {
  getLastWeekTime,
  getLastMonthTime,
  getLastQuarterTime,
  getLastYearTime,
  getStartTime,
} from '../../helpers/days'
import { Paragraph } from '../../components/Typography'
import TagsFilters from '../../components/TagsFilters'

const COLUMNS = ['Contact', 'Platform', 'Completed', 'Tags']

export default function ContactsSection({ card }) {
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const { updateInboxCards, setTagsFilter } = useActions().inbox
  const { inboxCards, dateFilter, tagsFilter } = useStore().inbox
  const [loading, setLoading] = useState(false)
  const containerRef = createRef()

  let inbox = inboxCards[card.cardID] || []
  if (Object.keys(tagsFilter).length > 0) {
    inbox = inbox.filter((f) => {
      if (!f.tags) return false
      let foundTag = false
      f.tags.forEach((t) => {
        if (foundTag) return
        if (tagsFilter[t.label]) {
          foundTag = true
        }
      })
      return foundTag
    })
  }

  const onInboxCardSelect = (contact) => () => {
    showModal({
      name: 'CONTACT_DETAILS',
      contact,
    })
  }

  const updateDateFilter = async (filters) => {
    setLoading(true)

    try {
      const result = await getCompletedCards({
        card,
        fromDate: filters.from,
        toDate: filters.to,
      })

      const receivedCards = []
      result.forEach((doc) => {
        receivedCards.push(doc.data())
      })

      filters.cardID = card.cardID
      updateInboxCards({
        dateFilter: filters,
        inboxCards: {
          ...inboxCards,
          [card.cardID]: receivedCards,
        },
      })
    } catch (e) {
      showToast({
        content: "Server error. Couldn't fetch cards.",
      })
    }
    setLoading(false)
  }

  return (
    <Container ref={containerRef}>
      <FiltersHeader
        dateFilter={dateFilter}
        filters={[
          {
            label: 'Last week',
            getValue: getLastWeekTime,
          },
          {
            label: 'Last month',
            getValue: getLastMonthTime,
          },
          {
            label: 'Last quarter',
            getValue: getLastQuarterTime,
          },
          {
            label: 'Last year',
            getValue: getLastYearTime,
          },
          {
            label: 'All',
            getValue: getStartTime,
          },
        ]}
        updateDateFilter={updateDateFilter}
        total={inbox.length}
      />

      {loading && <PageLoader />}

      {!loading && inbox.length === 0 && (
        <EmptyContainer>
          <EyeOffIcon size={24} color={colors.mainDark} />
          <Paragraph variant="secondary" padding="16px">
            No contacts, yet.
          </Paragraph>
          <Button
            icon={<SendIcon />}
            onClick={() => showModal({ name: 'SHARE_CARD', card })}
          >
            Share
          </Button>
        </EmptyContainer>
      )}

      {!loading && inbox.length > 0 && (
        <>
          <RowContainer>
            {COLUMNS.map((column, i) => (
              <RowHeader
                flex={i === 0 ? 2 : 1}
                key={i}
                isLast={i === COLUMNS.length - 1}
              >
                {column.toUpperCase()}
                {i === 3 && (
                  <TagsFilters
                    inbox={inboxCards[card.cardID]}
                    tagsFilter={tagsFilter}
                    setTagsFilter={setTagsFilter}
                  />
                )}
              </RowHeader>
            ))}
          </RowContainer>

          <Wrapper>
            {inbox.map((c) => (
              <InboxCardRow
                key={c.id}
                card={c}
                onInboxCardSelect={onInboxCardSelect}
              />
            ))}
          </Wrapper>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  z-index: 1;
  background-color: ${colors.background};
  overflow-y: auto;
  @media (max-width: 767px) {
    display: block;
    border-radius: 0;
    border-left: none;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

const EmptyContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  justify-content: center;
  flex-direction: column;
  padding: 80px 0;
  box-sizing: border-box;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  box-shadow: ${shadows.primaryLight};
  flex-shrink: 0;
  z-index: 10;
`

const RowHeader = styled.div`
  display: flex;
  flex: ${(props) => props.flex || 1};
  justify-content: ${(props) => (props.isLast ? 'flex-end' : 'flex-start')};
  font-size: 12px;
  color: ${colors.grey};
  width: 100%;
  @media (max-width: 767px) {
    flex: 1;
  }
`
