import { diff } from 'deep-object-diff'

function nestedObject(object, difference, path = '') {
  Object.keys(object).forEach((key) => {
    const x = object[key] || ''
    const fullPath = path ? path + '.' + key : '' + key
    if (key === 'tags' || key === 'sections') {
      difference[key] = x
      return
    }
    if (typeof x === 'object' && !Array.isArray(x) && x !== null) {
      nestedObject(x, difference, fullPath)
    } else {
      difference[fullPath] = x
    }
  })
}

export function generateDifferenceObject(originalObject, newObject) {
  const { personalDetails = {}, sections = [] } = newObject
  const difference = {}
  const objectDifference = diff(originalObject, newObject)
  delete objectDifference.lockedFields
  delete objectDifference.synchronizeChanges
  nestedObject(objectDifference, difference)
  if (difference.tags) {
    const { tags = [] } = personalDetails
    if (!difference.personalDetails) difference.personalDetails = {}
    difference.personalDetails.tags = tags
    delete difference.tags
  }
  if (difference.sections) {
    difference.sections = sections
  }
  return difference
}
