import styled from 'styled-components'
import { returnFontColor, returnFontWeight, returnLineHeight } from './helpers'

const Header = styled.h1`
  font-size: 21px;
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  color: ${(props) => returnFontColor(props.variant)};
  font-weight: ${(props) => returnFontWeight(props.weight || 'bold')};
  line-height: ${(props) => returnLineHeight(props.lineHeight)};
  text-align: ${(props) => props.align || 'left'};
`

export default Header
