export const CARDS_FILTERS = [
  {
    label: 'Most unread',
    sortFunction: sortByMostActive,
  },
  {
    label: 'Most responses',
    sortFunction: sortByMostResponses,
  },
  {
    label: 'Newest',
    sortFunction: sortByMostNewest,
  },
  {
    label: 'Oldest',
    sortFunction: sortByMostOldest,
  },
  {
    label: 'A -> Z',
    sortFunction: sortAlphabetically,
  },
  {
    label: 'Z -> A',
    sortFunction: sortReverseAlphabetically,
  },
]

function sortByMostActive(cards, inbox) {
  cards.sort((a, b) => {
    const inbox1 = inbox[a.cardID] || []
    const inbox2 = inbox[b.cardID] || []
    const received1 = inbox1.filter((f) => !f.seen).length
    const received2 = inbox2.filter((f) => !f.seen).length
    return received2 - received1
  })
}

function sortByMostResponses(cards, inbox) {
  cards.sort((a, b) => {
    const inbox1 = inbox[a.cardID] || []
    const inbox2 = inbox[b.cardID] || []
    return inbox2.length - inbox1.length
  })
}

function sortByMostNewest(cards) {
  cards.sort((a, b) => {
    return b.createdTimestamp - a.createdTimestamp
  })
}

function sortByMostOldest(cards) {
  cards.sort((a, b) => {
    return a.createdTimestamp - b.createdTimestamp
  })
}

function sortAlphabetically(cards) {
  cards.sort((a, b) => a.label.localeCompare(b.label))
}

function sortReverseAlphabetically(cards) {
  cards.sort((a, b) => b.label.localeCompare(a.label))
}
