import React from 'react'
import { useStore, useActions } from '../store'
import { modals as MODALS } from '../modals'

export const ModalsProvider = () => {
  const { modals } = useStore()
  const { closeModal } = useActions().modals

  return modals.map((modal) => {
    const Modal = MODALS[modal.name]
    return (
      <Modal
        key={modal.key}
        onClose={() => closeModal(modal.key)}
        {...modal.props}
      />
    )
  })
}
