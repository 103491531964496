import styled from 'styled-components'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import {
  BLANK_DIGITAL_CARD_TEMPLATE,
  BLANK_LINK_IN_BIO_TEMPLATE,
  CARDS_TEMPLATES,
} from '../core/templates/cards-templates'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { colors } from '../core/theme'
import { cloneArrayDeep, deepCloneObject } from '../helpers/deepCloneObject'
import { ArrowRight } from '../assets/icons'
import { useActions, useStore } from '../store'
import { ReactComponent as LinkInBio } from '../assets/icons/link_in_bio_type.svg'
import { ReactComponent as BusinessCard } from '../assets/icons/business_card_type.svg'
import { slugValidator } from '../helpers/validators/slugValidator'
import {
  checkIfSlugExists,
  setNewSlug,
  updateCardData,
} from '../api/database-api'
import { generateRandomNumber } from '../helpers/generateRandomNumber'
import FloatingInput from '../components/FloatingInput'
import FloatingSelect from '../components/FloatingSelect'

const CreateCardModal = ({ onClose, ...restProps }) => {
  const history = useHistory()
  const { license } = useStore()
  const { showToast } = useActions().toasts
  const { addCardToAccount } = useActions().cards
  const [error, setError] = useState(false)
  const [templateError, setTemplateError] = useState(false)
  const [name, updateName] = useState('')
  const [isCreating, setCreatingStatus] = useState(false)
  const { templates = [] } = license
  const [screen, setScreen] = useState(!restProps.template ? 'type' : 'details') // type, details
  const [type, setType] = useState(
    restProps.template ? restProps.template.pageType || 'Classic' : ''
  )
  const allTemplates = [
    type === 'Modern'
      ? BLANK_LINK_IN_BIO_TEMPLATE
      : BLANK_DIGITAL_CARD_TEMPLATE,
    ...cloneArrayDeep(templates),
    ...CARDS_TEMPLATES,
  ]
  const [selectedTemplate, selectTemplate] = useState(
    templates.length > 0
      ? restProps.template
        ? templates.find((t) => t.label === restProps.template.label)
        : allTemplates[0]
      : allTemplates[0]
  )

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const onTemplateSelected = (e) => {
    const item = e.target.value
    const template = allTemplates.filter((f) => f.label === item)[0]
    if (templateError) setTemplateError(false)
    selectTemplate(template)
  }

  const updateNameValue = (e) => {
    if (error) setError(false)
    updateName(e.target.value)
  }

  const createCard = useCallback(async () => {
    if (!name) {
      setError("Page name can't be empty.")
      return
    }

    if (isCreating) return
    setCreatingStatus(true)

    try {
      const card =
        selectedTemplate.id === 'blank'
          ? deepCloneObject(
              type === 'Modern'
                ? BLANK_LINK_IN_BIO_TEMPLATE
                : BLANK_DIGITAL_CARD_TEMPLATE
            )
          : deepCloneObject(selectedTemplate)
      card.label = name
      card.pageType = type
      const addedCard = await addCardToAccount({ card })
      try {
        let username = card.label.toLowerCase().split(' ').join('')
        const slugError = slugValidator(username)
        if (!slugError) {
          const doesExists = await checkIfSlugExists(username)
          if (doesExists) {
            username += generateRandomNumber(5)
          }
          setNewSlug(username, {
            license: addedCard.license,
            cardID: addedCard.cardID,
          }).then(() => {
            addedCard.slug = username
            updateCardData(addedCard)
          })
        }
      } catch (error2) {
        console.error(error2)
      }

      history.push(`/${addedCard.cardID}/edit`)
    } catch (e) {
      showToast({ content: 'Unexpected error occurred. Please try again.' })
    }

    setCreatingStatus(false)
    onClose()
  }, [
    addCardToAccount,
    history,
    isCreating,
    name,
    onClose,
    selectedTemplate,
    showToast,
  ])

  useEffect(() => {
    const onKeyUp = (event) => event.keyCode === 13 && createCard()
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [createCard])

  return (
    <BaseModal
      title="New Page"
      maxWidth="660px"
      onClose={onClose}
      actions={
        screen === 'type' ? (
          type ? (
            <Button
              key="Next"
              onClick={() => setScreen('details')}
              icon={<ArrowRight size={20} color={colors.white} />}
            >
              Next
            </Button>
          ) : null
        ) : (
          <Button
            key="Create"
            onClick={createCard}
            loading={isCreating}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Create Page
          </Button>
        )
      }
    >
      <Container>
        {screen === 'details' ? (
          <>
            <FloatingInput
              shrink
              value={name}
              onChange={updateNameValue}
              label="Page name"
              description="This name will be used as a title for your page."
              error={error}
              autoFocus
              maxLength={100}
              style={{ marginTop: 12 }}
            />
            <FloatingSelect
              label="Template"
              description="Use templates for better and easier start."
              onChange={onTemplateSelected}
              value={selectedTemplate.label}
              error={templateError}
              style={{ marginTop: 4 }}
              options={allTemplates.map((temp, i) => {
                return (
                  <option key={i} value={temp.label}>
                    {temp.label}
                  </option>
                )
              })}
            />
          </>
        ) : (
          <>
            <InfoHeader>What type of page would you like to create?</InfoHeader>
            <PagesContainer>
              <PageType
                selected={type === 'Modern'}
                onClick={() => setType('Modern')}
              >
                <PageTitle>Modern</PageTitle>
                <LinkInBio width={!isMobile ? 180 : '100%'} height="100%" />
              </PageType>
              <PageType
                selected={type === 'Classic'}
                onClick={() => setType('Classic')}
              >
                <PageTitle>Classic</PageTitle>
                <BusinessCard width={!isMobile ? 180 : '100%'} height="100%" />
              </PageType>
            </PagesContainer>
          </>
        )}
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 26px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const PagesContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

const PageType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 24px 16px 24px;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  background-color: ${(props) =>
    props.selected ? colors.mainLight : 'transparent'};
  border: ${(props) =>
    props.selected ? `1px solid ${colors.main}` : '1px solid transparent'};
  border-radius: 12px;
  box-sizing: border-box;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    padding: 6px 12px 16px 12px;
  }
`

const PageTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin: 4px 0 16px 0;
  text-align: center;
`

const InfoHeader = styled.h2`
  width: 100%;
  font-size: 17px;
  line-height: 1.5;
  margin: 0 0 20px 0;
  text-align: center;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
`

export default CreateCardModal
