import React, { useEffect, useState } from 'react'
import { checkIfSlugExists, deleteSlug, setNewSlug } from '../api/database-api'
import { config } from '../core/config'
import { slugValidator } from '../helpers/validators/slugValidator'
import { usePermission } from '../hooks/usePermission'
import { useActions } from '../store'
import Button from './Button'
import FloatingInput from './FloatingInput'
import { Row } from './Layout'
import { colors } from '../core/theme'

export default function CardSlugEditor({ card, updateCard, isTemplate }) {
  const { showToast } = useActions().toasts
  const { canEdit, showPermissionAlert } = usePermission()
  const [hasChanges, setHasChanges] = useState()
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [slug, setSlug] = useState(card.slug || '')

  useEffect(() => {
    setSlug(card.slug || '')
  }, [card.slug])

  const updateSlug = async () => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (loading) return
    setLoading(true)
    setError(false)

    try {
      if (!slug) {
        if (card.slug) {
          await deleteSlug(card.slug)
          delete card.slug
          updateCard(card, true)
        }
        setHasChanges(false)
        setLoading(false)
        return
      }

      const slugError = slugValidator(slug)
      if (slugError) {
        setError(slugError)
        setLoading(false)
        return
      }

      const doesExists = await checkIfSlugExists(slug)
      if (doesExists) {
        setError('Username is already taken!')
        setLoading(false)
        return
      }

      await setNewSlug(slug, {
        license: card.license,
        cardID: card.cardID,
      })

      if (card.slug) {
        await deleteSlug(card.slug)
        delete card.slug
      }

      card.slug = slug
      updateCard(card, true)
      setHasChanges(false)
      setLoading(false)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  return (
    <Row style={{ width: '100%', marginTop: 8 }}>
      {!isTemplate && (
        <FloatingInput
          prefix={`${config.CARDS_BASE_URL.replace('https://', '')}/`}
          label="Username"
          value={slug}
          error={error}
          extraSX={{
            '& fieldset': {
              borderRadius: hasChanges ? `4px 0 0 4px !important` : 'auto',
            },
          }}
          onChange={(e) => {
            if (e.target.value === card.slug) {
              setHasChanges(false)
            } else {
              setHasChanges(true)
            }
            setSlug(e.target.value)
            setError(false)
          }}
        />
      )}
      {hasChanges && (
        <Button
          loading={loading}
          onClick={updateSlug}
          color={colors.success}
          style={{
            height: 50,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }}
        >
          Save
        </Button>
      )}
    </Row>
  )
}
