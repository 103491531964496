import React from 'react'
import styled from 'styled-components'
import CardBase from './CardBase'
import { colors } from '../../core/theme'
import { Label } from '../Typography'

const SummaryCard = ({ data }) => {
  const { starts = 0, responses = 0, completion_rate = 0 } = data

  return (
    <CardBase
      body={
        <Container>
          <InfoContainer>
            <Label variant="secondary" className="no-wrap">
              Visits
            </Label>
            <Counter>{starts}</Counter>
          </InfoContainer>
          <InfoContainer>
            <Label variant="secondary" className="no-wrap">
              Interactions
            </Label>
            <Counter>{responses}</Counter>
          </InfoContainer>
          <InfoContainer>
            <Label variant="secondary" className="no-wrap">
              Interaction rate
            </Label>
            <Counter>{completion_rate}%</Counter>
          </InfoContainer>
        </Container>
      }
    />
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 4px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 36px;
  @media (max-width: 767px) {
    margin-bottom: 8px;
    margin-right: 0px;
  }
`

const Counter = styled.span`
  color: ${colors.primary};
  font-size: 28px;
  margin-top: 8px;
  @media (max-width: 767px) {
    margin-bottom: 8px;
  }
`

export default SummaryCard
