import React from 'react'
import {
  EditCardIcon,
  EditStyleIcon,
  AppsIcon,
  NotificationsIcon,
  TemplatesIcon,
  PersonalDetailsIcon,
  TweaksIcon,
  AccessoriesIcon,
  BillingIcon,
  MoneyIcon,
  HomeIcon,
  HelpCenterIcon,
} from '../assets/icons'
import { colors } from './theme'

export const DESKTOP_BREAKPOINT = 1280

export const KONECT_ACCESSORIES = [
  {
    name: 'Konect White Tag',
    price: '18',
    type: 'tag',
    image: require('../assets/images/tag-white.png').default,
    className: 'accessory-tag',
    link: 'https://shop.konect.to/products/white-konect-tap',
  },
  {
    name: 'Konect Black Tag',
    price: '18',
    type: 'tag',
    image: require('../assets/images/tag-black.png').default,
    className: 'accessory-tag',
    link: 'https://shop.konect.to/products/black-konect-tap',
  },
  {
    name: 'Classic White Card',
    price: '25',
    type: 'card',
    image: require('../assets/images/white-card.png').default,
    className: 'accessory-card',
    link: 'https://shop.konect.to/products/white-plastic-card',
  },
  {
    name: 'Classic Black Card',
    price: '25',
    type: 'card',
    image: require('../assets/images/black-card.png').default,
    className: 'accessory-card',
    link: 'https://shop.konect.to/products/black-classic-card',
  },
  {
    name: 'Classic Bamboo Card',
    price: '30',
    type: 'card',
    image: require('../assets/images/bamboo-card.png').default,
    className: 'accessory-card',
    link: 'https://shop.konect.to/products/bamboo-card',
  },
  // {
  //   name: 'Custom White Card',
  //   price: '35',
  //   type: 'card',
  //   image: require('../assets/images/white-custom-card.png').default,
  //   className: 'accessory-card',
  //   link: 'https://shop.konect.to/products/white-custom-classic-card',
  // },
  // {
  //   name: 'Custom Black Card',
  //   price: '35',
  //   type: 'card',
  //   image: require('../assets/images/black-custom-card.png').default,
  //   className: 'accessory-card',
  //   link: 'https://shop.konect.to/products/black-custom-classic-card',
  // },
  // {
  //   name: 'Custom Bamboo Card',
  //   price: '40',
  //   type: 'card',
  //   image: require('../assets/images/bamboo-custom-card.png').default,
  //   className: 'accessory-card',
  //   link: 'https://shop.konect.to/products/custom-bamboo-card',
  // },
]

export const BUTTON_TYPES = [
  {
    label: 'Link',
    value: 'link',
  },
  {
    label: 'Folder',
    value: 'folder',
  },
  {
    label: 'Podcast',
    value: 'podcast',
    isPremium: true,
  },
]

export const BUTTON_STYLE_TYPES = [
  {
    value: 'link',
    icon: require('../assets/icons/button-simple.svg').default,
  },
  {
    value: 'image-left',
    icon: require('../assets/icons/button-image-left.svg').default,
  },
  {
    value: 'image-top',
    icon: require('../assets/icons/button-image-top.svg').default,
    isPremium: true,
  },
  {
    value: 'grid',
    icon: require('../assets/icons/button-grid.svg').default,
    isPremium: true,
  },
  {
    value: 'carousel',
    icon: require('../assets/icons/button-carousel.svg').default,
    isPremium: true,
  },
]

export const PERMISSIONS = [
  { value: 'Full access', description: 'User can edit/read all the data.' },
  { value: 'Read only', description: 'User can only read the data.' },
  {
    value: 'Selected pages',
    description: 'User can only access selected pages.',
  },
  {
    value: 'Selected folders',
    description: 'User can only access selected folders.',
  },
]

export const ACCESSORIES_STYLES = {
  card: {
    dark: {
      text: '#ffffff',
      image: require('../assets/images/black-card.png').default,
    },
    white: {
      image: require('../assets/images/white-card.png').default,
      text: '#000000',
    },
    bamboo: {
      image: require('../assets/images/bamboo-card.png').default,
      text: '#000000',
    },
  },
  tag: {
    dark: {
      text: '#000000',
      image: require('../assets/images/tag-black.png').default,
    },
    white: {
      image: require('../assets/images/tag-white.png').default,
      text: '#000000',
    },
  },
}

export const INITIAL_FOLDERS = [
  { name: 'All', cards: [], createdTimestamp: new Date().getTime() },
]

export const SIDEBAR_TABS = [
  {
    label: 'Pages',
    tooltip: 'Pages',
    value: 'pages',
    icon: <HomeIcon />,
    to: '/',
  },
  {
    label: 'Accessories',
    tooltip: 'Accessories',
    value: 'accessories',
    icon: <AccessoriesIcon />,
    to: '/accessories',
  },
  {
    label: 'Templates',
    tooltip: 'Templates',
    value: 'templates',
    icon: <TemplatesIcon />,
    to: '/templates',
  },
  {
    label: 'Billing',
    tooltip: 'Billing',
    value: 'billing',
    icon: <BillingIcon />,
    to: '/billing',
  },
  {
    label: 'Help',
    tooltip: 'Help',
    value: 'help',
    icon: <HelpCenterIcon />,
    to: '/',
  },
]

export const DEFAULT_TAGS = [
  {
    color: '#F57C00',
    label: 'In progress',
    description: 'The work is still in progress.',
  },
  {
    color: '#0E8A16',
    label: 'Resolved',
    description: 'The work is done.',
  },
  {
    color: '#f44336',
    label: 'Rejected',
    description: 'This will not be worked on.',
  },
  {
    color: '#9C27B0',
    label: 'Feedback',
    description: 'Feedback about our product.',
  },
  {
    color: '#E91E63',
    label: 'Bug',
    description: "Something isn't working.",
  },
  {
    color: '#2962FF',
    label: 'Feature',
    description: 'New feature or request.',
  },
  {
    color: '#4C054C',
    label: 'Question',
    description: 'Someone asked a question.',
  },
]

export const EDIT_MENU_ITEMS = [
  {
    label: 'Personal Details',
    icon: <PersonalDetailsIcon />,
    type: 'personal',
  },
  {
    label: 'Style Your Page',
    icon: <EditStyleIcon />,
    type: 'style',
  },
  {
    label: 'Edit Content',
    icon: <EditCardIcon />,
    type: 'card',
  },
  {
    label: 'Email Notifications',
    icon: <NotificationsIcon />,
    type: 'email',
  },
  {
    label: 'Integrations & Apps',
    icon: <AppsIcon />,
    type: 'integrations',
  },
  {
    label: 'Additional Tweaks',
    icon: <TweaksIcon />,
    type: 'tweaks',
  },
  {
    label: 'Affiliate Program',
    icon: <MoneyIcon fill={colors.main} />,
    type: 'affiliates',
  },
]

export const BILLING_PLANS = [
  {
    name: 'premium',
    price: {
      monthly: 7,
      yearly: 70,
    },
  },
  {
    name: 'free',
    price: {
      monthly: 0,
      yearly: 0,
    },
  },
]

export const INTEGRATIONS = [
  {
    id: 'google',
    property: 'google_sheets',
    name: 'Google Sheets',
    icon: require('../assets/icons/google_sheets_icon.svg').default,
  },
  {
    id: 'hubspot',
    property: 'hubspot',
    name: 'HubSpot',
    icon: require('../assets/icons/hubspot_icon.svg').default,
  },
  {
    id: 'pipedrive',
    property: 'pipedrive',
    name: 'Pipedrive',
    icon: require('../assets/icons/pipedrive-logo.svg').default,
  },
  {
    id: 'mailchimp',
    name: 'Mailchimp',
    property: 'mailchimp',
    icon: require('../assets/icons/mailchimp_icon.svg').default,
  },
  {
    id: 'zendesk-sell',
    property: 'zendesk',
    name: 'Zendesk Sell',
    icon: require('../assets/icons/zendesk_icon.svg').default,
  },
  {
    id: 'slack',
    property: 'slack',
    name: 'Slack',
    icon: require('../assets/icons/slack_icon.svg').default,
  },
  {
    id: 'discord',
    property: 'discord',
    name: 'Discord',
    icon: require('../assets/icons/discord_icon.svg').default,
  },
  {
    id: 'activecampaign',
    name: 'ActiveCampaign',
    property: 'activecampaign',
    icon: require('../assets/icons/activecampaign-icon.svg').default,
    href: 'https://www.activecampaign.com/apps/konect-integration',
  },
  // {
  //   id: 'analytics',
  //   property: 'google_analytics',
  //   name: 'Google Analytics',
  //   icon: require('../assets/icons/google_analytics_icon.svg').default,
  // },
  // {
  //   id: 'pixel',
  //   property: 'facebook_pixel',
  //   name: 'Meta Pixel',
  //   icon: require('../assets/icons/facebook_pixel_icon.svg').default,
  // },
]

export const DEFAULT_EXCHANGE_FORM = {
  label: 'Contact Form',
  type: 'contact',
  info: "Capture your audience's details",
  enablePopup: true,
  question: 'Connect with',
  complete: {
    title: 'Thank you',
    description: 'Form submitted successfully.',
  },
  button: {
    label: 'Exchange Contact',
  },
  value: [
    {
      placeholder: 'Name',
      isRequired: true,
      regexType: 'Name',
      type: 'name',
      validatorError: 'Input must be a name.',
    },
    {
      placeholder: 'Email Address',
      regexType: 'Email address',
      type: 'email',
      validator:
        "^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*.?[a-zA-Z0-9])*.[a-zA-Z](-?[a-zA-Z0-9])+$",
      validatorError: 'Input must be an email address.',
      isRequired: true,
    },
    {
      placeholder: 'Phone Number',
      type: 'phone',
    },
    {
      placeholder: 'Message',
      multiline: true,
    },
  ],
}

export const ALL_SECTIONS = [
  {
    section: true,
    title: 'Basics',
  },
  {
    label: 'Social Icons',
    info: 'Link to all your favorite platforms',
    type: 'social-links',
  },
  {
    label: 'Link',
    info: 'Add a link and customize its look',
    type: 'link',
    title: 'Link',
    link: '',
    placeholder: {
      title: 'Link',
      link: '',
    },
  },
  {
    label: 'Text',
    type: 'description',
    question: '',
    info: 'Add a title and a description',
    value: '',
  },
  {
    label: 'Folder',
    info: 'Add a folder to easily group content',
    type: 'folder',
    title: 'Folder',
    placeholder: {
      title: 'Folder',
      type: 'folder',
    },
  },
  {
    label: 'Contact Form',
    type: 'contact',
    info: "Capture your audience's details",
    question: 'How can I help?',
    complete: {
      title: 'Thank you',
      description: 'Form submitted successfully.',
    },
    button: {
      label: 'Submit',
    },
    value: [
      {
        placeholder: 'Name',
        isRequired: true,
        regexType: 'Name',
        type: 'name',
        validatorError: 'Input must be a name.',
      },
      {
        placeholder: 'Email',
        regexType: 'Email address',
        type: 'email',
        validator:
          "^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*.?[a-zA-Z0-9])*.[a-zA-Z](-?[a-zA-Z0-9])+$",
        validatorError: 'Input must be an email address.',
        isRequired: true,
      },
      {
        placeholder: 'Message',
        multiline: true,
        isRequired: true,
      },
    ],
  },
  {
    label: 'Image',
    info: 'Add your favorite image',
    type: 'media',
    question: '',
    description: '',
    media: [
      {
        url: '',
      },
    ],
  },
  {
    section: true,
    title: 'Embeds',
  },
  {
    label: 'Video',
    info: 'Embed any video',
    type: 'video',
    question: '',
    description: '',
    video: '',
  },
  {
    label: 'Podcast',
    info: 'Add all your podcasts in one place',
    type: 'podcast',
    title: 'Podcast',
    podcast: {},
    placeholder: {
      title: 'Podcast',
      type: 'podcast',
      podcast: {},
    },
  },
  {
    label: 'Calendly',
    type: 'calendly',
    info: 'Embed your schedule page',
    question: 'Schedule a meeting',
    value: '',
  },
  {
    label: 'Booksy',
    info: 'Let your clients book appointments',
    type: 'booksy',
    question: 'Book now',
    value: '',
  },
]

export const ZENDESK_COLUMNS = [
  { name: 'First Name', id: 'first_name' },
  { name: 'Last Name', id: 'last_name' },
  { name: 'Job Title', id: 'title' },
  { name: 'Description', id: 'description' },
  { name: 'Industry', id: 'industry' },
  { name: 'Company', id: 'organization_name' },
  { name: 'Website', id: 'website' },
  { name: 'Email', id: 'email' },
  { name: 'Phone', id: 'phone' },
  { name: 'Mobile', id: 'mobile' },
  { name: 'Fax', id: 'fax' },
  { name: 'Twitter', id: 'twitter' },
  { name: 'Facebook', id: 'facebook' },
  { name: 'LinkedIn', id: 'linkedin' },
  { name: 'Skype', id: 'skype' },
]

export const RADIAN = Math.PI / 180

export const PIE_CHART_COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#f44336',
  '#E91E63',
  '#9C27B0',
]

export const SOCIAL_LINKS = [
  {
    section: true,
    title: 'Basic',
    columns: 3,
    icons: [
      {
        type: 'email',
        title: 'Email',
        label: 'Email',
        action: 'mailto:',
      },
      {
        type: 'company',
        title: 'Company',
        label: 'Link',
      },
      {
        type: 'phone',
        title: 'Call',
        label: 'Phone',
        action: 'tel:',
      },
      {
        type: 'address',
        title: 'Address',
        label: 'Address',
        action: 'https://www.google.com/maps?q=',
      },
      {
        type: 'sms',
        title: 'Text',
        label: 'Phone',
        action: 'sms:',
      },
      {
        type: 'link',
        title: 'Link',
      },
      {
        type: 'reviews',
        title: 'Reviews',
      },
      {
        type: 'applestore',
        title: 'Apple Store',
        value: 'https://apple.com/store',
      },
      {
        type: 'playstore',
        title: 'Play Store',
        value: 'https://play.google.com/store/',
      },
      {
        type: 'amazon',
        title: 'Amazon',
        value: 'https://amazon.com/',
      },
    ],
  },
  {
    section: true,
    title: 'Social',
    columns: 5,
    icons: [
      {
        type: 'twitter',
        title: 'X',
        value: 'https://x.com/',
      },
      {
        type: 'instagram',
        title: 'Instagram',
        value: 'https://instagram.com/',
      },
      {
        type: 'facebook',
        title: 'Facebook',
        value: 'https://facebook.com/',
      },
      {
        type: 'tiktok',
        title: 'TikTok',
        value: 'https://tiktok.com/',
      },
      {
        type: 'youtube',
        title: 'YouTube',
        value: 'https://youtube.com/',
      },
      {
        type: 'linkedin',
        title: 'LinkedIn',
        value: 'https://linkedin.com/',
      },
      {
        type: 'threads',
        title: 'Threads',
        value: 'https://threads.net/',
      },
      {
        type: 'onlyfans',
        title: 'OnlyFans',
        value: 'https://onlyfans.com/',
      },
      {
        type: 'twitch',
        title: 'Twitch',
        value: 'https://twitch.tv/',
      },
      {
        type: 'vimeo',
        title: 'Vimeo',
        value: 'https://vimeo.com/',
      },
      {
        type: 'github',
        title: 'GitHub',
        value: 'https://github.com/',
      },
      {
        type: 'patreon',
        title: 'Patreon',
        value: 'https://patreon.com/',
      },
      {
        type: 'yelp',
        title: 'Yelp',
        value: 'https://yelp.com/',
      },
      {
        type: 'booksy',
        title: 'Booksy',
        value: 'https://booksy.com/',
      },
      {
        type: 'pinterest',
        title: 'Pinterest',
        value: 'https://pinterest.com/',
      },
      {
        type: 'snapchat',
        title: 'Snapchat',
        value: 'https://snapchat.com/',
      },
      {
        type: 'clubhouse',
        title: 'Clubhouse',
        value: 'https://clubhouse.com/',
      },
      {
        type: 'reddit',
        title: 'Reddit',
        value: 'https://reddit.com/',
      },
      {
        type: 'medium',
        title: 'Medium',
        value: 'https://medium.com/',
      },
      {
        type: 'rumble',
        title: 'Rumble',
        value: 'https://rumble.com/',
      },
      {
        type: 'community',
        title: 'Community',
        value: 'https://community.com/',
      },
    ],
  },
  {
    section: true,
    title: 'Communication',
    columns: 3,
    icons: [
      {
        type: 'messenger',
        title: 'Messenger',
        value: 'https://messenger.com/',
      },
      {
        type: 'whatsapp',
        title: 'WhatsApp',
        label: 'WhatsApp Number',
        action: 'https://wa.me/',
        value: '',
      },
      {
        type: 'discord',
        title: 'Discord',
        value: 'https://discord.com/',
      },
      {
        type: 'telegram',
        title: 'Telegram',
        value: 'https://telegram.org/',
      },
      {
        type: 'zoom',
        title: 'Zoom',
        value: 'https://zoom.com/',
      },
      {
        type: 'calendly',
        title: 'Calendly',
        value: 'https://calendly.com/',
      },
      {
        type: 'wechat',
        label: 'WeChat ID',
        title: 'WeChat',
        action: 'weixin://dl/chat?',
        value: '',
      },
    ],
  },
  {
    section: true,
    title: 'Music',
    columns: 3,
    icons: [
      {
        type: 'spotify',
        title: 'Spotify',
        value: 'https://spotify.com/',
      },
      {
        type: 'applemusic',
        title: 'Apple Music',
        value: 'https://apple.com/apple-music/',
      },
      {
        type: 'applepodcast',
        title: 'Apple Podcast',
        value: 'https://apple.com/apple-podcasts/',
      },
      {
        type: 'soundcloud',
        title: 'Soundcloud',
        value: 'https://soundcloud.com/',
      },
      {
        type: 'bandcamp',
        title: 'Bandcamp',
        value: 'https://bandcamp.com/',
      },
    ],
  },
  {
    section: true,
    title: 'Payment',
    columns: 3,
    icons: [
      {
        type: 'paypal',
        title: 'PayPal',
        value: 'https://paypal.com/',
      },
      {
        type: 'stripe',
        title: 'Stripe',
        value: 'https://stripe.com/',
      },
      {
        type: 'venmo',
        title: 'Venmo',
        value: 'https://venmo.com/',
      },
      {
        type: 'cashapp',
        title: 'CashApp',
        value: 'https://cash.app/',
      },
    ],
  },
]
