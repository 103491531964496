import React, { useState } from 'react'
import styled from 'styled-components'
import BaseModal from './BaseModal'
import Button from '../components/Button'
import { colors } from '../core/theme'
import Tag from '../components/Tag'
import ColorPicker from '../components/ColorPicker'
import { useStore, useActions } from '../store'
import { ArrowRight } from '../assets/icons'
import FloatingInput from '../components/FloatingInput'

const CreateTagModal = ({ tag = {}, onClose }) => {
  const { license } = useStore()
  const { showToast } = useActions().toasts
  const { updateLicenseTags } = useActions().license
  const [label, updateLabel] = useState(tag.label || 'Tag')
  const [description, updateDescription] = useState(tag.description || '')
  const [color, updateColor] = useState(tag.color || colors.main)
  const [isCreating, setCreatingStatus] = useState(false)
  const [error, setError] = useState(false)
  const isEditing = !!tag.label

  const createTag = async () => {
    if (isCreating) return

    if (!label) {
      setError("Label can't be empty.")
      return
    }

    setCreatingStatus(true)
    const tagNames = license.tags.map((t) => t.label)

    if (tagNames.includes(label) && tag.label !== label) {
      setError('Tag already exists.')
      setCreatingStatus(false)
      return
    }

    try {
      await updateLicenseTags({
        tag: { label, description, color, oldLabel: tag.label },
        isEditing,
      })
    } catch (e) {
      showToast({
        content: e.message,
      })
    }

    setCreatingStatus(false)
    onClose()
  }

  const updateLabelValue = (e) => {
    if (error) setError(false)
    updateLabel(e.target.value)
  }

  const handleColorChange = ({ hex }) => {
    updateColor(hex)
  }

  return (
    <BaseModal
      title={isEditing ? 'Edit Tag' : 'New Tag'}
      maxWidth="600px"
      onClose={onClose}
      actions={
        <Button
          onClick={createTag}
          loading={isCreating}
          icon={<ArrowRight size={20} color={colors.white} />}
        >
          {isEditing ? 'Update Tag' : 'Create Tag'}
        </Button>
      }
    >
      <BodyWrapper>
        <PreviewContainer>
          <Tag label={label} color={color} />
          <ColorContainer>
            <Picker>
              <ColorPicker color={color} onChangeComplete={handleColorChange} />
            </Picker>
          </ColorContainer>
        </PreviewContainer>
        <FloatingInput
          value={label}
          onChange={updateLabelValue}
          label="Label"
          error={error}
          style={{ marginTop: 12 }}
        />
        <FloatingInput
          value={description}
          onChange={(e) => updateDescription(e.target.value)}
          label="Description (optional)"
          description="Describe tag in few words."
          style={{ marginTop: 8 }}
        />
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
`

const ColorContainer = styled.div`
  position: relative;
`

const Picker = styled.div`
  margin-left: 16px;
  position: fixed;
  z-index: 9;
`

export default CreateTagModal
