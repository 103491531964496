import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { cardColors } from '../../core/theme'
import { Paragraph } from '../../components/Typography'
import { DefaultAvatarIcon } from '../../assets/icons'
import EditableName from '../../components/EditableName'
import TemplateDetailsMenu from '../../components/TemplateDetailsMenu'
import { usePermission } from '../../hooks/usePermission'
import { useActions } from '../../store'

export default function TemplatesList({ templates, search }) {
  const { canEdit, showPermissionAlert } = usePermission()
  const { showModal } = useActions().modals
  const { renameTemplate } = useActions().license
  const { showToast } = useActions().toasts
  const history = useHistory()

  if (templates.length === 0) {
    return (
      <Container>
        <Paragraph lineHeight="medium" style={{ padding: '8px 20px 0 20px' }}>
          No templates to display.
          <br /> <br />
          You can create your own templates from pages using "Create template
          from page" option,
          <br />
          or you can click "Add Template" button and start from scratch.
        </Paragraph>
      </Container>
    )
  }

  return (
    <Container>
      {templates.map((card) => {
        const { cardBackgroundColor = cardColors.cardBackgroundColor, label } =
          card

        if (!label.includes(search.toLowerCase())) return null

        return (
          <CardContainer
            key={card.label}
            style={{ backgroundColor: cardBackgroundColor }}
          >
            <Banner card={card} />
            <Avatar card={card} />
            <TagContainer>
              <EditableName
                style={{
                  backgroundColor: '#EDEDED',
                  border: '1px solid #B2BBC2',
                  padding: '4px 14px',
                  borderRadius: '32px',
                }}
                value={card.label}
                save={async (value) => {
                  if (value === card.label) return
                  try {
                    await renameTemplate({ template: card, value })
                  } catch (e) {
                    showToast({ content: e.message })
                  }
                }}
              />
            </TagContainer>
            <MenuContainer>
              <UseButton
                onClick={() => {
                  if (!canEdit) {
                    showPermissionAlert()
                    return
                  }
                  showModal({ name: 'CREATE_CARD', template: card })
                }}
              >
                Use
              </UseButton>
              <EditButton
                onClick={() =>
                  history.push(
                    `/${card.templateId || card.label}/edit/template`
                  )
                }
              >
                Edit
              </EditButton>
              <TemplateDetailsMenu template={card} />
            </MenuContainer>
          </CardContainer>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  @media (max-width: 767px) {
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }
`

const CardContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  margin: 12px;
  max-width: 380px;
  border-radius: 20px;
  box-shadow: rgba(29, 36, 86, 0.05) 0px 0px 0px 1px,
    rgba(29, 36, 86, 0.07) 0px 3px 6px, rgba(29, 36, 86, 0.1) 0px 9px 24px;
  @media (max-width: 767px) {
    margin: 12px 0;
    max-width: 400px;
  }
`

const TagContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
`

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  padding-bottom: 24px;
`

const UseButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #b2bbc2;
  padding: 6px 0;
  text-align: center;
  width: 60px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
  :hover {
    background-color: #f5f5f9;
  }
  :active {
    background-color: #f5f5f9;
  }
`

const EditButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #b2bbc2;
  padding: 6px 0;
  text-align: center;
  width: 60px;
  border-radius: 32px;
  font-size: 14px;
  font-weight: 600;
  margin-right: 12px;
  margin-left: 12px;
  :hover {
    background-color: #f5f5f9;
  }
  :active {
    background-color: #f5f5f9;
  }
`

function Banner({ card }) {
  const { banner = {} } = card
  const { url } = banner
  const imageRef = useRef()

  useEffect(() => {
    if (imageRef && imageRef.current && imageRef.current.base) {
      const { width } = imageRef.current.base.getBoundingClientRect()
      if (width > 0) {
        imageRef.current.base.style.height = `${width * 0.5625}px`
      }
    }
  }, [imageRef])

  return (
    <BannerContainer>
      {url ? <BannerImage ref={imageRef} src={url} alt="banner" /> : <div />}
    </BannerContainer>
  )
}

const BannerContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  padding-bottom: 0;
`

const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;
  aspect-ratio: 16 / 9;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

function Avatar({ card }) {
  const { avatar = {}, personalDetails = {}, banner = {} } = card
  const { url, companyLogoURL } = avatar
  const { name, company } = personalDetails
  const avatarContainerStyle = {}

  if (!banner.url) {
    avatarContainerStyle.marginTop = '24px'
  }

  return (
    <div className="ks-avatar-wrapper" style={avatarContainerStyle}>
      <div className="ks-avatar-container">
        {url && <AvatarImage src={url} alt={name || 'avatar'} id="ks-avatar" />}
        {companyLogoURL && (
          <CompanyImage src={companyLogoURL} alt={company || 'company-logo'} />
        )}
        {!url && <DefaultAvatarIcon />}
      </div>
    </div>
  )
}

const AvatarImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgb(29, 36, 86, 15%), 0 2px 6px 0 rgb(29, 36, 86, 5%);
  box-sizing: border-box;
  border: 3px solid white;
  background-color: white;
  object-fit: cover;
`

const CompanyImage = styled.img`
  width: 55px;
  height: 55px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgb(29, 36, 86, 10%), 0 2px 6px 0 rgb(29, 36, 86, 5%);
  box-sizing: border-box;
  border: 2px solid white;
  background-color: white;
  position: absolute;
  right: -22px;
  bottom: 3px;
  object-fit: cover;
`
