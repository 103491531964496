import { encodeData } from './base64'
import { jsonToQueryString } from './jsonToQueryString'

export function generateCardSecretSlug(card) {
  const { license, cardID } = card
  const data = {}
  data.l = license
  data.c = cardID
  const cardQuery = jsonToQueryString(data)
  return encodeData(cardQuery)
}
