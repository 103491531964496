import React from 'react'
import styled from 'styled-components'
import { Tooltip, TooltipContent } from './Tooltip'
import { InfoIcon } from '../assets/icons'

export const InfoTooltip = ({
  description,
  size,
  color,
  icon,
  onClick,
  style,
  direction = 'right',
  arrowPosition = 'left',
  align = 'center',
  zIndex,
  hideMobile,
}) => {
  if (hideMobile) {
    return (
      <InfoContainer onClick={onClick} style={style}>
        {icon || <InfoIcon size={size} color={color} />}
      </InfoContainer>
    )
  }
  return (
    <Tooltip
      offset={1}
      zIndex={zIndex}
      onClick={onClick && onClick}
      align={align}
      trigger="hover"
      content={
        <TooltipContent arrowPosition={arrowPosition}>
          {description}
        </TooltipContent>
      }
      direction={direction}
      style={style}
    >
      <InfoContainer onClick={onClick}>
        {icon || <InfoIcon size={size} color={color} />}
      </InfoContainer>
    </Tooltip>
  )
}

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'auto')};
`
