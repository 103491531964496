import React, { useState } from 'react'
import styled from 'styled-components'
import { ArrowRight, SearchIcon } from '../assets/icons'
import Button from '../components/Button'
import { returnSocialIcon } from '../components/IconStylePicker'
import Input from '../components/Input'
import { Row } from '../components/Layout'
import { Label } from '../components/Typography'
import { SOCIAL_LINKS } from '../core/constants'
import { cardColors, colors } from '../core/theme'
import { generateUniqueID } from '../helpers/generateUniqueID'
import BaseModal from './BaseModal'

export default function AddLinkModal({
  onClose,
  addSocialLinks,
  cardSection,
  card,
}) {
  const { socialIconColor = cardColors.socialIconColor } = card
  const [selectedLinks, setSelectedLinks] = useState([])
  const [search, setSearch] = useState('')
  const filteredIcons = SOCIAL_LINKS.map((section) => {
    return {
      ...section,
      icons: section.icons.filter((link) => {
        if (link.section) return true
        const selected = selectedLinks.find((l) => l.type === link.type)
        if (selected) return true
        if (search) {
          const title = link.title.toLowerCase()
          if (!title.includes(search.toLowerCase())) return false
        }
        return true
      }),
    }
  })

  const onAddClicked = () => {
    selectedLinks.forEach((link) => {
      link.id = generateUniqueID()
    })
    addSocialLinks(selectedLinks)
    onClose()
  }

  const onLinkSelect = (link) => {
    if (selectedLinks.find((l) => l.type === link.type)) {
      setSelectedLinks(selectedLinks.filter((l) => l.type !== link.type))
    } else {
      setSelectedLinks([...selectedLinks, link])
    }
  }

  return (
    <BaseModal
      maxWidth="900px"
      headerClassName="search-icons-header"
      title={
        <Row align="center" style={{ width: '100%' }}>
          <SearchIcon size={18} />
          <Input
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
            }}
            inputClassName="search-icons-input"
            placeholder="Search..."
            style={{ margin: 0, width: 200 }}
            inputStyle={{
              fontSize: 13,
              border: 'none',
              padding: '0',
            }}
          />
        </Row>
      }
      fullscreen
      onClose={onClose}
      actions={
        <>
          <Button
            disabled={selectedLinks.length === 0}
            onClick={onAddClicked}
            style={{ margin: '0 4px' }}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Add {selectedLinks.length} selected
          </Button>
        </>
      }
    >
      <Container>
        {filteredIcons.filter((s) => s.icons.length !== 0).length === 0 && (
          <EmptyList>No icons found.</EmptyList>
        )}
        {filteredIcons.map((section) => {
          const icons = section.icons || []
          if (icons.length === 0) return <div />
          return (
            <>
              <SectionTitle>{section.title}</SectionTitle>
              <OverflowContainer>
                <IconsContainer width={section.columns * 290}>
                  {icons.map((link) => {
                    const selected = selectedLinks.find(
                      (l) => l.type === link.type
                    )
                    return (
                      <LinkContainer
                        selected={selected}
                        onClick={() => {
                          onLinkSelect(link)
                        }}
                      >
                        {returnSocialIcon({
                          ...cardSection,
                          ...link,
                          selected: true,
                          socialIconColor,
                        })}
                        <Label
                          style={{
                            width: '100%',
                            marginLeft: 8,
                            fontWeight: '500',
                          }}
                        >
                          {link.title}
                        </Label>
                        <Label
                          style={{
                            fontSize: 22,
                            flexShrink: 0,
                            lineHeight: 1.5,
                            fontWeight: '500',
                            textAlign: 'center',
                          }}
                        >
                          {selected ? '-' : '+'}
                        </Label>
                      </LinkContainer>
                    )
                  })}
                </IconsContainer>
              </OverflowContainer>
            </>
          )
        })}
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 16px 0;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 12px 0;
  }
`

const SectionTitle = styled.span`
  font-size: 13.5px;
  font-weight: 600;
  color: ${colors.primary};
  line-height: 1.5;
  margin-top: 8px;
  margin-bottom: 4px;
  width: 100%;
  margin-left: 28px;
  @media (max-width: 767px) {
    margin-left: 16px;
  }
`

const LinkContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 260px;
  background-color: ${(props) => (props.selected ? '#EDEDED' : '#fff')};
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  box-sizing: border-box;
  padding: 5px 10px 5px 6px;
  margin: 8px;
  flex-shrink: 0;
  :active {
    opacity: 0.7;
  }
`

const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: ${(props) => props.width}px;
  max-width: ${(props) => props.width}px;
  width: ${(props) => props.width}px;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
    min-width: 100%;
    max-width: 100%;
  }
  @media (min-width: 767px) {
    min-width: auto;
    max-width: auto;
  }
`

const OverflowContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-wrap: wrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 28px;
  box-sizing: border-box;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding-left: 16px;
  }
`

const EmptyList = styled(Label)`
  color: ${colors.grey};
  padding: 8px 0 8px 28px;
  @media (max-width: 767px) {
    padding: 8px 0 8px 16px;
  }
`
