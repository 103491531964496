/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components'
import { Label } from './Typography'

export default function PlanSwitch({ type, setType }) {
  return (
    <Container>
      <Label weight="medium" padding="0 8px 0 4px" lineHeight="medium">
        Billed monthly
      </Label>
      <Wrapper>
        <label className="switch">
          <input
            checked={type === 'yearly'}
            type="checkbox"
            onChange={() => {
              if (type === 'yearly') {
                setType('monthly')
              } else {
                setType('yearly')
              }
            }}
          />
          <span className="slider round" />
        </label>
      </Wrapper>
      <Label weight="medium" padding="0 4px 0 8px" lineHeight="medium">
        15% off paid annually
      </Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 16px;
`

const Wrapper = styled.div`
  position: relative;
`
