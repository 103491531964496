import React from 'react'
import styled from 'styled-components'
import Users from './Users'
import Profile from './Profile'
import { colors } from '../../core/theme'

const Company = () => {
  return (
    <Container>
      <Users />
      <Profile />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100vh;
  position: relative;
  background-color: ${colors.background};
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`

export default Company
