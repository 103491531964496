import React from 'react'
import styled from 'styled-components'
import { colors, shadows } from '../core/theme'
import Button from '../components/Button'
import { AddIcon } from '../assets/icons'
import BackButton from '../components/BackButton'
import TagRow from '../components/TagRow'
import { usePermission } from '../hooks/usePermission'
import { useActions, useStore } from '../store'
import { Label } from '../components/Typography'
import { TopBar } from '../components/TopBar'

const COLUMNS = ['Name', 'Description', 'Actions']

const TagsManager = () => {
  const { license } = useStore()
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const { removeTagFromLicense } = useActions().license
  const { canEdit, showPermissionAlert } = usePermission()

  const licenseTags = license.tags || []

  const removeTag = async (tag) => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    try {
      await removeTagFromLicense(tag)
    } catch (e) {
      showToast({
        content: 'Failed to delete tag. Please contact our support.',
      })
    }
  }

  return (
    <Container>
      <TopBar>
        <BackWrapper>
          <BackButton />
        </BackWrapper>
        Tags Manager
      </TopBar>
      <TagsWrapper>
        <TopBar>
          <AddTag
            className="primary-compact-button"
            onClick={() => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              showModal({ name: 'CREATE_TAG' })
            }}
            leftIcon={<AddIcon color={colors.white} />}
            small
          >
            New Tag
          </AddTag>
        </TopBar>
        <RowContainer>
          {COLUMNS.map((column, i) => (
            <RowHeader key={i} isLast={i === COLUMNS.length - 1}>
              {column.toUpperCase()}
            </RowHeader>
          ))}
        </RowContainer>
        <Wrapper>
          {licenseTags.map((tag) => (
            <TagRow
              tag={tag}
              key={tag.label}
              removeTag={removeTag}
              editTag={() => {
                if (!canEdit) {
                  showPermissionAlert()
                  return
                }

                showModal({ name: 'CREATE_TAG', tag })
              }}
            />
          ))}
          {licenseTags.length === 0 && (
            <Label padding="16px">No tags created, yet.</Label>
          )}
        </Wrapper>
      </TagsWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  background-color: ${colors.background};
  min-height: 100vh;
`

const BackWrapper = styled.div`
  position: absolute;
  left: 16px;
`

const AddTag = styled(Button)`
  position: absolute;
  font-size: 14px;
  left: 16px;
  margin: 0;
  z-index: 1;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 10px 24px 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  box-shadow: ${shadows.primaryLight};
  flex-shrink: 0;
  z-index: 1;
`

const RowHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.isLast ? 'flex-end' : 'flex-start')};
  font-size: 12px;
  color: ${colors.grey};
  width: 100%;
  @media (max-width: 767px) {
    flex: 1;
  }
`

const TagsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 980px;
  border: 1px solid ${colors.border};
  align-self: center;
  margin-top: -1px;
  @media (max-width: 767px) {
    border: none;
    border-top: 1px solid ${colors.border};
    padding-bottom: 80px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export default TagsManager
