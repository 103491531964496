import React from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import { BILLING_PLANS } from '../core/constants'
import { colors } from '../core/theme'
import { licenseStatus } from '../helpers/licenseStatus'
import { usePermission } from '../hooks/usePermission'
import { useActions, useStore } from '../store'
import Button from './Button'
import { Row } from './Layout'
import PremiumPageBlock from './PremiumPageBlock'
import { Title } from './Typography'

export default function PremiumPages({ license, type, isCustom }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { cardSlots = [] } = licenseStatus(license)
  const { showModal } = useActions().modals
  const { cards } = useStore().cards
  const { canEdit, showPermissionAlert } = usePermission()
  const sortedCardSlots = [...cardSlots].sort((a, b) => {
    return b.createdTimestamp - a.createdTimestamp
  })

  const PREMIUM_PAGES_COLUMNS = isMobile
    ? ['Assigned Page', 'Date Added']
    : ['Number', 'Assigned Page', 'Date Added']

  return (
    <Container>
      <Row
        style={{
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: '16px',
        }}
      >
        <Title>Premium Pages ({isCustom ? '∞' : cardSlots.length})</Title>
        {!isCustom && (
          <Button
            onClick={() => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }

              if (cardSlots.length === 0) {
                showModal({
                  name: 'CREATE_SUBSCRIPTION_MODAL',
                  price: BILLING_PLANS[0].price[type],
                  subscriptionName: 'premium',
                  subscriptionType: type,
                })
                return
              }

              showModal({ name: 'ADD_SLOTS_MODAL' })
            }}
            color={colors.black}
            style={{ borderRadius: 50 }}
          >
            {isMobile ? '+ Add Page' : '+ Add Premium Page'}
          </Button>
        )}
      </Row>
      {cardSlots.length > 0 ? (
        <RowContainer>
          {PREMIUM_PAGES_COLUMNS.map((column, i) => (
            <RowHeader key={i}>{column}</RowHeader>
          ))}
        </RowContainer>
      ) : isCustom ? (
        <RowContainer>All your pages are upgraded to premium 🎉</RowContainer>
      ) : (
        <RowContainer>No premium pages, yet.</RowContainer>
      )}
      {sortedCardSlots.map((slot, index) => (
        <PremiumPageBlock
          key={slot.createdTimestamp}
          slot={slot}
          cards={cards}
          cardSlots={cardSlots}
          index={index + 1}
          isMobile={isMobile}
          canEdit={canEdit}
          showPermissionAlert={showPermissionAlert}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 1100px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;
  @media (max-width: 1000px) {
    margin-top: 24px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #c3cdd3;
  flex-shrink: 0;
  z-index: 1;
  padding: 14px 16px;
  margin-bottom: 24px;
  font-size: 15px;
`

const RowHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.primary};
  width: 100%;
  @media (max-width: 767px) {
    font-size: 13.5px;
  }
`
