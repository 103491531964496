/* eslint-disable react/jsx-no-duplicate-props */
import React from 'react'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'
import InputAdornment from '@mui/material/InputAdornment'
import { colors } from '../core/theme'
import TemplateLock from './TemplateLock'

export default function FloatingInput({
  label,
  error,
  description,
  value,
  onChange,
  multiline,
  style,
  inputProps,
  autoComplete = 'off',
  type = 'text',
  name,
  placeholder,
  readOnly,
  autoFocus,
  prefix,
  shrink,
  maxLength,
  extraSX,
  showLock,
  isLocked = () => {},
  onLock,
  card = {},
  showLockMessage,
  onClick,
}) {
  const field = (
    <TextField
      autoComplete={autoComplete}
      type={type}
      name={name}
      label={label}
      variant="outlined"
      autoFocus={autoFocus}
      fullWidth
      error={error}
      helperText={error || description}
      value={value}
      onChange={onChange}
      multiline={multiline}
      placeholder={placeholder}
      InputLabelProps={{ shrink }}
      onClick={() => {
        if (onClick) {
          onClick()
          return
        }
        if (isLocked(label)) {
          showLockMessage()
        }
      }}
      inputProps={{
        maxLength,
        readOnly: isLocked(label) || readOnly,
        ...inputProps,
      }}
      InputProps={{
        startAdornment: prefix ? (
          <InputAdornment position="start" disablePointerEvents>
            {prefix}
          </InputAdornment>
        ) : null,
      }}
      style={{
        marginBottom: 18,
        ...style,
      }}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& > fieldset': { borderColor: colors.border },
          ...extraSX,
        },
      }}
    />
  )

  if (showLock || (card.cardID && isLocked(label))) {
    return (
      <Container>
        {field}
        <TemplateLock
          onLock={onLock}
          label={label}
          isLocked={isLocked}
          style={{ top: 'calc(50% - 18px)' }}
        />
      </Container>
    )
  }

  return field
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`
