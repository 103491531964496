import React from 'react'
import styled from 'styled-components'
import { PieChart, Pie, Cell } from 'recharts'
import CardBase from './CardBase'
import CardLegend from './CardLegend'
import { PIE_CHART_COLORS } from '../../core/constants'
import { Paragraph } from '../Typography'

const PlatformsCard = ({ data }) => {
  return (
    <CardBase
      title="Platforms"
      body={
        <Container>
          {data.length === 0 && (
            <Paragraph variant="secondary">No data to show</Paragraph>
          )}
          {data.length > 0 && (
            <PieChart width={200} height={200}>
              <Pie
                data={data}
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="count"
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          )}
          <CardLegend legend={data} colors={PIE_CHART_COLORS} />
        </Container>
      }
    />
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  min-width: 250px;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

export default PlatformsCard
