import { keyframes } from 'styled-components'

export const colors = {
  main: '#405de6',
  mainLight: '#f5f4ff',
  mainDark: '#13008f',
  mainHint: '#FBFCFF',
  error: '#EA1034',
  success: '#0B9E58',
  warning: '#F57C00',
  required: '#EA1034',
  google: '#0E5DF9',
  googleGreen: '#0F9D58',
  github: '#000000',
  facebook: '#3b5998',
  twitter: '#000',
  youtube: '#C3302B',
  discord: '#4a67d1',
  slack: '#4C054C',
  red: '#EA1034',
  blue: '#0099FF',
  darkblue: '#3F51B5',
  yellow: '#f6b745',
  orange: '#FF5722',
  purple: '#7b0099',
  filterColor: '#5A699E',
  add: '#007A5A',
  green: '#2EB67D',
  darkgreen: '#38978d',
  toastError: '#C9242D',
  toastSuccess: '#12805C',
  toastWarning: '#F57C00',
  toastInfo: '#0D66D0',
  white: '#ffffff',
  black: '#000000',
  background: '#FDFDFF',
  sidebar: '#EBEBEB',
  menu: '#f5f5f5',
  lightBorder: '#dee2e6',
  border: '#ced6dd',
  darkgrey: '#424d57',
  grey: '#647384',
  lightgrey: '#8A96A3',
  primary: '#000000',
  secondary: '#656565',
  tertiary: '#A6A6A6',
  placeHolder: '#A6A6A6',
  disabled: '#E0E0E0',
  overlay: 'rgba(0, 0, 0, 0.5)',
  tooltip: '#424d57',
  hover: '#fafafa',
  highest: '#0D66D0',
  prefix: '#EEEEEE',
}

export const cardColors = {
  primary: '#405de6',
  backgroundColor: '#f5f5f5',
  cardBackgroundColor: '#ffffff',
  fontPrimary: '#000000',
  socialIconColor: '#000000',
  highlightedIconColor: '#f5f7f8',
}

export const shadows = {
  primary: '4px 4px 8px 0 rgba(29, 36, 86, 0.06)',
  primaryMedium: '2px 2px 6px 0 rgba(29, 36, 86, 0.04)',
  primaryLight: '2px 2px 6px 0 rgba(29, 36, 86, 0.02)',
  menu: '0 4px 24px 2px rgba(0,0,0,.1)',
}

export const animations = {
  bounceIn: keyframes`
    0% {transform: translateY(0px) scale(0) rotate(-70deg)}
    50% {transform: translateY(-12px) scale(1.06) rotate(0deg)}
    100% {transform: translateY(0px) scale(1)}
`,
  pulse: keyframes`
    0% {transform: scale(1)}
    50% {transform: scale(1.1)}
    100% {transform: scale(1)}
`,
  slideUp: keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
`,
  dialogScaleIn: keyframes`
    0% {
      transform: scale(0.9);
    }
    100% {
      transform:  scale(1);
    }
  `,
  dialogOut: keyframes`
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform:  scale(0);
      opacity: 0;
    }
  `,
  dialogSlide: keyframes`
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
`,
  dialogFadeIn: keyframes`
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`,
  slideUpOpacity: keyframes`
    0% {
    transform: translateY(16%);
    opacity: 0;
    }
    100% {
    transform: translateY(0);
    opacity: 1;
    }
`,
  slideDown: keyframes`
    from {
      transform: translateY(0);
    }
    to {
 transform: translateY(100%);
    }
`,
  toastAnimation: keyframes`
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
`,
  fadeIn: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  fadeOut: keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `,
  spin: keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `,
  shrink: keyframes`
    0% { width: 100%; }
    100% { width: 0; }
  `,
}
