import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { useHistory } from 'react-router-dom'
import Button from './Button'
import { colors } from '../core/theme'
import { CheckIcon, EditIcon, ExcelIcon, MobileMenuIcon } from '../assets/icons'
import { useActions, useStore } from '../store'
import CardMenu from './CardMenu'
import { getCompletedCards } from '../api/database-api'
import { createCsvFile } from '../helpers/createCsvFile'

const ActionButtons = ({ card, inbox, tab, unseenCards }) => {
  const history = useHistory()
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const license = useStore().license
  const { markAllContactsAsRead } = useActions().inbox
  const { selectedFolder } = useStore().cards
  const { addCardToFolder } = useActions().cards
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const [exporting, setExportingStatus] = useState()

  return (
    <Container>
      {isMobile ? (
        <Button
          small
          outline
          className="primary-compact-button extra-height"
          style={{ marginRight: 10 }}
          borderColor={colors.purple}
          onClick={() => history.push(`/${card.cardID}/edit`)}
          leftIcon={<EditIcon size={14} color={colors.white} />}
        >
          Edit
        </Button>
      ) : (
        <Button
          small
          outline
          className="compact-button-outline extra-height"
          style={{ marginRight: 10 }}
          borderColor={colors.purple}
          onClick={() => history.push(`/${card.cardID}/edit`)}
          leftIcon={<EditIcon size={14} color={colors.primary} />}
        >
          Edit
        </Button>
      )}

      <Button
        small
        outline
        className="compact-button extra-height"
        borderColor={colors.main}
        onClick={() => showModal({ name: 'SHARE_CARD', card })}
      >
        Share
      </Button>

      {tab === 1 && isMobile && (
        <>
          <Button
            small
            outline
            style={{ marginLeft: 10 }}
            loading={exporting}
            className="compact-button-outline extra-height export-csv"
            onClick={async () => {
              if (inbox.length > 0) {
                if (inbox.length > 1900) {
                  setExportingStatus(true)
                  const result = await getCompletedCards({ card })
                  const receivedCards = []
                  result.forEach((doc) => {
                    receivedCards.push(doc.data())
                  })
                  createCsvFile(card, receivedCards)
                  setExportingStatus(false)
                  return
                }
                createCsvFile(card, inbox)
              } else {
                showToast({
                  content: 'No contacts to export.',
                })
              }
            }}
            borderColor={colors.darkgreen}
            icon={<ExcelIcon />}
          >
            Export to CSV
          </Button>
          {unseenCards > 0 && (
            <Button
              small
              outline
              borderColor={colors.main}
              className="compact-button"
              icon={<CheckIcon />}
              style={{ marginLeft: 10 }}
              onClick={() => {
                markAllContactsAsRead(inbox)
              }}
            >
              Mark all as read
            </Button>
          )}
        </>
      )}
      {!isMobile && (
        <CardMenu
          card={card}
          setFolder={(folder) => {
            try {
              addCardToFolder({ card, folder })
            } catch (e) {
              console.error(e)
              showToast({
                content: 'Failed to add card to folder. Please reload the app.',
              })
            }
          }}
          folders={license.folders}
          selectedFolder={selectedFolder}
          trigger={
            <MenuButton>
              <MobileMenuIcon />
            </MenuButton>
          }
        />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${colors.white};
  align-items: center;
  flex-shrink: 0;
  @media (max-width: 767px) {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.border};
    align-items: center;
    padding: 14px 16px;
    z-index: 100;
  }
`

const MenuButton = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;
`

export default ActionButtons
