import { BUTTON_STYLE_TYPES } from '../core/constants'

export function removePremiumFeaturesFromCard(card) {
  // POWERED BY
  if (card.poweredBy) {
    delete card.poweredBy
  }

  // COMPANY LOGO
  if (card.avatar) {
    if (card.avatar.companyLogoURL) {
      card.avatar.companyLogoURL =
        'https://storage.googleapis.com/konect-7e9af.appspot.com/public/company_lock.svg'
    }
  }

  // VERIFIED
  const { personalDetails = {} } = card
  if (personalDetails.verified) {
    delete card.personalDetails.verified
  }

  // card.poweredBy === 'hide'

  // FAVICON
  if (card.customFavicon) {
    delete card.customFavicon
  }

  // SECTIONS
  if (card.sections) {
    card.sections = card.sections.filter(
      (s) =>
        s.type === 'social-links' ||
        s.type === 'buttons' ||
        s.type === 'description' ||
        s.type === 'link' ||
        s.type === 'folder'
    )
  }

  // NEW BUTTONS
  card.sections = card.sections.filter((section) => {
    if (section.type === 'link' || section.type === 'folder') {
      const foundButton = BUTTON_STYLE_TYPES.find(
        (b) => b.value === section.buttonType
      )
      if (foundButton) {
        section.buttonType = 'image-left'
      }
      return true
    }
    return true
  })

  if (card.sections) {
    card.sections.forEach((section) => {
      // BUTTONS
      if (section.type === 'buttons') {
        if (section.buttons) {
          section.buttons = section.buttons.filter((button) => {
            if (button.type === 'podcast') return false
            const foundButton = BUTTON_STYLE_TYPES.find(
              (b) => b.value === button.buttonType
            )
            if (foundButton) {
              return !foundButton.isPremium
            }
            return true
          })
        }
      }
    })
  }

  return card
}
