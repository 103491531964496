import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { useMediaQuery } from 'react-responsive'
import Button from '../components/Button'
import FloatingInput from '../components/FloatingInput'
import { InfoTooltip } from '../components/InfoTooltip'
import { Row } from '../components/Layout'
import { EmailIcon, TwitterIcon } from '../assets/icons'
import Commissions from '../components/Commissions'
import { useStore } from '../store'
import Spinner from '../components/Spinner'
import {
  getAffiliateData,
  getAffiliateOrders,
  getAffiliateSummary,
} from '../api/database-api'
import AffiliatePayPal from '../components/AffiliatePayPal'

export default function Referrals() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { license } = useStore()
  const [copied, setCopy] = useState(false)
  const {
    affiliateRef,
    affiliateID,
    affiliatePayPal,
    affiliateLevel1 = 20,
    affiliateLevel2 = 10,
  } = license
  const referralLink = `https://konect.to?ref=${affiliateRef}`
  const referralText = 'Create your Konect page'
  const [affiliateStats, setAffiliateStats] = useState({ loading: true })
  const [orders, setOrders] = useState()
  const [summary, setSummary] = useState({ loading: true })

  useEffect(async () => {
    const data = await getAffiliateData(affiliateID)
    setAffiliateStats({ ...data, loading: false })
  }, [])

  useEffect(async () => {
    const data = await getAffiliateOrders(affiliateID)
    setOrders(data)
  }, [])

  useEffect(async () => {
    const data = await getAffiliateSummary(affiliateID)
    setSummary({ ...data, loading: false })
  }, [])

  return (
    <Container>
      <DataWrapper>
        <DataContainer
          style={{
            borderRight: '1px solid #cdd6dc',
            borderBottom: isMobile ? '1px solid #cdd6dc' : 'none',
          }}
        >
          <DataLabel>Clicks</DataLabel>
          <DataPrice>
            {affiliateStats.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              affiliateStats.clicks || 0
            )}
          </DataPrice>
        </DataContainer>
        <DataContainer
          style={{
            borderRight: isMobile ? 'none' : '1px solid #cdd6dc',
            borderBottom: isMobile ? '1px solid #cdd6dc' : 'none',
          }}
        >
          <DataLabel>Sign Ups</DataLabel>
          <DataPrice>
            {affiliateStats.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              affiliateStats.signUps || 0
            )}
          </DataPrice>
        </DataContainer>
        <DataContainer
          style={{
            borderRight: '1px solid #cdd6dc',
            borderBottom: isMobile ? '1px solid #cdd6dc' : 'none',
          }}
        >
          <DataLabel>Subscribed</DataLabel>
          <DataPrice>
            {affiliateStats.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              affiliateStats.subscriptions || 0
            )}
          </DataPrice>
        </DataContainer>
        <DataContainer
          style={{
            borderRight: isMobile ? 'none' : '1px solid #cdd6dc',
            borderBottom: isMobile ? '1px solid #cdd6dc' : 'none',
          }}
        >
          <DataLabel>
            On Hold{' '}
            <InfoTooltip
              icon={<InfoIcon />}
              direction="bottom"
              arrowPosition="top"
              style={{ margin: '0 0 0 8px' }}
              description="Commissions are on hold for 30 days"
            />
          </DataLabel>
          <DataPrice>
            {summary.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              `$${summary.due || 0}`
            )}
          </DataPrice>
        </DataContainer>
        <DataContainer
          style={{
            borderRight: '1px solid #cdd6dc',
          }}
        >
          <DataLabel>
            Next Payout{' '}
            <InfoTooltip
              icon={<InfoIcon />}
              direction="bottom"
              arrowPosition="top"
              style={{ margin: '0 0 0 8px' }}
              description="Payouts are made on the 1st of every month and you must have a minimum commission amount of $10"
            />
          </DataLabel>
          <DataPrice>
            {summary.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              `$${summary.pending || 0}`
            )}
          </DataPrice>
        </DataContainer>
        <DataContainer>
          <DataLabel>Total Paid</DataLabel>
          <DataPrice>
            {summary.loading ? (
              <Spinner size="24px" style={{ marginTop: 6 }} />
            ) : (
              `$${summary.total || 0}`
            )}
          </DataPrice>
        </DataContainer>
      </DataWrapper>
      <BodyWrapper>
        <BodyColumn>
          <BodyItem style={{ borderBottom: '1px solid #cdd6dc' }}>
            <Title>Two-Level Referral Program</Title>
            <Description>
              You will get paid <strong>{affiliateLevel1}%</strong> on direct
              sales and <strong>{affiliateLevel2}%</strong> from sales generated
              by your sign ups on both subscriptions and accessories.
            </Description>
            <Link
              href="https://konect.to/affiliates"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more →
            </Link>
          </BodyItem>
          <BodyItem style={{ borderBottom: '1px solid #cdd6dc' }}>
            <Title>Share Referral Link</Title>
            <Row style={{ marginTop: 16 }}>
              <FloatingInput
                readOnly
                value={referralLink}
                style={{ marginBottom: 0 }}
                extraSX={{
                  '& fieldset': {
                    borderTopRightRadius: `0 !important`,
                    borderBottomRightRadius: `0 !important`,
                    border: 'none',
                  },
                }}
                inputProps={{
                  className: 'grey-input',
                  style: {
                    borderRadius: 8,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
              <CopyToClipboard
                text={referralLink}
                onCopy={() => {
                  setCopy(true)
                  setTimeout(() => setCopy(false), 1500)
                }}
              >
                <Button
                  disabled={copied}
                  style={{
                    margin: '0',
                    height: 50,
                    backgroundColor: '#545454',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  {copied ? 'Copied' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </Row>
            <Row style={{ marginTop: 16 }}>
              <IconContainer
                href={`mailto:?&subject=${encodeURIComponent(
                  referralText
                )}&body=${encodeURIComponent(referralLink)}`}
              >
                <EmailIcon size={44} fill="#545454" />
              </IconContainer>
              <IconContainer
                href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
                  referralLink
                )}&t=${encodeURIComponent(referralText)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon size={44} fill="#545454" />
              </IconContainer>
              <IconContainer
                href={`https://x.com/intent/tweet?url=${encodeURIComponent(
                  referralLink
                )}&text=${encodeURIComponent(referralText)}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon size={22} fill="#545454" />
              </IconContainer>
            </Row>
          </BodyItem>
          <BodyItem>
            <AffiliatePayPal
              affiliatePayPal={affiliatePayPal}
              affiliateID={affiliateID}
            />
          </BodyItem>
        </BodyColumn>
        <CommissionsWrapper
          style={{
            borderLeft: isMobile ? 'none' : '1px solid #cdd6dc',
            borderTop: isMobile ? '1px solid #cdd6dc' : 'none',
          }}
        >
          <Commissions items={orders} license={license} />
        </CommissionsWrapper>
      </BodyWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 40px 44px;
  position: relative;
  overflow: auto;
  background-color: #f5f5f5;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 20px;
    padding-bottom: 100px;
  }
`

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 12px;
  @media (max-width: 767px) {
    flex-wrap: wrap;
  }
`

const DataContainer = styled.div`
  width: calc(100% / 6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 50%;
    height: 120px;
  }
`

const DataLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #110000;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 17px;
  }
`

const DataPrice = styled.span`
  font-size: 24px;
  color: #545454;
  font-weight: 600;
  margin-top: 16px;
  @media (max-width: 767px) {
    font-size: 23px;
  }
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  background-color: white;
  border-radius: 12px;
  margin-top: 24px;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const BodyColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const BodyItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 18px 22px;
  }
`

const CommissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  height: 100%;
  @media (max-width: 767px) {
    max-width: calc(100vw - 40px);
  }
`

const Title = styled.span`
  font-size: 18px;
  color: #110000;
  font-weight: 500;
  line-height: 1.5;
  @media (max-width: 767px) {
    font-size: 17px;
  }
`

const Description = styled.span`
  font-size: 15.5px;
  color: #545454;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 16px;
  @media (max-width: 767px) {
    font-size: 14.5px;
  }
`

const Link = styled.a`
  text-decoration: none;
  font-size: 15.5px;
  color: #545454;
  font-weight: 600;
  line-height: 1.5;
  margin-top: 16px;
  :visited {
    color: #545454;
  }
  @media (max-width: 767px) {
    font-size: 14.5px;
  }
`

const IconContainer = styled.a`
  cursor: pointer;
  width: 54px;
  height: 54px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  margin-right: 12px;
`

const InfoIcon = () => (
  <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.5 0h-2C1.9.5.5 2 0 5.6v1.1L0 7v2.5c.4 3.6 1.9 5 5.5 5.4h1.1l.4.1h2.5c3.6-.4 5-1.9 5.4-5.5V8.4L15 8V5.5C14.5 1.9 13 .5 9.4 0H8.4L8 0h-.5Zm0 3.8a.8.8 0 0 1 .1 1.4h-.1a.8.8 0 0 1 0-1.4Zm0 3a.7.7 0 0 1 .7.6v2.4h.1a.7.7 0 0 1 .7.6v.1a.8.8 0 0 1-.7.7h-.8a.8.8 0 0 1-.7-.6V8.2a.8.8 0 0 1-.1-1.4h.8Z"
      fill="#405DE6"
    />
  </svg>
)

const FacebookIcon = ({ size = 24, fill = 'inherit' }) => (
  <svg
    height={size}
    width={size}
    fill={fill}
    viewBox="0 0 75 75"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m46.5 39.7 1.1-6.9H41v-4.5c0-1.8 1-3.7 4-3.7h3v-5.9s-2.8-.4-5.4-.4c-5.6 0-9.2 3.3-9.2 9.3v5.2H27v7h6.2v17h7.6v-17Z" />
  </svg>
)
