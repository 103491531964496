import { BILLING_PLANS } from '../core/constants'

const licenseTrialStatus = (license) => {
  const { trialEnds } = license
  const currentDate = new Date().getTime()
  const isTrialActive = trialEnds - currentDate >= 0
  const trialDaysLeft = Math.ceil(
    (trialEnds - currentDate) / (1000 * 60 * 60 * 24)
  )
  return {
    isTrialActive,
    trialDaysLeft,
  }
}

export const licenseStatus = (license, card) => {
  const {
    subscriptionId,
    isBlocked,
    subscriptionType,
    cardSlots = [],
    affiliateRef,
  } = license
  let { subscriptionName } = license

  const isCustom = subscriptionName === 'custom'
  const isActive = isBlocked ? false : !!subscriptionId || isCustom

  const { isTrialActive, trialDaysLeft } = licenseTrialStatus(license)

  const isTemplate = window.location.pathname.includes('template')

  if (!isActive && !isTrialActive) {
    subscriptionName = 'free'
  }

  let isCardActive = false
  if (card) {
    isCardActive = cardSlots.find((s) => s.cardID === card.cardID)
    if (isCustom) {
      isCardActive = true
    }
  }
  if (isTemplate) {
    isCardActive = true
  }

  let subscriptionCost = 0
  const subscriptionObject = BILLING_PLANS.find(
    (p) => p.name === subscriptionName
  )
  if (subscriptionObject && subscriptionType) {
    const subscriptionPrice = subscriptionObject.price[subscriptionType]
    if (subscriptionPrice && cardSlots.length > 0) {
      subscriptionCost = cardSlots.length * subscriptionPrice
    }
  }

  return {
    subscriptionName,
    subscriptionType,
    isActive,
    isCustom,
    isBlocked,
    subscriptionId,
    isTrialActive,
    trialDaysLeft,
    cardSlots,
    isCardActive,
    affiliateRef,
    subscriptionCost,
  }
}
