/* eslint-disable no-alert */
import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import MobileCardEditor from './MobileCardEditor'

const EditCardPanel = ({
  card,
  updateCard,
  saveChanges,
  showSave,
  isSaving,
  isCardActive,
  isFreeCard,
  isTemplate,
  templateLockProps,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  window.menuItems = []

  const extraTopBarStyle = { zIndex: 1000 }
  if (isMobile) {
    extraTopBarStyle.padding = '0 8px'
    extraTopBarStyle.position = 'fixed'
  }

  if (isMobile) {
    return (
      <MobileCardEditor
        card={card}
        showSave={showSave}
        saveChanges={saveChanges}
        isFreeCard={isFreeCard}
        isSaving={isSaving}
        updateCard={updateCard}
        isCardActive={isCardActive}
        isTemplate={isTemplate}
        templateLockProps={templateLockProps}
      />
    )
  }

  return (
    <Container id="desktop-editor">
      <OverflowContainer id="editor-container">
        <MobileCardEditor
          card={card}
          showSave={showSave}
          saveChanges={saveChanges}
          isFreeCard={isFreeCard}
          isSaving={isSaving}
          updateCard={updateCard}
          isCardActive={isCardActive}
          isTemplate={isTemplate}
          templateLockProps={templateLockProps}
        />
      </OverflowContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  flex: 7;
  height: 100%;
  overflow-x: hidden;
  @media (max-width: 767px) {
    padding-bottom: 80px;
  }
`

const OverflowContainer = styled.div`
  overflow: auto;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export default EditCardPanel
