import React, { useState } from 'react'
import styled from 'styled-components'
import { animations, colors } from '../core/theme'
import Button from './Button'
import { useActions } from '../store'
import { Dropdown } from './Dropdown'
import { usePermission } from '../hooks/usePermission'
import FloatingInput from './FloatingInput'

const AddFolder = ({ setFolder }) => {
  const { showToast } = useActions().toasts
  const { addFolderToLicense } = useActions().license
  const { canEdit, showPermissionAlert, allowedFolders } = usePermission()
  const [hasAdd, setAddVisibility] = useState(false)
  const [folderName, updateName] = useState('')
  const [loading, setLoading] = useState(false)

  const addFolder = async () => {
    if (loading) return
    if (!folderName) {
      window.alert("Folder name can't be empty.")
      return
    }
    if (!canEdit || allowedFolders) {
      showPermissionAlert()
      return
    }
    setLoading(true)
    try {
      const addedFolder = await addFolderToLicense(folderName)
      if (addedFolder) {
        setFolder(addedFolder)
      }
    } catch (error) {
      showToast({ content: error.message })
    }
    updateName('')
    setLoading(false)
    setAddVisibility(false)
  }

  const hideAdd = () => setAddVisibility(false)
  const showAdd = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (hasAdd) setAddVisibility(false)
    else setAddVisibility(true)
  }

  return (
    <Dropdown
      isVisible={hasAdd}
      placement="bottom-start"
      onClose={hideAdd}
      triggerRenderer={({ ref }) => (
        <Container onClick={showAdd} ref={ref}>
          + New Folder
        </Container>
      )}
    >
      {hasAdd && (
        <AddDropdownContainer>
          <FloatingInput
            shrink
            label="Folder name"
            placeholder="e.g. Support, Sales"
            value={folderName}
            onChange={(e) => updateName(e.target.value)}
            autoFocus={hasAdd}
            style={{ marginTop: 8 }}
          />
          <Button
            style={{ margin: '8px 0 0 0', width: '100%', height: 48 }}
            onClick={addFolder}
            loading={loading}
          >
            Create Folder
          </Button>
        </AddDropdownContainer>
      )}
    </Dropdown>
  )
}

const AddDropdownContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(66, 77, 87, 0.1);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 300px;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  &:focus {
    outline: none;
  }
`

const Container = styled.div`
  font-weight: 500;
  cursor: pointer;
  color: ${colors.primary};
  font-size: 13px;
  transition: opacity 200ms ease-out;
  padding: 4px 6px;
  :hover {
    opacity: 0.7;
  }
`

export default AddFolder
