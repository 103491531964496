import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import ActionModal from './ActionModal'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { markResponseAsDeleted } from '../api/database-api'
import { useActions } from '../store'

const DeleteResponseModal = ({ card, onClose }) => {
  const history = useHistory()
  const { showToast } = useActions().toasts
  const [isDeleting, setDeleteStatus] = useState(false)

  const deleteResponse = async () => {
    if (isDeleting) return
    setDeleteStatus(true)
    try {
      history.goBack()
      await markResponseAsDeleted(card)
      showToast({
        content: 'Response deleted successfully.',
        variant: 'success',
      })
    } catch (e) {
      showToast({
        content: 'Unexpected error occurred. Please try again.',
      })
    }
    setDeleteStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DeleteIcon size={60} color={colors.error} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={deleteResponse}
            loading={isDeleting}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, delete
          </Button>
        </>
      }
      header="Delete contact?"
      description="You're about to delete this contact. Are you sure you want to continue?"
    />
  )
}

export default DeleteResponseModal
