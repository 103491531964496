import { deepCloneObject } from './deepCloneObject'

export function queryStringToJSON() {
  const pairs = window.location.search.slice(1).split('&')
  const result = {}
  pairs.forEach((pair) => {
    pair = pair.split('=')
    result[pair[0]] = decodeURIComponent(pair[1] || '')
  })

  return deepCloneObject(result)
}
