import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { DeleteIcon } from '../assets/icons'
import { Label, Caption } from './Typography'
import { returnCardQuestions } from '../helpers/returnCardQuestions'
import FloatingSelect from './FloatingSelect'

const HubSpotIntegrationDetails = ({ targetObject, card, updateCard }) => {
  const { hubspot } = targetObject
  if (!hubspot) return null

  const { properties = [], type = 'Contact' } = hubspot
  const formQuestions = returnCardQuestions(targetObject)

  const addEmptyMap = () => {
    if (!hubspot.questionsMap) {
      hubspot.questionsMap = [{}]
    } else {
      hubspot.questionsMap = [...hubspot.questionsMap, {}]
    }
    updateCard(card)
  }

  return (
    <Container>
      <FloatingSelect
        label="Select target"
        empty="- Select target -"
        value={type}
        options={['Contact', 'Ticket'].map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        })}
        style={{ maxWidth: 240 }}
        onChange={(e) => {
          targetObject.hubspot.type = e.target.value
          updateCard(card)
        }}
      />
      {type === 'Ticket' && (
        <>
          <Label padding="4px 0 8px 4px" weight="medium">
            Map field to ticket subject:{' '}
            <Caption style={{ color: colors.purple }}>(optional)</Caption>
          </Label>
          <Caption padding="0 4px 8px 4px">
            Default ticket's subject will be set to:{' '}
            <strong>{card.label}</strong>.
          </Caption>
          <FloatingSelect
            label="Select field"
            empty="- Select field -"
            value={hubspot.mapQuestionToSubject || ''}
            style={{ maxWidth: 320, padding: 4 }}
            options={formQuestions
              .map((q) => q.question || q.placeholder)
              .map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              })}
            onChange={(e) => {
              hubspot.mapQuestionToSubject = e.target.value
              updateCard(card)
            }}
          />
        </>
      )}
      {type === 'Contact' && (
        <>
          <Label padding="8px 0" weight="medium">
            Map fields to HubSpot's properties:
          </Label>
          <Caption padding="0 0 12px 0">
            Email address will be mapped automatically.
          </Caption>
          {hubspot.questionsMap &&
            hubspot.questionsMap.map((mappedQuestion, i) => {
              const { question = '', property = {} } = mappedQuestion
              return (
                <MapContainer key={question}>
                  <FloatingSelect
                    empty="- Select field -"
                    style={{ marginBottom: 0 }}
                    value={question}
                    onChange={(e) => {
                      hubspot.questionsMap[i] = {
                        ...hubspot.questionsMap[i],
                        question: e.target.value,
                      }
                      updateCard(card)
                    }}
                    options={formQuestions
                      .map((l) => l.question || l.placeholder)
                      .map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        )
                      })}
                  />
                  <Divider>{'->'}</Divider>
                  <FloatingSelect
                    empty="- Select property -"
                    style={{ marginBottom: 0 }}
                    value={property.label || ''}
                    onChange={(e) => {
                      const selectedProperty = properties.find(
                        (p) => p.label === e.target.value
                      )
                      hubspot.questionsMap[i] = {
                        ...hubspot.questionsMap[i],
                        property: selectedProperty,
                      }
                      updateCard(card)
                    }}
                    options={properties
                      .map((l) => l.label)
                      .map((option) => {
                        return (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        )
                      })}
                  />
                  <DeleteContainer
                    onClick={() => {
                      hubspot.questionsMap.splice(i, 1)
                      updateCard(card)
                    }}
                  >
                    <DeleteIcon size={18} />
                  </DeleteContainer>
                </MapContainer>
              )
            })}
          <Label
            weight="medium"
            variant="primary"
            onClick={addEmptyMap}
            style={{
              marginTop: 4,
              marginBottom: 8,
              alignSelf: 'flex-start',
              cursor: 'pointer',
            }}
          >
            + Add another
          </Label>
        </>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const Divider = styled.div`
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0 6px;
  white-space: nowrap;
`

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`

const DeleteContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: fill 200ms ease-out;
  :hover {
    fill: ${colors.red};
  }
`

export default HubSpotIntegrationDetails
