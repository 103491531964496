import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { colors } from '../core/theme'
import { DropImageIcon } from '../assets/icons'
import { uploadFormFavicon } from '../api/storage-api'
import { useActions, useStore } from '../store'
import Spinner from './Spinner'
import PremiumBadge from './PremiumBadge'
import { licenseStatus } from '../helpers/licenseStatus'
import TemplateLock from './TemplateLock'

export default function FaviconUpload({
  card,
  updateCard,
  showLock,
  isLocked = () => {},
  onLock,
  showLockMessage,
}) {
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const { license } = useStore()
  const [error, setError] = useState('')
  const [uploading, setUploading] = useState(false)
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const label = 'Custom Favicon'

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (!isCardActive && !isTrialActive) {
      showModal({ name: 'TRIAL_ENDED' })
      return
    }

    if (uploading) return

    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('File must be .ico type.')
      } else {
        setError('File exceeded max 100kb size.')
      }
      return
    }

    setError(false)
    setUploading(true)

    const uploadedLogo = acceptedFiles[0]
    if (!uploadedLogo) return

    try {
      card.customFavicon = await uploadFormFavicon(uploadedLogo, card)
      updateCard(card)
    } catch (e) {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }

    setUploading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    maxSize: 100000,
    disabled: isLocked(label),
  })
  const inputProps = getInputProps()

  const extraProps = {}
  if (card.customFavicon) {
    extraProps.onClick = () => {
      if (isLocked(label)) {
        return
      }
      const result = window.confirm(
        `You are about to delete this favicon. Are you sure you want to continue?`
      )
      if (result) {
        delete card.customFavicon
        updateCard(card)
      }
    }
  }

  if (!isCardActive && !isTrialActive) {
    inputProps.onClick = (e) => {
      e.stopPropagation()
      e.preventDefault()
      if (!isCardActive && !isTrialActive) {
        showModal({ name: 'TRIAL_ENDED' })
      }
    }
  }

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        if (isLocked(label)) {
          showLockMessage()
        }
      }}
    >
      <InputContainer {...getRootProps()} {...extraProps}>
        <EmptyInput {...inputProps} />
        <InputField error={error}>
          Custom Favicon{' '}
          <PremiumBadge
            type="feature"
            direction="right"
            arrowPosition="left"
            size={14}
            zIndex={1}
            margin="0 8px"
          />
        </InputField>
        <SuffixContainer
          showLock={showLock || (card.cardID && isLocked(label))}
        >
          {card.customFavicon ? (
            <SuffixImage src={card.customFavicon} alt="logo" />
          ) : uploading ? (
            <Spinner />
          ) : (
            <DropImageIcon />
          )}
        </SuffixContainer>
        {(showLock || (card.cardID && isLocked(label))) && (
          <TemplateLock onLock={onLock} label={label} isLocked={isLocked} />
        )}
      </InputContainer>
      {error && <Error>{error}</Error>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 18px;
`

const EmptyInput = styled.input`
  display: none;
  position: relative;
  width: 0;
  height: 0;
`

const Error = styled.span`
  font-size: 14px;
  color: ${colors.error};
  margin-top: 8px;
  margin-left: 6px;
  margin-bottom: 4px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  cursor: pointer;
`

const InputField = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 14px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  :hover {
    border: 1px solid
      ${(props) => (props.error ? colors.error : 'rgba(0, 0, 0, 0.87)')};
  }
  @media (max-width: 767px) {
    :hover {
      border: 1px solid
        ${(props) => (props.error ? colors.error : colors.border)};
    }
  }
`

const SuffixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  height: 50px;
  width: ${(props) => (props.showLock ? '68px' : '50px')};
  padding-right: ${(props) => (props.showLock ? '10px' : '0')};
  line-height: 1.5;
  color: ${colors.darkgrey};
  background-color: ${colors.prefix};
  border: 1px solid ${colors.border};
  border-left: none;
  box-sizing: border-box;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
`

const SuffixImage = styled.img`
  width: 40px;
  height: 40px;
  aspect-ratio: 1 / 1;
  object-fit: contain;
`
