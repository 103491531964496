const INITIAL_STATE = []

const showToast = ({ state }, toast) => {
  state.toasts = [
    ...state.toasts,
    {
      key: Math.random().toString(36),
      ...toast,
    },
  ]
}

const hideToast = ({ state }, key) => {
  state.toasts = state.toasts.filter((toast) => toast.key !== key)
}

const clearToasts = ({ state }) => {
  state.toasts = []
}

export default {
  state: INITIAL_STATE,
  actions: {
    showToast,
    hideToast,
    clearToasts,
  },
}
