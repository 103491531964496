import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { ArrowRight } from '../assets/icons'
import { useActions } from '../store'
import { Label } from '../components/Typography'
import FloatingInput from '../components/FloatingInput'

const GoogleAnalyticsModal = ({ onClose, card, updateCard }) => {
  const { showToast } = useActions().toasts
  const [loading, setLoading] = useState(false)
  const [trackId, updateTrackId] = useState('')
  const [error, setError] = useState(false)

  const connectIntegration = async () => {
    if (loading) return

    if (!trackId) {
      setError("Tracking ID can't be empty.")
      return
    }

    setLoading(true)
    await updateCard(
      {
        ...card,
        google_analytics: {
          id: trackId,
          createdTimestamp: new Date().getTime(),
        },
      },
      true
    )

    showToast({
      content: 'Google Analytics connected successfully.',
      variant: 'success',
    })

    setLoading(false)
    onClose(null)
  }

  const updateTrackingId = (e) => {
    updateTrackId(e.target.value)
    setError(false)
  }

  return (
    <BaseModal
      title="Google Analytics"
      maxWidth="640px"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} outline style={{ margin: '0 8px' }}>
            Cancel
          </Button>
          <Button
            onClick={connectIntegration}
            loading={loading}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Connect Analytics
          </Button>
        </>
      }
    >
      <BodyWrapper>
        <Info variant="secondary">
          Copy and paste your Google Analytics Tracking ID below.
        </Info>
        <FloatingInput
          value={trackId}
          onChange={updateTrackingId}
          description={
            <span>
              You can find your Tracking ID{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://support.google.com/analytics/thread/13109681?hl=en"
                style={{ fontWeight: '500' }}
              >
                here.
              </a>
            </span>
          }
          label="Tracking ID"
          error={error}
          placeholder="e.g. UA-XXXXX-Y"
          style={{ marginTop: 8 }}
        />
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 28px 16px 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const Info = styled(Label)`
  padding: 20px 0;
  @media (max-width: 767px) {
    padding: 4px 0 20px 0;
  }
`

export default GoogleAnalyticsModal
