import React from 'react'
import styled from 'styled-components'
import { TwitterIcon } from '../../assets/icons'
import { Tooltip, TooltipContent } from '../Tooltip'

const Twitter = ({ cardURL, text }) => (
  <IconContainer>
    <Tooltip
      trigger="hover"
      content={
        <TooltipContent arrowPosition="top">Share page on X</TooltipContent>
      }
      direction="bottom"
    >
      <Link
        href={`https://x.com/intent/tweet?url=${encodeURIComponent(
          cardURL
        )}&text=${encodeURIComponent(text)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <div
          style={{
            backgroundColor: '#000',
            width: 22,
            height: 22,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 2,
            marginTop: -3,
          }}
        >
          <TwitterIcon fill="#fff" size={15} />
        </div>
      </Link>
    </Tooltip>
  </IconContainer>
)

const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 12px 0 0;
`

const Link = styled.a`
  text-decoration: none;
  transition: opacity 0.3s ease-out;
  :hover {
    opacity: 0.7;
  }
`

export default Twitter
