import React, { useState, createRef, useEffect } from 'react'
import styled from 'styled-components'
import { getTooltipPosition, DIRECTION, ALIGNMENT } from './helpers'

const Tooltip = ({
  children,
  content,
  align,
  offset = 0,
  trigger,
  direction,
  zIndex,
  style,
}) => {
  const [isVisible, setVisibility] = useState(false)
  const [xPosition, setXPosition] = useState()
  const [yPosition, setYPosition] = useState()

  const tooltipRef = createRef()
  const tooltipBoxRef = createRef()

  useEffect(() => {
    const getAlignment = (alignmentDirection) => {
      const supportedAlignments = {
        [DIRECTION.Left]: [ALIGNMENT.Center, ALIGNMENT.Top, ALIGNMENT.Bottom],
        [DIRECTION.Right]: [ALIGNMENT.Center, ALIGNMENT.Top, ALIGNMENT.Bottom],
        [DIRECTION.Top]: [ALIGNMENT.Center, ALIGNMENT.Left, ALIGNMENT.Right],
        [DIRECTION.Bottom]: [ALIGNMENT.Center, ALIGNMENT.Left, ALIGNMENT.Right],
      }

      if (supportedAlignments[alignmentDirection].indexOf(align) > -1) {
        return align
      }

      return ALIGNMENT.Center
    }

    const calculatePosition = (alignmentDirection) => {
      if (!tooltipRef.current) {
        return true
      }

      const tooltipRefRect = tooltipRef.current.getBoundingClientRect()
      const tooltipBoxRefRect = tooltipBoxRef.current.getBoundingClientRect()

      tooltipRef.current.lastTooltipRefTop = tooltipRefRect.top
      tooltipRef.current.lastTooltipRefLeft = tooltipRefRect.left
      const alignment = getAlignment(alignmentDirection)

      const { tooltipXPosition, tooltipYPosition } = getTooltipPosition({
        direction: alignmentDirection,
        align: alignment,
        offset,
        tooltipRefRect,
        tooltipBoxRefRect,
      })

      setXPosition(tooltipXPosition)
      setYPosition(tooltipYPosition)

      return true
    }

    const onViewportChange = () => {
      if (isVisible) setVisibility(false)
    }

    window.addEventListener('scroll', onViewportChange)
    window.addEventListener('resize', onViewportChange)

    if (isVisible && tooltipRef.current) {
      const tooltipRefRect = tooltipRef.current.getBoundingClientRect()

      if (
        tooltipRefRect.top !== tooltipRef.current.lastTooltipRefTop ||
        tooltipRefRect.left !== tooltipRef.current.lastTooltipRefLeft
      ) {
        const directionsChecked = []
        let tooltipDirection = direction

        while (!calculatePosition(tooltipDirection)) {
          directionsChecked.push(tooltipDirection)
          tooltipDirection = direction
        }
      }
    }

    return () => {
      window.removeEventListener('scroll', onViewportChange)
      window.removeEventListener('resize', onViewportChange)
    }
  }, [isVisible, tooltipRef, direction, align, offset, tooltipBoxRef])

  const onMouseEnter = () => {
    if (trigger === 'hover') {
      setVisibility(true)
    }
  }

  const onMouseLeave = () => {
    if (trigger === 'hover') {
      setVisibility(false)
    }
  }

  return (
    <Container
      ref={tooltipRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      zIndex={zIndex}
      style={style}
    >
      {children}
      <TooltipBox
        ref={tooltipBoxRef}
        visible={isVisible && yPosition && xPosition}
        style={{
          top: `${yPosition}px`,
          left: `${xPosition}px`,
        }}
      >
        {content}
      </TooltipBox>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  z-index: ${(props) => props.zIndex || 999};
  width: auto;
`

const TooltipBox = styled.div`
  position: fixed;
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
`

export default Tooltip
