import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import KonectCard from 'konect-react'
import { CloseIcon } from '../assets/icons'
import { Paragraph } from '../components/Typography'
import { cardColors, colors } from '../core/theme'
import Modal from './Modal'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { removePremiumFeaturesFromCard } from '../helpers/removePremiumFeaturesFromCard'
import { useStore } from '../store'

export default function CardPreviewModal({ onClose, card, isFreeCard }) {
  const { license } = useStore()
  const { cardBackgroundColor = cardColors.cardBackgroundColor } = card
  const ref = useRef()
  const onModalClose = () => {
    if (ref && ref.current) {
      ref.current.classList.add('modal-out-animation')
    }
    setTimeout(onClose, 180)
  }

  useEffect(() => {
    if (window.ks_footer_calculatePageSize) {
      window.ks_footer_calculatePageSize()
    }
  }, [])

  return (
    <Modal onClose={onModalClose} forwardRef={ref}>
      <Container>
        <Header>
          <Paragraph weight="medium">{card.label}</Paragraph>
          <CloseButton onClick={onModalClose}>
            <CloseIcon color={colors.darkgrey} />
          </CloseButton>
        </Header>
        <OverflowContainer
          onScroll={() => {
            if (window.ks_trigger_page_scroll) {
              window.ks_trigger_page_scroll()
            }
          }}
        >
          <CardContainer style={{ backgroundColor: cardBackgroundColor }}>
            <KonectCard
              card={
                isFreeCard
                  ? removePremiumFeaturesFromCard(
                      deepCloneObject({
                        ...card,
                        affiliateRef: license.affiliateRef,
                      })
                    )
                  : {
                      ...card,
                      affiliateRef: license.affiliateRef,
                    }
              }
              editMode
            />
          </CardContainer>
        </OverflowContainer>
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 15px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  border-radius: 8px 8px 0 0;
  @media (max-width: 767px) {
    padding: 15px 14px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

const OverflowContainer = styled.div`
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`
