import React from 'react'
import styled from 'styled-components'
import Toast from './Toast'

const ToastWrapper = (props) => {
  const {
    verticalPosition,
    horizontalPosition,
    fixed,
    toasts,
    children,
    style,
  } = props

  return (
    <Container
      fixed={fixed}
      horizontalPosition={horizontalPosition}
      verticalPosition={verticalPosition}
      style={style}
    >
      {children}
      {toasts.map(({ id, variant, content, onClose, removable, action }) => (
        <Toast
          key={id}
          variant={variant}
          onClose={onClose}
          removable={removable}
          action={action}
          content={content}
        />
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99900000;
  position: ${(props) => (props.fixed ? 'fixed' : 'absolute')};
  margin: ${(props) => props.margin || '0'};

  ${(props) => {
    switch (props.horizontalPosition) {
      case 'center':
        return `left: calc(50%); transform: translateX(-50%);`
      case 'left':
        return `left: 5px;`
      case 'right':
        return `right: 5px;`
      default:
        return ``
    }
  }}

  ${(props) => {
    switch (props.verticalPosition) {
      case 'top':
        return `top: 5px;`
      case 'bottom':
        return `bottom: 5px;`
      default:
        return ``
    }
  }}
`

ToastWrapper.defaultProps = {
  toasts: [],
  fixed: true,
  block: false,
  verticalPosition: 'top',
  horizontalPosition: 'center',
}

export default ToastWrapper
