import React, { useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import {
  DeleteIcon,
  DraggableIcon,
  EditTextIcon,
  FolderIcon,
  FullFolderIcon,
} from '../assets/icons'
import { animations, cardColors } from '../core/theme'
import SectionSettingsModal from '../modals/SectionSettingsModal'
import { arrayMoveElement } from '../helpers/arrayMoveElement'
import { InfoTooltip } from './InfoTooltip'
import { returnSocialIcon } from './IconStylePicker'
import { returnSectionIcon } from '../modals/AddSectionModal'
import TemplateLock from './TemplateLock'

function canBeAddedToFolder(item, section, index) {
  const { buttons = [] } = section
  if (item.type === 'folder') return false
  for (let i = 0; i < index; i++) {
    const previousButton = buttons[i] || {}
    if (previousButton.type === 'folder') {
      return true
    }
  }
  return false
}

export default function MobileItemsList({
  items = [],
  card,
  section,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  isMobile,
  templateLockProps,
  isDragDisabled,
  sectionLabel,
}) {
  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return
    const sourceIndex = source.index
    const destinationIndex = destination.index
    if (sourceIndex === destinationIndex) return
    if (section.type === 'social-links') {
      section.links = arrayMoveElement(
        section.links,
        sourceIndex,
        destinationIndex
      )
    }
    if (section.type === 'buttons') {
      section.buttons = arrayMoveElement(
        section.buttons,
        sourceIndex,
        destinationIndex
      )
    }
    updateCard(card)
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(listProvided) => (
          <List {...listProvided.droppableProps} ref={listProvided.innerRef}>
            {items.map((item, index) => (
              <MobileItem
                isDragDisabled={isDragDisabled}
                templateLockProps={templateLockProps}
                key={item.id}
                item={item}
                index={index}
                section={section}
                card={card}
                updateCard={updateCard}
                showSave={showSave}
                saveChanges={saveChanges}
                isSaving={isSaving}
                isMobile={isMobile}
                sectionLabel={sectionLabel}
              />
            ))}
            {listProvided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  )
}

function MobileItem({
  item,
  index,
  section,
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  isMobile,
  templateLockProps,
  isDragDisabled,
  sectionLabel,
}) {
  const { socialIconColor = cardColors.socialIconColor } = card
  const [openSettings, setOpenSettings] = useState(false)
  const deleteItem = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const result = window.confirm('Are you sure you want to delete this item?')
    if (!result) return
    if (section.type === 'social-links') {
      section.links = section.links.filter((_a, a) => a !== index)
      updateCard(card)
    }
    if (section.type === 'buttons') {
      section.buttons = section.buttons.filter((_a, a) => a !== index)
      updateCard(card)
    }
  }
  let type = ''
  const label = `${section.id}-${item.id}`
  if (section.type === 'social-links') type = 'icon'
  if (section.type === 'buttons') type = 'button'
  const hasAddToFolder =
    type === 'button' && canBeAddedToFolder(item, section, index)

  const isContentBlocked = templateLockProps.isLocked('Add Content')
  const isSectionBlocked = templateLockProps.isLocked(sectionLabel)
  const isItemBlocked =
    isContentBlocked || isSectionBlocked || templateLockProps.isLocked(label)
  const showLock =
    isContentBlocked || isSectionBlocked
      ? false
      : templateLockProps.showLock || (card.cardID && isItemBlocked)

  return (
    <>
      <Draggable
        key={item.id || item.title}
        draggableId={item.id || item.title}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <ItemContainer
            showLock={showLock}
            type={type}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {!isDragDisabled && (
              <IconContainer style={{ marginRight: 8 }}>
                <DraggableIcon />
              </IconContainer>
            )}
            {type === 'icon' ? (
              <SectionTitleWrapper>
                {returnSocialIcon({
                  ...section,
                  ...item,
                  selected: true,
                  socialIconColor,
                  customSize:
                    section.layout === 'minimal'
                      ? isMobile
                        ? 32
                        : 32
                      : isMobile
                      ? 40
                      : 42,
                })}
                <SectionTitle type={type}>{item.title}</SectionTitle>
              </SectionTitleWrapper>
            ) : (
              <SectionTitleWrapper>
                <div style={{ display: 'flex' }}>
                  {returnSectionIcon(item.type || 'link', 42)}
                </div>
                <SectionTitle type={type}>{item.title}</SectionTitle>
              </SectionTitleWrapper>
            )}
            {!isItemBlocked && (
              <>
                {hasAddToFolder && (
                  <InfoTooltip
                    hideMobile={isMobile}
                    icon={
                      <IconContainer
                        onClick={() => {
                          if (item.addToFolder) {
                            delete item.addToFolder
                          } else {
                            item.addToFolder = true
                          }
                          updateCard(card)
                        }}
                      >
                        {item.addToFolder ? <FullFolderIcon /> : <FolderIcon />}
                      </IconContainer>
                    }
                    description={
                      item.addToFolder ? 'Remove from folder' : 'Add to folder'
                    }
                    direction="bottom"
                    arrowPosition="top"
                    zIndex={1}
                  />
                )}
                <IconContainer
                  id={`${section.type}-${item.id}`}
                  onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    setOpenSettings(true)
                  }}
                >
                  <EditTextIcon size={17} />
                </IconContainer>
                <IconContainer onClick={deleteItem}>
                  <DeleteIcon size={21} />
                </IconContainer>
              </>
            )}
            {showLock && (
              <TemplateLock
                onLock={templateLockProps.onLock}
                label={label}
                isLocked={templateLockProps.isLocked}
                type="content"
                style={{
                  right: '-14px',
                }}
              />
            )}
          </ItemContainer>
        )}
      </Draggable>
      {openSettings && (
        <SectionSettingsModal
          templateLockProps={templateLockProps}
          card={card}
          updateCard={updateCard}
          section={{ ...section, type }}
          item={item}
          index={index}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setOpenSettings(false)
          }}
        />
      )}
    </>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const List = styled.div`
  width: 100%;
  animation: ${animations.fadeIn} 500ms linear;
  box-sizing: border-box;
`

const ItemContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background-color: white;
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  padding: ${(props) =>
    props.type === 'icon'
      ? props.showLock
        ? '6px 12px 6px 8px'
        : '6px 4px 6px 8px'
      : props.showLock
      ? '12px 12px 12px 8px'
      : '12px 4px 12px 8px'};
  margin: 0 0 16px 0;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
`

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const SectionTitle = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: ${(props) => (props.type === 'icon' ? '8px' : '12px')};
`
