import styled from 'styled-components'
import { colors } from '../../core/theme'

const TabsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0;
  box-sizing: border-box;
  flex-shrink: 0;
  background-color: ${colors.white};
  border-radius: 0;
  border-bottom: 1px solid ${colors.border};
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`

export default TabsWrapper
