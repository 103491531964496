import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { getAccessoryData } from '../api/database-api'
import { ACCESSORIES_STYLES } from '../core/constants'
import { colors } from '../core/theme'
import Spinner from './Spinner'

export default function AccessoryView({ accessoryID }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState()
  const [data, setData] = useState({})
  const { color = 'dark', type = 'card' } = data
  const { background, text, image } = ACCESSORIES_STYLES[type][color]

  useEffect(async () => {
    try {
      const response = await getAccessoryData(accessoryID)
      const accessoryData = response.data()
      if (!accessoryData) {
        throw new Error(
          "Accessory with given ID doesn't exist. Please contact our support."
        )
      }
      setData(accessoryData)
    } catch (e) {
      console.error(e)
      setError('Something went wrong. Please contact our support.')
    }
    setLoading(false)
  }, [])

  if (loading) {
    return (
      <Container>
        <Spinner />
      </Container>
    )
  }

  if (error) {
    return (
      <Container>
        <Error>{error}</Error>
      </Container>
    )
  }

  if (image && type === 'tag') {
    return (
      <Container>
        <TagImage src={image} alt="" />
        <Description>
          Create a new account or login to activate your new Konect Tag.
        </Description>
      </Container>
    )
  }

  if (image && type === 'card') {
    return (
      <Container>
        <CardImage src={image} alt="" />
        <Description>
          Create a new account or login to activate your new Konect Card.
        </Description>
      </Container>
    )
  }

  return (
    <Container>
      <CardContainer color={background}>
        <Name color={text}>Konect.</Name>
      </CardContainer>
      <Description>
        Create a new account or login to activate your new Konect Card.
      </Description>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
`

const CardContainer = styled.div`
  width: 340px;
  height: 208px;
  background: ${(props) => props.color};
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 16px 28px;
  box-sizing: border-box;
  box-shadow: 0 2px 10px 0 rgb(49 49 93 / 12%), 0 8px 18px 0 rgb(49 49 93 / 8%);
  @media (max-width: 767px) {
    width: 280px;
    height: 172px;
    margin-top: 16px;
  }
`

const CardImage = styled.img`
  width: 340px;
  height: 208px;
  border-radius: 10px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 767px) {
    width: 280px;
    height: 172px;
    margin-top: 16px;
  }
`

const TagImage = styled.img`
  width: 160px;
  height: 160px;
  position: relative;
  justify-content: center;
  box-sizing: border-box;
  background-color: transparent;
  @media (max-width: 767px) {
    margin-top: 16px;
  }
`

const Name = styled.div`
  font-size: 44px;
  color: ${(props) => props.color};
  font-weight: bold;
  @media (max-width: 767px) {
    font-size: 38px;
  }
`

const Description = styled.p`
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  width: 100%;
  max-width: 280px;
  margin: 28px 0 0 0;
  box-sizing: border-box;
  font-weight: 400;
`

const Error = styled.p`
  font-size: 16px;
  line-height: 1.75;
  text-align: center;
  width: 100%;
  max-width: 280px;
  margin: 24px 0 0 0;
  box-sizing: border-box;
  font-weight: 400;
  color: ${colors.error};
`
