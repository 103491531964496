import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'

export default function FloatingSelect({
  label,
  value,
  onChange,
  description,
  error,
  empty,
  options,
  style,
}) {
  return (
    <Container style={style}>
      {label && <FloatingLabel>{label}</FloatingLabel>}
      <Wrapper error={error} hasLabel={!!label}>
        <SelectInput value={value} onChange={onChange}>
          {empty && (
            <option value="" disabled>
              {empty.length > 0 ? empty : '- Select an option -'}
            </option>
          )}
          {options && options}
        </SelectInput>
      </Wrapper>
      {error && <Error>{error}</Error>}
      {description && !error && <Description>{description}</Description>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 18px;
`

const FloatingLabel = styled.div`
  position: absolute;
  left: 7px;
  top: 0;
  background-color: white;
  padding: 2px 8px 2px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  z-index: 1;
  color: #444;
`

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  box-sizing: border-box;
  margin-top: ${(props) => (props.hasLabel ? '12px' : '0px')};
  :hover {
    border: 1px solid
      ${(props) => (props.error ? colors.error : 'rgba(0, 0, 0, 0.87)')};
  }
  @media (max-width: 767px) {
    :hover {
      border: 1px solid
        ${(props) => (props.error ? colors.error : colors.border)};
    }
  }
`

const SelectInput = styled.select`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  height: 50px;
  line-height: 50px;
  cursor: pointer;
  padding: 0 12px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  background: white
    url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 99% 50%;
  background-size: 20px;
  box-sizing: border-box;
  border: none;
  border-radius: 8px;
  transition: all 0.15s ease-in-out;
  color: ${(props) => (props.value ? colors.primary : colors.grey)};
  outline: none;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 0.6; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:focus {
    outline: 0;
    text-decoration: none;
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid ${colors.success};
    -webkit-text-fill-color: ${colors.primary};
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    text-decoration: none;
  }

  &:disabled {
    color: ${colors.primary};
    opacity: 0.4;
    cursor: not-allowed;
  }
`

const Description = styled.span`
  color: rgba(0, 0, 0, 0.6);
  font-family: Mulish, system-ui, sans-serif !important;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
`

const Error = styled.span`
  color: #d32f2f;
  font-family: Mulish, system-ui, sans-serif !important;
  font-size: 13px !important;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
`
