import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import EmailNotifications from '../components/CardEditItem/EmailNotifications'
import BaseModal from './BaseModal'

export default function EmailNotificationsModal({
  onClose,
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  section,
}) {
  return (
    <BaseModal
      title={
        showSave ? (
          <Button
            small
            loading={isSaving}
            onClick={() => saveChanges(card)}
            className="save-button"
          >
            Save
          </Button>
        ) : null
      }
      forceFullscreen
      onClose={onClose}
    >
      <Container>
        <EmailNotifications
          card={card}
          updateCard={updateCard}
          section={section}
        />
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`
