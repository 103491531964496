import React from 'react'
import styled from 'styled-components'
import { colors, shadows } from '../core/theme'

const AuthButton = ({ title, icon, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Icon>{icon}</Icon>
      {title}
    </Container>
  )
}

const Container = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  letter-spacing: 0.4px;
  font-weight: 600;
  width: 100%;
  color: ${colors.primary};
  background-color: ${colors.white};
  text-align: center;
  box-sizing: border-box;
  padding: 0 16px;
  line-height: 1.5;
  height: 51px;
  margin: 12px 0;
  border: 1px solid ${colors.border};
  border-radius: 7px;
  white-space: pre;
  box-shadow: ${shadows.primary}
  flex-shrink: 0;
  transition: all 0.2s ease-out;
  :hover {
    background-color: #f5f5f9;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`

export default AuthButton
