import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import { config } from '../core/config'

export const logoutUser = () => {
  firebase.auth().signOut()
  window.listeningForCards = false
  window.listeningForInbox = false
  window.userName = ''
}

export const signUpWithPasswordAndEmail = async ({ email, password, name }) => {
  try {
    window.userName = name
    const user = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
    return { user }
  } catch (error) {
    return {
      error: error.message,
    }
  }
}

export const loginWithPasswordAndEmail = async ({ email, password }) => {
  try {
    const user = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
    return { user }
  } catch (error) {
    return {
      error: error.message,
    }
  }
}

export const sendEmailWithPassword = async (email) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    return {}
  } catch (error) {
    return {
      error: error.message,
    }
  }
}

export const userLoginWithGoogle = () => {
  const provider = new firebase.auth.GoogleAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const userLoginWithTwitter = () => {
  const provider = new firebase.auth.TwitterAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const userLoginWithFacebook = () => {
  const provider = new firebase.auth.FacebookAuthProvider()
  return firebase.auth().signInWithPopup(provider)
}

export const revokePipedriveToken = (token) => {
  return axios.post(config.SERVER_URL + '/pipedrive/revoke', {
    token,
  })
}

export const sendAccountCreatedData = (license, profile) => {
  axios.post(config.SERVER_URL + '/gohighlevel/account-created', {
    license,
    profile,
  })
}

export const checkAffiliateData = (license, email) => {
  axios.post(config.SERVER_URL + '/billing/affiliate-check', {
    license,
    email,
  })
}

export const newAffiliateSignUp = (license, ref, affiliateID) => {
  axios.post(config.SERVER_URL + '/affiliate/new-sign-up', {
    license,
    ref,
    affiliateID,
  })
}

export const registerAffiliateUser = async (email, name) => {
  try {
    const affiliateResponse = await axios.post(
      config.SERVER_URL + '/affiliate/register-user',
      {
        email,
        name,
      }
    )
    return affiliateResponse.data
  } catch (e) {
    console.error(e)
    return null
  }
}

export const updateAccountCreatedData = (data) => {
  return axios.post(config.SERVER_URL + '/gohighlevel/account-updated', data)
}

export function getSenderLocation() {
  return new Promise((resolve) => {
    let hasLocation = false
    setTimeout(() => {
      if (!hasLocation) resolve('')
    }, 4000)
    fetch('https://pro.ip-api.com/json/?key=kgNLgHSjzWRCLx5')
      .then(async (result) => {
        const json = await result.json()
        hasLocation = true
        resolve(json)
      })
      .catch(() => resolve(''))
  })
}

export function getCannyToken(user) {
  return axios.post(config.SERVER_URL + '/canny', user)
}
