import Papa from 'papaparse'
import { firstLetterToUpper } from './firstLetterToUpper'

export function returnExtraCardHeaders(receivedCards) {
  const extraHeaders = []
  receivedCards.forEach((record) => {
    const extraData = record.extraData
    if (extraData && typeof extraData === 'object') {
      Object.keys(extraData).forEach((key) => {
        if (!extraHeaders.includes(key)) {
          extraHeaders.push(key)
        }
      })
    }
  })
  return extraHeaders
}

export function createCsvFile(card, receivedCards, returnString) {
  const sections = card.sections || []

  const rows = []
  const headers = []

  sections.forEach((section) => {
    if (section.type === 'contact') {
      const fields = section.value || []
      fields.forEach((field) => {
        const label = field.question || field.placeholder
        if (label) {
          headers.push(label)
        }
      })
    }
  })

  if (!headers.includes('Email')) {
    headers.push('Email')
  }

  if (!headers.includes('Name')) {
    headers.push('Name')
  }

  headers.push(...['Completed at', 'Device', 'Platform', 'Location'])

  if (card.allowIP) {
    headers.push('IP address')
  }

  receivedCards.forEach((record) => {
    const device = record.completedOn
      ? firstLetterToUpper(record.completedOn)
      : ''
    let location = ''
    const platform = record.platform || '?'
    const extraDataFind = record.extraData || {}
    const customer = record.customer || {}
    const completedLocation = record.completedLocation || {}

    if (extraDataFind['Email']) {
      customer.email = extraDataFind['Email']
    }

    if (extraDataFind['Name']) {
      customer.name = extraDataFind['Name']
    }

    const email = customer.email || ''
    const name = customer.name || ''
    const row = new Array(headers.length).fill('')

    if (completedLocation) {
      if (completedLocation.city || completedLocation.country) {
        location = `${completedLocation.city || ''}, ${
          completedLocation.country || ''
        }`
      } else {
        location = ''
      }
    }

    if (card.includeCardLabel) {
      row[headers.indexOf('Card')] = card.label
    }
    row[headers.indexOf('Email')] = email
    row[headers.indexOf('Name')] = name
    row[headers.indexOf('Completed at')] =
      record.completedAt ||
      (record.completedTimestamp
        ? new Date(record.completedTimestamp).toString()
        : '')

    row[headers.indexOf('Device')] = device
    row[headers.indexOf('Platform')] = platform
    row[headers.indexOf('Location')] = location
    row[headers.indexOf('IP address')] = completedLocation.query || ''

    const recordSections = record.sections || []

    recordSections.forEach((section) => {
      if (section.type === 'contact') {
        const fields = section.value || []
        fields.forEach((field) => {
          const label = field.question || field.placeholder
          const findIndex = headers.indexOf(label)
          const value = field.value
          if (findIndex >= 0) {
            if (!row[findIndex]) {
              row[findIndex] = value ? value.toString() : ''
            }
          }
        })
      }
    })

    // extra data handle
    const extraData = record.extraData
    if (extraData && typeof extraData === 'object') {
      Object.keys(extraData).forEach((key) => {
        if (!headers.includes(key)) {
          headers.push(key)
        }
        const findIndex = headers.indexOf(key)
        if (findIndex >= 0) {
          row[findIndex] = extraData[key] ? extraData[key].toString() : ''
        }
      })
    }

    rows.unshift(row)
  })

  rows.unshift(headers)
  rows.forEach((row) => {
    if (row.length < headers.length) {
      const missingColumns = new Array(headers.length - row.length).fill('')
      row.push(...missingColumns)
    }
  })

  const transformedRows = rows.map((row) =>
    row.map((cell) => {
      if (!cell) return ''
      return cell.replace(/#/g, '')
    })
  )

  const csvString = Papa.unparse(transformedRows)
  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })

  if (returnString) return csvString

  const link = document.createElement('a')
  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute(
    'download',
    `${card.label} - ${new Date().toLocaleDateString()}.csv`
  )
  document.body.appendChild(link)
  link.click()
  link.remove()
  return true
}
