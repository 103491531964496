import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import { UploadIcon } from '../assets/icons'
import { uploadCardAvatar } from '../api/storage-api'
import { useActions } from '../store'
import { Caption } from './Typography'
import Spinner from './Spinner'
import TemplateLock from './TemplateLock'

const AvatarUpload = ({
  card,
  updateCard,
  showLock,
  isLocked = () => {},
  onLock,
  showLockMessage,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { url } = card.avatar || {}
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const [error, setError] = useState()
  const [uploading, setUploading] = useState(false)
  const label = 'page-avatar'

  const saveCroppedImage = async (uploadedLogo, fileExtension) => {
    try {
      const logoUrl = await uploadCardAvatar(uploadedLogo, fileExtension, card)
      card.avatar = { ...card.avatar, url: logoUrl }
      updateCard(card)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (uploading) return
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('File must be an image.')
      } else {
        setError('File exceeded max 5mb size.')
      }
      return
    }

    setError(false)
    setUploading(true)

    const uploadedLogo = acceptedFiles[0]

    if (!uploadedLogo) return

    const imageDataUrl = URL.createObjectURL(uploadedLogo)
    const parts = uploadedLogo.name.split('.')
    const fileExtension = parts[parts.length - 1] || ''

    if (fileExtension.toLowerCase() === 'gif') {
      try {
        const logoUrl = await uploadCardAvatar(
          uploadedLogo,
          fileExtension,
          card
        )
        card.avatar = { ...card.avatar, url: logoUrl }
        updateCard(card)
      } catch (e) {
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
      setUploading(false)
      return
    }

    showModal({
      name: 'CROP_IMAGE',
      image: imageDataUrl,
      fileExtension,
      aspect: 1 / 1,
      onSave: saveCroppedImage,
    })

    setUploading(false)
  }

  const removeLogo = () => {
    if (isLocked(label)) return
    const result = window.confirm(
      `You are about to delete your avatar. Are you sure you want to continue?`
    )
    if (result) {
      delete card.avatar.url
      updateCard(card)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    maxSize: 5000000,
    disabled: isLocked(label),
  })

  return (
    <Container>
      <LogoContainer
        onClick={() => {
          if (isLocked(label)) {
            showLockMessage()
          }
        }}
      >
        {url ? (
          <DropZone onClick={removeLogo} remove={!isMobile && !isLocked(label)}>
            <PreviewImage src={url} alt="logo" />
          </DropZone>
        ) : (
          <DropZone
            isDragActive={isDragActive}
            {...getRootProps()}
            className="dropZone"
          >
            <EmptyInput {...getInputProps()} />
            {uploading ? (
              <Spinner />
            ) : (
              <Drop>
                <UploadIcon size={24} color={colors.grey} />
                <Caption
                  padding="4px"
                  align="center"
                  style={{ color: colors.grey, fontSize: 12 }}
                >
                  Avatar
                </Caption>
              </Drop>
            )}
          </DropZone>
        )}
        {(showLock || (card.cardID && isLocked(label))) && (
          <TemplateLock
            onLock={onLock}
            label="page-avatar"
            isLocked={isLocked}
            style={{ bottom: -4, top: 'auto', right: 'auto', left: 8 }}
            direction="bottom"
            arrowPosition="top"
          />
        )}
      </LogoContainer>
      <Error>{error}</Error>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  align-items: center;
  width: 100%;
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const DropZone = styled.div`
  cursor: pointer;
  margin-top: 6px;
  background-color: ${(props) =>
    props.isDragActive ? `${colors.success}55` : colors.menu};
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-out;
  border: 3px solid #fff;
  box-shadow: 0 1px 3px 0 rgb(29 36 86 / 15%), 0 2px 6px 0 rgb(29 36 86 / 5%);

  :hover {
    opacity: ${(props) => (props.remove ? 1 : 0.7)};
    ${(props) =>
      props.remove
        ? `&::before {
      position: absolute;
      top: 0;
      bottom: 0;
      content: "";
      width: 120px;
      height: 120px;
      border-radius: 50%;
      z-index: 9;
      background-color: ${colors.red}88;
    }`
        : ``}
  }
`

const Drop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const EmptyInput = styled.input`
  display: none;
  position: relative;
  width: 0;
  height: 0;
`

const Error = styled.span`
  font-size: 13.5px;
  color: ${colors.error};
  margin-top: 8px;
  z-index: 2;
`

const PreviewImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 1px 3px 0 rgb(29 36 86 / 15%), 0 2px 6px 0 rgb(29 36 86 / 5%);
`

export default AvatarUpload
