export function returnCardQuestions(targetObject) {
  const sections = targetObject.sections
    ? targetObject.sections
    : [targetObject]

  const questions = []

  sections.forEach((section) => {
    if (section.type === 'contact') {
      const fields = section.value || []
      fields.forEach((field) => {
        questions.push(field)
      })
    }
  })

  return questions
}
