import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ArrowRight } from '../assets/icons'
import { colors } from '../core/theme'
import Button from './Button'
import Logo from './Logo'
import { Title } from './Typography'
import { useStore, useActions } from '../store'
import { getSenderLocation, updateAccountCreatedData } from '../api/auth-api'
import SelectPageType from './SelectPageType'
import FloatingInput from './FloatingInput'
import FloatingSelect from './FloatingSelect'

export default function WelcomeForm() {
  const { profile } = useStore()
  const { updateLicenseData } = useActions().license
  const [company, setCompany] = useState({ value: '', error: '' })
  const [employees, setEmployees] = useState({ value: '', error: '' })
  const [phone, setPhone] = useState({ value: '', error: '' })
  const [country, setCountry] = useState({ value: '', error: '' })
  const [countryCode, setCountryCode] = useState({ value: '', error: '' })
  const [city, setCity] = useState({ value: '', error: '' })
  const [loading, setLoading] = useState()
  const [screen, setScreen] = useState('first_page') // welcome, first_page
  const { name, email } = profile || {}

  useEffect(async () => {
    const response = await getSenderLocation()
    if (response) {
      setCountry({ value: response.country, error: '' })
      setCountryCode({ value: response.countryCode, error: '' })
      setCity({ value: response.city, error: '' })
    }
  }, [])

  const moveToDashboard = async (pageType) => {
    setLoading(true)
    try {
      window.konectPageType = pageType
      const personData = {
        email,
        company: company.value,
        employees: employees.value,
        phone: phone.value,
        country: country.value,
        countryCode: countryCode.value,
        city: city.value,
        pageType,
      }
      updateAccountCreatedData(personData)
      await updateLicenseData({
        welcomeForm: true,
        company: company.value || 'My Company',
      })
    } catch (e) {
      console.error(e)
      window.alert('Something went wrong. Please contact our support.')
    }
    setLoading(false)
  }

  const moveToPagePicker = async () => {
    setScreen('first_page')
  }

  if (screen === 'first_page') {
    return (
      <Container>
        <TopBar>
          <Logo />
        </TopBar>
        <InfoHeader>
          What type of page would you like to create first?
        </InfoHeader>
        <SelectPageType moveToDashboard={moveToDashboard} loading={loading} />
      </Container>
    )
  }

  return (
    <Container>
      <TopBar>
        <Logo />
      </TopBar>
      <InputContainer>
        <Header
          padding="10px 0"
          align="center"
          lineHeight="medium"
          weight="medium"
        >
          Welcome {name} 👋
        </Header>

        <Divider>
          <DividerText>Let us know about you</DividerText>
        </Divider>

        <FloatingInput
          shrink
          label="Company name"
          name="company"
          type="company"
          value={company.value}
          error={company.error}
          onChange={(e) => setCompany({ value: e.target.value, error: '' })}
          style={{ marginTop: 12 }}
        />
        <FloatingSelect
          label="Team size"
          empty="- Select size -"
          name="employees"
          type="employees"
          value={employees.value}
          options={[
            '1',
            '2 to 5',
            '6 to 10',
            '11 to 25',
            '26 to 50',
            '51 to 200',
            '201 to 500',
            '501 to 1000',
            '1000+',
          ].map((option) => {
            return (
              <option key={option} value={option}>
                {option}
              </option>
            )
          })}
          onChange={(e) => {
            setEmployees({ value: e.target.value, error: '' })
          }}
        />
        <FloatingInput
          shrink
          label="Phone number"
          name="tel"
          type="tel"
          value={phone.value}
          error={phone.error}
          onChange={(e) => setPhone({ value: e.target.value, error: '' })}
          style={{ marginTop: 16 }}
        />
        <Button
          onClick={moveToPagePicker}
          loading={loading}
          icon={<ArrowRight size={20} color={colors.white} />}
          style={{
            marginTop: 20,
            height: 50,
          }}
        >
          Go to Dashboard
        </Button>
        <Info>You can skip this form by clicking button above.</Info>
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='20' width='20' viewBox='0 0 24 24' %3E%3Ccircle fill='%23e0e0e0' cx='10' cy='10' r='1'/%3E%3C/svg%3E");
  background-repeat: repeat;
  background-color: ${colors.mainHint};
  min-height: 100vh;
`

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin-top: 32px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 16px;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  width: 90%;
  max-width: 420px;
`

const Header = styled(Title)`
  @media (max-width: 767px) {
    padding: 20px 0 8px 0;
  }
`

const Info = styled.div`
  text-align: center;
  width: 100%;
  color: ${colors.secondary};
  font-size: 12px;
  margin: 16px 0;
`

const Divider = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.primary};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 30px 0;
`

const DividerText = styled.span`
  position: absolute;
  font-size: 13px;
  background-color: ${colors.mainHint};
  color: ${colors.primary};
  padding: 4px 12px;
  font-weight: 500;
`

const InfoHeader = styled.h2`
  font-size: 19px;
  line-height: 1.5;
  margin: 16px 0;
  text-align: center;
  padding: 16px;
  padding-bottom: 0;
  box-sizing: border-box;
`
