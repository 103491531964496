import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'

const Input = forwardRef(
  (
    {
      multiline,
      label,
      description,
      value,
      onChange,
      placeholder,
      required,
      error,
      name,
      type,
      readOnly,
      style,
      inputStyle,
      labelStyle,
      className,
      inputClassName,
      rows = 4,
      prefix,
      maxLength,
      ...restProps
    },
    ref
  ) => (
    <Container style={style} className={className}>
      {label && (
        <InputLabel style={labelStyle}>
          {label}{' '}
          {required && <span style={{ color: colors.required }}>*</span>}
        </InputLabel>
      )}
      <InputContainer>
        {prefix && <PrefixContainer>{prefix}</PrefixContainer>}
        {multiline ? (
          <TextArea
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            name={name}
            readOnly={readOnly}
            maxLength={maxLength || 25000}
            style={inputStyle}
            rows={rows}
            ref={ref}
            error={error}
            hasPrefix={prefix}
            className={inputClassName}
            {...restProps}
          />
        ) : (
          <InputField
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            name={name}
            readOnly={readOnly}
            maxLength={maxLength || 2500}
            style={inputStyle}
            ref={ref}
            error={error}
            hasPrefix={prefix}
            className={inputClassName}
            {...restProps}
          />
        )}
      </InputContainer>
      {error && <InputError>{error}</InputError>}
      {description && !error && (
        <InputDescription>{description}</InputDescription>
      )}
    </Container>
  )
)

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
`

const InputContainer = styled.div`
  display: flex;
  width: 100%;
`

const InputLabel = styled.label`
  font-size: 13.5px;
  font-weight: 500;
  color: ${colors.primary};
  line-height: 1.5;
  text-align: left;
  margin-bottom: 8px;
  padding: 0 4px;
`

const InputDescription = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.secondary};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const InputError = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.error};
  padding: 0 6px;
  line-height: 1.5;
  text-align: left;
  margin-top: 8px;
`

const PrefixContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px 12px;
  height: 48px;
  line-height: 1.5;
  color: ${colors.darkgrey};
  background-color: ${colors.prefix};
  border: 1px solid ${colors.border};
  border-right: none;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
`

const InputField = styled.input`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 12px;
  height: 48px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  ${(props) =>
    props.hasPrefix
      ? `
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  `
      : ''}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 0.6; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:focus {
    border-color: ${(props) =>
      props.readOnly ? colors.border : props.color || colors.main};
    outline: 0;
    text-decoration: none;
    box-shadow: 0 2px 6px rgb(50 50 9 / 0%), 2px 3px 6px rgb(0 31 255 / 10%);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid ${colors.success};
    -webkit-text-fill-color: ${colors.primary};
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    text-decoration: none;
  }

  &:disabled {
    color: ${colors.primary};
    opacity: 0.4;
    cursor: not-allowed;
  }
`

const TextArea = styled.textarea`
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: text;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 12px;
  min-height: 48px;
  max-height: 400px;
  width: 100%;
  resize: vertical;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  ${(props) =>
    props.hasPrefix
      ? `
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  `
      : ''}

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.primary};
    opacity: 0.6; /* Firefox */
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${colors.primary};
    opacity: 0.6;
  }

  &:focus {
    border-color: ${(props) =>
      props.readOnly ? colors.border : props.color || colors.main};
    outline: 0;
    text-decoration: none;
    box-shadow: 0 2px 6px rgb(50 50 9 / 0%), 2px 3px 6px rgb(0 31 255 / 10%);
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    border: 1px solid ${colors.success};
    -webkit-text-fill-color: ${colors.primary};
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
    text-decoration: none;
  }

  &:disabled {
    color: ${colors.primary};
    opacity: 0.4;
    cursor: not-allowed;
  }
`

export default Input
