import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { CheckIcon } from '../assets/icons'

const Checkbox = ({
  onChange = () => {},
  onClick,
  className,
  style,
  boxStyle,
  label,
  value,
  radio,
  disabled,
  color,
}) => {
  return (
    <Container
      disabled={disabled}
      className={className}
      style={style}
      onClick={(e) => {
        if (disabled) return
        if (onClick) onClick(e)
        onChange(!value)
      }}
    >
      <Box color={color} radio={radio} checked={value} style={boxStyle}>
        {value && <CheckIcon size={12} color="white" />}
      </Box>
      {label}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6px 4px;
  align-items: center;
  justify-content: flex-start;
  font-size: 13.5px;
  color: ${colors.primary};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.5;
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-color: ${(props) =>
    props.checked ? props.color || colors.main : colors.white};
  border-radius: ${(props) => (props.radio ? '50%' : '2px')};
  border: ${(props) => (props.checked ? 'none' : `1px solid ${colors.grey}`)};
  margin-right: 10px;
`

export default Checkbox
