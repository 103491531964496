import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Avatar from './Avatar'
import { colors } from '../core/theme'
import { MenuIcon, DeleteIcon, SendIcon } from '../assets/icons'
import { Dropdown, DropdownList } from './Dropdown'
import { sendInvitationToUser } from '../api/database-api'
import { useActions } from '../store'
import { Column, Row } from './Layout'
import { Label, Caption } from './Typography'

const InvitationRow = ({ invitation }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { showToast } = useActions().toasts
  const { deleteInvitation } = useActions().invitation
  const { email, permission } = invitation

  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <UserContainer>
      <Profile>
        <Avatar size={36} profile={invitation} />
        {!isMobile && (
          <Column padding="0 12px">
            <Caption lineHeight="medium" className="clip-text">
              {email}
            </Caption>
          </Column>
        )}
      </Profile>
      <Row flex="1">
        <Caption
          lineHeight="medium"
          className="clip-text"
          variant="warning"
          weight="medium"
        >
          Pending...
        </Caption>
      </Row>
      <Row flex="1">
        <Label lineHeight="medium" className="clip-text">
          {permission}
        </Label>
      </Row>
      <ActionsWrapper>
        <Dropdown
          isVisible={isMenuVisible}
          placement="bottom-end"
          onClose={hideMenu}
          triggerRenderer={({ ref }) => (
            <MenuButton onClick={showMenu} ref={ref}>
              <MenuIcon size={18} color={colors.primary} />
            </MenuButton>
          )}
        >
          <DropdownList
            onClose={hideMenu}
            items={[
              {
                content: 'Resend invitation',
                onItemSelect: async () => {
                  try {
                    await sendInvitationToUser(invitation)
                    showToast({
                      content: 'Invitation sent successfully.',
                      variant: 'success',
                    })
                  } catch (e) {
                    showToast({
                      content:
                        'Something went wrong. Please contact our support.',
                    })
                  }
                },
                icon: <SendIcon color={colors.primary} size={18} />,
              },
              {
                content: 'Delete invitation',
                color: colors.error,
                onItemSelect: () => {
                  const result = window.confirm(
                    `You are about to delete this invitation. Are you sure you want to continue?`
                  )
                  if (result) {
                    deleteInvitation(invitation)
                    hideMenu()
                  }
                },
                icon: <DeleteIcon color={colors.error} size={18} />,
              },
            ]}
          />
        </Dropdown>
      </ActionsWrapper>
    </UserContainer>
  )
}

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  align-items: center;
  padding: 0 16px;
  min-height: 64px;
  max-height: 64px;
`

const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  max-width: 320px;
  padding: 4px;
  box-sizing: border-box;
`

const Profile = styled.div`
  display: flex;
  flex-direction: row;
  flex: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  align-items: center;
  @media (max-width: 767px) {
    flex: 1;
  }
`

const MenuButton = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  transition: opacity 0.2s ease-out;
  :active {
    opacity: 0.5;
  }
`

export default InvitationRow
