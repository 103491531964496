import React, { useEffect, useState } from 'react'
import Cropper from 'react-easy-crop'
import styled from 'styled-components'
import { DoneIcon } from '../assets/icons'
import Button from '../components/Button'
import { colors } from '../core/theme'
import { useActions } from '../store'
import BaseModal from './BaseModal'

export default function CropImageModal({
  onClose,
  image,
  onSave,
  fileExtension,
  aspect,
}) {
  const { showToast } = useActions().toasts
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [uploading, setUploading] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setRotation(0)
    })
  }, [])

  const onCropComplete = (croppedArea, newCroppedAreaPixels) => {
    setCroppedAreaPixels(newCroppedAreaPixels)
  }

  const saveCroppedImage = async () => {
    setUploading(true)
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        0,
        {
          horizontal: false,
          vertical: false,
        },
        fileExtension
      )
      await onSave(croppedImage, fileExtension)
      onClose()
    } catch (e) {
      console.error(e)
      showToast({ content: e.message })
    }
    setUploading(false)
  }

  return (
    <BaseModal
      title=""
      maxWidth="600px"
      bodyHeight="400px"
      onClose={onClose}
      fullscreen
      actions={
        <>
          <Button
            loading={uploading}
            onClick={saveCroppedImage}
            style={{ margin: '0 4px' }}
            icon={<DoneIcon size={18} color={colors.white} />}
          >
            Save
          </Button>
        </>
      }
    >
      <Container>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          rotation={rotation}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180
}

function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)
  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
  fileExtension
) {
  const image = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  )

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0)

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file)
    }, `image/${fileExtension}`)
  })
}
