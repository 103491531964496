import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Attribute from './Attribute'
import { renderDataFromSections } from '../helpers/renderDataFromSections'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'
import { useActions } from '../store'
import { Subheading } from './Typography'
import { sortCountsInArray } from '../helpers/sortCountsInArray'

const CardDetails = ({ card }) => {
  const {
    id,
    cardID,
    completed,
    completedLocation = {},
    completedOn,
    completedTimestamp,
    sourceURL,
    sections = [],
    browser,
    platform,
    seen,
    extraData,
    iframe,
    interactions = {},
  } = card
  const { updateCardSeenStatus } = useActions().inbox
  const [updateStatus, startUpdating] = useState('not updated')

  useEffect(() => {
    if (updateStatus === 'not updated' && !seen) {
      ;(async () => {
        try {
          startUpdating('updating')
          await updateCardSeenStatus({ cardID, id })
          startUpdating('updated')
        } catch (error) {
          console.error(error)
        }
      })()
    }
  }, [completed, cardID, id, seen, updateCardSeenStatus, updateStatus])

  return (
    <>
      <div style={{ height: 20 }} />
      <Header>Form</Header>
      {renderDataFromSections(sections)}
      {extraData && Object.keys(extraData).length > 0 && (
        <>
          <Header>Extra Data</Header>
          {Object.keys(extraData).map((question, i) => (
            <Attribute key={i} label={question} value={extraData[question]} />
          ))}
        </>
      )}
      {Object.keys(interactions).length > 0 && (
        <>
          <Header>Interactions</Header>
          {sortCountsInArray(Object.values(interactions)).map((item, i) => (
            <Attribute key={i} label={item.title} value={item.count} />
          ))}
        </>
      )}
      <Header>Details</Header>
      {completedLocation.city && (
        <Attribute
          label="Sent from"
          href={`https://maps.google.com/?q=${completedLocation.lat},${completedLocation.lon}`}
          value={`${completedLocation.city}, ${completedLocation.country}`}
        />
      )}
      {completedLocation.query && card.allowIP && (
        <Attribute label="IP address" value={completedLocation.query} />
      )}
      {completedOn && (
        <Attribute label="Device" value={firstLetterToUpper(completedOn)} />
      )}
      {platform && <Attribute label="Platform" value={platform} />}
      {browser && <Attribute label="Browser" value={browser} />}
      {sourceURL && iframe && (
        <Attribute
          label={card.completed === false ? 'Abandoned at' : 'Completed at'}
          valueHref={sourceURL}
        />
      )}
      {completedTimestamp && (
        <Attribute
          label={card.completed === false ? 'Abandoned on' : 'Completed on'}
          value={new Date(completedTimestamp).toLocaleString()}
        />
      )}
    </>
  )
}

const Header = styled(Subheading)`
  font-size: 16px;
  line-height: 1.75;
  padding: 8px 0;
`

export default CardDetails
