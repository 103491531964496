import styled from 'styled-components'
import React, { useState } from 'react'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { useActions, useStore } from '../store'
import { PERMISSIONS } from '../core/constants'
import { Label } from '../components/Typography'
import { Row } from '../components/Layout'
import { InfoTooltip } from '../components/InfoTooltip'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import FloatingInput from '../components/FloatingInput'
import FloatingSelect from '../components/FloatingSelect'

const ChangePermissionModal = ({ employee, onClose }) => {
  const { showToast } = useActions().toasts
  const { changeEmployeePermission } = useActions().license
  const { cards } = useStore().cards
  const license = useStore().license
  const folders = license.folders || []
  const {
    email,
    permission = 'Full access',
    allowedCards,
    allowedFolders,
  } = employee
  const [isChanging, setChangingStatus] = useState(false)
  const [selectedPermission, selectPermission] = useState(
    PERMISSIONS.filter((perm) => perm.value === permission)[0]
  )
  const [selectedCards, selectCards] = useState(() => {
    if (allowedCards) {
      const foundCards = cards.filter((c) => allowedCards.includes(c.cardID))
      if (foundCards) {
        return foundCards
      }
    }
    return [cards[0]]
  })
  const [selectedFolders, selectFolders] = useState(() => {
    if (allowedFolders) {
      const foundFolders = folders.filter((f) =>
        allowedFolders.includes(f.createdTimestamp)
      )
      if (foundFolders) {
        return foundFolders
      }
    }
    return [cards[0]]
  })

  const onPermissionSelected = (e) => {
    const perm = PERMISSIONS.filter((p) => p.value === e.target.value)[0]
    selectPermission(perm)
  }

  const changePermission = async () => {
    if (isChanging) return
    setChangingStatus(true)

    let newAllowedCards = ''
    if (selectedPermission.value === 'Selected pages') {
      if (selectedCards.length === 0) {
        showToast({
          content: 'Select at least one card.',
        })
        setChangingStatus(false)
        return
      }
      newAllowedCards = [...selectedCards.map((c) => c.cardID)]
    }

    let newAllowedFolders
    if (selectedPermission.value === 'Selected folders') {
      if (selectedFolders.length === 0) {
        showToast({
          content: 'Select at least one folder.',
        })
        setChangingStatus(false)
        return
      }
      if (selectedFolders.filter((f) => f.createdTimestamp).length === 0) {
        showToast({
          content: 'Select at least one folder.',
        })
        setChangingStatus(false)
        return
      }
      newAllowedFolders = selectedFolders
        .filter((f) => f.createdTimestamp)
        .map((f) => f.createdTimestamp)
    }

    await changeEmployeePermission({
      employee,
      permission: selectedPermission.value,
      allowedCards: newAllowedCards,
      allowedFolders: newAllowedFolders,
    })
    setChangingStatus(false)
    onClose()
  }

  return (
    <BaseModal
      title="Employee Permission"
      maxWidth="660px"
      onClose={onClose}
      actions={
        <Button onClick={changePermission} loading={isChanging}>
          Change Permission
        </Button>
      }
    >
      <BodyWrapper>
        <FloatingInput
          shrink
          value={email}
          label="Employee"
          readOnly
          description="The person whose permission will be changed."
        />
        <FloatingSelect
          label="Permission"
          description={selectedPermission.description}
          onChange={onPermissionSelected}
          value={selectedPermission.value}
          options={PERMISSIONS.map((perm) => {
            return (
              <option key={perm.value} value={perm.value}>
                {perm.value}
              </option>
            )
          })}
        />
        {selectedPermission.value === 'Selected pages' && (
          <>
            <FloatingLabel>Select pages</FloatingLabel>
            {selectedCards.map((card, i) => {
              return (
                <Row
                  key={i}
                  align="center"
                  style={{ width: '100%', marginBottom: 16 }}
                >
                  <FloatingSelect
                    onChange={(e) => {
                      const foundCard = cards.find(
                        (c) => c.label === e.target.value
                      )
                      if (foundCard) {
                        selectedCards[i] = foundCard
                        selectCards([...selectedCards])
                      }
                    }}
                    value={card.label}
                    style={{ marginBottom: 0 }}
                    options={cards.map((c) => {
                      return (
                        <option key={c.label} value={c.label}>
                          {c.label}
                        </option>
                      )
                    })}
                  />
                  <InfoTooltip
                    icon={<DeleteIcon size={20} color={colors.grey} />}
                    zIndex={999999 - i}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      selectCards(
                        selectedCards.filter((_, index) => index !== i)
                      )
                    }}
                    description="Delete page"
                    style={{ margin: '0 0 0 10px' }}
                    direction="bottom"
                    arrowPosition="top"
                  />
                </Row>
              )
            })}
            <div
              className="clickable-element"
              style={{ margin: '0px 6px 8px 6px', alignSelf: 'flex-start' }}
              onClick={() => {
                if (selectedCards.length >= 50) {
                  window.alert('You can add max 50 page.')
                  return
                }
                selectCards([...selectedCards, cards[0]])
              }}
            >
              <Label variant="primary" weight="medium">
                + Add page
              </Label>
            </div>
          </>
        )}
        {selectedPermission.value === 'Selected folders' && (
          <>
            <FloatingLabel>Select folders</FloatingLabel>
            {selectedFolders.map((folder, i) => {
              return (
                <Row
                  key={i}
                  align="center"
                  style={{ width: '100%', marginBottom: 16 }}
                >
                  <FloatingSelect
                    onChange={(e) => {
                      const foundFolder = folders.find(
                        (f) => f.name === e.target.value
                      )
                      if (foundFolder) {
                        selectedFolders[i] = foundFolder
                        selectFolders([...selectedFolders])
                      }
                    }}
                    value={folder.name || ''}
                    empty="- Select folder -"
                    style={{ marginBottom: 0 }}
                    options={folders.map((f) => {
                      return (
                        <option key={f.name} value={f.name}>
                          {f.name}
                        </option>
                      )
                    })}
                  />
                  <InfoTooltip
                    icon={<DeleteIcon size={20} color={colors.grey} />}
                    zIndex={999999 - i}
                    onClick={(e) => {
                      e.stopPropagation()
                      e.preventDefault()
                      selectFolders(
                        selectedFolders.filter((_, index) => index !== i)
                      )
                    }}
                    description="Delete folder"
                    style={{ margin: '0 0 0 10px' }}
                    direction="bottom"
                    arrowPosition="top"
                  />
                </Row>
              )
            })}
            <div
              className="clickable-element"
              style={{ margin: '0px 6px 8px 6px', alignSelf: 'flex-start' }}
              onClick={() => {
                if (selectedFolders.length >= 50) {
                  window.alert('You can add max 50 folders.')
                  return
                }
                selectFolders([...selectedFolders, {}])
              }}
            >
              <Label variant="primary" weight="medium">
                + Add folder
              </Label>
            </div>
          </>
        )}
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 36px 24px 24px 24px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 767px) {
    padding: 32px 16px 16px 16px;
  }
`

const FloatingLabel = styled.div`
  padding: 2px 8px 10px 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  z-index: 1;
  color: #444;
`

export default ChangePermissionModal
