import React from 'react'
import styled from 'styled-components'
import BaseModal from './BaseModal'
import { useStore, useActions } from '../store'
import { ACCESSORIES_STYLES } from '../core/constants'
import FloatingSelect from '../components/FloatingSelect'

export default function EditAccessoryModal({ onClose, accessory }) {
  const { license } = useStore()
  const { cards } = useStore().cards
  const { updateAccessory } = useActions().accessories
  const { showToast } = useActions().toasts
  const { cardID, color = 'dark', type = 'card', uid } = accessory
  const foundCard = cards.find((c) => c.cardID === cardID) || {}
  const { image } = ACCESSORIES_STYLES[type][color]
  const licenseUsers = Object.values(license.users)
  const foundUser = licenseUsers.find((u) => u.uid === uid) || {
    name: '',
  }

  const onCardSelected = async (e) => {
    const newFoundCard = cards.find((c) => c.label === e.target.value)
    if (newFoundCard) {
      try {
        await updateAccessory({
          ...accessory,
          cardID: newFoundCard.cardID,
          license: newFoundCard.license,
        })
      } catch (err) {
        console.error(err)
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
    } else {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  const onTeamMemberSelected = async (e) => {
    const newFoundUser = licenseUsers.find((u) => u.uid === e.target.value)
    if (newFoundUser) {
      try {
        await updateAccessory({
          ...accessory,
          uid: newFoundUser.uid,
        })
      } catch (err) {
        console.error(err)
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
    } else {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  return (
    <BaseModal title="" maxWidth="500px" onClose={onClose}>
      <BodyWrapper>
        <AccessoryImage src={image} alt="" />
        <FloatingSelect
          label="Page to open"
          onChange={onCardSelected}
          value={foundCard.label || ''}
          empty="- Select page -"
          options={cards.map((c) => {
            return (
              <option key={c.label} value={c.label}>
                {c.label}
              </option>
            )
          })}
        />
        <FloatingSelect
          label="Team member (optional)"
          onChange={onTeamMemberSelected}
          value={foundUser.uid || ''}
          empty="- Select member -"
          options={licenseUsers.map((u) => {
            return (
              <option key={u.name || u.email} value={u.uid}>
                {u.name || u.email}
              </option>
            )
          })}
        />
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 24px;
  box-sizing: border-box;
  position: relative;
  align-items: center;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const AccessoryImage = styled.img`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  background-color: transparent;
  object-fit: contain;
  margin-bottom: 24px;
`
