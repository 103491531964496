import React, { useEffect } from 'react'
import { getAccessoryData } from '../api/database-api'
import WelcomeForm from '../components/WelcomeForm'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'
import { queryStringToJSON } from '../helpers/queryStringToJSON'
import Accounts from '../pages/accounts'
import { useActions, useStore } from '../store'
import { config } from '../core/config'

const RouterProvider = ({ children }) => {
  const { showModal } = useActions().modals
  const { profile, license } = useStore()
  const { showToast } = useActions().toasts
  const { activateAccessory } = useActions().accessories
  const parameters = queryStringToJSON()

  useEffect(async () => {
    if (license) {
      if (parameters.accessory_id) {
        if (window.recentlyActivatedAccessory === parameters.accessory_id) {
          return
        }

        try {
          window.recentlyActivatedAccessory = parameters.accessory_id
          const response = await getAccessoryData(parameters.accessory_id)
          const accessoryData = response.data()
          if (!accessoryData) {
            throw new Error(
              "Accessory with given ID doesn't exist. Please contact our support."
            )
          }
          if (accessoryData.status === 'active') {
            throw new Error(
              "Accessory with given ID was already activated. Contact our support if it wasn't you."
            )
          }
          await activateAccessory(accessoryData)
          setTimeout(() => {
            if (accessoryData.type === 'card') {
              showModal({
                name: 'HOW_TO_VIDEO',
                title: 'Watch this quick video before using your Konect Card',
                video: config.HOW_TO_SHARE_CARD,
                onNext: () => {
                  if (window[`konectAccessoryShowModal-${accessoryData.id}`]) {
                    window[`konectAccessoryShowModal-${accessoryData.id}`](true)
                  }
                },
              })
            }
            if (accessoryData.type === 'tag') {
              showModal({
                name: 'HOW_TO_VIDEO',
                title: 'Watch this quick video before using your Konect Tag',
                video: config.HOW_TO_SHARE_TAG,
                onNext: () => {
                  if (window[`konectAccessoryShowModal-${accessoryData.id}`]) {
                    window[`konectAccessoryShowModal-${accessoryData.id}`](true)
                  }
                },
              })
            }
          }, 100)
          showToast({
            content: `${
              firstLetterToUpper(accessoryData.type) || 'Accessory'
            } added successfully.`,
            variant: 'success',
          })
        } catch (error) {
          console.error(error)
          showToast({
            content: error.message,
            duration: 10000,
          })
        }
      }
    }
  }, [license])

  if (!profile) {
    return <Accounts accessoryID={parameters.accessory_id} />
  }

  if (profile) {
    const isOwner = license.createdBy === profile.email
    if (isOwner && !license.welcomeForm) {
      return <WelcomeForm />
    }
  }

  return children
}

export default RouterProvider
