import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { DeleteIcon } from '../assets/icons'
import { Caption, Label } from './Typography'
import { returnCardQuestions } from '../helpers/returnCardQuestions'
import FloatingSelect from './FloatingSelect'

const PipedriveIntegrationDetails = ({ card, targetObject, updateCard }) => {
  const { pipedrive } = targetObject
  if (!pipedrive) return null

  const { type, fields = [] } = pipedrive
  const formQuestions = returnCardQuestions(targetObject)

  const addEmptyMap = () => {
    if (!pipedrive.questionsMap) {
      pipedrive.questionsMap = [{}]
    } else {
      pipedrive.questionsMap = [...pipedrive.questionsMap, {}]
    }
    updateCard(card)
  }

  return (
    <Container>
      <FloatingSelect
        label="Select type to create in Pipedrive"
        empty="- Select type -"
        value={type}
        options={['Person', 'Lead', 'Deal'].map((option) => {
          return (
            <option key={option} value={option}>
              {option}
            </option>
          )
        })}
        style={{ maxWidth: 240, marginBottom: 12 }}
        onChange={(e) => {
          targetObject.pipedrive.type = e.target.value
          updateCard(card)
        }}
      />
      <Label padding="4px 0 8px 4px" weight="medium">
        Map fields to Pipedrive's properties:
      </Label>
      <Caption padding="0 4px 16px 4px">
        Mapping email and name is required.
      </Caption>
      {pipedrive.questionsMap &&
        pipedrive.questionsMap.map((mappedQuestion, i) => {
          const { question = '', property = {} } = mappedQuestion
          return (
            <MapContainer key={question}>
              <FloatingSelect
                empty="- Select field -"
                value={question}
                onChange={(e) => {
                  pipedrive.questionsMap[i] = {
                    ...pipedrive.questionsMap[i],
                    question: e.target.value,
                  }
                  updateCard(card)
                }}
                options={formQuestions
                  .map((l) => l.question || l.placeholder)
                  .map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    )
                  })}
                style={{ marginBottom: 0 }}
              />
              <Divider>{'->'}</Divider>
              <FloatingSelect
                empty="- Select property -"
                value={property.label || ''}
                onChange={(e) => {
                  const selectedProperty = fields.find(
                    (p) => p.label === e.target.value
                  )
                  pipedrive.questionsMap[i] = {
                    ...pipedrive.questionsMap[i],
                    property: selectedProperty,
                  }
                  updateCard(card)
                }}
                options={fields
                  .map((l) => l.label)
                  .map((option) => {
                    return (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    )
                  })}
                style={{ marginBottom: 0 }}
              />
              <DeleteContainer
                onClick={() => {
                  pipedrive.questionsMap.splice(i, 1)
                  updateCard(card)
                }}
              >
                <DeleteIcon size={18} />
              </DeleteContainer>
            </MapContainer>
          )
        })}
      <Label
        weight="medium"
        variant="primary"
        onClick={addEmptyMap}
        style={{
          marginTop: 4,
          marginBottom: 8,
          alignSelf: 'flex-start',
          cursor: 'pointer',
        }}
      >
        + Add another
      </Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const Divider = styled.div`
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0 6px;
  white-space: nowrap;
`

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`

const DeleteContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: fill 200ms ease-out;
  :hover {
    fill: ${colors.red};
  }
`

export default PipedriveIntegrationDetails
