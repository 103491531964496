import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions } from '../store'

const CancelSubscriptionModal = ({ onClose }) => {
  const { showToast } = useActions().toasts
  const { license } = useActions()
  const [isCanceling, setCancelStatus] = useState(false)

  const cancelSubscription = async () => {
    if (isCanceling) return
    setCancelStatus(true)
    try {
      await license.cancelSubscription()
    } catch (error) {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
    setCancelStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      header="Cancel subscription?"
      description="Your license will be moved to FREE plan. Pages will not work correctly. Are you sure you want to continue?"
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={cancelSubscription}
            loading={isCanceling}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, cancel
          </Button>
        </>
      }
    />
  )
}

export default CancelSubscriptionModal
