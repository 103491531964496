import React from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../core/theme'

const Button = ({
  onClick,
  loading,
  children,
  icon,
  leftIcon,
  small,
  outline,
  borderColor,
  color,
  style,
  className,
  progress,
  disabled,
  onlyIcon,
  id,
}) => (
  <StyledButton
    onClick={!loading && onClick}
    color={color}
    small={small}
    loading={loading ? 'true' : undefined}
    outline={outline}
    borderColor={borderColor}
    style={style}
    className={className}
    disabled={disabled}
    id={id}
  >
    {loading ? (
      <>
        <Spinner color={outline ? borderColor : colors.white} />
        {progress && progress}
      </>
    ) : (
      <>
        {leftIcon && <LeftIcon onlyIcon={onlyIcon}>{leftIcon}</LeftIcon>}
        {children}
        {icon && <Icon onlyIcon={onlyIcon}>{icon}</Icon>}
      </>
    )}
  </StyledButton>
)

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.onlyIcon ? '0' : '8px')};
`

const LeftIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => (props.onlyIcon ? '0' : '8px')};
`

const Spinner = styled.div`
  border: 2px solid transparent;
  border-top: 2px solid ${(props) => props.color || colors.main};
  border-right: 2px solid ${(props) => props.color || colors.main};
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 0.75s linear infinite;
  box-sizing: border-box;
  margin: 0 4px;
`

const StyledButton = styled.button`
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.15s ease;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  letter-spacing: 0.4px;
  white-space: nowrap;
  background-color: ${(props) =>
    props.outline ? colors.white : props.color || colors.main};
  color: ${(props) =>
    props.outline ? props.color || colors.primary : colors.white};
  border: ${(props) =>
    props.outline ? `1px solid ${props.borderColor || colors.border}` : 'none'};
  font-size: ${(props) => (props.small ? '13px' : '14px')};
  padding: 0 16px;
  line-height: 1.5;
  margin: 0;
  height: ${(props) => (props.small ? '38px' : '44px')};
  opacity: ${(props) => (props.loading ? 0.6 : 1)};
  box-shadow: 2px 2px 6px 0 rgba(29, 36, 86, 0.04),
    0 1px 3px rgba(0, 0, 0, 0.06);
  :hover {
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
    box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.1), 0 2px 4px rgb(0 0 0 / 8%);
    outline: 0;
  }
  :focus {
    outline: 0;
    box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.1), 0 2px 4px rgb(0 0 0 / 8%);
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
  }
  :active {
    outline: 0;
    opacity: ${(props) => (props.loading ? 0.6 : 0.7)};
    box-shadow: 2px 2px 8px 0 rgba(29, 36, 86, 0.1), 0 2px 4px rgb(0 0 0 / 8%);
  }
  :disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }
`

export default Button
