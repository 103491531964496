import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { DuplicateIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { useActions } from '../store'
import { generateUniqueID } from '../helpers/generateUniqueID'

export default function DuplicateTemplateModal({ template, onClose }) {
  const { showToast } = useActions().toasts
  const { createTemplateFromCard } = useActions().license
  const [loading, setLoadingStatus] = useState(false)

  const duplicateTemplate = async () => {
    setLoadingStatus(true)

    try {
      const duplicatedTemplate = deepCloneObject(template)
      duplicatedTemplate.label += ' Copy'
      duplicatedTemplate.templateId = generateUniqueID()
      duplicatedTemplate.createdTimestamp = new Date().getTime()
      await createTemplateFromCard(duplicatedTemplate)
      showToast({
        content: 'Template duplicated successfully.',
        variant: 'success',
      })
    } catch (e) {
      console.error(e)
      showToast({ content: e.message })
    }

    setLoadingStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DuplicateIcon size={50} color={colors.main} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={duplicateTemplate}
            loading={loading}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, duplicate
          </Button>
        </>
      }
      header={`Duplicate ${template.label}?`}
      description="You're about to duplicate one of your templates. Are you sure you want to continue?"
    />
  )
}
