import React, { useState, useEffect, memo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import Logo from './Logo'
import { Tooltip, TooltipContent } from './Tooltip'
import { DESKTOP_BREAKPOINT, SIDEBAR_TABS } from '../core/constants'
import Avatar from './Avatar'
import { useStore } from '../store'
import { ReferralsIcon } from '../assets/icons'

const SideMenu = () => {
  const { license } = useStore()
  const isMediumDevice = useMediaQuery({ maxWidth: DESKTOP_BREAKPOINT })
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const profile = useStore().profile
  const TABS = [...SIDEBAR_TABS]

  if (license.affiliateID) {
    TABS.splice(2, 0, {
      label: 'Referrals',
      tooltip: 'Referrals',
      value: 'referrals',
      icon: <ReferralsIcon />,
      to: '/referrals',
    })
  }

  const [currentTab, setActiveTab] = useState('')
  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname.includes('company')) {
      setActiveTab('company')
      return
    }

    if (pathname.includes('tags-manager')) {
      setActiveTab('tags-manager')
      return
    }

    const tab = TABS.filter((t) => pathname.includes(t.value))[0] || {
      value: 'pages',
    }

    setActiveTab(tab.value)
  }, [pathname])

  if (!isMediumDevice) {
    return (
      <Container
        className="side-menu"
        style={{ alignItems: 'flex-start', paddingTop: '20px' }}
      >
        {!isMobile && <Logo style={{ paddingLeft: 20 }} />}
        <TabsContainer style={{ paddingTop: 12 }}>
          {TABS.map((tab) => (
            <DesktopTab
              to={tab.to}
              selected={tab.value === currentTab}
              onClick={(e) => {
                if (tab.value === 'help') {
                  if (window.Beacon) {
                    window.Beacon('open')
                  }
                  e.preventDefault()
                  e.stopPropagation()
                  return
                }
                setActiveTab(tab.value)
                window.scrollTo(0, 0)
              }}
            >
              {tab.icon}
              <TabLabel>{tab.label}</TabLabel>
            </DesktopTab>
          ))}
        </TabsContainer>
        <LeaveFeedback>
          <FeedbackLink
            href="https://app.konect.to/?canny=true&companyID=636c567598f8aa0605572783&redirect=https://feedback.konect.to/"
            rel="noopener noreferrer"
            target="_blank"
          >
            Leave Feedback
          </FeedbackLink>
        </LeaveFeedback>
        <DesktopTab
          to="/company"
          selected={currentTab === 'company'}
          onClick={() => {
            setActiveTab('company')
            window.scrollTo(0, 0)
          }}
        >
          <Avatar size={38} profile={profile} />
          <TabLabel>Settings</TabLabel>
        </DesktopTab>
      </Container>
    )
  }

  return (
    <Container className="side-menu">
      {!isMobile && <Logo iconOnly />}
      <TabsContainer>
        {TABS.map((tab) => {
          return (
            <Tooltip
              key={tab.value}
              trigger="hover"
              align="center"
              direction="right"
              content={
                !isMobile && (
                  <TooltipContent arrowPosition="left">
                    {tab.tooltip}
                  </TooltipContent>
                )
              }
            >
              <Tab
                to={tab.to}
                selected={tab.value === currentTab}
                onClick={(e) => {
                  if (tab.value === 'help') {
                    if (window.Beacon) {
                      window.Beacon('open')
                    }
                    e.preventDefault()
                    e.stopPropagation()
                    return
                  }
                  setActiveTab(tab.value)
                  window.scrollTo(0, 0)
                }}
                hideMobile={tab.hideMobile}
              >
                {tab.icon}
              </Tab>
            </Tooltip>
          )
        })}
      </TabsContainer>
      <NavLink to="/company" style={{ padding: 11 }}>
        <Tooltip
          trigger="hover"
          align="center"
          direction="right"
          content={
            !isMobile && (
              <TooltipContent arrowPosition="left">
                Company & Profile
              </TooltipContent>
            )
          }
        >
          <Avatar size={40} profile={profile} />
        </Tooltip>
      </NavLink>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  display: flex;
  height: 100%;
  background-color: ${colors.menu};
  z-index: 9999;
  padding-top: 24px;
  box-sizing: border-box;
  align-items: center;
  border-right: 1px solid ${colors.border};
  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    padding-top: 16px;
    border-right: none;
    background-color: ${colors.sidebar};
  }
  @media (min-width: 767px) {
    top: 0;
    left: 0;
    flex-direction: column;
  }
  @media (max-width: 767px) {
    flex-direction: row;
    z-index: 1000;
    bottom: -1px;
    left: 0;
    right: 0;
    width: 100%;
    height: auto;
    padding: 0;
  }
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-shrink: 0;
  padding-top: 16px;
  overflow: auto;
  @media (max-width: 767px) {
    flex-direction: row;
    padding: 0;
  }
`

const DesktopTab = styled(NavLink)`
  display: flex;
  text-decoration: none;
  align-items: center;
  width: 200px;
  box-sizing: border-box;
  padding: 18px 20px;
  transition: fill 0.1s linear, color 0.1s linear;
  flex-shrink: 0;
  fill: ${colors.black};
  color: ${colors.primary};
  font-weight: ${(props) => (props.selected ? '700' : '500')};
`

const TabLabel = styled.span`
  font-size: 14px;
  padding-left: 16px;
  white-space: pre;
  color: inherit;
`

const Tab = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 64px;
  height: 64px;
  transition: fill 0.1s linear, background-color 0.1s linear;
  fill: ${(props) => (props.selected ? colors.main : colors.black)};
  cursor: pointer;
  background-color: ${(props) =>
    props.selected ? colors.menu : colors.sidebar};
  :hover {
    fill: ${colors.main};
  }
  @media (max-width: 767px) {
    display: ${(props) => (props.hideMobile ? 'none' : 'flex')};
  }
`

const LeaveFeedback = styled.div`
  width: 100%;
  padding: 4px 20px;
  box-sizing: border-box;
`

const FeedbackLink = styled.a`
  text-decoration: none;
  font-size: 13px;
  color: #334ab8;
  font-weight: 500;
  text-align: left;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  :active {
    opacity: 0.6;
  }
`

export default memo(SideMenu)
