import styled from 'styled-components'
import { animations, colors } from '../core/theme'

const Spinner = styled.div`
  border: 2px solid transparent;
  border-top: 2px solid ${colors.primary};
  border-right: 2px solid ${colors.primary};
  border-radius: 50%;
  width: ${(props) => props.size || '20px'};
  height: ${(props) => props.size || '20px'};
  animation: ${animations.spin} 0.75s linear infinite;
  box-sizing: border-box;
`

export default Spinner
