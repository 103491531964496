import React, { useState } from 'react'
import styled from 'styled-components'
import { DeleteIcon, MenuIcon } from '../assets/icons'
import { animations, colors } from '../core/theme'
import { usePermission } from '../hooks/usePermission'
import DeleteFolderModal from '../modals/DeleteFolderModal'
import { useActions, useStore } from '../store'
import { Dropdown, DropdownListItem } from './Dropdown'
import EditFolder from './EditFolder'

const FolderOptions = ({ folder }) => {
  const { license } = useStore()
  const { toasts } = useActions()
  const [show, setVisibility] = useState(false)
  const [hasDelete, showDelete] = useState()
  const { canEdit, showPermissionAlert } = usePermission()

  const hideMenu = () => setVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (show) setVisibility(false)
    else setVisibility(true)
  }

  return (
    <>
      <Dropdown
        isVisible={show}
        placement="bottom-start"
        onClose={hideMenu}
        zIndex={999999}
        triggerRenderer={({ ref }) => (
          <OptionContainer ref={ref} onClick={showMenu}>
            <MenuIcon size={14} color={colors.primary} />
          </OptionContainer>
        )}
      >
        <DropdownContainer>
          <EditFolder folder={folder} />
          <DropdownListItem
            content="Delete folder"
            color={colors.error}
            icon={<DeleteIcon color={colors.error} size={16} />}
            onClick={() => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }

              if (license.folders.length === 1) {
                toasts.showToast({
                  content: 'You must have at least one folder.',
                })
                hideMenu()
                return
              }
              showDelete(true)
              hideMenu()
            }}
          />
        </DropdownContainer>
      </Dropdown>
      {hasDelete && (
        <DeleteFolderModal folder={folder} onClose={() => showDelete(false)} />
      )}
    </>
  )
}

const DropdownContainer = styled.div`
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(66, 77, 87, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  width: 260px;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
  &:focus {
    outline: none;
  }
`

const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 4px;
`

export default FolderOptions
