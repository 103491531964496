import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { EditTextIcon, DoneIcon } from '../assets/icons'
import { colors } from '../core/theme'
import FloatingInput from './FloatingInput'

const EditableName = ({ value, save, style, className }) => {
  const [name, updateName] = useState(value)
  const [isEditing, setEditingStatus] = useState(false)

  useEffect(() => {
    const onKeyUp = (event) => {
      if (isEditing) {
        if (event.keyCode === 13) {
          setEditingStatus(false)
          save(name)
        }
      }
    }
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [save, isEditing])

  useEffect(() => {
    updateName(value)
  }, [value])

  const onEditClicked = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (isEditing) {
      const inputValue = e.target.value || name
      if (!inputValue) {
        return
      }
      setEditingStatus(false)
      save(inputValue)
      return
    }

    if (!isEditing) {
      setEditingStatus(true)
    }
  }

  const onChange = (e) => {
    updateName(e.target.value)
  }

  return (
    <Container style={style} className={className}>
      {isEditing && (
        <FloatingInput
          autoFocus
          onChange={onChange}
          value={name}
          style={{ marginBottom: 0 }}
        />
      )}
      {!isEditing && <Name>{name}</Name>}
      <IconContainer onClick={onEditClicked}>
        {isEditing ? (
          <DoneIcon size={20} color={colors.success} />
        ) : (
          <EditTextIcon color={colors.lightgrey} />
        )}
      </IconContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Name = styled.span`
  font-size: 14px;
  color: ${colors.primary};
  line-height: 1.5;
  font-weight: 500;
`

const IconContainer = styled.div`
  display: flex;
  margin-left: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
`

export default EditableName
