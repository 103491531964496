import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import IconStylePicker from '../components/IconStylePicker'
import Booksy from '../components/SectionSettings/Booksy'
import ButtonSettings from '../components/SectionSettings/ButtonSettings'
import Calendly from '../components/SectionSettings/Calendly'
import ContactForm from '../components/SectionSettings/ContactForm'
import Description from '../components/SectionSettings/Description'
import Embed from '../components/SectionSettings/Embed'
import Media from '../components/SectionSettings/Media'
import SocialIcon from '../components/SectionSettings/SocialIcon'
import Video from '../components/SectionSettings/Video'
import BaseModal from './BaseModal'

export default function SectionSettingsModal({
  onClose,
  card,
  updateCard,
  section,
  item,
  index,
  showSave,
  isSaving,
  saveChanges,
  templateLockProps,
}) {
  const containerStyle = {}
  if (
    section.type === 'button' ||
    section.type === 'icon' ||
    section.type === 'link' ||
    section.type === 'folder' ||
    section.type === 'podcast'
  ) {
    containerStyle.padding = '0'
  }

  return (
    <BaseModal
      title={
        showSave ? (
          <Button
            small
            loading={isSaving}
            onClick={() => saveChanges(card)}
            className="save-button"
          >
            Save
          </Button>
        ) : null
      }
      forceFullscreen
      onClose={onClose}
    >
      <Container style={containerStyle}>
        {(section.type === 'link' ||
          section.type === 'folder' ||
          section.type === 'podcast') && (
          <ButtonSettings
            button={section}
            card={card}
            updateCard={updateCard}
            section={section}
            index={index}
          />
        )}
        {section.type === 'button' && (
          <ButtonSettings
            button={item}
            card={card}
            updateCard={updateCard}
            section={section}
            index={index}
          />
        )}
        {section.type === 'icon' && (
          <SocialIcon
            link={item}
            card={card}
            section={section}
            updateCard={updateCard}
          />
        )}
        {section.type === 'iframe' && (
          <Embed card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'booksy' && (
          <Booksy card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'video' && (
          <Video card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'calendly' && (
          <Calendly card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'media' && (
          <Media card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'contact' && (
          <ContactForm
            templateLockProps={templateLockProps}
            card={card}
            section={section}
            updateCard={updateCard}
            showSave={showSave}
            saveChanges={saveChanges}
            isSaving={isSaving}
          />
        )}
        {section.type === 'description' && (
          <Description card={card} section={section} updateCard={updateCard} />
        )}
        {section.type === 'social-links' && (
          <IconStylePicker
            card={card}
            section={section}
            updateCard={updateCard}
          />
        )}
      </Container>
    </BaseModal>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 24px 20px 24px;
  @media (max-width: 767px) {
    padding: 0 20px 20px 20px;
  }
`
