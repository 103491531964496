import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import {
  ToastSuccess,
  ToastError,
  ToastInfo,
  ToastWarning,
  CloseIcon,
} from '../../assets/icons'
import { colors, animations } from '../../core/theme'

const toastIcons = {
  success: <ToastSuccess />,
  warning: <ToastWarning />,
  error: <ToastError />,
  info: <ToastInfo />,
}

const Toast = ({
  content,
  variant,
  onClose,
  removable,
  id,
  duration,
  style,
}) => {
  const ref = useRef()

  const onToastClose = () => {
    if (ref && ref.current) {
      ref.current.classList.add('toast-out-animation')
    }
    setTimeout(onClose, 280)
  }

  useEffect(() => {
    if (duration) {
      setTimeout(onToastClose, duration)
    }
  })

  return (
    <Container variant={variant} id={id} style={style} ref={ref}>
      <Icon>{toastIcons[variant]}</Icon>
      <Label>{content}</Label>
      {removable && (
        <CloseContainer aria-label="Close toast" onClick={onToastClose}>
          <CloseIcon size={18} color={colors.white} />
        </CloseContainer>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  width: 330px;
  padding: 13px 12px;
  margin: 4px;
  font-weight: 500;
  background: #fff;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  animation: ${animations.toastAnimation} 300ms ease-out;

  ${(props) => {
    switch (props.variant) {
      case 'success':
        return `background: ${colors.toastSuccess}`
      case 'warning':
        return `background: ${colors.toastWarning}`
      case 'error':
        return `background: ${colors.toastError}`
      case 'info':
        return `background: ${colors.toastInfo}`
      default:
        return ``
    }
  }}
`

const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 1px solid #ffffff80;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
`

const Label = styled.span`
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  font-weight: 500;
  line-height: 1.5;
`

export default Toast
