const config = {}

config.MAX_CARDS_PER_LICENSE = 2000
config.HOW_TO_SHARE_CARD = 'https://www.youtube.com/embed/x9p36ev8PgI'
config.HOW_TO_SHARE_TAG = 'https://www.youtube.com/embed/LZwxPIiWhZE'
config.CARDS_BASE_URL = 'https://konect.to'
config.TWITTER_PROFILE = 'https://x.com/KonectHQ'
config.PRIVACY_POLICY = 'https://konect.to/privacy-policy'
config.TERMS_AND_CONDITIONS = 'https://konect.to/terms-of-service'
config.ACTIVE_CAMPAIGN_CLIENT_ID = 'm91ZVJd9BYyzJOdNve7ucKpwldX15FrksX0I'
config.REQUEST_INTEGRATION_FORM =
  'https://feedback.konect.to/feature-requests?category=integrations&selectedCategory=integrations'
config.SERVER_URL =
  'https://us-central1-konect-7e9af.cloudfunctions.net/backend'
config.FIREBASE_CONFIG = {
  apiKey: 'AIzaSyBnrddTL3LVrMlu0K4Z4u5wtQfAxQ7Fgj0',
  authDomain: 'app.konect.to',
  projectId: 'konect-7e9af',
  storageBucket: 'konect-7e9af.appspot.com',
  messagingSenderId: '1085128028498',
  appId: '1:1085128028498:web:b8367f528c9f1f55638a8b',
}

export { config }
