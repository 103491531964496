import React from 'react'
import styled from 'styled-components'
import { colors, shadows } from '../../core/theme'
import { Subheading } from '../Typography'

const CardBase = ({ title, body }) => {
  return (
    <Container>
      {title && (
        <Subheading weight="600" style={{ paddingBottom: 8, fontSize: 17 }}>
          {title}
        </Subheading>
      )}
      {body}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  box-shadow: ${shadows.primaryLight};
  border-radius: 6px;
  margin: 16px;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    margin: 14px;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 12px;
    margin: 12px 0;
  }
`

export default CardBase
