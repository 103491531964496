import React from 'react'
import { LockIcon, UnLockIcon } from '../assets/icons'
import { licenseStatus } from '../helpers/licenseStatus'
import { useStore } from '../store'
import { InfoTooltip } from './InfoTooltip'

export default function PremiumBadge({
  type = 'section',
  direction = 'right',
  arrowPosition = 'left',
  align,
  size = 16,
  zIndex = 9999,
  margin,
  premiumFeature,
  card,
}) {
  const { license } = useStore()
  const { isActive, isTrialActive } = licenseStatus(license)

  if (premiumFeature) {
    const { isCardActive } = licenseStatus(license, card)
    if (isCardActive) return null
    return (
      <InfoTooltip
        align={align}
        zIndex={zIndex}
        icon={<LockIcon size={size} />}
        description="This feature is only available on premium plans."
        direction={direction}
        arrowPosition={arrowPosition}
        style={{ margin: margin || '0 6px' }}
      />
    )
  }

  if (isActive) return null

  const isTemplate = window.location.pathname.includes('template')
  if (isTemplate) return null

  return (
    <InfoTooltip
      align={align}
      zIndex={zIndex}
      icon={
        isTrialActive ? <UnLockIcon size={size} /> : <LockIcon size={size} />
      }
      description={
        isTrialActive
          ? `You are on a free trial. After trial ends this ${type} will be disabled.`
          : `Free trial ended. This ${type} is disabled.`
      }
      direction={direction}
      arrowPosition={arrowPosition}
      style={{ margin: margin || '0 6px' }}
    />
  )
}
