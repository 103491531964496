import React from 'react'
import styled from 'styled-components'
import { FacebookIcon } from '../../assets/icons'
import { Tooltip, TooltipContent } from '../Tooltip'

const Facebook = ({ cardURL, text }) => (
  <IconContainer>
    <Tooltip
      trigger="hover"
      content={
        <TooltipContent arrowPosition="top">
          Share page on Facebook
        </TooltipContent>
      }
      direction="bottom"
    >
      <Link
        href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(
          cardURL
        )}&t=${encodeURIComponent(text)}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <FacebookIcon size={22} />
      </Link>
    </Tooltip>
  </IconContainer>
)

const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 12px 0 0;
`

const Link = styled.a`
  text-decoration: none;
  transition: opacity 0.3s ease-out;
  :hover {
    opacity: 0.7;
  }
`

export default Facebook
