import { cannyGetQueryParameterByName } from './cannyGetQueryParameterByName'

export function cannyGetRedirectURL(ssoToken) {
  const companyID = cannyGetQueryParameterByName('companyID')
  if (!companyID) {
    return null
  }

  return (
    'https://canny.io/api/redirects/sso?companyID=' +
    companyID +
    '&ssoToken=' +
    ssoToken.toString() +
    '&redirect=https://feedback.konect.to/'
  )
}
