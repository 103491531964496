import styled from 'styled-components'
import { returnFontColor, returnFontWeight, returnLineHeight } from './helpers'

const Caption = styled.span`
  font-size: 13px;
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  color: ${(props) => returnFontColor(props.variant || 'secondary')};
  font-weight: ${(props) => returnFontWeight(props.weight)};
  line-height: ${(props) => returnLineHeight(props.lineHeight)};
  text-align: ${(props) => props.align || 'left'};
`

export default Caption
