import React from 'react'
import styled from 'styled-components'
import { colors, shadows } from '../../core/theme'
import EditableName from '../../components/EditableName'
import UserRow from '../../components/UserRow'
import Button from '../../components/Button'
import { AddIcon } from '../../assets/icons'
import InvitationRow from '../../components/InvitationRow'
import { usePermission } from '../../hooks/usePermission'
import { useActions, useStore } from '../../store'
import { TopBar } from '../../components/TopBar'
import { updateCustomer } from '../../api/billing-api'

const COLUMNS = ['Employee', 'Added', 'Permission', 'Actions']

const Users = () => {
  const { showModal } = useActions().modals
  const { updateLicenseData } = useActions().license
  const { canEdit, showPermissionAlert } = usePermission()
  const { license } = useStore()
  const { company = 'My Company', users, invitations = {} } = license

  const onCompanyNameSave = (value) => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }
    if (value) {
      updateLicenseData({ ...license, company: value })
      if (license.customerId) {
        updateCustomer(license, { name: value })
      }
    }
  }

  return (
    <Container>
      <Header>
        <EditableName value={company} save={onCompanyNameSave} />
        <AddEmployee
          onClick={() => {
            if (!canEdit) {
              showPermissionAlert()
              return
            }
            showModal({ name: 'INVITE_EMPLOYEE' })
          }}
          leftIcon={<AddIcon color={colors.white} />}
          small
        >
          Invite
        </AddEmployee>
      </Header>
      <RowContainer>
        {COLUMNS.map((column, i) => (
          <RowHeader
            flex={i === 0 ? 2 : 1}
            key={i}
            isLast={i === COLUMNS.length - 1}
          >
            {column.toUpperCase()}
          </RowHeader>
        ))}
      </RowContainer>
      <Wrapper>
        {Object.keys(users).map((user) => (
          <UserRow key={user} user={users[user]} license={license} />
        ))}
        {Object.keys(invitations).map((invitation) => (
          <InvitationRow
            key={invitation}
            invitation={invitations[invitation]}
            license={license}
          />
        ))}
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  min-height: 380px;
  height: 100%;
`

const Header = styled(TopBar)`
  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`

const AddEmployee = styled(Button)`
  position: absolute;
  font-size: 14px;
  right: 16px;
  margin: 0;
  z-index: 1;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 10px 24px 10px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  box-shadow: ${shadows.primaryLight};
  flex-shrink: 0;
  z-index: 1;
`

const RowHeader = styled.div`
  display: flex;
  flex: ${(props) => props.flex || 1};
  justify-content: ${(props) => (props.isLast ? 'flex-end' : 'flex-start')};
  font-size: 12px;
  color: ${colors.grey};
  width: 100%;
  @media (max-width: 767px) {
    flex: 1;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  height: 100%;
`

export default Users
