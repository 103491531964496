import React from 'react'
import styled from 'styled-components'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import { useActions } from '../store'
import FloatingSelect from './FloatingSelect'
import { Row } from './Layout'

export default function PremiumPageBlock({
  slot,
  cards,
  cardSlots,
  index,
  isMobile,
  canEdit,
  showPermissionAlert,
}) {
  const { setCardForSlot } = useActions().license
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const foundCard = cards.find((c) => c.cardID === slot.cardID) || {}
  const filteredCards = cards.filter((card) => {
    if (card.cardID === foundCard.cardID) return true
    if (cardSlots.some((s) => s.cardID === card.cardID)) return false
    return true
  })
  const isActive = Boolean(foundCard.cardID)

  const onCardSelected = async (e) => {
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    const newFoundCard = filteredCards.find((c) => c.label === e.target.value)
    if (newFoundCard) {
      try {
        await setCardForSlot({ card: newFoundCard, slot })
      } catch (err) {
        console.error(err)
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
    } else {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  return (
    <>
      <Container isActive={isActive}>
        {!isMobile && (
          <Row flex="1" align="center">
            <RowLabel>#{index}</RowLabel>
          </Row>
        )}
        <Row flex="1" align="center">
          <FloatingSelect
            onChange={onCardSelected}
            value={foundCard.label || ''}
            empty="- Select page -"
            style={{ marginBottom: 0, width: isMobile ? '90%' : '75%' }}
            options={filteredCards.map((c) => {
              return (
                <option key={c.label} value={c.label}>
                  {c.label}
                </option>
              )
            })}
          />
        </Row>
        <Row flex="1" align="center">
          <RowLabel>
            {slot.createdTimestamp
              ? new Date(slot.createdTimestamp).toLocaleDateString()
              : 'Not activated'}
          </RowLabel>
        </Row>
        <DeleteContainer
          onClick={() => {
            if (!canEdit) {
              showPermissionAlert()
              return
            }
            showModal({ name: 'DELETE_SLOT_MODAL', slot })
          }}
        >
          <DeleteIcon size={20} />
        </DeleteContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border: 1px solid ${(props) => (props.isActive ? '#c3cdd3' : colors.warning)};
  background-color: #ededed;
  border-radius: 12px;
  margin-bottom: 24px;
  position: relative;
  height: 100px;
`

const DeleteContainer = styled.div`
  position: absolute;
  right: 14px;
  cursor: pointer;
  z-index: 1;
  flex-shrink: 0;
  @media (max-width: 767px) {
    right: 12px;
  }
`

const RowLabel = styled.span`
  font-size: 14px;
  color: ${colors.primary};
  padding-left: 2px;
`
