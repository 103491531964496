import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row } from './Layout'
import { Title, Label } from './Typography'
import { colors } from '../core/theme'
import { CheckIcon } from '../assets/icons'
import { returnFormattedDate } from '../helpers/days'
import { getCustomerInvoices } from '../api/billing-api'

const Invoices = ({ license }) => {
  const [invoices, setInvoices] = useState([])
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const invoicesData = await getCustomerInvoices(license)
        setInvoices(invoicesData.data)
      } catch (e) {
        setError('Failed to load invoices. Please contact our support.')
      }
    })()
  }, [])

  return (
    <Container>
      <Title weight="bold">Invoice History</Title>
      {error && (
        <Label variant="error" padding="12px 0">
          {error}
        </Label>
      )}
      {invoices.length > 0 && (
        <Table>
          <Row>
            <Column>
              <Label weight="medium" variant="secondary">
                Date
              </Label>
            </Column>
            <Column>
              <Label weight="medium" variant="secondary">
                Amount
              </Label>
            </Column>
            <Column />
          </Row>
          {invoices
            .filter((i) => i.amount_paid)
            .map((invoice) => (
              <Row key={invoice.id}>
                <Column>
                  <Label>
                    {returnFormattedDate(new Date(invoice.created * 1000))}
                  </Label>
                </Column>
                <Column>
                  <CheckIcon color={colors.success} />
                  <Label padding="0 4px">
                    ${Number.parseFloat(invoice.amount_paid / 100).toFixed(2)}
                  </Label>
                </Column>
                <Column>
                  <a
                    href={invoice.invoice_pdf}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download Invoice
                  </a>
                </Column>
              </Row>
            ))}
        </Table>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 28px 0 8px 0;
  width: 100%;
  max-width: 500px;
`

const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
  width: 100%;
  padding: 4px 0;
  border: 1px solid ${colors.border};
  background-color: ${colors.white};
  border-radius: 6px;
`

const Column = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 10px 15px;
  font-size: 14px;
`

export default Invoices
