import React from 'react'
import ReactDOM from 'react-dom'
import { createOvermind } from 'overmind'
import { Provider as StoreProvider } from 'overmind-react'
import 'konect-react/index.css'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { store as OvermindStore } from './store'
import { ModalsProvider } from './providers/ModalsProvider'
import { ToastsProvider } from './providers/ToastsProvider'
import { AuthProvider } from './providers/AuthProvider'
import RouterProvider from './providers/RouterProvider'
import './scss/index.scss'

const Application = () => (
  <BrowserRouter>
    <StoreProvider value={createOvermind(OvermindStore)}>
      <AuthProvider>
        <RouterProvider>
          <App />
        </RouterProvider>
        <ToastsProvider />
        <ModalsProvider />
      </AuthProvider>
    </StoreProvider>
  </BrowserRouter>
)

ReactDOM.render(<Application />, document.getElementById('root'))
