import React, { useState } from 'react'
import styled from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import { UploadIcon } from '../assets/icons'
import { useActions } from '../store'
import { Caption } from './Typography'
import { uploadCardBanner } from '../api/storage-api'
import Spinner from './Spinner'
import TemplateLock from './TemplateLock'

const BannerUpload = ({
  card,
  updateCard,
  showLock,
  isLocked = () => {},
  onLock,
  showLockMessage,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { url } = card.banner || {}
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const [error, setError] = useState()
  const [uploading, setUploading] = useState(false)
  const label = 'page-banner'

  const saveCroppedImage = async (uploadedImage, fileExtension) => {
    try {
      const bannerUrl = await uploadCardBanner(
        uploadedImage,
        fileExtension,
        card
      )
      card.banner = { ...card.banner, url: bannerUrl }
      updateCard(card)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }
  }

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (uploading) return
    if (rejectedFiles.length > 0) {
      if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
        setError('File must be an image.')
      } else {
        setError('File exceeded max 10mb size.')
      }
      return
    }

    setError(false)
    setUploading(true)

    const uploadedImage = acceptedFiles[0]
    if (!uploadedImage) return

    const imageDataUrl = URL.createObjectURL(uploadedImage)
    const parts = uploadedImage.name.split('.')
    const fileExtension = parts[parts.length - 1] || ''

    if (fileExtension.toLowerCase() === 'gif') {
      try {
        const bannerUrl = await uploadCardBanner(
          uploadedImage,
          fileExtension,
          card
        )
        card.banner = { ...card.banner, url: bannerUrl }
        updateCard(card)
      } catch (e) {
        showToast({
          content: 'Something went wrong. Please contact our support.',
        })
      }
      setUploading(false)
      return
    }

    showModal({
      name: 'CROP_IMAGE',
      image: imageDataUrl,
      fileExtension,
      aspect: 16 / 9,
      onSave: saveCroppedImage,
    })

    setUploading(false)
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    maxSize: 10000000,
    disabled: isLocked(label),
  })

  return (
    <Container>
      {error && <Error>{error}</Error>}
      <LogoContainer
        onClick={() => {
          if (isLocked(label)) {
            showLockMessage()
          }
        }}
      >
        {url ? (
          <DropZone
            remove={!isMobile && !isLocked(label)}
            onClick={() => {
              if (isLocked(label)) return
              const result = window.confirm(
                `You are about to delete your banner. Are you sure you want to continue?`
              )
              if (result) {
                delete card.banner
                updateCard(card)
              }
            }}
          >
            <PreviewImage src={url} alt="logo" />
          </DropZone>
        ) : (
          <DropZone
            isDragActive={isDragActive}
            {...getRootProps()}
            className="dropZone"
          >
            <EmptyInput {...getInputProps()} />
            {uploading && <Spinner />}
            {!uploading && (
              <Drop>
                <UploadIcon size={24} color={colors.grey} />
                <Caption
                  padding="4px"
                  align="center"
                  style={{ color: colors.grey, fontSize: 12 }}
                >
                  Banner
                </Caption>
              </Drop>
            )}
          </DropZone>
        )}
        {(showLock || (card.cardID && isLocked(label))) && (
          <TemplateLock
            onLock={onLock}
            label={label}
            isLocked={isLocked}
            style={{ bottom: -12, top: 'auto' }}
          />
        )}
      </LogoContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 4px 0;
  align-items: center;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
`

const DropZone = styled.div`
  cursor: pointer;
  margin-top: 12px;
  background-color: ${(props) =>
    props.isDragActive ? `${colors.success}55` : colors.menu};
  border: 1px solid
    ${(props) => (props.isDragActive ? colors.success : colors.border)};
  position: relative;
  width: 380px;
  height: auto;
  aspect-ratio: 16 / 9;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-out;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
  :hover {
    opacity: ${(props) => (props.remove ? 1 : 0.8)};
    ${(props) =>
      props.remove
        ? `&::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";
      border-radius: 8px;
      z-index: 9;
      background-color: ${colors.red}88;
    }`
        : ``}
  }
`

const Drop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const EmptyInput = styled.input`
  display: none;
  position: relative;
  width: 0;
  height: 0;
`

const Error = styled.span`
  font-size: 14px;
  color: ${colors.error};
  margin-top: 12px;
  margin-left: 4px;
  margin-bottom: 4px;
`

const PreviewImage = styled.img`
  width: 378px;
  height: auto;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 8px;
  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
`

export default BannerUpload
