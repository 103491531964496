import React from 'react'
import { Toast, ToastWrapper } from '../components/Toast'
import { useStore, useActions } from '../store'

export const ToastsProvider = () => {
  const { toasts } = useStore()
  const { hideToast } = useActions().toasts

  return (
    <ToastWrapper
      style={{ margin: '20px 0 0 0' }}
      verticalPosition="top"
      horizontalPosition="center"
    >
      {toasts.map((toast) => (
        <Toast
          key={toast.key}
          content={toast.content}
          variant={toast.variant || 'error'}
          duration={toast.duration || 3000}
          onClose={() => hideToast(toast.key)}
          removable
        />
      ))}
    </ToastWrapper>
  )
}
