import axios from 'axios'
import { config } from '../core/config'

export const generateBillingSession = (data) => {
  return axios.post(`${config.SERVER_URL}/billing/checkout_session`, data)
}

export const generateUpdateBillingSession = (data) => {
  return axios.post(
    `${config.SERVER_URL}/billing/checkout-update-billing-details`,
    data
  )
}

export const updateLicenseSubscription = (subscription) => {
  return axios.post(
    `${config.SERVER_URL}/billing/update-subscription`,
    subscription
  )
}

export const cancelLicenseSubscription = (
  subscription,
  license,
  canceledBy
) => {
  return axios.post(`${config.SERVER_URL}/billing/cancel-subscription`, {
    subscription,
    license,
    canceledBy,
  })
}

export const getCustomerInvoices = (license) => {
  return axios.post(`${config.SERVER_URL}/billing/invoices`, {
    customerId: license.customerId,
    license: license.license,
  })
}

export const updateCustomer = (license, parameters) => {
  return axios.post(`${config.SERVER_URL}/billing/update-customer`, {
    parameters,
    customerId: license.customerId,
    license: license.license,
  })
}
