import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { Dropdown } from './Dropdown'
import { FilterIcon } from '../assets/icons'
import Tag from './Tag'
import { Column, Row } from './Layout'
import Checkbox from './Checkbox'
import { Label } from './Typography'

export default function TagsFilters({ inbox = [], tagsFilter, setTagsFilter }) {
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  const foundTags = useMemo(() => {
    const tags = {}
    inbox.forEach((f) => {
      if (f.tags) {
        f.tags.forEach((t) => {
          tags[t.label] = t
        })
      }
    })
    return tags
  }, [inbox])

  return (
    <Dropdown
      isVisible={isMenuVisible}
      placement="bottom-start"
      onClose={hideMenu}
      zIndex={99999}
      triggerRenderer={({ ref }) => (
        <Filter onClick={showMenu} ref={ref}>
          <FilterIcon color={colors.darkgrey} size={16} />
        </Filter>
      )}
    >
      <Label padding="16px 16px 2px 16px" weight="medium">
        Sort by tags
      </Label>
      <Column padding="10px 12px">
        {Object.keys(foundTags).length === 0 && (
          <Label variant="secondary" padding="8px">
            No tags found.
          </Label>
        )}
        {Object.keys(foundTags).map((key) => {
          const tag = foundTags[key]
          return (
            <Row padding="6px 4px">
              <Checkbox
                value={tagsFilter[tag.label]}
                color={tag.color}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                onChange={() => {
                  if (tagsFilter[tag.label]) {
                    const copy = { ...tagsFilter }
                    delete copy[tag.label]
                    setTagsFilter(copy)
                  } else {
                    setTagsFilter({ ...tagsFilter, [tag.label]: tag })
                  }
                }}
              />
              <Tag {...tag} />
            </Row>
          )
        })}
      </Column>
    </Dropdown>
  )
}

const Filter = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-shrink: 0;
  color: ${colors.darkgrey};
  font-size: 13px;
  font-weight: 400;
  margin-left: 8px;
`
