import React, { useState } from 'react'
import styled from 'styled-components'
import { getColorForAvatar } from '../helpers/getColorForAvatar'

const Avatar = ({ profile, size = 42 }) => {
  const { avatar, name, email } = profile
  const [error, setError] = useState(false)
  const label = name || email || '?'

  if (avatar && !error) {
    return (
      <Container size={size}>
        <ImageAvatar
          size={size}
          src={avatar}
          onError={() => setError(true)}
          title={name || email}
          alt="avatar"
        />
      </Container>
    )
  }

  return (
    <Container
      size={size}
      style={{ backgroundColor: getColorForAvatar(email || name) }}
    >
      {label.charAt(0).toUpperCase()}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: #ffffff;
  background-color: #ffffff;
  border-radius: 50%;
  font-size: ${(props) => props.size / 2}px;
  text-decoration: none;
  user-select: none;
  flex-shrink: 0;
`

const ImageAvatar = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  border-radius: 50%;
  object-fit: cover;
`

export default Avatar
