import { addNewCard, markCardAsDeleted } from '../api/database-api'
import { generateUniqueCardID } from '../helpers/generateUniqueCardID'
import { generateCardSecretSlug } from '../helpers/generateCardSecretSlug'
import { config } from '../core/config'
import { CARDS_FILTERS } from '../helpers/filters'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { generateSectionsIDs } from '../helpers/generateSectionsIDs'

const INITIAL_STATE = {
  cards: [],
  tab: 0,
  selectedFolder: { cards: [] },
  cardsFilter: CARDS_FILTERS[0],
  reportsFilter: CARDS_FILTERS[2],
}

function setTab({ state }, tab) {
  state.cards.tab = tab
}

const updateCards = ({ state }, cards) => {
  const { license, profile } = state
  const users = license.users || {}
  const foundUser = users[profile.email] || {}
  const allowedFolders = foundUser.allowedFolders || []

  let selectedFolder = state.cards.selectedFolder.name
    ? state.cards.selectedFolder
    : license.folders[0]

  if (allowedFolders) {
    const foundFolder = license.folders.find((f) => f.id === allowedFolders[0])
    if (foundFolder) {
      selectedFolder = foundFolder
    }
  }

  state.cards = {
    ...state.cards,
    cards: [...cards],
    selectedFolder,
  }
}

function changeFilter({ state }, filter) {
  state.cards.cardsFilter = filter
}

function changeReportsFilter({ state }, filter) {
  state.cards.reportsFilter = filter
}

const addCardToFolder = ({ state, actions }, { card, folder }) => {
  const { license } = state
  const updatedLicense = { ...license }

  const cardFolder =
    updatedLicense.folders.filter((g) => g.cards.includes(card.cardID))[0] || {}

  if (cardFolder.name) {
    cardFolder.cards = cardFolder.cards.filter((f) => f !== card.cardID)
  }

  folder.cards.push(card.cardID)
  updatedLicense.folders = [...updatedLicense.folders]
  state.cards.selectedFolder = { ...folder }
  return actions.license.updateLicenseData(updatedLicense)
}

const changeFolder = ({ state }, folder) => {
  state.cards = {
    ...state.cards,
    selectedFolder: folder,
  }
}

const addCardToAccount = async ({ state, actions }, { card, duplicate }) => {
  const profile = state.profile
  const cardCount = state.cards.cards.length
  if (cardCount > config.MAX_CARDS_PER_LICENSE) {
    throw new Error('Cards limit reached.')
  }
  card.cardID = generateUniqueCardID()
  card.createdTimestamp = new Date().getTime()
  card.license = profile.license || ''
  card.cardURL = config.CARDS_BASE_URL + '/' + generateCardSecretSlug(card)
  delete card.lockedFields

  if (!duplicate) {
    card.creatorEmail = profile.email || ''
    card.personalDetails = card.personalDetails || {
      name: card.label || '',
      headline: '',
      title: '',
      company: '',
    }
  }

  if (!duplicate) {
    generateSectionsIDs(card)
  }

  if (state.cards.selectedFolder) {
    actions.cards.addCardToFolder({ card, folder: state.cards.selectedFolder })
  }

  await addNewCard(card)
  return card
}

const deleteCard = async ({ state }, card) => {
  const { profile } = state
  return markCardAsDeleted(card, profile)
}

const clear = ({ state }) => {
  state.cards = deepCloneObject(INITIAL_STATE)
}

export default {
  state: INITIAL_STATE,
  actions: {
    updateCards,
    addCardToFolder,
    changeFolder,
    addCardToAccount,
    deleteCard,
    clear,
    changeFilter,
    changeReportsFilter,
    setTab,
  },
}
