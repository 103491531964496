import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  loginWithPasswordAndEmail,
  signUpWithPasswordAndEmail,
  userLoginWithGoogle,
} from '../api/auth-api'
import Logo from '../components/Logo'
import AuthButton from '../components/AuthButton'
import { ReactComponent as GoogleLogo } from '../assets/icons/google_logo.svg'
import { config } from '../core/config'
import { useActions, useStore } from '../store'
import { Title } from '../components/Typography'
import Button from '../components/Button'
import { colors } from '../core/theme'
import { emailValidator } from '../helpers/validators/emailValidator'
import { passwordValidator } from '../helpers/validators/passwordValidator'
import { repeatPasswordValidator } from '../helpers/validators/repeatPasswordValidator'
import { nameValidator } from '../helpers/validators/nameValidator'
import AccessoryView from '../components/AccessoryView'
import FloatingInput from '../components/FloatingInput'
import { queryStringToJSON } from '../helpers/queryStringToJSON'

const Accounts = ({ accessoryID }) => {
  const parameters = queryStringToJSON()
  const { invitation } = useStore()
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const [tab, setTab] = useState(
    window.location.pathname.includes('login') ? 'login' : 'register'
  )
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState({ value: '', error: '' })
  const [email, setEmail] = useState({
    value: parameters.email || '',
    error: '',
  })
  const [password, setPassword] = useState({ value: '', error: '' })
  const [repeatPassword, setRepeatPassword] = useState({ value: '', error: '' })

  const loginUser = (provider) => async () => {
    try {
      switch (provider) {
        case 'google':
          await userLoginWithGoogle()
          break
        default:
      }
    } catch (e) {
      showToast({ content: e.message })
    }
  }

  const signInUser = async () => {
    if (loading) return
    const nameError = tab === 'register' ? nameValidator(name.value) : false
    const emailError = emailValidator(email.value)
    const passwordError = passwordValidator(password.value)
    const repeatPasswordError =
      tab === 'register'
        ? repeatPasswordValidator(repeatPassword.value, password.value)
        : false
    if (emailError || passwordError || repeatPasswordError || nameError) {
      setName({ ...name, error: nameError })
      setEmail({ ...email, error: emailError })
      setPassword({ ...password, error: passwordError })
      setRepeatPassword({ ...repeatPassword, error: repeatPasswordError })
      return
    }
    setLoading(true)
    const { error } =
      tab === 'register'
        ? await signUpWithPasswordAndEmail({
            email: email.value,
            password: password.value,
            name: name.value,
          })
        : await loginWithPasswordAndEmail({
            email: email.value,
            password: password.value,
          })
    if (error) {
      showToast({ content: error })
    }
    setLoading(false)
  }

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.keyCode === 13) {
        signInUser()
      }
    }
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [signInUser])

  return (
    <Container>
      <TopBar>
        <Logo />
      </TopBar>

      <InputContainer>
        {invitation ? (
          invitation.error ? (
            <Header
              padding="10px 0"
              align="center"
              lineHeight="medium"
              variant="error"
              weight="medium"
            >
              Invitation no longer exists.
            </Header>
          ) : (
            <Header
              padding="10px 0"
              align="center"
              lineHeight="big"
              weight="medium"
            >
              You were invited by{' '}
              {invitation.invitedBy.name || invitation.invitedBy.email}.<br />
              <strong style={{ color: colors.main }}>
                Sign up to accept the invitation.
              </strong>
            </Header>
          )
        ) : accessoryID ? (
          <AccessoryView accessoryID={accessoryID} />
        ) : (
          <Header
            padding="10px 0"
            align="center"
            lineHeight="medium"
            weight="medium"
          >
            {tab === 'register' ? 'Create account' : 'Welcome back 👋'}
          </Header>
        )}

        <AuthButton
          onClick={loginUser('google')}
          title={
            tab === 'register' ? 'Sign up with Google' : 'Log in with Google'
          }
          icon={<GoogleLogo width={20} height={20} />}
        />

        <Divider>
          <DividerText>
            Or, {tab === 'register' ? 'sign up' : 'log in'} with your email
          </DividerText>
        </Divider>
        {tab === 'register' && (
          <FloatingInput
            shrink
            label="Full Name"
            placeholder="John Smith"
            name="name"
            type="name"
            value={name.value}
            error={name.error}
            onChange={(e) => setName({ value: e.target.value, error: '' })}
            style={{ marginTop: 12 }}
          />
        )}
        <FloatingInput
          shrink
          label="Email"
          placeholder="e.g. user@email.com"
          name="email"
          type="email"
          autoComplete="email"
          value={email.value}
          error={email.error}
          onChange={(e) => setEmail({ value: e.target.value, error: '' })}
          style={{ marginTop: 12 }}
        />
        <FloatingInput
          shrink
          label="Password"
          placeholder="********"
          name="password"
          type="password"
          autoComplete="new-password"
          value={password.value}
          error={password.error}
          onChange={(e) => setPassword({ value: e.target.value, error: '' })}
          style={{ marginTop: 12 }}
        />
        {tab === 'register' && (
          <FloatingInput
            shrink
            required
            label="Repeat Password"
            placeholder="********"
            name="password"
            type="password"
            autoComplete="new-password"
            value={repeatPassword.value}
            error={repeatPassword.error}
            style={{ marginTop: 12 }}
            onChange={(e) =>
              setRepeatPassword({ value: e.target.value, error: '' })
            }
          />
        )}
        {tab === 'login' && (
          <ForgotPassword>
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                showModal({ name: 'RESET_PASSWORD' })
              }}
            >
              Forgot password?
            </span>
          </ForgotPassword>
        )}
        <Button
          onClick={signInUser}
          loading={loading}
          style={{
            marginTop: 20,
            height: 50,
          }}
        >
          {tab === 'register' ? 'Sign up' : 'Log in'}
        </Button>
        {tab === 'register' ? (
          <BottomText>
            Already have an account?{' '}
            <strong
              style={{ color: colors.main, cursor: 'pointer' }}
              onClick={() => {
                setTab('login')
              }}
            >
              Log in
            </strong>
          </BottomText>
        ) : (
          <BottomText>
            Don't have an account?{' '}
            <strong
              style={{ color: colors.main, cursor: 'pointer' }}
              onClick={() => {
                setTab('register')
              }}
            >
              Sign up
            </strong>
          </BottomText>
        )}
        {tab === 'register' && (
          <Policy>
            By clicking "Sign up" you agree to our{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={config.TERMS_AND_CONDITIONS}
              style={{ fontWeight: '500' }}
            >
              terms
            </a>{' '}
            and{' '}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={config.PRIVACY_POLICY}
              style={{ fontWeight: '500' }}
            >
              privacy policy.
            </a>
          </Policy>
        )}
      </InputContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 auto;
  background-color: ${colors.mainHint};
  min-height: 100vh;
`

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1100px;
  margin-top: 32px;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 16px;
  }
`

const Header = styled(Title)`
  @media (max-width: 767px) {
    padding: 20px 0 8px 0;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
  width: 90%;
  max-width: 420px;
`

const ForgotPassword = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 13px;
  color: ${colors.secondary};
  margin-bottom: 4px;
`

const Divider = styled.div`
  position: relative;
  border-bottom: 1px solid ${colors.primary};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 0 30px 0;
`

const DividerText = styled.span`
  position: absolute;
  font-size: 13px;
  background-color: ${colors.mainHint};
  color: ${colors.primary};
  padding: 4px 12px;
  font-weight: 500;
`

const BottomText = styled.span`
  text-align: center;
  width: 100%;
  color: ${colors.primary};
  font-size: 13px;
  margin: 14px 0;
`

const Policy = styled.div`
  text-align: center;
  width: 100%;
  color: ${colors.secondary};
  font-size: 12px;
  margin: 16px 0;
`

export default Accounts
