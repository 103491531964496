import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import MobileSectionItem from '../MobileSectionItem'
import { arrayMoveElement } from '../../helpers/arrayMoveElement'

export default function Content({
  updateCard,
  card,
  showSave,
  isSaving,
  saveChanges,
  isMobile,
  showAddSection,
  openSectionModal,
  templateLockProps,
}) {
  const { sections = [] } = card
  const [openSection, setOpenSection] = useState()
  const containerRef = useRef()
  const [maxWidth, setMaxWidth] = useState()

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return
    const sourceIndex = source.index
    const destinationIndex = destination.index
    if (sourceIndex === destinationIndex) return
    card.sections = arrayMoveElement(
      card.sections,
      sourceIndex,
      destinationIndex
    )
    updateCard(card)
  }

  useEffect(() => {
    if (containerRef.current) {
      const container = document.getElementById('content-container')
      const { width } = containerRef.current.getBoundingClientRect()
      if (container) {
        setMaxWidth(width)
      }
    }
  }, [containerRef])

  return (
    <Container
      id="content-container"
      ref={containerRef}
      style={{ maxWidth: maxWidth || 'initial' }}
    >
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={() => {
          setOpenSection(null)
        }}
      >
        <Droppable droppableId="droppable">
          {(listProvided) => (
            <List {...listProvided.droppableProps} ref={listProvided.innerRef}>
              {maxWidth &&
                sections.map((section, index) => (
                  <MobileSectionItem
                    isDragDisabled={templateLockProps.isLocked('Add Content')}
                    templateLockProps={templateLockProps}
                    key={section.id}
                    section={section}
                    index={index}
                    isOpen={openSection === index}
                    card={card}
                    updateCard={updateCard}
                    showSave={showSave}
                    saveChanges={saveChanges}
                    isSaving={isSaving}
                    isMobile={isMobile}
                    showAddSection={showAddSection}
                    openSectionModal={openSectionModal}
                    onClick={() => {
                      if (openSection === index) {
                        setOpenSection(null)
                      } else {
                        setOpenSection(index)
                      }
                    }}
                  />
                ))}
              {listProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 22px;
  padding-bottom: 0;
  @media (max-width: 767px) {
    padding: 20px 16px;
  }
`

const List = styled.div`
  width: 100%;
`
