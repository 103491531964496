import React from 'react'
import styled from 'styled-components'
import { Draggable } from 'react-beautiful-dnd'
import {
  DeleteIcon,
  DraggableIcon,
  MoveDownIcon,
  MoveUpIcon,
} from '../../../assets/icons'
import { colors } from '../../../core/theme'
import { arrayMoveElement } from '../../../helpers/arrayMoveElement'
import FloatingInput from '../../FloatingInput'
import { InfoTooltip } from '../../InfoTooltip'
import { Label } from '../../Typography'
import RequiredSwitch from '../../RequiredSwitch'

export default function ContactFieldAttributes({
  field,
  section,
  updateCard,
  card,
  i,
}) {
  const { isRequired, select } = field

  const deleteField = (index) => (e) => {
    const result = window.confirm(
      `You are about to delete this field. Are you sure you want to continue?`
    )
    if (result) {
      e.stopPropagation()
      e.preventDefault()
      section.value = section.value.filter((_a, a) => a !== index)
      updateCard(card)
    }
  }

  const changeFieldPlaceholder = (e) => {
    field.placeholder = e.target.value
    updateCard(card)
  }

  const moveOption = (direction, index) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    const newIndex = direction === 'up' ? index - 1 : index + 1
    if (newIndex < 0) return
    if (newIndex >= select.length) return
    field.select = arrayMoveElement(select, index, newIndex)
    updateCard(card)
  }

  const deleteOption = (index) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    field.select = select.filter((_a, a) => a !== index)
    updateCard(card)
  }

  const changeOption = (index) => (e) => {
    select[index] = e.target.value
    field.select = [...select]
    updateCard(card)
  }

  const addOption = () => {
    if (select.length >= 500) {
      window.alert('You can have max 500 options')
      return
    }
    const index = select.length + 1
    field.select = [...select, `Option ${index}`]
    updateCard(card)
  }

  return (
    <Draggable key={field.id} draggableId={field.id} index={i}>
      {(provided, snapshot) => (
        <Container
          id={`section-${section.type}-${section.id}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style
          )}
        >
          <FieldContainer>
            <IconContainer style={{ marginRight: 10 }}>
              <DraggableIcon />
            </IconContainer>
            <FloatingInput
              onChange={changeFieldPlaceholder}
              value={field.placeholder}
              style={{ marginBottom: 0 }}
              inputProps={{
                style: {
                  borderRadius: 8,
                },
              }}
            />
            <RequiredContainer>
              <RequiredSwitch
                value={isRequired}
                onClick={() => {
                  if (field.isRequired) {
                    field.isRequired = false
                  } else {
                    field.isRequired = true
                  }
                  updateCard(card)
                }}
              />
            </RequiredContainer>
            <InfoTooltip
              icon={<DeleteIcon size={23} color="#5E6461" />}
              onClick={deleteField(i)}
              description="Delete field"
              direction="bottom"
              arrowPosition="top"
            />
          </FieldContainer>
          {select && (
            <SecondValuesContainer>
              {select.map((option, index) => (
                <AnswerContainer key={index}>
                  <FloatingInput
                    style={{ marginBottom: 0 }}
                    inputProps={{
                      style: {
                        borderRadius: 8,
                      },
                    }}
                    onChange={changeOption(index)}
                    value={option}
                  />
                  <InfoTooltip
                    icon={
                      <MoveDownIcon
                        size={22}
                        color={
                          index === select.length - 1
                            ? colors.disabled
                            : '#5E6461'
                        }
                      />
                    }
                    onClick={moveOption('down', index)}
                    description="Move option down"
                    style={{ margin: '14px 0 0 8px' }}
                    direction="bottom"
                    arrowPosition="top"
                  />
                  <InfoTooltip
                    icon={
                      <MoveUpIcon
                        size={22}
                        color={index === 0 ? colors.disabled : '#5E6461'}
                      />
                    }
                    onClick={moveOption('up', index)}
                    description="Move option up"
                    direction="bottom"
                    arrowPosition="top"
                    style={{ margin: '14px 0 0 8px' }}
                  />
                  <InfoTooltip
                    icon={<DeleteIcon size={22} color="#5E6461" />}
                    onClick={deleteOption(index)}
                    description="Delete option"
                    direction="bottom"
                    arrowPosition="top"
                    style={{ margin: '14px 0 0 8px' }}
                  />
                </AnswerContainer>
              ))}
              <div
                className="clickable-element"
                onClick={addOption}
                style={{ margin: '18px 4px 14px 4px' }}
              >
                <Label weight="medium">+ Add option</Label>
              </div>
            </SecondValuesContainer>
          )}
        </Container>
      )}
    </Draggable>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
  background-color: #f1f1f1;
  padding: 14px 10px 14px 12px;
  border-radius: 8px;
  box-sizing: border-box;
`

const RequiredContainer = styled.div`
  padding: 0 14px;
`

const SecondValuesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 60px;
  box-sizing: border-box;
  @media (max-width: 1280px) {
    padding-left: 0px;
  }
`

const AnswerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  margin-top: 18px;
`

const FieldContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
`

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})
