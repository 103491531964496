import React from 'react'
import styled from 'styled-components'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'
import Spinner from './Spinner'

export default function Commissions({ license, items }) {
  const { affiliateID } = license

  if (!items) {
    return (
      <>
        <Title>Commissions</Title>
        <SpinnerContainer>
          <Spinner size="24px" style={{ marginTop: 20 }} />
        </SpinnerContainer>
      </>
    )
  }

  return (
    <>
      <Title>Commissions</Title>
      <Container>
        <ItemsContainer>
          {items.length === 0 && <Empty>No commissions, yet.</Empty>}
          {items.length > 0 &&
            items.map((item) => {
              const {
                id,
                affiliate_id,
                number = '',
                commission,
                status = '',
                created,
              } = item
              // eslint-disable-next-line eqeqeq
              const level = affiliateID == affiliate_id ? 'Level 1' : 'Level 2'
              const type = number.includes('#') ? 'Accessory' : 'Subscription'
              let background = '#F5F5F5'
              if (status === 'approved') {
                background = '#E6EDE1'
              }
              if (status === 'rejected') {
                background = '#F4E4E4'
              }
              return (
                <Item key={id} style={{ backgroundColor: background }}>
                  <Label>{level}</Label>
                  <Label>{type}</Label>
                  <Label>{new Date(created).toLocaleDateString()}</Label>
                  <Label>${commission}</Label>
                  <Label>{firstLetterToUpper(status)}</Label>
                </Item>
              )
            })}
        </ItemsContainer>
      </Container>
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 32px;
  padding-top: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 18px 22px;
    padding-top: 0;
    overflow: auto;
  }
`

const SpinnerContainer = styled.div`
  padding-left: 32px;
  @media (max-width: 767px) {
    padding-left: 22px;
  }
`

const Title = styled.span`
  font-size: 18px;
  color: #110000;
  font-weight: 500;
  line-height: 1.5;
  padding: 28px 0 0 32px;
  @media (max-width: 767px) {
    font-size: 17px;
    padding: 18px 0 0 22px;
  }
`

const Empty = styled.span`
  font-size: 15.5px;
  color: #545454;
  margin-top: 20px;
  @media (max-width: 767px) {
    font-size: 14.5px;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  @media (max-width: 767px) {
    width: 524px;
  }
`

const Item = styled.div`
  display: flex;
  width: 100%;
  border-radius: 12px;
  margin-top: 16px;
  padding: 16px 0;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  font-size: 15px;
  color: #545454;
  font-weight: 500;
  @media (max-width: 767px) {
    font-size: 14.5px;
  }
`
