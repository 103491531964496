import React, { useState } from 'react'
import styled from 'styled-components'
import { StatusScreen } from 'konect-react'
import EmailNotificationsModal from '../modals/EmailNotificationsModal'
import IntegrationsModal from '../modals/IntegrationsModal'
import { ReloadIcon, SettingsIcon } from '../assets/icons'
import { Label } from './Typography'
import { colors } from '../core/theme'
import ColorInput from './ColorInput'
import { lightOrDark } from '../helpers/lightOrDark'
import FloatingInput from './FloatingInput'
import { websiteAddressValidator } from '../helpers/validators/websiteAddressValidator'
import { InfoTooltip } from './InfoTooltip'
import Switch from './Switch'

export default function FormAdvancedSettings({
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  section,
}) {
  const [openEmailSettings, setEmailOpenSettings] = useState(false)
  const [openIntegrationsSettings, setIntegrationsOpenSettings] =
    useState(false)
  const { button = {}, enablePopup } = section
  const { primaryColor = colors.main } = card
  const buttonsTheme = lightOrDark(button.backgroundColor || primaryColor)
  const {
    buttonTextColor = buttonsTheme === 'dark' ? colors.white : colors.black,
  } = card
  const isTemplate = window.location.pathname.includes('template')
  const buttonStyle = {
    backgroundColor: button.backgroundColor || primaryColor,
    color: button.buttonTextColor || buttonTextColor,
  }

  return (
    <>
      <Container>
        <Header>Advanced Settings</Header>
        <ColorInput
          value="Button Color"
          style={{ marginTop: 20, marginBottom: 0 }}
          inputStyle={{ backgroundColor: '#f5f5f5' }}
          pickerStyle={{ top: 0 }}
          color={buttonStyle.backgroundColor}
          changeToDefault={() => {
            delete button.backgroundColor
            section.button = { ...button }
            updateCard(card)
          }}
          onChangeComplete={({ hex }) => {
            button.backgroundColor = hex
            section.button = { ...button }
            updateCard(card)
          }}
        />
        <ColorInput
          value="Button Text Color"
          inputStyle={{ backgroundColor: '#f5f5f5' }}
          pickerStyle={{ top: 0 }}
          style={{ marginTop: 20, marginBottom: 0 }}
          color={buttonStyle.color}
          changeToDefault={() => {
            delete button.buttonTextColor
            section.button = { ...button }
            updateCard(card)
          }}
          onChangeComplete={({ hex }) => {
            button.buttonTextColor = hex
            section.button = { ...button }
            updateCard(card)
          }}
        />
        {!card.email && (
          <OptionWrapper>
            <Option
              onClick={() => {
                setEmailOpenSettings(true)
              }}
            >
              <Label
                weight="medium"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Email Notifications
              </Label>
              <SettingsContainer
                onClick={() => {
                  setEmailOpenSettings(true)
                }}
              >
                <SettingsIcon size={20} />
              </SettingsContainer>
            </Option>
          </OptionWrapper>
        )}
        {!isTemplate && (
          <OptionWrapper>
            <Option
              onClick={() => {
                setIntegrationsOpenSettings(true)
              }}
            >
              <Label
                weight="medium"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Integrations
              </Label>
              <SettingsContainer
                onClick={() => {
                  setIntegrationsOpenSettings(true)
                }}
              >
                <SettingsIcon size={20} />
              </SettingsContainer>
            </Option>
          </OptionWrapper>
        )}
        {!enablePopup && (
          <OptionWrapper>
            <Option>
              <Label
                weight="medium"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Redirect to Website
              </Label>
              <Switch
                value={section.redirect}
                onClick={() => {
                  if (!section.redirect) {
                    section.redirect = {}
                  } else {
                    delete section.redirect
                  }
                  updateCard(card)
                }}
              />
            </Option>
            {section.redirect && (
              <FloatingInput
                label="Redirect to"
                placeholder="https://konect.to"
                value={section.redirect.url}
                onChange={(e) => {
                  section.redirect.url = e.target.value
                  updateCard(card)
                }}
                style={{ marginTop: 12, marginBottom: 16 }}
                error={websiteAddressValidator(section.redirect.url)}
              />
            )}
          </OptionWrapper>
        )}
        <OptionWrapper style={{ paddingBottom: 20 }}>
          <Option>
            <Label
              weight="medium"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Customize Submit Message
            </Label>
          </Option>
          <StatusScreenContainer>
            <StatusScreen
              card={card}
              edit
              status="complete"
              section={section}
              style={{ paddingTop: 60, paddingBottom: 60 }}
            />
            <InputContainer style={{ marginTop: 16 }}>
              <FloatingInput
                label="Header"
                value={section.complete.title}
                style={{ marginBottom: 0 }}
                inputProps={{
                  style: {
                    borderRadius: 8,
                  },
                }}
                onChange={(e) => {
                  section.complete.title = e.target.value
                  updateCard(card)
                }}
              />
              <InfoTooltip
                icon={<ReloadIcon size={18} color={colors.lightgrey} />}
                onClick={() => {
                  section.complete.title = 'Thank you'
                  updateCard(card)
                }}
                direction="bottom"
                arrowPosition="top"
                style={{ margin: '0 0 0 8px' }}
                description="Reset to default"
              />
            </InputContainer>
            <InputContainer style={{ marginTop: 24 }}>
              <FloatingInput
                label="Description"
                value={section.complete.description}
                style={{ marginBottom: 0 }}
                inputProps={{
                  style: {
                    borderRadius: 8,
                  },
                }}
                onChange={(e) => {
                  section.complete.description = e.target.value
                  updateCard(card)
                }}
              />
              <InfoTooltip
                icon={<ReloadIcon size={18} color={colors.lightgrey} />}
                onClick={() => {
                  section.complete.description = 'Message sent successfully.'
                  updateCard(card)
                }}
                direction="bottom"
                arrowPosition="top"
                style={{ margin: '0 0 0 8px' }}
                description="Reset to default"
              />
            </InputContainer>
          </StatusScreenContainer>
        </OptionWrapper>
      </Container>
      {openEmailSettings && (
        <EmailNotificationsModal
          card={card}
          section={section}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setEmailOpenSettings(false)
          }}
        />
      )}
      {openIntegrationsSettings && (
        <IntegrationsModal
          card={card}
          section={section}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setIntegrationsOpenSettings(false)
          }}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
`

const Header = styled.span`
  font-size: 14.5px;
  font-weight: 500;
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 14px;
  margin-top: 20px;
  background-color: #f5f5f5;
`

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const StatusScreenContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 12px;
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
