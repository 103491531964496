import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { InfoFullIcon, SettingsIcon } from '../../assets/icons'
import { colors } from '../../core/theme'
import { licenseStatus } from '../../helpers/licenseStatus'
import EmailNotificationsModal from '../../modals/EmailNotificationsModal'
import { useActions, useStore } from '../../store'
import AdminSettings from '../CardEditItem/AdminSettings'
import FaviconUpload from '../FaviconUpload'
import FloatingInput from '../FloatingInput'
import PremiumBadge from '../PremiumBadge'
import Switch from '../Switch'
import { Label } from '../Typography'
import Integration from '../Integration'
import TemplateLock from '../TemplateLock'
import SwitchInput from '../SwitchInput'
import { InfoTooltip } from '../InfoTooltip'

export const PLUGINS = [
  {
    id: 'analytics',
    property: 'google_analytics',
    name: 'Google Analytics',
    icon: require('../../assets/icons/google_analytics_icon.svg').default,
  },
  {
    id: 'pixel',
    property: 'facebook_pixel',
    name: 'Meta Pixel',
    icon: require('../../assets/icons/facebook_pixel_icon.svg').default,
  },
]

export default function Settings({
  updateCard,
  card,
  showSave,
  isSaving,
  saveChanges,
  isTemplate,
  showLock,
  isLocked = () => {},
  onLock,
  showLockMessage,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { license, profile } = useStore()
  const { showModal } = useActions().modals
  const { isCardActive, isTrialActive, affiliateRef } = licenseStatus(
    license,
    card
  )
  const [openSettings, setOpenSettings] = useState(false)
  const isAdmin = profile.isGhost
  const { enableAffiliate = true } = card
  const showPoweredBy = card.poweredBy === 'hide' && isCardActive
  const showAffiliate = showPoweredBy && affiliateRef

  return (
    <>
      <Container>
        {isTemplate && (
          <SwitchInput
            label="Synchronize Changes"
            nodeLabel={
              <>
                Synchronize Changes{' '}
                <InfoTooltip
                  zIndex={1}
                  icon={<InfoFullIcon size={18} />}
                  direction="bottom"
                  arrowPosition="top"
                  style={{ margin: '0 0 0 6px' }}
                  description="All changes to the template will affect connected pages. Content of pages will be overwritten."
                />
              </>
            }
            value={card.synchronizeChanges}
            onChange={() => {
              if (!card.synchronizeChanges) {
                card.synchronizeChanges = true
                updateCard(card)
              } else {
                delete card.synchronizeChanges
                updateCard(card)
              }
            }}
          />
        )}
        {!isTemplate && (
          <FloatingInput
            label="Page Name"
            value={card.label}
            error={!card.label && "Name can't be empty."}
            onChange={(e) => {
              card.label = e.target.value
              updateCard(card)
            }}
          />
        )}
        {card.email && (
          <OptionWrapper>
            <Option>
              <Label
                weight="medium"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Email Notifications{' '}
                <PremiumBadge
                  type="feature"
                  direction="right"
                  arrowPosition="left"
                  size={14}
                  zIndex={1}
                  margin="0 8px"
                />
              </Label>
              <SettingsContainer
                onClick={() => {
                  if (!isCardActive && !isTrialActive) {
                    showModal({ name: 'TRIAL_ENDED' })
                    return
                  }
                  setOpenSettings(true)
                }}
              >
                <SettingsIcon size={20} />
              </SettingsContainer>
            </Option>
          </OptionWrapper>
        )}

        <OptionWrapper
          style={{
            marginTop: card.email ? 18 : 0,
            borderBottomLeftRadius: showAffiliate ? 0 : 8,
            borderBottomRightRadius: showAffiliate ? 0 : 8,
          }}
        >
          <Option>
            <Label
              weight="medium"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              Remove Konect Branding{' '}
              <PremiumBadge
                type="feature"
                direction="bottom"
                arrowPosition="top"
                size={14}
                zIndex={1}
                margin="0 8px"
                premiumFeature
                card={card}
              />
            </Label>
            <Switch
              style={{
                paddingRight:
                  showLock ||
                  (card.cardID && isLocked('Remove Konect Branding'))
                    ? '8px'
                    : 0,
              }}
              value={showPoweredBy}
              disabled={isLocked('Remove Konect Branding')}
              onContainerClick={() => {
                if (isLocked('Remove Konect Branding')) {
                  showLockMessage()
                }
              }}
              onClick={(e) => {
                if (!isCardActive) {
                  showModal({ name: 'TRIAL_ENDED' })
                  e.stopPropagation()
                  e.preventDefault()
                  return
                }
                if (card.poweredBy) {
                  delete card.poweredBy
                } else {
                  card.poweredBy = 'hide'
                }
                updateCard(card)
              }}
            />
          </Option>
          {(showLock ||
            (card.cardID && isLocked('Remove Konect Branding'))) && (
            <TemplateLock
              onLock={onLock}
              label="Remove Konect Branding"
              isLocked={isLocked}
            />
          )}
        </OptionWrapper>
        {showAffiliate && (
          <OptionWrapper
            style={{
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              borderTop: 'none',
            }}
          >
            <Option>
              <Label
                weight="medium"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                Enable Affiliate Program
              </Label>
              <Switch
                style={{
                  paddingRight:
                    showLock ||
                    (card.cardID && isLocked('Enable Affiliate Program'))
                      ? '8px'
                      : 0,
                }}
                disabled={isLocked('Enable Affiliate Program')}
                value={enableAffiliate}
                onContainerClick={() => {
                  if (isLocked('Enable Affiliate Program')) {
                    showLockMessage()
                  }
                }}
                onClick={() => {
                  if (enableAffiliate) {
                    delete card.affiliate
                    card.enableAffiliate = false
                  } else {
                    card.enableAffiliate = true
                  }
                  updateCard(card)
                }}
              />
            </Option>
            {(showLock ||
              (card.cardID && isLocked('Enable Affiliate Program'))) && (
              <TemplateLock
                onLock={onLock}
                label="Enable Affiliate Program"
                isLocked={isLocked}
              />
            )}
          </OptionWrapper>
        )}
        <FaviconUpload
          card={card}
          updateCard={updateCard}
          showLock={showLock}
          isLocked={isLocked}
          onLock={onLock}
          showLockMessage={showLockMessage}
        />
        <PluginsWrapper>
          <FloatingLabel>Plugins</FloatingLabel>
          <PluginsContainer>
            {PLUGINS.map((integration) => (
              <Integration
                key={integration.id}
                integration={integration}
                card={card}
                updateCard={updateCard}
                canUseIntegrations
                isMobile={isMobile}
                isLocked={isLocked('Plugins')}
                showLockMessage={showLockMessage}
              />
            ))}
          </PluginsContainer>
          {(showLock || (card.cardID && isLocked('Plugins'))) && (
            <TemplateLock
              onLock={onLock}
              label="Plugins"
              type="feature"
              isLocked={isLocked}
              style={{
                top: 'calc(50% - 8px)',
              }}
            />
          )}
        </PluginsWrapper>
        {isAdmin && <AdminSettings card={card} updateCard={updateCard} />}
      </Container>
      {openSettings && (
        <EmailNotificationsModal
          card={card}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setOpenSettings(false)
          }}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 24px 24px;
  padding-bottom: 0;
  @media (max-width: 767px) {
    padding: 24px 20px;
  }
`

const OptionWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 14px;
`

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`

const SettingsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

const PluginsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-top: 16px;
`

const PluginsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
  flex-shrink: 0;
  margin-top: 12px;
  border: 1px solid ${colors.border};
  padding: 16px 20px;
  padding-bottom: 0;
  border-radius: 8px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 16px 18px;
    padding-bottom: 0;
  }
`

const FloatingLabel = styled.div`
  position: absolute;
  left: 7px;
  top: 0;
  background-color: white;
  padding: 2px 8px 2px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  z-index: 1;
  color: #444;
`
