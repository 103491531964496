import React, { useState } from 'react'
import styled from 'styled-components'
import {
  MenuIcon,
  DuplicateIcon,
  DeleteIcon,
  NewTabIcon,
  SaveIcon,
} from '../assets/icons'
import { colors } from '../core/theme'
import { Dropdown, DropdownList } from './Dropdown'
import { useActions, useStore } from '../store'
import { usePermission } from '../hooks/usePermission'
import FolderPicker from './FolderPicker'
import { config } from '../core/config'

const CardMenu = ({ card, setFolder, folders, selectedFolder, trigger }) => {
  const { cards } = useStore().cards
  const { canEdit, showPermissionAlert } = usePermission()
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <Dropdown
      isVisible={isMenuVisible}
      placement="bottom-start"
      onClose={hideMenu}
      zIndex={9999999}
      triggerRenderer={({ ref }) => (
        <Container onClick={showMenu} ref={ref}>
          {trigger || <MenuIcon size={22} color={colors.primary} />}
        </Container>
      )}
    >
      <DropdownList
        onClose={hideMenu}
        items={[
          {
            content: 'Open page in new tab',
            onItemSelect: () => {
              const cardURL = card.slug
                ? `${config.CARDS_BASE_URL}/${card.slug}`
                : card.cardURL
              window.open(cardURL, '_blank')
            },
            icon: <NewTabIcon color={colors.primary} size={18} />,
          },
          {
            content: 'Duplicate page',
            onItemSelect: () => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              showModal({ name: 'DUPLICATE_CARD', card })
            },
            icon: <DuplicateIcon color={colors.primary} size={18} />,
          },
          {
            item: (
              <FolderPicker
                setFolder={(data) => {
                  setFolder(data)
                  hideMenu()
                }}
                folders={folders}
                selectedFolder={selectedFolder}
                card={card}
              />
            ),
          },
          {
            content: 'Create template from page',
            onItemSelect: () => {
              showModal({ name: 'CREATE_TEMPLATE_MODAL', card })
            },
            icon: <SaveIcon color={colors.primary} size={18} />,
          },
          {
            content: 'Delete page',
            color: colors.error,
            onItemSelect: () => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              if (cards.length <= 1) {
                showToast({
                  content: 'You must have at least one page.',
                })
                return
              }
              showModal({ name: 'DELETE_CARD', card })
            },
            icon: <DeleteIcon color={colors.error} size={18} />,
          },
        ]}
      />
    </Dropdown>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

export default CardMenu
