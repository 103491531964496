import { updateUserProfile, findUser, getProfile } from '../api/database-api'
import { uploadProfileAvatar } from '../api/storage-api'

const INITIAL_STATE = null

const updateProfile = async ({ state }, profile) => {
  await updateUserProfile(profile)
  state.profile = profile
}

const updateProfileAvatar = async ({ state, actions }, logo) => {
  const { license, profile } = state
  const { email, uid } = profile
  const updatedProfile = { ...profile }

  if (!uid) {
    const foundUser = await findUser(email)
    if (foundUser && foundUser.uid) {
      updatedProfile.uid = foundUser.uid
    }
  }

  updatedProfile.avatar = await uploadProfileAvatar(logo, updatedProfile)
  actions.profile.updateProfile(updatedProfile)
  actions.license.updateLicenseData({
    ...license,
    users: { ...license.users, [email]: updatedProfile },
  })
}

const getUserProfile = async ({ state }, { user }) => {
  const invitation = state.invitation
  const profile = await getProfile({
    user,
    invitation,
  })
  state.profile = profile
  return profile
}

const clear = ({ state }) => {
  state.profile = INITIAL_STATE
}

export default {
  state: INITIAL_STATE,
  actions: {
    updateProfile,
    updateProfileAvatar,
    getUserProfile,
    clear,
  },
}
