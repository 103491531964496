import React, { useState } from 'react'
import styled from 'styled-components'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  AddIcon,
  DropdownFieldIcon,
  MultilineFieldIcon,
  SingleFieldIcon,
} from '../../assets/icons'
import { colors } from '../../core/theme'
import { generateQuestionID } from '../../helpers/generateQuestionID'
import Button from '../Button'
import ContactFieldAttributes from '../CardEditItem/EditCard/ContactFieldAttributes'
import FloatingInput from '../FloatingInput'
import { Dropdown, DropdownList } from '../Dropdown'
import { arrayMoveElement } from '../../helpers/arrayMoveElement'
import FormAdvancedSettings from '../FormAdvancedSettings'
import { deepCloneObject } from '../../helpers/deepCloneObject'

export default function ContactForm({
  section,
  card,
  showSave,
  saveChanges,
  isSaving,
  templateLockProps,
  label,
  ...restProps
}) {
  const updateCard = (c) => {
    if (window.ks_update_popupModal_state && section.enablePopup) {
      window.ks_update_popupModal_state(deepCloneObject(section))
    }
    restProps.updateCard(c)
  }
  const { question, description, button = {}, enablePopup } = section

  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  const addField = (type) => {
    if (section.value.length >= 100) {
      window.alert('You can add max 100 fields.')
      return
    }
    const index = section.value.length + 1
    section.value = [
      ...section.value,
      {
        placeholder: 'Field ' + index,
        id: generateQuestionID(),
        ...type,
      },
    ]
    updateCard(card)
  }

  const onQuestionChange = (e) => {
    section.question = e.target.value

    updateCard(card)
  }

  const onDescriptionChange = (e) => {
    section.description = e.target.value
    updateCard(card)
  }

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination) return
    const sourceIndex = source.index
    const destinationIndex = destination.index
    if (sourceIndex === destinationIndex) return
    section.value = arrayMoveElement(
      section.value,
      sourceIndex,
      destinationIndex
    )
    updateCard(card)
  }

  return (
    <EditSectionContainer isLocked={templateLockProps.isLocked(label)}>
      <Header>Configure Contact Form</Header>
      <FloatingInput
        onChange={onQuestionChange}
        label="Header"
        value={question}
      />
      {!enablePopup && (
        <FloatingInput
          onChange={onDescriptionChange}
          label="Description"
          value={description}
          style={{ marginTop: 10 }}
        />
      )}
      <FloatingInput
        onChange={(e) => {
          button.label = e.target.value
          updateCard(card)
        }}
        value={button.label}
        label="Submit Button"
        style={{ marginTop: 10 }}
      />
      <Divider />
      <Row>
        Input fields
        <Required>REQUIRE</Required>
      </Row>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(listProvided) => (
            <List {...listProvided.droppableProps} ref={listProvided.innerRef}>
              {section.value.map((field, i) => (
                <ContactFieldAttributes
                  key={i}
                  i={i}
                  field={field}
                  section={section}
                  updateCard={updateCard}
                  card={card}
                />
              ))}
              {listProvided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>

      <Dropdown
        isVisible={isMenuVisible}
        placement="top-start"
        onClose={hideMenu}
        zIndex={9999999}
        triggerRenderer={({ ref }) => (
          <div
            onClick={showMenu}
            ref={ref}
            style={{
              display: 'flex',
              alignSelf: 'flex-start',
            }}
          >
            <Button
              leftIcon={<AddIcon color={colors.white} />}
              style={{ margin: '8px 4px 12px 4px' }}
              small
            >
              Add Field
            </Button>
          </div>
        )}
      >
        <DropdownList
          onClose={hideMenu}
          items={[
            {
              content: 'Single field',
              onItemSelect: () => {
                addField()
              },
              icon: <SingleFieldIcon />,
            },
            {
              content: 'Multiline field',
              onItemSelect: () => {
                addField({ multiline: true })
              },
              icon: <MultilineFieldIcon />,
            },
            {
              content: 'Dropdown field',
              onItemSelect: () => {
                addField({ select: ['Option 1', 'Option 2', 'Option 3'] })
              },
              icon: <DropdownFieldIcon />,
            },
          ]}
        />
      </Dropdown>
      <Divider />
      <FormAdvancedSettings
        card={card}
        section={section}
        updateCard={updateCard}
        showSave={showSave}
        saveChanges={saveChanges}
        isSaving={isSaving}
      />
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;
  padding-bottom: 24px;
  @media (max-width: 767px) {
    margin-top: 24px;
  }
  ${(props) =>
    props.isLocked
      ? ` ::after {
    content: '';
    z-index: 9999;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000010;
    border-radius: 4px;
  }`
      : ''}
`

const Header = styled.span`
  font-size: 14.5px;
  font-weight: 500;
  margin-bottom: 30px;
`

const Row = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Required = styled.span`
  font-size: 12.5px;
  font-weight: 500;
  color: #5e6461;
  padding-right: 46px;
`

const Divider = styled.div`
  width: 100%;
  background-color: ${colors.border};
  height: 1px;
  margin: 16px 0;
`

const List = styled.div`
  width: 100%;
`
