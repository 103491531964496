import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { DeleteIcon } from '../assets/icons'
import { Label } from './Typography'
import { ZENDESK_COLUMNS } from '../core/constants'
import Checkbox from './Checkbox'
import { returnCardQuestions } from '../helpers/returnCardQuestions'
import FloatingSelect from './FloatingSelect'

const ZendeskIntegrationDetails = ({ card, targetObject, updateCard }) => {
  const { zendesk } = targetObject
  if (!zendesk) return null
  const { questionsMap = [], target } = zendesk
  const formQuestions = returnCardQuestions(targetObject)

  const addEmptyMap = () => {
    if (!zendesk.questionsMap) {
      zendesk.questionsMap = [{}]
    } else {
      zendesk.questionsMap = [...zendesk.questionsMap, {}]
    }
    updateCard(card)
  }

  return (
    <Container>
      <Label padding="8px 4px" weight="medium">
        Are you creating Leads or Contacts?
      </Label>
      <CheckboxContainer>
        <Checkbox
          value={zendesk.target === 'Lead'}
          onChange={() => {
            if (zendesk.target !== 'Lead') {
              zendesk.target = 'Lead'
              updateCard(card)
            }
          }}
          label="Leads"
          radio
        />
        <Checkbox
          value={zendesk.target === 'Contact'}
          onChange={() => {
            if (zendesk.target !== 'Contact') {
              zendesk.target = 'Contact'
              updateCard(card)
            }
          }}
          label="Contacts"
          radio
        />
      </CheckboxContainer>
      <Label padding="18px 4px 12px 4px" weight="medium">
        Map fields to {target} properties:
      </Label>
      {questionsMap.map((mappedQuestion, i) => {
        const { question = '', column = { name: '' } } = mappedQuestion
        return (
          <MapContainer key={question}>
            <FloatingSelect
              empty="- Select field -"
              value={question}
              options={formQuestions
                .map((l) => l.question || l.placeholder)
                .map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  )
                })}
              style={{ marginBottom: 0 }}
              onChange={(e) => {
                zendesk.questionsMap[i] = {
                  ...zendesk.questionsMap[i],
                  question: e.target.value,
                }
                updateCard(card)
              }}
            />
            <Divider>{'->'}</Divider>
            <FloatingSelect
              empty="- Select property -"
              value={column.name}
              options={ZENDESK_COLUMNS.map((c) => c.name).map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              })}
              style={{ marginBottom: 0 }}
              onChange={(e) => {
                const foundColumn = ZENDESK_COLUMNS.find(
                  (c) => c.name === e.target.value
                )
                zendesk.questionsMap[i] = {
                  ...zendesk.questionsMap[i],
                  column: foundColumn,
                }
                updateCard(card)
              }}
            />
            <DeleteContainer
              onClick={() => {
                zendesk.questionsMap.splice(i, 1)
                updateCard(card)
              }}
            >
              <DeleteIcon size={18} />
            </DeleteContainer>
          </MapContainer>
        )
      })}
      <Label
        weight="medium"
        variant="primary"
        onClick={addEmptyMap}
        style={{
          marginTop: 4,
          marginBottom: 8,
          alignSelf: 'flex-start',
          cursor: 'pointer',
        }}
      >
        + Add another
      </Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const Divider = styled.div`
  color: ${colors.primary};
  font-size: 14px;
  font-weight: 500;
  margin: 0 6px;
  white-space: nowrap;
`

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 14px;
`

const DeleteContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: fill 200ms ease-out;
  :hover {
    fill: ${colors.red};
  }
`

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export default ZendeskIntegrationDetails
