import React from 'react'
import styled from 'styled-components'
import FloatingInput from '../FloatingInput'

export default function Description({ section, card, updateCard }) {
  const { question } = section

  const onQuestionChange = (e) => {
    section.question = e.target.value
    updateCard(card)
  }

  return (
    <EditSectionContainer>
      <FloatingInput
        onChange={onQuestionChange}
        label="Header *"
        value={question}
      />
      <FloatingInput
        multiline
        onChange={(e) => {
          section.value = e.target.value
          updateCard(card)
        }}
        label="Description"
        value={section.value}
        style={{ marginTop: 8 }}
      />
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;
`
