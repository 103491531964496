import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { colors, animations } from '../core/theme'
import { Dropdown } from './Dropdown'
import Tag from './Tag'
import { useStore } from '../store'
import { Label } from './Typography'

const AddTagButton = ({ card, onTagAdd, onClose }) => {
  const { license } = useStore()

  const cardTagNames = (card.tags || []).map((t) => t.label)
  const licenseTags = license.tags
    ? license.tags.filter((t) => !cardTagNames.includes(t.label))
    : []

  const [hasDropdown, setDropdownVisibility] = useState(false)

  const hideDropdown = () => setDropdownVisibility(false)
  const showDropdown = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (hasDropdown) setDropdownVisibility(false)
    else setDropdownVisibility(true)
  }

  const addTag = (tag) => (e) => {
    e.stopPropagation()
    e.preventDefault()
    onTagAdd(tag)
    hideDropdown()
  }

  return (
    <Dropdown
      isVisible={hasDropdown}
      placement="bottom-start"
      onClose={hideDropdown}
      triggerRenderer={({ ref }) => (
        <AddTag onClick={showDropdown} ref={ref}>
          + Add Tag
        </AddTag>
      )}
    >
      <DropdownContainer>
        <TagsContainer>
          {licenseTags.length === 0 && (
            <Label variant="secondary">All tags used</Label>
          )}
          {licenseTags.map((tag) => (
            <Tag
              onClick={addTag(tag)}
              key={tag.label}
              color={tag.color}
              label={tag.label}
            />
          ))}
        </TagsContainer>
        <Divider />
        <CreateTag
          to="/tags-manager"
          onClick={(e) => {
            e.stopPropagation()
            onClose()
          }}
        >
          <Label variant="primary" weight="medium">
            Manage Tags
          </Label>
        </CreateTag>
      </DropdownContainer>
    </Dropdown>
  )
}

const AddTag = styled.div`
  cursor: pointer;
  color: ${colors.main};
  margin: 4px;
  font-size: 13px;
  font-weight: 500;
  white-space: pre;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.6;
  }
`

const CreateTag = styled(NavLink)`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-top: 4px;
`

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(66, 77, 87, 0.05);
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 260px;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  &:focus {
    outline: none;
  }
`

const TagsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${colors.border};
  margin: 12px 0 8px 0;
`

export default AddTagButton
