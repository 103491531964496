import { getAnalyticsForReports } from '../api/database-api'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'
import { calculatePercentageInArray } from '../helpers/calculatePercentageInArray'
import { sortCountsInArray } from '../helpers/sortCountsInArray'
import { dayDifferenceBetweenDays } from '../helpers/dayDifferenceBetweenDays'
import { getFormattedDate } from '../helpers/days'

const INITIAL_REPORT = {
  insights: {
    summary: {},
    interactions: {},
    activity: [],
    devices: [],
    platforms: [],
    browsers: [],
    locations: [],
  },
}

const prepareReport = async (_, { card, dateFilter }) => {
  const report = INITIAL_REPORT
  let daysToCheck = dayDifferenceBetweenDays(
    new Date(dateFilter.from),
    new Date(dateFilter.to || new Date().getTime())
  )
  if (daysToCheck > 100) {
    daysToCheck = 100
  }

  const days = {}
  for (let i = 0; i <= daysToCheck; i++) {
    const dayBefore = new Date(dateFilter.to || new Date().getTime())
    dayBefore.setDate(dayBefore.getDate() - i)
    const dayFormat = getFormattedDate(dayBefore)
    days[dayFormat] = { date: dayBefore.toLocaleDateString() }
  }

  const analyticsResponses = await getAnalyticsForReports({
    card,
    days,
  })

  const activity = []
  const devices = []
  const platforms = []
  const browsers = []
  const locations = []
  const interactions = {}
  let starts = 0

  analyticsResponses.forEach((dayData) => {
    const visitsCount = Number.parseInt(dayData.visits || 0, 10)
    let interactionsCount = 0
    starts += visitsCount

    Object.keys(dayData).forEach((key) => {
      const count = Number.parseInt(dayData[key] || 0, 10)

      // INTERACTIONS
      if (key.startsWith('i-')) {
        const interaction = key.replace('i-', '')
        if (interactions[interaction]) {
          interactions[interaction].count += count
        } else {
          interactions[interaction] = { title: interaction, count }
        }
        interactionsCount += count
      }

      // DEVICE
      else if (key.startsWith('d-')) {
        const device = firstLetterToUpper(key.replace('d-', ''))
        if (device) {
          const found = devices.find((d) => d.name === device)
          if (found) found.count += count
          else {
            devices.push({ name: device, count })
          }
        }
      }

      // PLATFORM
      else if (key.startsWith('p-')) {
        const platform = key.replace('p-', '')
        if (platform) {
          const found = platforms.find((p) => p.name === platform)
          if (found) found.count += count
          else {
            platforms.push({ name: platform, count })
          }
        }
      }

      // BROWSER
      else if (key.startsWith('b-')) {
        const browser = key.replace('b-', '')
        if (browser) {
          const found = browsers.find((b) => b.name === browser)
          if (found) found.count += count
          else {
            browsers.push({ name: browser, count })
          }
        }
      }

      // LOCATIONS
      else if (key.startsWith('c-')) {
        const [country, countryCode] = key.replace('c-', '').split('-')
        if (country) {
          const found = locations.find((l) => l.country === country)
          if (found) found.count += count
          else {
            locations.push({ country, code: countryCode, count })
          }
        }
      }
    })

    activity.push({
      name: dayData.date,
      Interactions: interactionsCount,
      Visits: visitsCount,
      count: interactionsCount > visitsCount ? interactionsCount : visitsCount,
    })
  })

  report.insights.activity = activity.reverse()

  // INTERACTIONS
  report.insights.interactions = sortCountsInArray(
    calculatePercentageInArray(Object.values(interactions))
  )

  // SUMMARY
  let responses = 0
  report.insights.interactions.forEach((a) => {
    if (a.count) {
      responses += a.count
    }
  })

  const summaryCard = {
    starts,
    responses,
    completion_rate:
      starts === 0
        ? 0
        : Number.parseFloat((responses / starts) * 100).toFixed(2),
  }

  report.insights.summary = summaryCard

  // DEVICES
  report.insights.devices = sortCountsInArray(
    calculatePercentageInArray(devices)
  )
  // PLATFORMS
  report.insights.platforms = sortCountsInArray(
    calculatePercentageInArray(platforms)
  )
  // BROWSERS
  report.insights.browsers = sortCountsInArray(
    calculatePercentageInArray(browsers)
  )
  // LOCATIONS
  report.insights.locations = sortCountsInArray(
    calculatePercentageInArray(locations)
  ).slice(0, 8)

  return report
}

export default {
  actions: {
    prepareReport,
  },
}
