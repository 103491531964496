import React, { useEffect, useRef, useState } from 'react'
import SketchPicker from 'react-color/lib/components/sketch/Sketch'
import styled from 'styled-components'
import { colors } from '../core/theme'
import TemplateLock from './TemplateLock'

export default function DesignColorPicker({
  onChangeComplete,
  color = '',
  presetColors = [],
  showLock,
  isLocked = () => {},
  onLock,
  label,
  card,
  showLockMessage,
}) {
  const [showPicker, setPickerState] = useState(false)
  const pickerRef = useRef()

  const changePickerState = () => {
    if (isLocked(label)) {
      showLockMessage()
      return
    }
    setPickerState(!showPicker)
  }

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (pickerRef.current) {
        if (!pickerRef.current.contains(event.target) && showPicker) {
          setPickerState(false)
        }
      }
    }
    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [pickerRef.current, showPicker])

  return (
    <Container>
      <ColorContainer onClick={changePickerState} color={color} />
      {showPicker && (
        <PickerContainer
          ref={pickerRef}
          onMouseLeave={() => setPickerState(false)}
        >
          <SketchPicker
            color={color}
            disableAlpha
            onChangeComplete={onChangeComplete}
            presetColors={presetColors}
          />
        </PickerContainer>
      )}
      {(showLock || (card.cardID && isLocked(label))) && (
        <TemplateLock onLock={onLock} label={label} isLocked={isLocked} />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 6px;
  margin: ${(props) => props.margin || 0};
  background-color: ${colors.white};
`

const ColorContainer = styled.div`
  cursor: pointer;
  width: 110px;
  height: 40px;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: ${(props) => props.color};
  border: 1px solid ${colors.border};
`

const PickerContainer = styled.div`
  position: absolute;
  z-index: 9999999;
  top: 40px;
  right: 0;
`
