import React, { useState } from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import { useDropzone } from 'react-dropzone'
import { colors } from '../../core/theme'
import Avatar from '../../components/Avatar'
import Attribute from '../../components/Attribute'
import { usePermission } from '../../hooks/usePermission'
import { useActions, useStore } from '../../store'
import { Paragraph, Subheading, Title } from '../../components/Typography'
import { TopBar } from '../../components/TopBar'

const Profile = () => {
  const { profile } = useStore()
  const { permission } = usePermission()
  const { showModal } = useActions().modals
  const { showToast } = useActions().toasts
  const { updateProfileAvatar } = useActions().profile

  const { name, email, createdTimestamp, license } = profile
  const [uploading, setUploading] = useState(false)

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (uploading) return

    if (rejectedFiles.length > 0) {
      showToast({ content: 'File exceeded max 5mb size.' })
      return
    }

    setUploading(true)

    const uploadedLogo = acceptedFiles[0]
    if (!uploadedLogo) return

    try {
      await updateProfileAvatar(uploadedLogo)
    } catch (e) {
      showToast({
        content: 'Something went wrong. Please contact our support.',
      })
    }

    setUploading(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    maxSize: 5000000,
  })

  return (
    <Container>
      <Header>My Profile</Header>
      <AvatarContainer>
        <Dropzone {...getRootProps()} style={{ opacity: uploading ? 0.5 : 1 }}>
          <Input {...getInputProps()} />
          <Avatar size={100} profile={profile} />
        </Dropzone>
      </AvatarContainer>
      <Details>
        <Subheading lineHeight="big">{name}</Subheading>
        <Title lineHeight="medium" variant="secondary">
          {email}
        </Title>
        <DetailsWrapper>
          <Subheading padding="8px 0">Details</Subheading>
          <Attribute
            label="Member since"
            value={new Date(createdTimestamp).toLocaleDateString()}
          />
          <Attribute label="Permission" value={permission} />
          <Attribute label="License" value={license} />
        </DetailsWrapper>
      </Details>
      <Buttons>
        <NavLink to="/tags-manager">
          <ActionButton>
            <Paragraph weight="medium" variant="primary">
              Tags Manager
            </Paragraph>
          </ActionButton>
        </NavLink>
        <ActionButton onClick={() => showModal({ name: 'LOG_OUT' })}>
          <Paragraph weight="medium" variant="error">
            Logout
          </Paragraph>
        </ActionButton>
      </Buttons>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: center;
  border-left: 1px solid ${colors.border};
  background-color: white;
  min-width: 280px;
  @media (min-width: 1280px) {
    max-width: 470px;
  }
  @media (max-width: 1280px) {
    max-width: 400px;
  }
  @media (max-width: 767px) {
    height: auto;
    border-left: none;
    padding-bottom: 64px;
  }
`

const AvatarContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 24px;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.8;
  }
  :active {
    opacity: 0.5s;
  }
`

const Details = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
`

const Header = styled(TopBar)`
  justify-content: flex-start;
  @media (max-width: 767px) {
    border-top: 1px solid ${colors.border};
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const ActionButton = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  padding: 0 16px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.border};
  transition: opacity 200ms ease-out;
  text-decoration: none;
  :hover {
    opacity: 0.8;
  }
`

const Input = styled.input`
  display: none;
  position: relative;
  width: 0;
  height: 0;
`

const Dropzone = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default Profile
