import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { licenseStatus } from '../helpers/licenseStatus'
import Toast from '../components/Toast/Toast'
import { useStore, useActions } from '../store'
import { generateUpdateBillingSession } from '../api/billing-api'
import { queryStringToJSON } from '../helpers/queryStringToJSON'
import { Label, Subheading } from '../components/Typography'
import Invoices from '../components/Invoices'
import { usePermission } from '../hooks/usePermission'
import PlanSwitch from '../components/PlanSwitch'
import Button from '../components/Button'
import PricingPlan from '../components/PricingPlan'
import CheckItem from '../components/CheckItem'
import { BILLING_PLANS } from '../core/constants'
import PremiumPages from '../components/PremiumPages'

const Billing = () => {
  const { license, profile } = useStore()
  const { showToast } = useActions().toasts
  const { showModal } = useActions().modals
  const paymentFailed = window.location.pathname.includes('error')
  const parameters = queryStringToJSON()
  const { canEdit, showPermissionAlert } = usePermission()
  const {
    isActive,
    isTrialActive,
    trialDaysLeft,
    subscriptionName,
    subscriptionType,
    isCustom,
  } = licenseStatus(license)
  const [type, setType] = useState(subscriptionType || 'monthly') // monthly / yearly
  const [tab, setTab] = useState(isActive && !isCustom ? 'premium' : 'plans') // plans, premium

  useEffect(() => {
    ;(async () => {
      if (
        parameters['update-card'] &&
        profile &&
        license.customerId &&
        license.subscriptionId
      ) {
        try {
          const { data } = await generateUpdateBillingSession({
            customerEmail: profile.email,
            license: profile.license,
            customerId: license.customerId,
            subscriptionId: license.subscriptionId,
          })

          const { url } = data
          if (!url) {
            throw new Error('Something went wrong. Please contact our support.')
          }

          window.location.href = url
        } catch (e) {
          showToast({ content: e.message })
        }
      }
    })()
  }, [parameters, profile, license])

  return (
    <Container>
      {paymentFailed && (
        <Toast
          variant="error"
          content="Billing failed. Please contact our support."
          style={{ margin: '12px 0' }}
        />
      )}
      <Subheading weight="bold">Billing</Subheading>
      <ButtonsContainer>
        <TabButton selected={tab === 'plans'} onClick={() => setTab('plans')}>
          All Plans
        </TabButton>
        <TabButton
          selected={tab === 'premium'}
          onClick={() => setTab('premium')}
        >
          Premium Pages
        </TabButton>
      </ButtonsContainer>
      {!isActive && tab === 'plans' && (
        <PlanSwitch setType={setType} type={type} />
      )}
      {tab === 'plans' && (
        <>
          <PlansContainer>
            <PricingPlan
              name="Starter"
              description="All the essentials you need to create professional pages."
              price="Free"
              button={
                isActive ? (
                  subscriptionName === 'custom' ? (
                    <div />
                  ) : (
                    <PlanLabel />
                  )
                ) : isTrialActive ? (
                  <PlanLabel>
                    <Strong>{trialDaysLeft} days</Strong> of free trial left.
                  </PlanLabel>
                ) : (
                  <PlanLabel>
                    <strong style={{ color: colors.red }}>
                      Free trial ended!
                    </strong>
                  </PlanLabel>
                )
              }
              features={
                <>
                  <CheckItem label="Contact info" />
                  <CheckItem label="Custom username" />
                  <CheckItem label="Profile image" />
                  <CheckItem label="Banner" />
                  <CheckItem label="Analytics" />
                  <CheckItem label="Buttons" />
                  <CheckItem label="Social links" />
                </>
              }
            />
            <PricingPlan
              name="Premium"
              description="Upgrade your pages with features that guarantee to grow your business."
              price={
                <span>
                  ${BILLING_PLANS[0].price[type]}{' '}
                  <span className="price-description">per page / {type}</span>
                </span>
              }
              button={
                subscriptionName === 'custom' ? (
                  <div />
                ) : (
                  <Button
                    onClick={() => {
                      if (!canEdit) {
                        showPermissionAlert()
                        return
                      }

                      if (isActive) {
                        showModal({ name: 'CANCEL_SUBSCRIPTION' })
                        return
                      }

                      showModal({
                        name: 'CREATE_SUBSCRIPTION_MODAL',
                        price: BILLING_PLANS[0].price[type],
                        subscriptionName: 'premium',
                        subscriptionType: type,
                      })
                    }}
                    outline={isActive}
                    borderColor="transparent"
                    color={isActive ? colors.red : ''}
                    style={{
                      height: '50px',
                      padding: '0',
                      fontWeight: isActive ? '600' : '700',
                      backgroundColor: isActive ? 'transparent' : '',
                      boxShadow: isActive ? 'none' : '',
                    }}
                  >
                    {isActive ? 'Cancel Subscription' : 'Upgrade'}
                  </Button>
                )
              }
              features={
                <>
                  <CheckItem label="Everything in Starter +" bold />
                  <CheckItem label="Company logo" />
                  <CheckItem label="Schedule meetings" />
                  <CheckItem label="Contact lead forms" />
                  <CheckItem label="Images" />
                  <CheckItem label="Videos" />
                  <CheckItem label="Integrations" />
                </>
              }
            />
            <PricingPlan
              name="Scale"
              description="The perfect option for companies interested in managing over 100+ pages."
              price="Custom"
              button={
                subscriptionName === 'custom' ? (
                  <PlanLabel>
                    <strong style={{ color: colors.main }}>Your Plan</strong>
                  </PlanLabel>
                ) : (
                  <a
                    href="https://savvycal.com/konect/scale"
                    rel="noopener noreferrer"
                    target="_blank"
                    style={{ width: '100%' }}
                  >
                    <Button
                      outline
                      style={{
                        height: '50px',
                        padding: '0',
                        fontWeight: '700',
                        width: '100%',
                      }}
                    >
                      Talk to Sales
                    </Button>
                  </a>
                )
              }
              features={
                <>
                  <CheckItem label="Everything in Premium +" bold />
                  <CheckItem label="Custom integrations" />
                  <CheckItem label="Dedicated customer success manager" />
                </>
              }
            />
          </PlansContainer>
          {license.customerId && license.subscriptionId && (
            <Label lineHeight="medium" margin="0 0 8px 0">
              Want to update your billing card details?{' '}
              <span
                onClick={async () => {
                  if (!canEdit) {
                    showPermissionAlert()
                    return
                  }
                  try {
                    const { data } = await generateUpdateBillingSession({
                      customerEmail: profile.email,
                      license: profile.license,
                      customerId: license.customerId,
                      subscriptionId: license.subscriptionId,
                    })
                    const { url } = data
                    if (!url) {
                      throw new Error(
                        'Something went wrong. Please contact our support.'
                      )
                    }
                    window.location.href = url
                  } catch (e) {
                    showToast({ content: e.message })
                  }
                }}
                className="clickable-element"
              >
                Click here.
              </span>
            </Label>
          )}
          {license.customerId && <Invoices license={license} />}
        </>
      )}
      {tab === 'premium' && (
        <PremiumPages type={type} license={license} isCustom={isCustom} />
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 36px 40px;
  position: relative;
  overflow: auto;
  background-color: ${colors.background};
  @media (max-width: 767px) {
    padding: 20px;
    padding-bottom: 100px;
    overflow: visible;
  }
`

const Strong = styled.strong`
  color: ${colors.main};
  padding-right: 4px;
`

const ButtonsContainer = styled.div`
  display: flex;
  border-radius: 50px;
  border: 1px solid #c3cdd3;
  background-color: #ededed;
  margin-top: 24px;
  margin-bottom: 16px;
`

const TabButton = styled(Button)`
  border-radius: 50px;
  border: none;
  width: 160px;
  background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
  box-shadow: none;
  color: ${colors.primary};
  :hover {
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
    box-shadow: none;
    outline: 0;
  }
  :focus {
    outline: 0;
    box-shadow: none;
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
  }
  :active {
    outline: 0;
    opacity: ${(props) => (props.loading ? 0.6 : 0.7)};
    box-shadow: none;
  }
`

const PlansContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  padding: 24px 0;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 16px 0;
  }
`

const PlanLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  text-align: center;
  font-weight: 700;
  height: 50px;
  width: 100%;
  @media (max-width: 1000px) {
    height: auto;
  }
`

export default Billing
