import ShareCardModal from './ShareCardModal'
import CancelSubscriptionModal from './CancelSubscriptionModal'
import LogoutModal from './LogoutModal'
import DeleteResponseModal from './DeleteResponseModal'
import CreateTagModal from './CreateTagModal'
import ChangePermissionModal from './ChangePermissionModal'
import RemoveEmployeeModal from './RemoveEmployeeModal'
import InviteEmployeeModal from './InviteEmployeeModal'
import DuplicateCardModal from './DuplicateCardModal'
import DeleteCardModal from './DeleteCardModal'
import CreateCardModal from './CreateCardModal'
import ResetPasswordModal from './ResetPasswordModal'
import AddLinkModal from './AddLinkModal'
import GoogleAnalyticsModal from './GoogleAnalyticsModal'
import FacebookPixelModal from './FacebookPixelModal'
import CreateTemplateModal from './CreateTemplateModal'
import DeleteAccessoryModal from './DeleteAccessoryModal'
import CreateSubscriptionModal from './CreateSubscriptionModal'
import AddSlotsModal from './AddSlotsModal'
import DeleteSlotModal from './DeleteSlotModal'
import HowToVideoModal from './HowToVideoModal'
import TrialEndedModal from './TrialEndedModal'
import ContactDetailsModal from './ContactDetailsModal'
import CropImageModal from './CropImageModal'
import DuplicateTemplateModal from './DuplicateTemplateModal'

export const modals = {
  SHARE_CARD: ShareCardModal,
  CREATE_CARD: CreateCardModal,
  DUPLICATE_CARD: DuplicateCardModal,
  DUPLICATE_TEMPLATE: DuplicateTemplateModal,
  DELETE_CARD: DeleteCardModal,
  DELETE_RESPONSE: DeleteResponseModal,
  CREATE_TAG: CreateTagModal,
  CHANGE_USER_PERMISSION: ChangePermissionModal,
  REMOVE_EMPLOYEE: RemoveEmployeeModal,
  INVITE_EMPLOYEE: InviteEmployeeModal,
  CANCEL_SUBSCRIPTION: CancelSubscriptionModal,
  LOG_OUT: LogoutModal,
  RESET_PASSWORD: ResetPasswordModal,
  ADD_LINK_MODAL: AddLinkModal,
  GOOGLE_ANALYTICS: GoogleAnalyticsModal,
  FACEBOOK_PIXEL: FacebookPixelModal,
  CREATE_TEMPLATE_MODAL: CreateTemplateModal,
  DELETE_ACCESSORY_MODAL: DeleteAccessoryModal,
  CREATE_SUBSCRIPTION_MODAL: CreateSubscriptionModal,
  ADD_SLOTS_MODAL: AddSlotsModal,
  DELETE_SLOT_MODAL: DeleteSlotModal,
  HOW_TO_VIDEO: HowToVideoModal,
  TRIAL_ENDED: TrialEndedModal,
  CONTACT_DETAILS: ContactDetailsModal,
  CROP_IMAGE: CropImageModal,
}
