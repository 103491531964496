import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import KonectCard from 'konect-react'
import styled from 'styled-components'
import LoadingError from '../components/LoadingError'
import { colors } from '../core/theme'
import TimeAgo from '../components/TimeAgo'
import { getCardById } from '../api/database-api'
import Attribute from '../components/Attribute'
import CardDetails from '../components/CardDetails'
import PageLoader from '../components/PageLoader'
import BackButton from '../components/BackButton'
import InboxCardDetailsMenu from '../components/InboxCardDetailsMenu'
import { useStore } from '../store'
import { deepCloneObject } from '../helpers/deepCloneObject'
import Avatar from '../components/Avatar'
import { Row } from '../components/Layout'
import { Title, Paragraph } from '../components/Typography'
import { TopBar } from '../components/TopBar'

const InboxCardDetails = () => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { profile } = useStore()
  const { inboxCards } = useStore().inbox

  const cardID = window.location.pathname.split('/')[2]
  const responseID = window.location.pathname.split('/')[3]

  const [selectedCard, updateCard] = useState({})
  const [error, setError] = useState(false)

  const { label, completedTimestamp, customer = {} } = selectedCard

  useEffect(() => {
    const cards = inboxCards[cardID] || []
    const foundCard = cards.filter((temp) => temp.id === responseID)[0] || {}

    if (!foundCard.id && profile.license) {
      ;(async () => {
        try {
          const result = await getCardById(responseID, profile.license)
          const card = result.data() || {}
          updateCard(card)
          if (!card.cardID) {
            setError(true)
          }
        } catch (e) {
          setError(true)
        }
      })()

      return
    }

    updateCard(deepCloneObject(foundCard))
  }, [responseID, cardID, inboxCards, profile.license])

  if (error) {
    return (
      <LoadingError
        error={
          <span>
            Page does not exist or
            <br />
            the link is broken
          </span>
        }
      />
    )
  }

  if (!selectedCard.cardID) {
    return <PageLoader />
  }

  const pathToCard = isMobile ? `/${cardID}/report` : `/${cardID}`

  return (
    <Container>
      <PreviewContainer>
        {!isMobile && (
          <TopBar style={{ justifyContent: 'space-between' }}>
            <BackButton backTo={pathToCard} />
          </TopBar>
        )}
        <CardOverflowContainer>
          <CardContainer>
            <KonectCard card={selectedCard} isPreview />
          </CardContainer>
        </CardOverflowContainer>
      </PreviewContainer>

      <DetailsContainer>
        {isMobile && (
          <TopBar style={{ justifyContent: 'space-between' }}>
            <BackButton backTo={pathToCard} />
          </TopBar>
        )}
        <TopBar style={{ justifyContent: 'space-between', zIndex: 9999 }}>
          <Paragraph weight="medium">Details</Paragraph>
          <InboxCardDetailsMenu card={selectedCard} />
        </TopBar>
        <OverflowContainer>
          <DetailsWrapper>
            <Row align="center">
              <div>
                <Avatar size={56} profile={selectedCard.customer} />
              </div>
              <DataContainer>
                <Title padding="4px 0" weight="medium">
                  {customer.name || label}
                </Title>
                {customer.email && (
                  <Attribute label="Email" value={customer.email} />
                )}
                {completedTimestamp && (
                  <Paragraph variant="secondary">
                    {selectedCard.completed === false
                      ? 'Abandoned'
                      : 'Completed'}
                    : <TimeAgo datetime={completedTimestamp} />
                  </Paragraph>
                )}
              </DataContainer>
            </Row>
            <CardDetails card={selectedCard} />
          </DetailsWrapper>
        </OverflowContainer>
      </DetailsContainer>
    </Container>
  )
}

const OverflowContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
  height: 100vh;
  @media (max-width: 767px) {
    flex-direction: column-reverse;
    height: auto;
    padding-bottom: 64px;
  }
`

const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 5;
  @media (max-width: 767px) {
    display: none;
  }
`

const CardOverflowContainer = styled.div`
  overflow: auto;
  height: 100%;
`

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  border-left: 1px solid ${colors.border};
  @media (min-width: 1280px) {
    max-width: 470px;
  }
  @media (max-width: 1280px) {
    max-width: 400px;
  }
  @media (max-width: 767px) {
    border-left: none;
    border-bottom: 1px solid ${colors.border};
  }
`

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  box-sizing: border-box;
  padding: 16px 20px;
`

const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  padding: 0 0 0 20px;
`

export default InboxCardDetails
