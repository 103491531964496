import { getLicenseAccessories, updateAccessoryData } from '../api/database-api'

const INITIAL_STATE = null

async function getAccessories({ state }) {
  const { license } = state
  const accessories = await getLicenseAccessories(license.license)
  accessories.sort((a, b) => {
    return b.activatedTimestamp - a.activatedTimestamp
  })
  state.accessories = accessories
  return accessories
}

async function updateAccessory({ state }, accessory) {
  const accessories = state.accessories || []
  await updateAccessoryData(accessory)
  state.accessories = accessories.map((a) => {
    if (a.id === accessory.id) {
      return accessory
    }
    return a
  })
}

async function activateAccessory({ state, actions }, accessory) {
  const { license, profile } = state
  const newAccessory = {
    ...accessory,
    status: 'active',
    license: license.license,
    activatedTimestamp: new Date().getTime(),
    activatedBy: {
      email: profile.email || '',
      name: profile.name || '',
    },
  }
  await updateAccessoryData(newAccessory)
  await actions.accessories.getAccessories()
}

async function removeAccessory({ state }, accessory) {
  const accessories = state.accessories || []
  await updateAccessoryData({
    ...accessory,
    status: 'not-active',
    cardID: null,
    license: null,
    activatedBy: null,
    activatedTimestamp: null,
    title: null,
    uid: null,
  })
  state.accessories = accessories.filter((a) => a.id !== accessory.id)
}

const clear = ({ state }) => {
  state.accessories = INITIAL_STATE
}

export default {
  state: INITIAL_STATE,
  actions: {
    getAccessories,
    updateAccessory,
    removeAccessory,
    activateAccessory,
    clear,
  },
}
