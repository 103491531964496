export const returnDivider = (question) => {
  if (!question) return ''
  const divider =
    getLastCharOfString(question) === '?'
      ? ''
      : getLastCharOfString(question) === ':'
      ? ''
      : ':'
  return divider
}

function getLastCharOfString(string = '') {
  const last = string.substring(string.length - 1, string.length)
  return last || ''
}
