import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/firestore'
// import Papa from 'papaparse'
// import { v4 as uuidv4 } from 'uuid'
import { config } from '../core/config'
import { DEFAULT_TAGS, INITIAL_FOLDERS } from '../core/constants'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { startLicenseTrial } from '../helpers/startLicenseTrial'
import { generateRandomString } from '../helpers/generateRandomString'
import { generateRandomNumber } from '../helpers/generateRandomNumber'
import {
  getLastMonthTime,
  monthAsString,
  sortDatesInArray,
} from '../helpers/days'
import {
  checkAffiliateData,
  newAffiliateSignUp,
  registerAffiliateUser,
  sendAccountCreatedData,
} from './auth-api'
import { slugValidator } from '../helpers/validators/slugValidator'
import {
  BLANK_DIGITAL_CARD_TEMPLATE,
  BLANK_LINK_IN_BIO_TEMPLATE,
} from '../core/templates/cards-templates'

export const getAffiliateData = async (id) => {
  try {
    const response = await firebase
      .firestore()
      .collection('affiliates')
      .doc(id.toString())
      .get()
    return response.data()
  } catch (e) {
    console.error(e)
    return {
      clicks: 0,
      signUps: 0,
      subscriptions: 0,
    }
  }
}

export const getAffiliateOrders = async (id) => {
  try {
    const response = await axios.post(config.SERVER_URL + '/affiliate/orders', {
      id,
    })
    return response.data
  } catch (e) {
    console.error(e)
    return []
  }
}

export const getAffiliateSummary = async (id) => {
  try {
    const response = await axios.post(
      config.SERVER_URL + '/affiliate/payments-summary',
      {
        id,
      }
    )
    return response.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

export const updateAffiliateUser = async (data, id) => {
  return axios.post(config.SERVER_URL + '/affiliate/update-user', {
    data,
    id,
  })
}

export const getCardById = (id, license) => {
  return firebase
    .firestore()
    .collection('licenses')
    .doc(license)
    .collection('inbox')
    .doc(id)
    .get()
}

export const getUserInvitation = async (invitation) => {
  const response = await axios.post(config.SERVER_URL + '/invitations', {
    invitation,
  })
  return response.data
}

export const sendInvitationToUser = async (invitation) => {
  return axios.post(config.SERVER_URL + '/invitations/send', {
    invitation,
  })
}

export const getProfile = async ({ user, invitation }) => {
  const userUID = user.email === 'notifications@konect.to' ? '00000' : user.uid
  const profileRef = firebase.firestore().collection('users').doc(userUID)
  const profileDoc = await profileRef.get()

  if (profileDoc.exists) {
    const profile = profileDoc.data()
    profile.uid = userUID
    if (invitation) {
      if (profile.license) {
        profile.oldLicense = profile.license
        profile.invitationCompletedTimestamp = new Date().getTime()
      }
      profile.license = invitation.license
      profile.createdTimestamp = new Date().getTime()
      if (invitation.permission) {
        profile.permission = invitation.permission
      }
      if (invitation.allowedCards) {
        profile.allowedCards = invitation.allowedCards
      }
      if (invitation.allowedFolders) {
        profile.allowedFolders = invitation.allowedFolders
      }
      await profileRef.set(profile)
    }
    return profile
  }

  if (!user.email) {
    throw Error(`Something went wrong. Please contact our support at.`)
  }

  const profile = {
    createdTimestamp: new Date().getTime(),
    name: user.displayName || window.userName || '',
    avatar: user.photoURL || '',
    email: user.email || '',
    license: invitation ? invitation.license : generateRandomString(6),
    uid: userUID,
  }

  if (invitation) {
    if (invitation.allowedCards) {
      profile.allowedCards = invitation.allowedCards
    }
    if (invitation.allowedFolders) {
      profile.allowedFolders = invitation.allowedFolders
    }
  }

  await profileRef.set(profile)
  return profile
}

export const getLicense = async (profile) =>
  new Promise((resolve, reject) => {
    const docRef = firebase
      .firestore()
      .collection('licenses')
      .doc(profile.license)
      .collection('config')
      .doc('metadata')

    docRef
      .get()
      .then(async (doc) => {
        const { email, isGhost } = profile

        if (doc.exists) {
          const license = doc.data()
          const users = license.users || {}

          if (!license.trialEnds) {
            startLicenseTrial(license)
            await docRef.set(license, {
              merge: true,
            })
          }

          if (!license.folders) {
            license.folders = INITIAL_FOLDERS
            await docRef.update({ folders: license.folders })
          }

          if (!license.tags) {
            license.tags = DEFAULT_TAGS
            await docRef.update({ tags: license.tags })
          }

          if (!users[email] && !isGhost) {
            license.users = {
              ...users,
              [email]: profile,
            }
            license.users[email].addedAt = new Date().toString()
            license.users[email].addedTimestamp = new Date().getTime()
            await docRef.update({ users: license.users })
            sendAccountCreatedData(license, profile)
          }

          if (!license.license) {
            license.license = profile.license
            await docRef.update({ license: license.license })
          }

          if (!license.affiliateID) {
            const owner = license.users[license.createdBy] || {}
            const affiliateData = await registerAffiliateUser(
              license.createdBy,
              owner.name
            )
            if (affiliateData) {
              if (affiliateData.id) {
                license.affiliateID = affiliateData.id
                license.affiliateRef = affiliateData.ref_code
                license.affiliateToken = affiliateData.token
                docRef.update({
                  affiliateID: license.affiliateID,
                  affiliateRef: license.affiliateRef,
                  affiliateToken: license.affiliateToken,
                })
              }
            }
          }

          resolve(license)
        } else {
          const license = {}
          license.createdTimestamp = new Date().getTime()
          license.createdBy = email || ''
          license.license = profile.license
          license.folders = INITIAL_FOLDERS
          license.users = {
            [email]: profile,
          }
          if (!license.tags) {
            license.tags = DEFAULT_TAGS
          }
          if (!license.affiliateID) {
            const affiliateData = await registerAffiliateUser(
              license.createdBy,
              profile.name
            )
            if (affiliateData) {
              if (affiliateData.id) {
                license.affiliateID = affiliateData.id
                license.affiliateRef = affiliateData.ref_code
                license.affiliateToken = affiliateData.token
              }
            }
          }
          if (window.konectAffiliateRef) {
            license.konectAffiliateRef = window.konectAffiliateRef
            newAffiliateSignUp(
              license.license,
              license.konectAffiliateRef,
              license.affiliateID
            )
          }
          startLicenseTrial(license)
          await docRef.set(license)
          sendAccountCreatedData(license, profile)
          checkAffiliateData(license, email)
          resolve(license)
        }
      })
      .catch((error) => reject(error))
  })

export function getAnalyticsForReports({ card, days }) {
  if (!days) return {}
  return Promise.all(
    Object.keys(days).map(async (day) => {
      const dayResponse = await firebase
        .firestore()
        .collection('licenses')
        .doc(card.license)
        .collection('analytics')
        .doc(card.cardID)
        .collection('summary')
        .doc(day)
        .get()
      if (dayResponse.exists) {
        days[day] = { ...days[day], ...dayResponse.data() }
      }
      return days[day]
    })
  )
}

export const getCardsForReport = ({
  card,
  fromDate = 1,
  toDate = new Date().getTime(),
}) => {
  const cardsLimit = card.responsesLimit || 4000
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('inbox')
    .where('cardID', '==', card.cardID)
    .where('openedTimestamp', '<=', toDate)
    .where('openedTimestamp', '>=', fromDate)
    .orderBy('openedTimestamp', 'desc')
    .limit(cardsLimit)
    .get()
}

export const getCompletedCards = ({
  card,
  fromDate = 1,
  toDate = new Date().getTime(),
}) => {
  const cardsLimit = card.responsesLimit || 4000
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('inbox')
    .where('cardID', '==', card.cardID)
    .where('completedTimestamp', '<=', toDate)
    .where('completedTimestamp', '>=', fromDate)
    .orderBy('completedTimestamp', 'desc')
    .limit(cardsLimit)
    .get()
}

export const getMonthlyUsage = async ({ license }) => {
  const monthlyUsage = []
  if (!license) return monthlyUsage
  try {
    const usageData = await firebase
      .firestore()
      .collection('licenses')
      .doc(license)
      .collection('limits')
      .limit(12)
      .get()
    usageData.forEach((doc) => {
      const date = doc.id.replace('-', '-01-')
      monthlyUsage.push({
        date,
        month: monthAsString(parseInt(date.split('-')[0], 10) - 1),
        year: date.split('-')[2],
        ...doc.data(),
      })
    })
  } catch (error) {
    console.error(error)
  }
  sortDatesInArray(monthlyUsage)
  return monthlyUsage
}

export const sendCompletedCard = (card) => {
  return axios.post(config.SERVER_URL + '/cards/completed', {
    card,
  })
}

export const updateUserProfile = (profile) => {
  return firebase
    .firestore()
    .collection('users')
    .doc(profile.uid)
    .update(profile)
}

export const updateInboxCardData = (card) => {
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('inbox')
    .doc(card.id)
    .update(card)
}

export const updateCardData = (card) => {
  if (!card.cardID) throw new Error('Card format is invalid')
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('cards')
    .doc(card.cardID)
    .set(card)
}

export const updateConnectedPageData = (card) => {
  if (!card.cardID) throw new Error('Card format is invalid')
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('cards')
    .doc(card.cardID)
    .update(card)
}

export const addNewCard = (card) => {
  if (!card.cardID) throw Error('Card format is invalid')
  return firebase
    .firestore()
    .collection('licenses')
    .doc(card.license)
    .collection('cards')
    .doc(card.cardID)
    .set(card)
}

export function markTemplateAsDeleted(template, license) {
  firebase
    .firestore()
    .collection('licenses')
    .doc(license)
    .collection('deletedTemplates')
    .doc(template.templateId || template.label)
    .set(template)
}

export const markCardAsDeleted = async (card, profile) => {
  if (!card.cardID) throw new Error('Card format is invalid')
  card.deletedTimestamp = new Date().getTime()
  card.deletedBy = profile.email || ''

  if (card.slug) {
    await deleteSlug(card.slug)
    delete card.slug
  }

  try {
    await firebase
      .firestore()
      .collection('licenses')
      .doc(profile.license)
      .collection('cards')
      .doc(card.cardID)
      .delete()

    firebase
      .firestore()
      .collection('licenses')
      .doc(profile.license)
      .collection('deletedCards')
      .doc(card.cardID)
      .set(card)
  } catch (e) {
    throw new Error('Failed to remove card.')
  }
}

export const markResponseAsDeleted = async (card) => {
  if (!card.cardID) throw new Error('Card format is invalid.')
  const deletedResponse = deepCloneObject(card)
  deletedResponse.deletedTimestamp = new Date().getTime()

  try {
    await firebase
      .firestore()
      .collection('licenses')
      .doc(card.license)
      .collection('inbox')
      .doc(card.id)
      .delete()

    firebase
      .firestore()
      .collection('licenses')
      .doc(card.license)
      .collection('deletedResponses')
      .doc(card.id)
      .set(deletedResponse)
  } catch (e) {
    throw new Error('Failed to remove card.')
  }
}

export const updateLicense = (license) => {
  return firebase
    .firestore()
    .collection('licenses')
    .doc(license.license)
    .collection('config')
    .doc('metadata')
    .update(license)
}

export const findUser = async (email) => {
  let user
  const querySnapshot = await firebase
    .firestore()
    .collection('users')
    .where('email', '==', email)
    .get()
  querySnapshot.forEach((doc) => {
    user = doc.data()
    user.uid = doc.id
  })
  return user
}

export const startListeningForInboxChanges = ({
  profile,
  updateInboxCards,
  license,
}) => {
  if (window.listeningForInbox) return
  window.listeningForInbox = true

  const dateFilter = {
    label: 'Last month',
    from: getLastMonthTime(),
  }

  firebase
    .firestore()
    .collection('licenses')
    .doc(profile.license)
    .collection('inbox')
    .where('completedTimestamp', '>=', dateFilter.from)
    .orderBy('completedTimestamp', 'desc')
    .limit(license.inboxLimit || 2000)
    .onSnapshot((inbox) => {
      let onlyModified = false
      inbox.docChanges().forEach((change) => {
        if (change.type === 'modified') onlyModified = true
      })

      if (onlyModified) return
      const inboxCards = {}
      inbox.forEach((doc) => {
        const card = doc.data()
        if (!inboxCards[card.cardID]) {
          inboxCards[card.cardID] = [card]
        } else {
          inboxCards[card.cardID].push(card)
        }
      })
      updateInboxCards({ inboxCards, dateFilter })
    })
}

export const startListeningForCards = ({
  profile,
  updateCards,
  addCard,
  license,
  history,
  updateAccessory,
}) => {
  if (window.listeningForCards) return
  const { name = '' } = profile
  const folders = license.folders || []
  let isFistLogin = false
  window.listeningForCards = true

  const users = license.users || {}
  const foundUser = users[profile.email] || {}
  const allowedCards = foundUser.allowedCards
  const allowedFolders = foundUser.allowedFolders

  firebase
    .firestore()
    .collection('licenses')
    .doc(profile.license)
    .collection('cards')
    .orderBy('createdTimestamp', 'desc')
    .onSnapshot(async (querySnapshot) => {
      if (querySnapshot.size === 0 && !isFistLogin) {
        isFistLogin = true
        const pageType = window.konectPageType || ''
        const firstCard =
          pageType === 'Modern'
            ? BLANK_LINK_IN_BIO_TEMPLATE
            : BLANK_DIGITAL_CARD_TEMPLATE

        firstCard.label = name || 'First Page'
        firstCard.personalDetails = {
          name: profile.name || '',
          headline: '',
          title: '',
          company: '',
        }

        const addedCard = await addCard({
          card: firstCard,
        })

        let username = name.toLowerCase().split(' ').join('')
        const slugError = slugValidator(username)
        if (!slugError) {
          const doesExists = await checkIfSlugExists(username)
          if (doesExists) {
            username += generateRandomNumber(5)
          }
          setNewSlug(username, {
            license: addedCard.license,
            cardID: addedCard.cardID,
          }).then(() => {
            addedCard.slug = username
            updateCardData(addedCard)
          })
        }

        setTimeout(() => {
          if (window.recentlyActivatedAccessory) {
            updateAccessory({
              id: window.recentlyActivatedAccessory,
              cardID: addedCard.cardID,
              license: addedCard.license,
            })
          }
        }, 100)

        history.push(`/${addedCard.cardID}/edit`)
      }

      if (querySnapshot.size > 0) {
        const cards = []
        const userCards = []
        if (allowedFolders) {
          const userFolders = folders.filter((f) =>
            allowedFolders.includes(f.createdTimestamp)
          )
          userFolders.forEach((folder) => {
            if (folder.cards) {
              userCards.push(...folder.cards)
            }
          })
        }
        querySnapshot.forEach((card) => {
          const cardData = card.data()
          if (cardData.cardURL) {
            cardData.cardURL = cardData.cardURL.replace(
              'https://konect.social',
              'https://konect.to'
            )
          }
          if (allowedCards) {
            if (!allowedCards.includes(cardData.cardID)) {
              return
            }
          }
          if (allowedFolders) {
            if (!userCards.includes(cardData.cardID)) {
              return
            }
          }
          cards.push(cardData)
        })
        updateCards(cards)
      }
    })
}

export async function deleteSlug(slug = '') {
  return firebase
    .firestore()
    .collection('slugs')
    .doc(slug.toLowerCase())
    .delete()
}

export async function checkIfSlugExists(slug) {
  const response = await firebase
    .firestore()
    .collection('slugs')
    .doc(slug.toLowerCase())
    .get()
  return response.exists
}

export function setNewSlug(slug, card) {
  return firebase
    .firestore()
    .collection('slugs')
    .doc(slug.toLowerCase())
    .set(card)
}

export function getAccessoryData(id) {
  return firebase.firestore().collection('cards').doc(id).get()
}

export function updateAccessoryData(accessory) {
  return firebase
    .firestore()
    .collection('cards')
    .doc(accessory.id)
    .update({ ...accessory })
}

export async function getLicenseAccessories(license) {
  try {
    const accessories = []
    const snapshot = await firebase
      .firestore()
      .collection('cards')
      .where('status', '==', 'active')
      .where('license', '==', license)
      .get()
    snapshot.forEach((doc) => {
      const card = doc.data()
      accessories.push(card)
    })
    return accessories
  } catch (e) {
    console.error(e)
    return []
  }
}

// window.findCards = async function getCards() {
//   try {
//     const cards = []
//     const snapshot = await firebase
//       .firestore()
//       .collection('cards')
//       .where('status', '==', 'active')
//       // .where('cardID', '==', 'tFR4')
//       // .where('license', '==', 'yIUdhk')
//       .get()
//     snapshot.forEach((doc) => {
//       const card = doc.data()
//       cards.push(card)
//     })
//     console.log(cards)
//   } catch (e) {
//     console.error(e)
//   }
// }

// window.generateCodes = async function generateCodes(count) {
//   const cards = []
//   for (let i = 0; i < count; i++) {
//     try {
//       const card = {
//         id: uuidv4(),
//         createdTimestamp: new Date().getTime(),
//         status: 'not-active', // active, not-active
//         type: 'card', // card, tag
//         color: 'dark', // white, dark, bamboo
//       }
//       await firebase.firestore().collection('cards').doc(card.id).set(card)
//       cards.push(card)
//       console.log(`New ${card.type} generated.`)
//     } catch (error) {
//       console.error(error)
//     }
//   }

//   const headers = ['ID', 'URL', 'Type', 'Color']
//   const rows = []
//   rows.push(headers)
//   cards.forEach((card) => {
//     const row = []
//     row[0] = card.id
//     row[1] = `https://konect.to/card/${card.id}`
//     row[2] = card.type
//     row[3] = card.color
//     rows.push(row)
//   })
//   // array to csv
//   console.log('Generating CSV.')
//   const csvString = Papa.unparse(rows)
//   const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
//   const link = document.createElement('a')
//   link.setAttribute('href', window.URL.createObjectURL(blob))
//   link.setAttribute('download', `cards ${new Date().toLocaleDateString()}.csv`)
//   document.body.appendChild(link)
//   link.click()
//   link.remove()
// }

// window.getCards = async function getCards() {
//   try {
//     const cards = []
//     const snapshot = await firebase
//       .firestore()
//       .collection('cards')
//       .where('type', '==', 'tag')
//       // .orderBy('createdTimestamp', 'desc')
//       .get()

//     snapshot.forEach((doc) => {
//       const card = doc.data()
//       cards.push(card)
//     })

//     const headers = ['ID', 'URL', 'Type', 'Color']
//     const rows = []
//     rows.push(headers)
//     cards.forEach((card) => {
//       const row = []
//       row[0] = card.id
//       row[1] = `https://konect.to/card/${card.id}`
//       row[2] = card.type
//       row[3] = card.color
//       rows.push(row)
//     })
//     // array to csv
//     console.log('Generating CSV.')
//     const csvString = Papa.unparse(rows)
//     const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' })
//     const link = document.createElement('a')
//     link.setAttribute('href', window.URL.createObjectURL(blob))
//     link.setAttribute(
//       'download',
//       `cards ${new Date().toLocaleDateString()}.csv`
//     )
//     document.body.appendChild(link)
//     link.click()
//     link.remove()
//   } catch (error) {
//     console.error(error)
//   }
// }

// window.copyCardToOtherLicense = async () => {
//   const licenseFrom = 'dow6xA'
//   const licenseTo = 'gBLeI9'
//   const cardID = 'FEhC'

//   try {
//     const response = await firebase
//       .firestore()
//       .collection('licenses')
//       .doc(licenseFrom)
//       .collection('cards')
//       .doc(cardID)
//       .get()

//     const cardToCopy = response.data()
//     cardToCopy.license = licenseTo
//     cardToCopy.cardURL = `${config.CARDS_BASE_URL}/${generateCardSecretSlug(
//       cardToCopy
//     )}`

//     await firebase
//       .firestore()
//       .collection('licenses')
//       .doc(licenseTo)
//       .collection('cards')
//       .doc(cardID)
//       .set(cardToCopy)

//     console.log('Card copied succesfully.')
//   } catch (e) {
//     throw new Error('Failed to copy card')
//   }
// }

// window.restoreCard = async () => {
//   const license = 'DgQShp'
//   const cardName = 'HZVQmK'

//   try {
//     const formDoc = await firebase
//       .firestore()
//       .collection('licenses')
//       .doc(license)
//       .collection('deletedCards')
//       .doc(cardName)
//       .get()

//     const form = formDoc.data()
//     delete form.deletedBy
//     delete form.deletedTimestamp

//     await firebase
//       .firestore()
//       .collection('licenses')
//       .doc(form.license)
//       .collection('cards')
//       .doc(form.cardID)
//       .set(form)

//     console.log('Page restored!')
//   } catch (e) {
//     console.log(e)
//     throw new Error('Failed to remove page')
//   }
// }
