import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Button as KonectButton,
  FolderButton as KonectFolderButton,
} from 'konect-react'
import { useMediaQuery } from 'react-responsive'
import { cardColors, colors } from '../../core/theme'
import { websiteAddressValidator } from '../../helpers/validators/websiteAddressValidator'
import { useActions, useStore } from '../../store'
import { licenseStatus } from '../../helpers/licenseStatus'
import ButtonTypePicker from '../ButtonTypePicker'
import { BUTTON_STYLE_TYPES, BUTTON_TYPES } from '../../core/constants'
import ButtonImageUpload from '../ButtonImageUpload'
import PodcastButtonSettings from './PodcastButtonSettings'
import ColorInput from '../ColorInput'
import SwitchInput from '../SwitchInput'
import FolderButton from '../FolderButton'
import { ReloadIcon } from '../../assets/icons'
import { lightOrDark } from '../../helpers/lightOrDark'
import FloatingInput from '../FloatingInput'

export default function ButtonSettings({
  button,
  card,
  updateCard,
  section,
  index,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const {
    primaryColor = colors.main,
    cardBackgroundColor = cardColors.cardBackgroundColor,
    sections = [],
  } = card
  const { license } = useStore()
  const [error, setError] = useState()
  const { backgroundColor = primaryColor, buttonSize = 'large' } = button
  const buttonsTheme = lightOrDark(backgroundColor)
  const buttonTextColor =
    button.buttonTextColor ||
    (buttonsTheme === 'dark' ? colors.white : colors.black)
  const { showModal } = useActions().modals
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const type = button.type || BUTTON_TYPES[0].value
  const buttonType = button.buttonType || BUTTON_STYLE_TYPES[0].value
  let hasDescription = buttonType !== 'link'
  const hasFileUpload = buttonType !== 'link'
  if (buttonType === 'image-left' && buttonSize === 'small') {
    hasDescription = false
  }

  function returnFolderButtons() {
    const { buttons = [] } = section
    if (!section.buttons) {
      const folderButtons = []
      for (let x = index + 1; x < sections.length; x++) {
        const nextSection = sections[x] || {}
        if (nextSection.type === 'folder') {
          break
        }
        if (nextSection.addToFolder) {
          nextSection.isAlreadyRendered = true
          folderButtons.push({
            ...nextSection,
            isAlreadyRendered: false,
            isSection: true,
            isFolder: true,
          })
        }
      }
      return folderButtons
    }
    const folderButtons = []
    for (let x = index + 1; x < buttons.length; x++) {
      const nextButton = buttons[x] || {}
      if (nextButton.type === 'folder') {
        break
      }
      if (nextButton.addToFolder) {
        nextButton.isAlreadyRendered = true
        folderButtons.push({ ...nextButton, isAlreadyRendered: false })
      }
    }
    return folderButtons
  }

  return (
    <>
      <PreviewContainer style={{ backgroundColor: cardBackgroundColor }}>
        <ButtonContainer>
          {button.type === 'folder' ? (
            <KonectFolderButton
              button={button}
              folderButtons={returnFolderButtons()}
              card={card}
              onButtonPressed={() => {}}
              isMobile={isMobile}
              editMode
            />
          ) : (
            <KonectButton
              button={{ ...button, id: '' }}
              card={card}
              onButtonPressed={() => {}}
            />
          )}
        </ButtonContainer>
      </PreviewContainer>
      <FieldsContainer>
        <ButtonTypePicker
          button={button}
          onSizeChange={(size) => {
            button.buttonSize = size
            updateCard(card)
          }}
          onChange={(style) => {
            if (!isCardActive && !isTrialActive && style.isPremium) {
              showModal({ name: 'TRIAL_ENDED' })
              return
            }
            if (!isCardActive && !isTrialActive && button.type === 'podcast') {
              showModal({ name: 'TRIAL_ENDED' })
              return
            }
            if (button.buttonType !== style.value) {
              button.buttonType = style.value
              updateCard(card)
            }
          }}
        />
        {type === 'link' && (
          <FloatingInput
            label="Link"
            onChange={(e) => {
              button.link = e.target.value
              if (websiteAddressValidator(button.link)) {
                setError(websiteAddressValidator(button.link))
              } else {
                setError('')
              }
              updateCard(card)
            }}
            value={button.link}
            error={error}
          />
        )}
        <FloatingInput
          label="Title"
          value={button.title}
          onChange={(e) => {
            button.title = e.target.value
            updateCard(card)
          }}
        />
        {hasDescription && (
          <FloatingInput
            label="Description"
            value={button.description}
            onChange={(e) => {
              button.description = e.target.value
              updateCard(card)
            }}
          />
        )}
        {hasFileUpload && (
          <ButtonImageUpload
            card={card}
            button={button}
            updateCard={updateCard}
          />
        )}
        <FolderButton label="Custom Styles">
          <Info>
            Changes made will override the default colors selected in the Design
            Tab. Click the <ReloadIcon size={14} /> icon to reset the colors
            back to their original settings.
          </Info>
          <ColorInput
            value="Button Color"
            color={backgroundColor}
            changeToDefault={() => {
              delete button.backgroundColor
              updateCard(card)
            }}
            onChangeComplete={({ hex }) => {
              button.backgroundColor = hex
              updateCard(card)
            }}
          />
          <ColorInput
            value="Button Text Color"
            color={buttonTextColor}
            changeToDefault={() => {
              delete button.buttonTextColor
              updateCard(card)
            }}
            onChangeComplete={({ hex }) => {
              button.buttonTextColor = hex
              updateCard(card)
            }}
          />
          <SwitchInput
            label="Outlined"
            value={button.outline}
            onChange={(status) => {
              button.outline = status
              updateCard(card)
            }}
          />
          <SwitchInput
            label="Pulse Animation"
            value={button.animation}
            onClick={() => {
              if (button.animation) {
                delete button.animation
              } else {
                button.animation = 'pulse 2s infinite linear'
              }
              updateCard(card)
            }}
          />
        </FolderButton>
        {type === 'podcast' && (
          <PodcastButtonSettings
            button={button}
            updateCard={updateCard}
            card={card}
          />
        )}
      </FieldsContainer>
    </>
  )
}

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 12px 28px 60px 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 8px 20px 60px 20px;
  }
`

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding: 40px 24px;
  margin-bottom: 12px;
  box-sizing: border-box;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 368px;
`

const Info = styled.span`
  font-size: 13px;
  padding: 10px 14px;
  color: #444444;
  background: #f5f5f5;
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  margin-bottom: 16px;
`
