import firebase from 'firebase/app'
import 'firebase/storage'
import { generateRandomString } from '../helpers/generateRandomString'

export const uploadCustomLinkIcon = async (file, card) => {
  const { cardID, license } = card
  const parts = file.name.split('.')
  const fileExtension = parts[parts.length - 1] || ''
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`icons/${license}/${cardID}/icon_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadMediaImage = async (file, fileExtension, card) => {
  const { cardID, license } = card
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`media/${license}/${cardID}/media_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadButtonImage = async (file, fileExtension, card) => {
  const { cardID, license } = card
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`buttons/${license}/${cardID}/button_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadCardBanner = async (file, fileExtension, card) => {
  const { cardID, license } = card
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`banners/${license}/${cardID}/banner_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadCardAvatar = async (file, fileExtension, card) => {
  const { cardID, license } = card
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`avatars/${license}/${cardID}/avatar_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadCardCompanyLogo = async (file, fileExtension, card) => {
  const { cardID, license } = card
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`company/${license}/${cardID}/logo_${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadProfileAvatar = async (file, profile) => {
  const { license } = profile
  const parts = file.name.split('.')
  const fileExtension = parts[parts.length - 1] || ''
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`profile/${license}/avatar/${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })
  return uploadTask.ref.getDownloadURL()
}

export const uploadFormFavicon = async (file, card) => {
  const { cardID, license } = card
  const parts = file.name.split('.')
  const fileExtension = parts[parts.length - 1] || ''
  const fileId = generateRandomString(5) + '.' + fileExtension
  const uploadTask = await firebase
    .storage()
    .ref()
    .child(`favicons/${license}/${cardID}/favicon${fileId}`)
    .put(file, {
      contentType: file.type,
      cacheControl: 'public,max-age=31536000',
    })

  return uploadTask.ref.getDownloadURL()
}
