import React, { useState } from 'react'
import styled from 'styled-components'
import { Dropdown, DropdownListItem } from './Dropdown'
import { animations } from '../core/theme'
import { useActions } from '../store'
import Button from './Button'
import { EditTextIcon } from '../assets/icons'
import { usePermission } from '../hooks/usePermission'
import FloatingInput from './FloatingInput'

const EditFolder = ({ folder }) => {
  const { license, toasts } = useActions()
  const [showEdit, setEditVisibility] = useState(false)
  const [loading, setLoadingStatus] = useState(false)
  const [error, setError] = useState(false)
  const [name, setFolderName] = useState(folder.name)
  const { canEdit, showPermissionAlert } = usePermission()

  const hideMenu = () => setEditVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (showEdit) setEditVisibility(false)
    else setEditVisibility(true)
  }

  const updateFolder = async () => {
    if (loading) return
    if (!canEdit) {
      showPermissionAlert()
      return
    }

    if (!name) {
      setError("Name can't be empty.")
      return
    }

    setLoadingStatus(true)
    try {
      await license.updateFolder({
        updatedFolderName: name,
        oldFolderName: folder.name,
      })
    } catch (e) {
      toasts.showToast({
        content: e.message,
      })
    }
    setLoadingStatus(false)
    hideMenu()
  }

  return (
    <Dropdown
      isVisible={showEdit}
      placement="bottom-start"
      onClose={hideMenu}
      zIndex={999999}
      triggerRenderer={({ ref }) => (
        <div ref={ref} style={{ width: '100%' }}>
          <DropdownListItem
            content="Change name"
            onClick={showMenu}
            icon={<EditTextIcon size={16} />}
          />
        </div>
      )}
    >
      {showEdit && (
        <DropdownContainer>
          <FloatingInput
            shrink
            label="Folder Name"
            value={name}
            error={error}
            autoFocus={showEdit}
            style={{ marginTop: 8 }}
            onChange={(e) => {
              setFolderName(e.target.value)
            }}
          />
          <Button
            style={{ margin: '8px 0 0 0', width: '100%', height: 48 }}
            onClick={updateFolder}
            loading={loading}
          >
            Update Folder
          </Button>
        </DropdownContainer>
      )}
    </Dropdown>
  )
}

const DropdownContainer = styled.div`
  cursor: default;
  background-color: #fff;
  box-shadow: 0 0 12px 0 rgba(66, 77, 87, 0.1);
  padding: 20px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 300px;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 999;
  &:focus {
    outline: none;
  }
`

export default EditFolder
