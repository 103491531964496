import axios from 'axios'
import { createCsvFile, returnExtraCardHeaders } from './createCsvFile'
import { config } from '../core/config'
import { getCompletedCards } from '../api/database-api'

export const connectGoogleSheets = async ({ card, section }) => {
  const isSignedIn = window.gapi.auth2.getAuthInstance().isSignedIn.get()

  if (isSignedIn) {
    await window.gapi.auth2.getAuthInstance().signOut()
  }

  const { code } = await window.gapi.auth2
    .getAuthInstance()
    .grantOfflineAccess()

  const result = await getCompletedCards({ card })
  const receivedCards = []

  result.forEach((doc) => {
    receivedCards.push(doc.data())
  })

  const csv = createCsvFile(card, receivedCards, true)
  const extraHeaders = returnExtraCardHeaders(receivedCards)

  const updatedCard = await axios.post(
    `${config.SERVER_URL}/google-sheets/create`,
    {
      card,
      code,
      csv,
      extraHeaders,
      section,
    }
  )

  return updatedCard.data
}
