import React from 'react'
import styled from 'styled-components'
import ActionModal from './ActionModal'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { CircleCheckIcon } from '../assets/icons'
import { BILLING_PLANS } from '../core/constants'
import { useActions, useStore } from '../store'
import { usePermission } from '../hooks/usePermission'
import { licenseStatus } from '../helpers/licenseStatus'

const FEATURES = [
  'Remove Konect branding',
  'Verified Pages',
  'Contact Forms',
  'Brand Logo',
  'Videos & Images',
  'Schedule Meetings',
  'Embed Content & More!',
]

export default function TrialEndedModal({ onClose, title }) {
  const license = useStore().license
  const { showModal } = useActions().modals
  const { canEdit, showPermissionAlert } = usePermission()
  const { cardSlots = [] } = licenseStatus(license)

  return (
    <ActionModal
      onClose={onClose}
      icon={
        <Image src={require('../assets/icons/banner.svg').default} alt="logo" />
      }
      actions={
        <Button
          style={{ width: '100%', height: 50, margin: '10px 0 0 0' }}
          onClick={() => {
            if (!canEdit) {
              showPermissionAlert()
              return
            }

            if (cardSlots.length === 0) {
              showModal({
                name: 'CREATE_SUBSCRIPTION_MODAL',
                price: BILLING_PLANS[0].price['monthly'],
                subscriptionName: 'premium',
                subscriptionType: 'monthly',
              })
            } else {
              showModal({ name: 'ADD_SLOTS_MODAL' })
            }

            onClose()
          }}
        >
          Upgrade
        </Button>
      }
      header={
        <span
          style={{
            fontSize: 15,
            fontWeight: '500',
            marginTop: 18,
            marginBottom: 10,
          }}
        >
          {title ||
            'Unlock this feature and many more on this page by upgrading to the Premium Plan.'}
        </span>
      }
      body={
        <FeaturesContainer>
          {FEATURES.map((feature) => (
            <Feature>
              <CircleCheckIcon size={16} fill={colors.main} />
              <FeatureName>{feature}</FeatureName>
            </Feature>
          ))}
        </FeaturesContainer>
      }
    />
  )
}

const Image = styled.img`
  height: 20px;
`

const FeaturesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 36px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`

const Feature = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 13px;
`

const FeatureName = styled.span`
  font-size: 14px;
  padding-left: 8px;
`
