import React from 'react'
import styled from 'styled-components'
import Input from './Input'
import { Label } from './Typography'

const NumberPicker = ({
  label,
  min,
  max,
  name,
  unit,
  onChange,
  value,
  style,
  className,
}) => {
  return (
    <Container style={style} className={className}>
      <Label weight="medium" style={{ whiteSpace: 'nowrap' }}>
        {label}
      </Label>
      <Input
        onChange={onChange}
        type="number"
        name={name}
        min={min}
        max={max}
        value={value}
        style={{ maxWidth: 80, margin: '0 0 0 8px' }}
      />
      <Label padding="0 8px">{unit}</Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export default NumberPicker
