import React, { useState } from 'react'
import styled from 'styled-components'
import { DeleteIcon, DuplicateIcon, HorizontalMenuIcon } from '../assets/icons'
import { colors } from '../core/theme'
import { Dropdown, DropdownList } from './Dropdown'
import { usePermission } from '../hooks/usePermission'
import { useActions } from '../store'

export default function TemplateDetailsMenu({ template, style }) {
  const { canEdit, showPermissionAlert } = usePermission()
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const { showModal } = useActions().modals
  const { deleteTemplate } = useActions().license
  const { showToast } = useActions().toasts
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <Dropdown
      isVisible={isMenuVisible}
      placement="bottom-start"
      onClose={hideMenu}
      triggerRenderer={({ ref }) => (
        <div
          onClick={showMenu}
          ref={ref}
          style={{ cursor: 'pointer', ...style }}
        >
          <MenuButton>
            <HorizontalMenuIcon color="#222" />
          </MenuButton>
        </div>
      )}
    >
      <DropdownList
        onClose={hideMenu}
        items={[
          {
            content: 'Duplicate template',
            onItemSelect: () => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              showModal({ name: 'DUPLICATE_TEMPLATE', template })
            },
            icon: <DuplicateIcon color={colors.primary} size={18} />,
          },
          {
            content: 'Delete template',
            color: colors.error,
            onItemSelect: async () => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              const result = window.confirm(
                `You are about to delete template. Are you sure you want to continue?`
              )
              if (result) {
                try {
                  await deleteTemplate(template)
                } catch (error) {
                  console.error(error)
                  showToast(error.message)
                }
              }
              hideMenu()
            },
            icon: <DeleteIcon color={colors.error} size={18} />,
          },
        ]}
      />
    </Dropdown>
  )
}

const MenuButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #b2bbc2;
  border-radius: 32px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 31px;
  width: 60px;
  :hover {
    background-color: #f5f5f9;
  }
  :active {
    background-color: #f5f5f9;
  }
`
