import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import AccessoryBlock from '../components/AccessoryBlock'
import Button from '../components/Button'
import PageLoader from '../components/PageLoader'
import ShopAccessory from '../components/ShopAccessory'
import { Subheading } from '../components/Typography'
import { KONECT_ACCESSORIES } from '../core/constants'
import { colors } from '../core/theme'
import { useActions, useStore } from '../store'

export default function AccessoriesPage() {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { license, profile, accessories } = useStore()
  const { cards } = useStore().cards
  const { getAccessories } = useActions().accessories
  const [tab, setTab] = useState('active') // active, shop
  const { showToast } = useActions().toasts
  const users = license.users || {}
  const foundUser = users[profile.email] || {}
  const allowedCards = foundUser.allowedCards

  const ACCESSORIES_COLUMNS = isMobile
    ? ['Accessory Type', 'Page To Open']
    : ['Accessory Type', 'Team Member', 'Page To Open', 'Date Activated']

  useEffect(async () => {
    const foundAccessories = await getAccessories()
    if (
      foundAccessories &&
      foundAccessories.length === 0 &&
      !window.recentlyActivatedAccessory
    ) {
      setTab('shop')
    }
  }, [])

  if (!accessories) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  return (
    <Container>
      <Subheading weight="bold">Accessories</Subheading>
      <ButtonsContainer>
        <TabButton selected={tab === 'active'} onClick={() => setTab('active')}>
          Active
        </TabButton>
        <TabButton selected={tab === 'shop'} onClick={() => setTab('shop')}>
          Shop
        </TabButton>
      </ButtonsContainer>
      {tab === 'shop' && (
        <ShopWrapper>
          {KONECT_ACCESSORIES.map((accessory) => (
            <ShopAccessory
              key={accessory.name}
              accessory={accessory}
              license={license}
            />
          ))}
        </ShopWrapper>
      )}
      {tab === 'active' && (
        <ActiveWrapper>
          {accessories.length > 0 ? (
            <RowContainer>
              {ACCESSORIES_COLUMNS.map((column, i) => (
                <RowHeader key={i}>{column}</RowHeader>
              ))}
            </RowContainer>
          ) : (
            <RowContainer>No active accessories, yet.</RowContainer>
          )}
          {accessories.map((item) => (
            <AccessoryBlock
              key={item.id}
              accessory={item}
              cards={cards}
              showToast={showToast}
              allowedCards={allowedCards}
              isMobile={isMobile}
            />
          ))}
        </ActiveWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 36px 40px;
  position: relative;
  background-color: ${colors.background};
  @media (max-width: 767px) {
    padding: 20px;
    padding-bottom: 100px;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  border-radius: 50px;
  border: 1px solid #c3cdd3;
  background-color: #ededed;
  margin-top: 24px;
`

const ActiveWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 1100px;
  box-sizing: border-box;
  width: 100%;
  margin-top: 32px;
  @media (max-width: 1000px) {
    margin-top: 24px;
  }
`

const ShopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: flex-start;
  max-width: 1200px;
  box-sizing: border-box;
  width: 100%;
  @media (max-width: 1000px) {
    margin-top: 24px;
  }
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #c3cdd3;
  flex-shrink: 0;
  z-index: 1;
  padding: 14px 16px;
  margin-bottom: 24px;
  font-size: 15px;
`

const RowHeader = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 600;
  color: ${colors.primary};
  width: 100%;
  @media (max-width: 767px) {
    font-size: 13.5px;
  }
`

const TabButton = styled(Button)`
  border-radius: 50px;
  border: none;
  width: 160px;
  background-color: ${(props) => (props.selected ? 'white' : 'transparent')};
  box-shadow: none;
  color: ${colors.primary};
  :hover {
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
    box-shadow: none;
    outline: 0;
  }
  :focus {
    outline: 0;
    box-shadow: none;
    opacity: ${(props) => (props.loading ? 0.6 : 0.9)};
  }
  :active {
    outline: 0;
    opacity: ${(props) => (props.loading ? 0.6 : 0.7)};
    box-shadow: none;
  }
`
