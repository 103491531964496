export const BLANK_LINK_IN_BIO_TEMPLATE = {
  id: 'blank',
  label: 'Blank (no template)',
  shareCard: true,
  contactButton: {
    title: 'Save Contact',
    hideContactButton: true,
  },
  sections: [
    {
      type: 'social-links',
      layout: 'minimal',
      links: [
        {
          label: 'Email',
          title: 'Email',
          action: 'mailto:',
          type: 'email',
        },
        {
          value: 'https://x.com/',
          title: 'X',
          type: 'twitter',
        },
        {
          type: 'instagram',
          title: 'Instagram',
          value: 'https://instagram.com/',
        },
        {
          title: 'LinkedIn',
          value: 'https://linkedin.com/',
          premium: true,
          type: 'linkedin',
        },
        {
          title: 'Facebook',
          value: 'https://facebook.com/',
          premium: true,
          type: 'facebook',
        },
      ],
      style: 'round',
      label: 'Social Links',
    },
    {
      label: 'Link',
      type: 'link',
      title: 'Link #1',
      link: '',
    },
    {
      label: 'Link',
      type: 'link',
      title: 'Link #2',
      link: '',
    },
    {
      label: 'Link',
      type: 'link',
      title: 'Link #3',
      link: '',
    },
  ],
}

export const BLANK_DIGITAL_CARD_TEMPLATE = {
  id: 'blank',
  label: 'Blank (no template)',
  shareCard: true,
  contactButton: {
    title: 'Save Contact',
  },
  sections: [
    {
      links: [
        {
          label: 'Phone',
          type: 'phone',
          title: 'Call',
          action: 'tel:',
        },
        {
          type: 'sms',
          title: 'Text',
          label: 'Phone',
          action: 'sms:',
        },
        {
          title: 'Email',
          label: 'Email',
          type: 'email',
          action: 'mailto:',
        },
        {
          title: 'Instagram',
          type: 'instagram',
          value: 'https://instagram.com/',
        },
        {
          title: 'Facebook',
          premium: true,
          value: 'https://facebook.com/',
          type: 'facebook',
        },
        {
          value: 'https://linkedin.com/',
          title: 'LinkedIn',
          type: 'linkedin',
          premium: true,
        },
      ],
      label: 'Social Links',
      style: 'square',
      type: 'social-links',
    },
    {
      type: 'contact',
      button: {
        label: 'Submit',
      },
      label: 'Contact Form',
      value: [
        {
          validatorError: 'Input must be a name.',
          type: 'name',
          regexType: 'Name',
          placeholder: 'Name',
          isRequired: true,
        },
        {
          regexType: 'Email address',
          validator:
            "^[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~](.?[-!#$%&'*+\\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*.?[a-zA-Z0-9])*.[a-zA-Z](-?[a-zA-Z0-9])+$",
          validatorError: 'Input must be an email address.',
          type: 'email',
          isRequired: true,
          placeholder: 'Email',
        },
        {
          placeholder: 'Message',
          multiline: true,
          isRequired: false,
        },
      ],
      question: 'Connect with me',
      complete: {
        description: 'Form submitted successfully.',
        title: 'Thank you',
      },
    },
  ],
}

export const CARDS_TEMPLATES = []
