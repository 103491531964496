import React from 'react'
import styled from 'styled-components'
import { CheckIcon } from '../assets/icons'

export default function CheckItem({ label, bold }) {
  return (
    <Container>
      <CheckIcon size={20} />
      <ItemLabel style={{ fontWeight: bold ? '700' : '400' }}>
        {label}
      </ItemLabel>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`

const ItemLabel = styled.span`
  font-size: 15px;
  margin-left: 16px;
`
