import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import { TopBar } from './TopBar'
import BackButton from './BackButton'
import Button from './Button'
import { useActions } from '../store'
import {
  DuplicateIcon,
  LightingIcon,
  MobileMenuIcon,
  NewTabIcon,
  SaveIcon,
} from '../assets/icons'
import { Dropdown, DropdownList } from './Dropdown'
import { config } from '../core/config'
import { Row } from './Layout'
import { injectCustomCSS } from '../helpers/injectCustomCSS'
import Profile from './MobilePageEditItems/Profile'
import Design from './MobilePageEditItems/Design'
import Integrations from './MobilePageEditItems/Integrations'
import Settings from './MobilePageEditItems/Settings'
import Content from './MobilePageEditItems/Content'
import AddSectionModal from '../modals/AddSectionModal'
import CardPreviewModal from '../modals/CardPreviewModal'
import { INTEGRATIONS } from '../core/constants'
import SectionSettingsModal from '../modals/SectionSettingsModal'
import { usePermission } from '../hooks/usePermission'
import AddContentButton from './AddContentButton'

const TABS = [
  {
    name: 'Profile',
  },
  {
    name: 'Design',
  },
  {
    name: 'Content',
  },
  {
    name: 'Integrations',
  },
  {
    name: 'Settings',
  },
]

export default function MobileCardEditor({
  card,
  showSave,
  saveChanges,
  isSaving,
  isFreeCard,
  updateCard,
  isCardActive,
  isTemplate,
  templateLockProps,
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()
  const { showModal } = useActions().modals
  const { canEdit, showPermissionAlert } = usePermission()
  const [hasAddSection, showAddSection] = useState(false)
  const [mobilePreview, setMobilePreview] = useState(false)
  const [openSettings, setOpenSettings] = useState({
    open: false,
    section: null,
    index: undefined,
  })
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const [tab, setTab] = useState(TABS[0])

  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  const extraTopBarStyle = { zIndex: 1000 }
  extraTopBarStyle.padding = '0 8px'
  extraTopBarStyle.position = isMobile ? 'fixed' : 'sticky'
  extraTopBarStyle['justify-content'] = 'flex-end'

  useEffect(() => {
    if (isMobile) {
      injectCustomCSS('.side-menu { display: none; }')
      return () => {
        injectCustomCSS('.side-menu { display: flex; }')
      }
    }
    return () => {}
  }, [isMobile])

  const openSectionModal = (section, index) => {
    if (openSettings.open && index === openSettings.index) {
      setOpenSettings({ open: false })
    } else {
      setOpenSettings({ open: true, section, index })
    }
  }
  window.kc_open_section_modal = openSectionModal

  return (
    <>
      <Container>
        <TopBar style={extraTopBarStyle}>
          <BackWrapper>
            <BackButton
              fill={colors.black}
              onClick={async () => {
                if (showSave) {
                  const confirmation = window.confirm(
                    'You have some unsaved work. Do you want to save it before continuing?'
                  )
                  if (confirmation) {
                    await saveChanges(card)
                  }
                }
                if (isTemplate) {
                  history.push(`/templates`)
                  return
                }
                if (isMobile) {
                  history.push(`/${card.cardID}/report`)
                } else {
                  history.push(`/${card.cardID}`)
                }
              }}
            />
          </BackWrapper>
          {!isMobile && tab === TABS[2] && (
            <AddContentButton
              {...templateLockProps}
              showAddSection={showAddSection}
              style={{
                width: '50%',
                maxWidth: '190px',
                marginRight: 8,
              }}
            />
          )}
          {!isMobile && showSave && (
            <Button
              small
              loading={isSaving}
              onClick={() => saveChanges(card)}
              style={{
                width: '50%',
                maxWidth: '190px',
                backgroundColor: colors.success,
                marginRight: 8,
              }}
            >
              Save
            </Button>
          )}
          {isMobile && (
            <>
              {isTemplate ? (
                <Button
                  small
                  outline
                  borderColor={colors.main}
                  className="compact-button"
                  onClick={() => {
                    if (!canEdit) {
                      showPermissionAlert()
                      return
                    }
                    showModal({ name: 'CREATE_CARD', template: card })
                  }}
                >
                  Use Template
                </Button>
              ) : (
                <>
                  {!isCardActive && (
                    <Button
                      small
                      outline
                      borderColor={colors.main}
                      style={{ marginRight: 12 }}
                      className="compact-button-outline"
                      leftIcon={<LightingIcon />}
                      onClick={async () => {
                        showModal({ name: 'TRIAL_ENDED' })
                      }}
                    >
                      Upgrade
                    </Button>
                  )}
                  <Button
                    small
                    outline
                    borderColor={colors.main}
                    className="compact-button"
                    onClick={async () => {
                      if (showSave) {
                        const confirmation = window.confirm(
                          'You have some unsaved work. Do you want to save it before continuing?'
                        )
                        if (confirmation) {
                          await saveChanges(card)
                        }
                      }
                      showModal({ name: 'SHARE_CARD', card })
                    }}
                  >
                    Share
                  </Button>
                  <Dropdown
                    isVisible={isMenuVisible}
                    placement="bottom-end"
                    onClose={hideMenu}
                    zIndex={9999999}
                    triggerRenderer={({ ref }) => (
                      <MenuButton
                        onClick={showMenu}
                        ref={ref}
                        style={{ cursor: 'pointer' }}
                      >
                        <MobileMenuIcon />
                      </MenuButton>
                    )}
                  >
                    <DropdownList
                      onClose={hideMenu}
                      items={[
                        {
                          content: 'Open page in new tab',
                          onItemSelect: () => {
                            const cardURL = card.slug
                              ? `${config.CARDS_BASE_URL}/${card.slug}`
                              : card.cardURL
                            window.open(cardURL, '_blank')
                          },
                          icon: <NewTabIcon color={colors.primary} size={18} />,
                        },
                        {
                          content: 'Duplicate page',
                          onItemSelect: () => {
                            if (!canEdit) {
                              showPermissionAlert()
                              return
                            }
                            showModal({ name: 'DUPLICATE_CARD', card })
                          },
                          icon: (
                            <DuplicateIcon color={colors.primary} size={18} />
                          ),
                        },
                        {
                          content: 'Create template from page',
                          onItemSelect: () => {
                            showModal({ name: 'CREATE_TEMPLATE_MODAL', card })
                          },
                          icon: <SaveIcon color={colors.primary} size={18} />,
                        },
                      ]}
                    />
                  </Dropdown>
                </>
              )}
            </>
          )}
        </TopBar>
        <MobileTopBar>
          <TabsContainer>
            {TABS.map((item) => {
              if (item.name === 'Integrations') {
                let hasIntegration = false
                INTEGRATIONS.forEach((integration) => {
                  if (card[integration.property]) {
                    hasIntegration = true
                  }
                })
                if (!hasIntegration) {
                  return null
                }
              }
              return (
                <Tab
                  key={item.name}
                  selected={tab.name === item.name}
                  onClick={() => {
                    setTab(item)
                    const editorContainer = isMobile
                      ? window.document.getElementById('app-container')
                      : window.document.getElementById('editor-container')
                    if (editorContainer) {
                      editorContainer.scrollTop = 0
                    }
                  }}
                >
                  {item.name}
                </Tab>
              )
            })}
          </TabsContainer>
          {isMobile && (
            <Row style={{ width: '100%', padding: '16px 16px 0 16px' }}>
              <Button
                small
                style={{ width: '100%' }}
                onClick={() => {
                  setMobilePreview(true)
                }}
              >
                Preview
              </Button>
              {showSave ? (
                <Button
                  small
                  loading={isSaving}
                  onClick={() => saveChanges(card)}
                  style={{
                    width: '100%',
                    backgroundColor: colors.success,
                    marginLeft: 8,
                  }}
                >
                  Save
                </Button>
              ) : tab.name === 'Content' ? (
                <AddContentButton
                  {...templateLockProps}
                  showAddSection={showAddSection}
                  style={{
                    width: '100%',
                    marginLeft: 8,
                  }}
                />
              ) : null}
            </Row>
          )}
        </MobileTopBar>
        {!isMobile && <Divider />}
        {tab.name === 'Profile' && (
          <Profile
            updateCard={updateCard}
            card={card}
            showSave={showSave}
            saveChanges={saveChanges}
            isSaving={isSaving}
            isTemplate={isTemplate}
            templateLockProps={templateLockProps}
          />
        )}
        {tab.name === 'Content' && (
          <Content
            templateLockProps={templateLockProps}
            showAddSection={showAddSection}
            updateCard={updateCard}
            card={card}
            showSave={showSave}
            saveChanges={saveChanges}
            isSaving={isSaving}
            isMobile={isMobile}
            openSectionModal={openSectionModal}
          />
        )}
        {tab.name === 'Design' && (
          <Design
            updateCard={updateCard}
            card={card}
            templateLockProps={templateLockProps}
          />
        )}
        {tab.name === 'Integrations' && (
          <Integrations updateCard={updateCard} card={card} />
        )}
        {tab.name === 'Settings' && (
          <Settings
            updateCard={updateCard}
            card={card}
            showSave={showSave}
            saveChanges={saveChanges}
            isSaving={isSaving}
            isTemplate={isTemplate}
            {...templateLockProps}
          />
        )}
      </Container>
      {hasAddSection && (
        <AddSectionModal
          card={card}
          updateCard={updateCard}
          data={hasAddSection}
          onClose={() => {
            showAddSection(false)
          }}
        />
      )}
      {mobilePreview && (
        <CardPreviewModal
          onClose={() => setMobilePreview(false)}
          card={card}
          isFreeCard={isFreeCard}
        />
      )}
      {openSettings.open && (
        <SectionSettingsModal
          templateLockProps={templateLockProps}
          card={card}
          updateCard={updateCard}
          section={card.sections[openSettings.index]}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          index={openSettings.index}
          onClose={() => {
            setOpenSettings({ open: false })
          }}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  width: 100%;
  padding-bottom: 80px;
`

const Divider = styled.div`
  width: 100%;
  height: 58px;
`

const BackWrapper = styled.div`
  position: absolute;
  left: 16px;
`

const MobileTopBar = styled.div`
  position: absolute;
  top: 58px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-shrink: 0;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  background-color: ${colors.white};
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  justify-content: flex-start;
  padding: 0;
  z-index: 100;
  @media (max-width: 767px) {
    position: sticky;
    top: 0;
    padding: 58px 0 16px 0;
  }
  @media (max-width: 1400px) {
    font-size: 15px;
  }
`

const MenuButton = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
`

const TabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-shrink: 0;
  padding: 0 8px;
  border-bottom: none;
  overflow: auto;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  @media (max-width: 767px) {
    border-bottom: 1px solid ${colors.border};
  }
  ::-webkit-scrollbar {
    display: none;
  }
`

const Tab = styled.span`
  font-size: 15px;
  text-align: center;
  white-space: pre;
  padding: 16px 8px;
  margin: 0 8px;
  box-sizing: border-box;
  color: ${(props) => (props.selected ? '#000' : '#555')};
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  cursor: pointer;
  border-bottom: ${(props) =>
    props.selected ? '1.5px solid #000' : '1.5px solid transparent'};
  @media (max-width: 767px) {
    margin: 0 5px;
  }
`
