import React, { useState } from 'react'
import styled from 'styled-components'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { QRCode } from 'react-qr-svg'
import Button from '../components/Button'
import { colors } from '../core/theme'
import { TabsWrapper } from '../components/Tabs'
import IconTab from '../components/Tabs/IconTab'
import { LinkIcon, QRIcon } from '../assets/icons'
import { Facebook, Twitter, LinkedIn } from '../components/SocialMedia'
import { firstLetterToUpper } from '../helpers/firstLetterToUpper'
import BaseModal from './BaseModal'
import { Column, Row } from '../components/Layout'
import { config } from '../core/config'
import FloatingInput from '../components/FloatingInput'

const SEND_TABS = ['link', 'QR']

function returnTabIcon(type, isSelected) {
  switch (type.toLowerCase()) {
    case 'link':
      return (
        <LinkIcon color={isSelected ? colors.main : colors.primary} size={28} />
      )
    case 'qr':
      return (
        <QRIcon fill={isSelected ? colors.main : colors.primary} size={24} />
      )
    default:
      return null
  }
}

const ShareCardModal = ({ card, onClose }) => {
  const [copied, setCopy] = useState(false)
  const [currentTab, setActiveTab] = useState('link')

  const onTabClicked = (tab) => () => setActiveTab(tab)

  const onCopy = () => {
    setCopy(true)
    setTimeout(() => setCopy(false), 1500)
  }

  const cardURL = card.slug
    ? `${config.CARDS_BASE_URL}/${card.slug}`
    : card.cardURL

  return (
    <BaseModal title={card.label} onClose={onClose} bodyHeight="380px">
      <TabsWrapper>
        {SEND_TABS.map((tab) => (
          <IconTab
            key={tab}
            onClick={onTabClicked(tab)}
            isSelected={currentTab === tab}
            icon={returnTabIcon(tab, currentTab === tab)}
            label={firstLetterToUpper(tab)}
            color={colors.main}
          />
        ))}
        <SocialMedia>
          <Facebook cardURL={cardURL} text={card.label} />
          <LinkedIn cardURL={cardURL} />
          <Twitter cardURL={cardURL} text={card.label} />
        </SocialMedia>
      </TabsWrapper>
      {currentTab === 'link' && (
        <Column>
          <Header>Share Page via Link</Header>
          <BodyWrapper>
            <Row style={{ marginTop: 16 }}>
              <FloatingInput
                label="Link"
                readOnly
                value={cardURL}
                style={{ marginBottom: 0 }}
              />
              <CopyToClipboard text={cardURL} onCopy={onCopy}>
                <Button
                  disabled={copied}
                  style={{ margin: '1px 0 0 12px', height: 48 }}
                >
                  {copied ? 'Copied' : 'Copy'}
                </Button>
              </CopyToClipboard>
            </Row>
          </BodyWrapper>
        </Column>
      )}
      {currentTab === 'QR' && (
        <Column>
          <Header>Share Page via QR code</Header>
          <BodyWrapper>
            <QRContainer>
              <QRCode style={{ width: 256 }} value={cardURL} />
            </QRContainer>
          </BodyWrapper>
        </Column>
      )}
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 26px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const SocialMedia = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  padding-right: 12px;
  height: 57px;
  @media (max-width: 767px) {
    display: none;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  width: 100%;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  justify-content: center;
  padding: 0 24px;
  height: 55px;
  font-size: 14px;
  font-weight: 500;
  @media (max-width: 767px) {
    padding: 0 16px;
  }
`

const QRContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  padding: 12px 0;
`

export default ShareCardModal
