import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { returnPresetIcon } from 'konect-react'
import BaseModal from './BaseModal'
import Button from '../components/Button'
import { colors } from '../core/theme'
import { deepCloneObject } from '../helpers/deepCloneObject'
import { ALL_SECTIONS } from '../core/constants'
import {
  ArrowRight,
  BooksyIcon,
  CalendlyIcon,
  ContactFormIcon,
  ContentFolderIcon,
  ContentImageIcon,
  ContentLinkIcon,
  PodcastIcon,
  SocialIcons,
  TextIcon,
  VideoIcon,
} from '../assets/icons'
import { generateUniqueID } from '../helpers/generateUniqueID'
import PremiumBadge from '../components/PremiumBadge'
import { useActions, useStore } from '../store'
import { licenseStatus } from '../helpers/licenseStatus'
import { Label } from '../components/Typography'
import { Column } from '../components/Layout'

export default function AddSectionModal({
  card,
  updateCard,
  onClose,
  sectionIndex,
  data = {},
}) {
  const { license } = useStore()
  const { isActive, isTrialActive } = licenseStatus(license)
  const { showModal } = useActions().modals
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [selectedSection, setSection] = useState({})
  const isButtonSection = data.type === 'buttons'
  const CONTENT_ITEMS = isButtonSection
    ? ALL_SECTIONS.filter((b) => b.placeholder)
    : ALL_SECTIONS
  const sections = card.sections || []
  const isOldEditor = sections.some((s) => s.type === 'buttons')
  const isTemplate = window.location.pathname.includes('template')

  const addSection = () => {
    const sectionToAdd = deepCloneObject(selectedSection)
    const isFreeSection =
      sectionToAdd.type === 'social-links' ||
      sectionToAdd.type === 'buttons' ||
      sectionToAdd.type === 'link' ||
      sectionToAdd.type === 'folder' ||
      sectionToAdd.type === 'description'

    if (!isActive && !isTrialActive) {
      if (!isFreeSection && !isTemplate) {
        showModal({ name: 'TRIAL_ENDED' })
        return
      }
    }

    sectionToAdd.id = generateUniqueID()
    delete sectionToAdd.info
    let isAddedToPreviousSection = false

    if (sectionToAdd.type === 'social-links') {
      sectionToAdd.links = []
      if (card.pageType === 'Modern') {
        sectionToAdd.layout = 'minimal'
      }
      setTimeout(() => {
        const addLinksButton = document.getElementById(sectionToAdd.id)
        if (addLinksButton) {
          addLinksButton.click()
        }
      }, 100)
    }
    if (sectionToAdd.type === 'media') {
      const media = sectionToAdd.media || []
      media.forEach((item) => {
        item.id = generateUniqueID()
      })
    }
    if (
      sectionToAdd.type === 'link' ||
      sectionToAdd.type === 'folder' ||
      sectionToAdd.type === 'podcast'
    ) {
      if (isOldEditor) {
        const previousSection = isButtonSection
          ? data
          : sections[sections.length - 1]
        const placeholder = {
          ...sectionToAdd.placeholder,
          id: generateUniqueID(),
        }
        delete sectionToAdd.placeholder
        if (previousSection && previousSection.type === 'buttons') {
          placeholder.title += ` #${previousSection.buttons.length + 1}`
          previousSection.buttons.push(placeholder)
          sectionToAdd.label = 'Buttons'
          sectionToAdd.type = 'buttons'
          sectionToAdd.buttons = previousSection.buttons
          isAddedToPreviousSection = previousSection
        } else {
          sectionToAdd.label = 'Buttons'
          sectionToAdd.type = 'buttons'
          placeholder.title += ` #1`
          sectionToAdd.buttons = [placeholder]
        }
      } else {
        delete sectionToAdd.placeholder
        const contentIndex = sections.filter(
          (s) => s.type === sectionToAdd.type
        ).length
        sectionToAdd.title += ` #${contentIndex + 1}`
      }
    }
    if (sectionToAdd.type === 'contact') {
      const fields = sectionToAdd.value || []
      fields.forEach((field) => {
        field.id = generateUniqueID()
        delete field.value
        delete field.error
      })
    }
    if (!isAddedToPreviousSection) {
      if (typeof sectionIndex === 'number') {
        sections.splice(sectionIndex + 1, 0, sectionToAdd)
      } else {
        sections.push(sectionToAdd)
      }
    }
    card.sections = sections
    updateCard(card)
    onClose()
    setTimeout(() => {
      if (sectionToAdd.type === 'social-links') {
        const addButton = document.getElementById(
          `add-${sectionToAdd.type}-${sectionToAdd.id}`
        )
        const sectionItem = document.getElementById(
          `section-${sectionToAdd.type}-${sectionToAdd.id}`
        )
        if (addButton) {
          addButton.click()
        }
        if (sectionItem) {
          sectionItem.click()
        }
      } else if (sectionToAdd.type === 'buttons') {
        const sectionItem = document.getElementById(
          `section-${sectionToAdd.type}-${
            isAddedToPreviousSection
              ? isAddedToPreviousSection.id
              : sectionToAdd.id
          }`
        )
        if (sectionItem && !isButtonSection) {
          sectionItem.click()
        }
        setTimeout(() => {
          const addedButton = document.getElementById(
            `${sectionToAdd.type}-${
              sectionToAdd.buttons[sectionToAdd.buttons.length - 1].id
            }`
          )
          if (addedButton) {
            addedButton.click()
          }
        }, 100)
      } else {
        const sectionElement = document.getElementById(
          `${sectionToAdd.type}-${sectionToAdd.id}`
        )
        if (sectionElement) {
          sectionElement.click()
        }
      }
    })
  }

  useEffect(() => {
    const onKeyUp = (event) => {
      if (event.keyCode === 13 && selectedSection.type) {
        addSection()
      }
    }
    document.addEventListener('keyup', onKeyUp, true)
    return () => {
      document.removeEventListener('keyup', onKeyUp, true)
    }
  }, [addSection])

  return (
    <BaseModal
      title="Select your content"
      maxWidth="800px"
      bodyHeight={isMobile ? 'auto' : '400px'}
      onClose={onClose}
      fullscreen
      actions={
        <>
          <Button
            onClick={onClose}
            color={colors.primary}
            outline
            style={{ margin: '0 6px' }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedSection.type}
            onClick={addSection}
            style={{ margin: '0 4px' }}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Add Content
          </Button>
        </>
      }
    >
      <SectionsContainer>
        {CONTENT_ITEMS.map((section) => {
          if (section.section) {
            return <SectionTitle>{section.title}</SectionTitle>
          }
          const selected = selectedSection.type === section.type
          const isFreeSection =
            section.type === 'social-links' ||
            section.type === 'buttons' ||
            section.type === 'link' ||
            section.type === 'folder' ||
            section.type === 'description'
          const showPremium = !isActive && !isTrialActive && !isFreeSection

          return (
            <SectionContainer
              selected={selected}
              onClick={() => {
                if (selected) {
                  setSection({})
                } else {
                  setSection(section)
                }
              }}
            >
              <div>{returnSectionIcon(section.type)}</div>
              <Column style={{ width: '100%', marginLeft: 12 }}>
                <Label weight="600">{section.label}</Label>
                <Label lineHeight="medium" style={{ paddingTop: 4 }}>
                  {section.info}
                </Label>
              </Column>
              {showPremium ? (
                <PremiumBadge
                  direction="bottom"
                  arrowPosition="top"
                  margin="0"
                />
              ) : (
                <Label
                  style={{
                    fontSize: 22,
                    flexShrink: 0,
                    lineHeight: 1.5,
                    fontWeight: '500',
                    textAlign: 'center',
                  }}
                >
                  {selected ? '-' : '+'}
                </Label>
              )}
            </SectionContainer>
          )
        })}
      </SectionsContainer>
    </BaseModal>
  )
}

export function returnSectionIcon(type, size = 50, image, section) {
  if (image && type === 'link') {
    return (
      <div
        style={{
          boxSizing: 'border-box',
          borderRadius: '12px',
          display: 'flex',
        }}
      >
        <img
          style={{
            width: size,
            height: size,
            objectFit: 'cover',
            borderRadius: '12px',
          }}
          src={image}
          alt={type}
        />
      </div>
    )
  }

  if (type === 'social-links') {
    return <SocialIcons size={size} />
  }
  if (type === 'folder') {
    return <ContentFolderIcon size={size} />
  }
  if (type === 'contact') {
    return <ContactFormIcon size={size} />
  }
  if (type === 'media') {
    return <ContentImageIcon size={size} />
  }
  if (type === 'description') {
    return <TextIcon size={size} />
  }
  if (type === 'video') {
    return <VideoIcon size={size} />
  }
  if (type === 'podcast') {
    return <PodcastIcon size={size} />
  }
  if (type === 'booksy') {
    return <BooksyIcon size={size} />
  }
  if (type === 'calendly') {
    return (
      <div
        style={{
          border: '1px solid #C3CDD3',
          boxSizing: 'border-box',
          borderRadius: '12px',
          display: 'flex',
        }}
      >
        <CalendlyIcon size={size} />
      </div>
    )
  }
  if (type === 'link' || type === 'buttons') {
    return (
      <div
        style={{
          display: 'flex',
          boxSizing: 'border-box',
          borderRadius: '12px',
          width: size,
          height: size,
        }}
      >
        {returnPresetIcon(
          { ...section, buttonType: 'image-left' },
          size,
          'preset-border',
          <ContentLinkIcon size={size} />
        )}
      </div>
    )
  }
  return ''
}

const SectionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: calc(50% - 16px);
  background-color: ${(props) => (props.selected ? '#EDEDED' : '#fff')};
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 200ms ease-out, background-color 200ms ease-out;
  box-sizing: border-box;
  padding: 10px 14px 10px 12px;
  margin: 8px;
  flex-shrink: 0;
  :active {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    margin: 8px 0;
    width: 100%;
  }
`

const SectionTitle = styled.span`
  font-size: 13.5px;
  font-weight: 600;
  color: ${colors.primary};
  line-height: 1.5;
  margin-top: 8px;
  margin-bottom: 4px;
  width: 100%;
`

const SectionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding: 16px 28px;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 12px 16px;
  }
`
