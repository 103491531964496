import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors, shadows } from '../../core/theme'
import PageLoader from '../../components/PageLoader'
import Insights from './Insights'
import { useActions, useStore } from '../../store'
import BackButton from '../../components/BackButton'
import { Row } from '../../components/Layout'
import { Title } from '../../components/Typography'
import Avatar from '../../components/Avatar'
import ActionButtons from '../../components/ActionButtons'
import TabsWrapper from '../../components/Tabs/TabsWrapper'
import Tab from '../../components/Tabs/Tab'
import ContactsSection from './ContactsSection'
import Button from '../../components/Button'
import { CheckIcon, ExcelIcon } from '../../assets/icons'
import { getCompletedCards } from '../../api/database-api'
import { createCsvFile } from '../../helpers/createCsvFile'

const CardSection = ({ selectedCard, updateReport }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const { setTab } = useActions().cards
  const { showToast } = useActions().toasts
  const { markAllContactsAsRead, updateDateFilter } = useActions().inbox
  const { cards, tab } = useStore().cards
  const cardID = window.location.pathname.split('/')[1]
  const card =
    selectedCard || cards.find((temp) => temp.cardID === cardID) || {}
  const { inboxCards, cardDateFilter } = useStore().inbox
  const { avatar = {} } = card
  const { initialLoading } = useStore().inbox
  const receivedCards = inboxCards[card.cardID] || []
  const unseenCards = receivedCards.filter((f) => !f.seen && f.completed).length

  const { prepareReport } = useActions().reports
  const [error, setError] = useState()
  const [report, setReport] = useState()

  useEffect(() => {
    if (card.cardID) {
      ;(async () => {
        setReport(null)
        if (updateReport) {
          updateReport(null)
        }
        setError(false)
        try {
          const newReport = await prepareReport({
            card,
            dateFilter: cardDateFilter,
          })
          setReport(newReport)
          if (updateReport) {
            updateReport(newReport)
          }
        } catch (e) {
          setError(e.message)
        }
      })()
    }
  }, [card, cardDateFilter])

  if (!card.label || initialLoading) {
    return (
      <Container>
        <PageLoader />
      </Container>
    )
  }

  return (
    <Container>
      <Header>
        <Row align="center">
          <div>
            <Avatar
              size={32}
              profile={{
                avatar:
                  avatar.url ||
                  require('../../assets/images/default-avatar.png').default,
                name: avatar.name,
              }}
            />
          </div>
          <Title
            padding="0 14px"
            weight="medium"
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {card.label}
          </Title>
        </Row>
        {isMobile && <BackButton backTo={`/${card.cardID}`} />}
        {!isMobile && (
          <ActionButtons
            inbox={receivedCards}
            unseenCards={unseenCards}
            tab={tab}
            card={card}
          />
        )}
      </Header>

      {isMobile && (
        <ActionButtons
          inbox={receivedCards}
          unseenCards={unseenCards}
          tab={tab}
          card={card}
        />
      )}

      <TabsWrapper>
        <Tab isSelected={tab === 0} onClick={() => setTab(0)}>
          Analytics
        </Tab>
        <Tab isSelected={tab === 1} onClick={() => setTab(1)}>
          Contacts{' '}
          {unseenCards > 0 ? (
            <strong style={{ color: colors.red }}>({unseenCards})</strong>
          ) : (
            ''
          )}
        </Tab>
        {!isMobile && tab === 1 && (
          <div className="desktop-contact-actions">
            {unseenCards > 0 && (
              <Button
                small
                outline
                borderColor={colors.main}
                className="compact-button mark-as-read"
                icon={<CheckIcon />}
                onClick={() => {
                  markAllContactsAsRead(receivedCards)
                }}
              >
                Mark all as read
              </Button>
            )}
            <Button
              small
              outline
              style={{ marginLeft: 10 }}
              className="compact-button-outline extra-height export-csv"
              onClick={async () => {
                if (receivedCards.length > 0) {
                  if (receivedCards.length > 1900) {
                    const result = await getCompletedCards({ card })
                    const rCards = []
                    result.forEach((doc) => {
                      rCards.push(doc.data())
                    })
                    createCsvFile(card, rCards)
                    return
                  }
                  createCsvFile(card, receivedCards)
                } else {
                  showToast({
                    content: 'No contacts to export.',
                  })
                }
              }}
              borderColor={colors.darkgreen}
              icon={<ExcelIcon />}
            >
              Export to CSV
            </Button>
          </div>
        )}
      </TabsWrapper>
      {tab === 0 && (
        <Insights
          dateFilter={cardDateFilter}
          report={report}
          updateDateFilter={updateDateFilter}
          error={error}
        />
      )}
      {tab === 1 && <ContactsSection card={card} />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  z-index: 1;
  overflow: auto;
  border-left: 1px solid ${colors.border};
  background-color: ${colors.background};
  box-shadow: ${shadows.menu};
  border-top-left-radius: 10px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    border-left: none;
    padding-bottom: 64px;
    border-left: none;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${colors.border};
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 0 16px;
  min-height: 61px;
  max-height: 61px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
`

export default CardSection
