import React from 'react'
import styled from 'styled-components'
import { cardColors, colors } from '../../core/theme'
import { lightOrDark } from '../../helpers/lightOrDark'
import DesignColorPicker from '../DesignColorPicker'
import { Label } from '../Typography'

export default function Design({ updateCard, card, templateLockProps }) {
  const {
    primaryColor = cardColors.primary,
    backgroundColor = cardColors.backgroundColor,
    cardBackgroundColor = cardColors.cardBackgroundColor,
    textColor = cardColors.fontPrimary,
    socialIconColor = cardColors.socialIconColor,
    highlightedIconColor = cardColors.highlightedIconColor,
  } = card
  const buttonsTheme = lightOrDark(primaryColor)
  const buttonTextColor =
    card.buttonTextColor ||
    (buttonsTheme === 'dark' ? colors.white : colors.black)

  const handleColorChange =
    (type) =>
    ({ hex }) => {
      card[type] = hex
      updateCard(card)
    }

  return (
    <Container>
      <ColorContainer>
        <Label weight="medium">Background Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Background Color"
          onChangeComplete={handleColorChange('cardBackgroundColor')}
          color={cardBackgroundColor}
          presetColors={[
            '#ffffff',
            '#dddddd',
            '#bbbbbb',
            '#999999',
            '#676767',
            '#333333',
            '#000000',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Desktop Background Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Desktop Background Color"
          onChangeComplete={handleColorChange('backgroundColor')}
          color={backgroundColor}
          presetColors={[
            '#f5f5f5',
            '#FCEBED',
            '#fff8e6',
            '#f1faf1',
            '#edf9f8',
            '#000000',
            '#ffffff',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Text Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Text Color"
          onChangeComplete={handleColorChange('textColor')}
          color={textColor}
          presetColors={[
            '#000000',
            '#333333',
            '#676767',
            '#999999',
            '#bbbbbb',
            '#dddddd',
            '#ffffff',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Button Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Button Color"
          color={primaryColor}
          onChangeComplete={handleColorChange('primaryColor')}
          presetColors={[
            '#405de6',
            '#e23b4d',
            '#ffc107',
            '#74d674',
            '#50ccbd',
            '#000000',
            '#ffffff',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Button Text Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Button Text Color"
          color={buttonTextColor}
          onChangeComplete={handleColorChange('buttonTextColor')}
          presetColors={[
            '#ffffff',
            '#405de6',
            '#e23b4d',
            '#ffc107',
            '#74d674',
            '#50ccbd',
            '#000000',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Social Icon Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Social Icon Color"
          onChangeComplete={handleColorChange('socialIconColor')}
          color={socialIconColor}
          presetColors={[
            '#000000',
            '#ffffff',
            '#405de6',
            '#e23b4d',
            '#ffc107',
            '#74d674',
            '#50ccbd',
          ]}
        />
      </ColorContainer>
      <ColorContainer>
        <Label weight="medium">Highlighted Social Icon Color</Label>
        <DesignColorPicker
          {...templateLockProps}
          label="Highlighted Social Icon Color"
          onChangeComplete={handleColorChange('highlightedIconColor')}
          color={highlightedIconColor}
          presetColors={[
            '#f5f7f8',
            '#dcdedf',
            '#c4c5c6',
            '#939494',
            '#585858',
            '#000000',
            '#ffffff',
          ]}
        />
      </ColorContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 28px;
  padding-bottom: 0;
  height: 100%;
  @media (max-width: 767px) {
    padding: 20px;
  }
`

const ColorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 14px 0;
`
