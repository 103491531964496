import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions } from '../store'

const DeleteAccessoryModal = ({ accessory, onClose }) => {
  const { showToast } = useActions().toasts
  const { removeAccessory } = useActions().accessories
  const [isDeleting, setDeleteStatus] = useState(false)

  const deleteAccessory = async () => {
    if (isDeleting) return
    setDeleteStatus(true)
    try {
      await removeAccessory(accessory)
    } catch (e) {
      console.error(e)
      showToast({
        content: 'Unexpected error occurred. Please try again.',
      })
    }
    setDeleteStatus(false)
    onClose(null)
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DeleteIcon size={60} color={colors.error} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={deleteAccessory}
            loading={isDeleting}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, remove
          </Button>
        </>
      }
      header={`Remove ${accessory.type || 'accessory'}?`}
      description={`You're about to remove this ${
        accessory.type || 'accessory'
      }. Are you sure you want to continue?`}
    />
  )
}

export default DeleteAccessoryModal
