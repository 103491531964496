import React, { useEffect, useRef } from 'react'
import { Manager, Reference, Popper } from 'react-popper'
import styled from 'styled-components'
import { animations, colors } from '../../core/theme'

const Dropdown = ({
  children,
  zIndex,
  triggerRenderer,
  isVisible,
  cssStyle,
  onClose,
  placement,
  eventsEnabled,
  positionFixed,
  referenceElement,
  modifiers,
  extraStyle = {},
  hideOnLeave,
}) => {
  const popupRef = useRef()

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        onClose &&
        popupRef.current &&
        !popupRef.current.contains(event.target)
      ) {
        onClose()
      }
    }

    document.addEventListener('click', handleDocumentClick)
    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [onClose, popupRef])

  const onMouseLeave = () => {
    if (hideOnLeave) {
      if (onClose) {
        onClose()
      }
    }
  }

  return (
    <Manager>
      <Reference>{triggerRenderer}</Reference>
      {isVisible && (
        <Popper
          innerRef={popupRef}
          placement={placement}
          eventsEnabled={eventsEnabled}
          positionFixed={positionFixed}
          referenceElement={referenceElement}
          modifiers={modifiers}
        >
          {(
            { ref, style, placement } // eslint-disable-line
          ) => (
            <Container
              ref={ref}
              style={{ ...style, zIndex, ...extraStyle }}
              cssStyle={cssStyle}
              data-placement={placement}
              onMouseLeave={onMouseLeave}
            >
              {children}
            </Container>
          )}
        </Popper>
      )}
    </Manager>
  )
}

const Container = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 10px 0 rgba(66, 77, 87, 0.2);
  border: 1px solid ${colors.border};
  border-radius: 6px;
  min-width: 210px;
  max-width: 336px;
  width: auto;
  animation: ${animations.fadeIn} 100ms linear;
  display: flex;
  flex-direction: column;

  &:focus {
    outline: none;
  }

  ${(props) => props.cssStyle}
`

Dropdown.defaultProps = {
  zIndex: 20,
  closeOnEscPress: true,
  closeOnEnterPress: false,
  placement: 'bottom-start',
}

export default Dropdown
