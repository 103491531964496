import React from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import CardBase from './CardBase'
import { findHighestValueInArray } from '../../helpers/findHighestValueInArray'
import { PIE_CHART_COLORS } from '../../core/constants'

const ActivityCard = ({ data }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const highestValue = findHighestValueInArray(data)

  return (
    <CardBase
      title="Activity"
      body={
        <Container>
          <BarChart
            width={isMobile ? window.innerWidth * 0.85 : 700}
            height={isMobile ? 200 : 320}
            data={data}
            margin={{
              top: 0,
              right: 10,
              left: -25,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="name" />
            <YAxis domain={[0, Math.round(highestValue * 1.25)]} />
            <Tooltip />
            <Legend />
            <Bar dataKey="Visits" fill={PIE_CHART_COLORS[0]} />
            <Bar dataKey="Interactions" fill={PIE_CHART_COLORS[1]} />
          </BarChart>
        </Container>
      }
    />
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  font-size: 14px;
`

export default ActivityCard
