import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import { colors } from '../core/theme'
import TemplateLock from './TemplateLock'

export default function AddContentButton({
  showAddSection,
  style,
  showLock,
  isLocked = () => {},
  onLock,
  card = {},
  showLockMessage,
}) {
  const label = 'Add Content'
  return (
    <AddContentButtonContainer style={style}>
      <Button
        small
        onClick={() => {
          if (isLocked(label)) {
            showLockMessage(`Content is locked and can't be edited.`)
            return
          }
          showAddSection(true)
        }}
        style={{
          width: '100%',
          backgroundColor: colors.primary,
        }}
      >
        Add Content
      </Button>
      {(showLock || (card.cardID && isLocked(label))) && (
        <TemplateLock onLock={onLock} label={label} isLocked={isLocked} />
      )}
    </AddContentButtonContainer>
  )
}

const AddContentButtonContainer = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
`
