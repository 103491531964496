import React, { useState } from 'react'
import styled from 'styled-components'
import { isValidURL } from '../../helpers/validators/isValidURL'
import FloatingInput from '../FloatingInput'

export default function Calendly({ section, card, updateCard }) {
  const { question, description } = section
  const [error, setError] = useState()

  const onQuestionChange = (e) => {
    section.question = e.target.value
    updateCard(card)
  }

  const onDescriptionChange = (e) => {
    section.description = e.target.value
    updateCard(card)
  }

  return (
    <EditSectionContainer>
      <FloatingInput
        onChange={(e) => {
          const url = e.target.value
          if (!url) {
            setError("Calendly link can't be empty.")
            section.value = e.target.value
            updateCard(card)
            return
          }
          if (!isValidURL(url)) {
            setError('Calendly link must be valid address.')
            section.value = e.target.value
            updateCard(card)
            return
          }
          section.value = e.target.value
          updateCard(card)
          setError(false)
        }}
        error={error}
        required
        label="Calendly link *"
        value={section.value}
      />
      <FloatingInput
        onChange={onQuestionChange}
        label="Header"
        value={question}
        style={{ marginTop: 8 }}
      />
      <FloatingInput
        onChange={onDescriptionChange}
        label="Description"
        value={description}
        style={{ marginTop: 8 }}
      />
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;
`
