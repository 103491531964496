import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { DeleteIcon } from '../assets/icons'
import { Caption, Label } from './Typography'
import FloatingInput from './FloatingInput'

const MailchimpIntegrationDetails = ({ card, targetObject, updateCard }) => {
  const { mailchimp } = targetObject
  if (!mailchimp) return null

  const {
    tags = [
      {
        createdTimestamp: new Date().getTime(),
      },
    ],
  } = mailchimp

  const addEmptyTag = () => {
    tags.push({ createdTimestamp: new Date().getTime() })
    mailchimp.tags = tags
    updateCard(card)
  }

  return (
    <Container>
      <Label padding="8px 0 0 0" weight="medium">
        Extra tags: <Caption color="secondary">(optional)</Caption>
      </Label>
      <Caption padding="8px 0 16px 0" lineHeight="medium" variant="secondary">
        Assign tags to contacts after being created.
      </Caption>
      {tags.map((tag, i) => {
        const { name, createdTimestamp } = tag
        return (
          <MapContainer key={createdTimestamp}>
            <FloatingInput
              value={name}
              placeholder="Enter tag name"
              style={{ marginBottom: 0 }}
              onChange={(e) => {
                tags[i] = {
                  ...tags[i],
                  name: e.target.value,
                }
                mailchimp.tags = tags
                updateCard(card)
              }}
            />
            <DeleteContainer
              onClick={() => {
                tags.splice(i, 1)
                mailchimp.tags = tags
                updateCard(card)
              }}
            >
              <DeleteIcon size={18} />
            </DeleteContainer>
          </MapContainer>
        )
      })}
      <Label
        weight="medium"
        variant="primary"
        onClick={addEmptyTag}
        style={{
          marginTop: 4,
          marginBottom: 8,
          alignSelf: 'flex-start',
          cursor: 'pointer',
        }}
      >
        + Add another
      </Label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 8px;
  box-sizing: border-box;
  margin-bottom: 8px;
  width: 100%;
  @media (max-width: 767px) {
    padding-left: 0;
  }
`

const MapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 14px;
`

const DeleteContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  transition: fill 200ms ease-out;
  :hover {
    fill: ${colors.red};
  }
`

export default MailchimpIntegrationDetails
