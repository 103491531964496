import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: ${(props) => props.align || 'initial'};
  justify-content: ${(props) => props.justify || 'initial'};
  padding: ${(props) => props.padding || 0};
  margin: ${(props) => props.margin || 0};
  flex: ${(props) => props.flex || 'initial'};
`

export default Row
