import React, { useState } from 'react'
import styled from 'styled-components'
import { isValidURL } from '../../helpers/validators/isValidURL'
import Input from '../Input'
import NumberPicker from '../NumberPicker'

export default function Embed({ section, card, updateCard }) {
  const { question, description } = section
  const [error, setError] = useState()

  const onQuestionChange = (e) => {
    section.question = e.target.value
    updateCard(card)
  }

  const onDescriptionChange = (e) => {
    section.description = e.target.value
    updateCard(card)
  }

  return (
    <EditSectionContainer>
      <Input
        onChange={(e) => {
          const url = e.target.value
          if (!url) {
            setError("Iframe link can't be empty.")
            section.value = e.target.value
            updateCard(card)
            return
          }
          if (!isValidURL(url)) {
            setError('Iframe link must be valid address.')
            section.value = e.target.value
            updateCard(card)
            return
          }
          section.value = e.target.value
          updateCard(card)
          setError(false)
        }}
        error={error}
        required
        label="Link"
        description="Link to website that will be displayed."
        value={section.value}
      />
      <Input onChange={onQuestionChange} label="Header" value={question} />
      <Input
        onChange={onDescriptionChange}
        label="Description"
        value={description}
      />
      <NumberPicker
        label="Size"
        min="10"
        max="5000"
        unit="px"
        name="number"
        style={{ padding: 4 }}
        onChange={(e) => {
          const value = parseInt(e.target.value, 10)
          if (value > 0 && value < 5000) {
            section.height = value
            updateCard(card)
          }
        }}
        value={section.height}
      />
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 24px;
`
