/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import styled from 'styled-components'
import { ArrowDown, ArrowUp } from '../assets/icons'
import { animations, colors } from '../core/theme'

export default function FolderButton({
  label,
  error,
  style,
  inputStyle,
  className,
  children,
}) {
  const [open, setOpen] = useState()
  return (
    <Container style={style} className={className}>
      <InputField
        onClick={() => setOpen((prev) => !prev)}
        style={inputStyle}
        error={error}
      >
        {label}
        {open ? (
          <ArrowUp size={24} color={colors.primary} />
        ) : (
          <ArrowDown size={24} color={colors.primary} />
        )}
      </InputField>
      {open && <ItemsWrapper>{children}</ItemsWrapper>}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 18px;
`

const InputField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${(props) => (props.error ? colors.error : colors.border)};
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 14px;
  height: 50px;
  width: 100%;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.primary};
  box-sizing: border-box;
  transition: box-shadow 0.15s ease-in-out;
  background-color: white;
  box-shadow: 0 1px 3px rgba(50, 50, 93, 0.05);
  :hover {
    border: 1px solid
      ${(props) => (props.error ? colors.error : 'rgba(0, 0, 0, 0.87)')};
  }
  @media (max-width: 767px) {
    :hover {
      border: 1px solid
        ${(props) => (props.error ? colors.error : colors.border)};
    }
  }
`

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  will-change: opacity;
  animation: ${animations.fadeIn} 500ms ease-out;
  margin-top: 16px;
`
