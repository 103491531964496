import React, { useState } from 'react'
import styled from 'styled-components'
import AvatarUpload from '../AvatarUpload'
import BannerUpload from '../BannerUpload'
import CardSlugEditor from '../CardSlugEditor'
import CompanyLogoUpload from '../CompanyLogoUpload'
import FloatingInput from '../FloatingInput'
import TagsEditor from '../TagsEditor'
import { VerifiedIcon } from '../../assets/icons'
import PremiumBadge from '../PremiumBadge'
import { useActions, useStore } from '../../store'
import { licenseStatus } from '../../helpers/licenseStatus'
import AdvancedContactDetailsModal from '../../modals/AdvancedContactDetailsModal'
import ExchangeFormModal from '../../modals/ExchangeFormModal'
import SwitchInput from '../SwitchInput'

export default function Profile({
  updateCard,
  card,
  showSave,
  isSaving,
  saveChanges,
  isTemplate,
  templateLockProps,
}) {
  const { license } = useStore()
  const {
    personalDetails = {},
    contactButton = {},
    enableSaveContact = true,
  } = card
  const { name, title, company, headline } = personalDetails
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const { showModal } = useActions().modals
  const [openAdvancedSettings, setOpenAdvancedSettings] = useState(false)
  const [openExchangeFormSettings, setExchangeFormSettings] = useState(false)

  return (
    <>
      <Container>
        <Uploader>
          <BannerUpload
            {...templateLockProps}
            card={card}
            updateCard={updateCard}
          />
          <AvatarUploadContainer>
            <AvatarUpload
              {...templateLockProps}
              card={card}
              updateCard={updateCard}
            />
            <CompanyLogoContainer>
              <CompanyLogoUpload
                {...templateLockProps}
                card={card}
                updateCard={updateCard}
              />
            </CompanyLogoContainer>
          </AvatarUploadContainer>
        </Uploader>
        <CardSlugEditor
          card={card}
          updateCard={updateCard}
          isTemplate={isTemplate}
        />
        <FloatingInput
          {...templateLockProps}
          label="Full Name"
          value={name}
          style={{ marginTop: 8 }}
          onChange={(e) => {
            personalDetails.name = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
        <FloatingInput
          {...templateLockProps}
          label="Job Title"
          value={title}
          style={{ marginTop: 8 }}
          onChange={(e) => {
            personalDetails.title = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
        <FloatingInput
          {...templateLockProps}
          label="Company"
          style={{ marginTop: 8 }}
          value={company}
          onChange={(e) => {
            personalDetails.company = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
        <FloatingInput
          {...templateLockProps}
          multiline
          label="Bio"
          value={headline}
          style={{ marginTop: 12 }}
          onChange={(e) => {
            personalDetails.headline = e.target.value
            card.personalDetails = personalDetails
            updateCard(card)
          }}
        />
        <TagsEditor
          {...templateLockProps}
          card={card}
          updateCard={updateCard}
        />
        <SwitchInput
          {...templateLockProps}
          label="Verified Profile"
          value={personalDetails.verified && isCardActive}
          onClick={(e) => {
            if (!isCardActive) {
              e.stopPropagation()
              e.preventDefault()
              showModal({ name: 'TRIAL_ENDED' })
              return
            }
            if (!personalDetails.verified) {
              if (!card.personalDetails) {
                card.personalDetails = {}
              }
              card.personalDetails.verified = true
              updateCard(card)
            } else {
              delete card.personalDetails.verified
              updateCard(card)
            }
          }}
          nodeLabel={
            <>
              Verified Profile{' '}
              <div
                style={{
                  marginLeft: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <VerifiedIcon />
              </div>
              <PremiumBadge
                type="feature"
                direction="bottom"
                arrowPosition="top"
                size={14}
                zIndex={1}
                margin="0 8px"
                premiumFeature
                card={card}
              />
            </>
          }
        />
        <SwitchInput
          {...templateLockProps}
          label="Enable Sharing Options"
          value={card.shareCard}
          onChange={() => {
            if (!card.shareCard) {
              card.shareCard = []
              updateCard(card)
            } else {
              delete card.shareCard
              updateCard(card)
            }
          }}
        />
        <SwitchInput
          {...templateLockProps}
          label="Enable Save Contact"
          value={enableSaveContact}
          onChange={(value) => {
            if (!value) {
              contactButton.hideContactButton = true
              card.contactButton = contactButton
            }
            card.enableSaveContact = value
            updateCard(card)
          }}
          style={{ marginBottom: 0 }}
          inputStyle={{
            borderBottomLeftRadius: enableSaveContact ? 0 : 8,
            borderBottomRightRadius: enableSaveContact ? 0 : 8,
            boxShadow: 'none',
          }}
        />
        {enableSaveContact && (
          <SwitchInput
            {...templateLockProps}
            label="Show Button"
            value={!contactButton.hideContactButton}
            onChange={(value) => {
              contactButton.hideContactButton = !value
              card.contactButton = contactButton
              updateCard(card)
            }}
            style={{ marginBottom: 0 }}
            inputStyle={{
              boxShadow: 'none',
              borderRadius: 0,
              borderTop: 'none',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              height: contactButton.hideContactButton ? '50px' : 'auto',
              padding: contactButton.hideContactButton
                ? '10px 14px'
                : '14px 14px',
            }}
            extraChild={
              !contactButton.hideContactButton && (
                <FloatingInput
                  style={{
                    marginTop: 24,
                    marginBottom: 10,
                    width:
                      templateLockProps.showLock ||
                      (card.cardID && templateLockProps.isLocked('Show Button'))
                        ? '98%'
                        : '100%',
                  }}
                  label="Title"
                  value={contactButton.title}
                  readOnly={templateLockProps.isLocked('Show Button')}
                  onClick={() => {
                    if (templateLockProps.isLocked('Show Button')) {
                      templateLockProps.showLockMessage()
                    }
                  }}
                  onChange={(e) => {
                    contactButton.title = e.target.value
                    card.contactButton = contactButton
                    updateCard(card)
                  }}
                />
              )
            }
          />
        )}
        {enableSaveContact && (
          <>
            <SwitchInput
              settings
              label="Contact Details"
              value={enableSaveContact}
              onClick={() => {
                setOpenAdvancedSettings(true)
              }}
              style={{ marginBottom: 0 }}
              inputStyle={{
                borderRadius: 0,
                borderTop: 'none',
                boxShadow: 'none',
              }}
            />
            <SwitchInput
              settings
              label="Contact Exchange Form"
              value={enableSaveContact}
              onClick={() => {
                if (window.ks_show_exchangeModal) {
                  const canUseFeature = isCardActive || isTrialActive
                  const popupForm =
                    canUseFeature && card.sections.find((s) => s.enablePopup)
                  if (popupForm) {
                    window.ks_show_exchangeModal(popupForm)
                  }
                }
                setExchangeFormSettings(true)
              }}
              style={{ marginBottom: 0 }}
              inputStyle={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTop: 'none',
                boxShadow: 'none',
              }}
            />
          </>
        )}
      </Container>
      {openAdvancedSettings && (
        <AdvancedContactDetailsModal
          templateLockProps={templateLockProps}
          card={card}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setOpenAdvancedSettings(false)
          }}
        />
      )}
      {openExchangeFormSettings && (
        <ExchangeFormModal
          templateLockProps={templateLockProps}
          card={card}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          onClose={() => {
            setExchangeFormSettings(false)
          }}
        />
      )}
    </>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  padding: 20px 24px;
  padding-bottom: 0;
  @media (max-width: 767px) {
    padding: 20px;
  }
`

const Uploader = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 70px;
`

const AvatarUploadContainer = styled.div`
  position: relative;
  position: absolute;
  bottom: 0;
  margin-bottom: -70px;
`

const CompanyLogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  margin-left: 90px;
  z-index: 1;
`
