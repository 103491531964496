export function cannyGetQueryParameterByName(name) {
  const pairStrings = window.location.search.slice(1).split('&')
  const pairs = pairStrings.map((pair) => {
    return pair.split('=')
  })
  return pairs.reduce((value, pair) => {
    if (value) return value
    return pair[0] === name ? decodeURIComponent(pair[1]) : null
  }, null)
}
