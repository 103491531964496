import React, { useState } from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { ArrowRight } from '../assets/icons'
import { useActions } from '../store'
import { Label } from '../components/Typography'
import FloatingInput from '../components/FloatingInput'

const FacebookPixelModal = ({ onClose, card, updateCard }) => {
  const { showToast } = useActions().toasts
  const [loading, setLoading] = useState(false)
  const [id, setId] = useState('')
  const [error, setError] = useState(false)

  const connectIntegration = async () => {
    if (loading) return

    if (!id) {
      setError("Meta Pixel ID can't be empty.")
      return
    }

    setLoading(true)

    await updateCard(
      {
        ...card,
        facebook_pixel: {
          id,
          createdTimestamp: new Date().getTime(),
        },
      },
      true
    )

    showToast({
      content: 'Meta Pixel connected successfully.',
      variant: 'success',
    })

    setLoading(false)
    onClose(null)
  }

  return (
    <BaseModal
      title="Meta Pixel"
      maxWidth="640px"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} outline style={{ margin: '0 8px' }}>
            Cancel
          </Button>
          <Button
            onClick={connectIntegration}
            loading={loading}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Connect
          </Button>
        </>
      }
    >
      <BodyWrapper>
        <Info variant="secondary">
          Copy and paste your Meta Pixel ID below.
        </Info>
        <FloatingInput
          value={id}
          onChange={(e) => {
            setId(e.target.value)
            setError(false)
          }}
          description={
            <span>
              You can find your ID{' '}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
                style={{ fontWeight: '500' }}
              >
                here.
              </a>
            </span>
          }
          label="Meta Pixel ID"
          error={error}
          placeholder="e.g. 287882272511111"
          style={{ marginTop: 8 }}
        />
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 28px 16px 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`

const Info = styled(Label)`
  padding: 20px 0;
  @media (max-width: 767px) {
    padding: 4px 0 20px 0;
  }
`

export default FacebookPixelModal
