export function findHighestValueInArray(array) {
  if (!array) return 0
  if (array.length === 0) return 0

  let highestValue = array[0]
  let canBeHighest = true

  array.forEach((item, i) => {
    const count = item.count || 0
    const highestCount = highestValue.count || 0

    if (count > highestCount) {
      highestValue = item
      canBeHighest = true
    }
    if (count === highestCount && i !== 0) {
      canBeHighest = false
    }
  })

  if (!canBeHighest) return null

  highestValue.highest = true
  return highestValue.count
}
