import React from 'react'
import styled from 'styled-components'
import { LinkedInIcon } from '../../assets/icons'
import { Tooltip, TooltipContent } from '../Tooltip'

const LinkedIn = ({ cardURL }) => (
  <IconContainer>
    <Tooltip
      trigger="hover"
      content={
        <TooltipContent arrowPosition="top">
          Share page on LinkedIn
        </TooltipContent>
      }
      direction="bottom"
    >
      <Link
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
          cardURL
        )}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkedInIcon size={22} />
      </Link>
    </Tooltip>
  </IconContainer>
)

const IconContainer = styled.div`
  cursor: pointer;
  margin: 0 12px 0 0;
`

const Link = styled.a`
  text-decoration: none;
  transition: opacity 0.3s ease-out;
  :hover {
    opacity: 0.7;
  }
`

export default LinkedIn
