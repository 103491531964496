function hasWhiteSpace(s) {
  return /\s/.test(s)
}

// eslint-disable-next-line no-useless-escape
const format = /[ `#^&*()=\[\]{};':"\\|,<>\/?~]/

export function slugValidator(slug) {
  if (!slug) return "Slug can't be empty."
  if (hasWhiteSpace(slug)) {
    return "Slug can't contains spaces."
  }
  if (format.test(slug)) {
    return "Slug can't contain special characters."
  }
  return ''
}
