import React, { useState } from 'react'
import ActionModal from './ActionModal'
import { DeleteIcon } from '../assets/icons'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { useActions, useStore } from '../store'

const DeleteFolderModal = ({ folder, onClose }) => {
  const store = useStore()
  const { showToast } = useActions().toasts
  const { license, cards } = useActions()
  const [isDeleting, setDeleteStatus] = useState(false)

  const deleteFolder = async () => {
    setDeleteStatus(true)
    try {
      await license.removeFolder(folder)
      cards.changeFolder(store.license.folders[0])
    } catch (e) {
      showToast({ content: 'Failed to remove folder. Please reload the app.' })
      console.error(e)
    }
    setDeleteStatus(false)
    onClose()
  }

  return (
    <ActionModal
      onClose={onClose}
      icon={<DeleteIcon size={64} color={colors.error} />}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={deleteFolder}
            loading={isDeleting}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, delete
          </Button>
        </>
      }
      header={`Delete ${folder.name}?`}
      description={
        <span>
          You’re about to delete one of your folders. Cards in that folder will{' '}
          <strong style={{ color: colors.red }}>NOT</strong> be deleted.
        </span>
      }
    />
  )
}

export default DeleteFolderModal
