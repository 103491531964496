import React, { useRef } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import Modal from './Modal'
import { animations, colors } from '../core/theme'
import { CloseIcon } from '../assets/icons'
import { Paragraph } from '../components/Typography'

const BaseModal = ({
  title,
  onClose,
  actions,
  children,
  bodyHeight,
  maxWidth,
  fullscreen,
  forceFullscreen,
  headerClassName,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const ref = useRef()
  const onModalClose = () => {
    if (ref && ref.current) {
      ref.current.classList.add('modal-out-animation')
    }
    setTimeout(onClose, 180)
  }

  if ((isMobile && fullscreen) || forceFullscreen) {
    return (
      <Modal
        onClose={onModalClose}
        forwardRef={ref}
        forceFullscreen={forceFullscreen}
      >
        <MobileContainer>
          <Header className={headerClassName}>
            <Paragraph weight="medium" style={{ fontSize: '14.5px' }}>
              {title}
            </Paragraph>
            <CloseButton onClick={onModalClose}>
              <CloseIcon color={colors.darkgrey} />
            </CloseButton>
          </Header>
          <MobileBody>{children}</MobileBody>
          {actions && <ActionsContainer>{actions}</ActionsContainer>}
        </MobileContainer>
      </Modal>
    )
  }

  return (
    <Modal onClose={onModalClose} forwardRef={ref}>
      <Container maxWidth={maxWidth}>
        <Header className={headerClassName}>
          <Paragraph weight="600" style={{ fontSize: '14.5px' }}>
            {title}
          </Paragraph>
          <CloseButton onClick={onModalClose}>
            <CloseIcon color={colors.darkgrey} />
          </CloseButton>
        </Header>
        <Body height={bodyHeight}>{children}</Body>
        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </Container>
    </Modal>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${(props) => props.maxWidth || '680px'};
  border-radius: 8px;
  box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.3);
  background-color: ${colors.white};
  animation: ${animations.dialogScaleIn} 200ms ease-out;
  @media (max-width: 767px) {
    width: 96%;
    margin: 0;
  }
`

const MobileContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${colors.white};
  animation: ${animations.dialogScaleIn} 200ms ease-out;
  border-left: 1px solid ${colors.border};
  @media (max-width: 767px) {
    border-left: none;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${(props) => props.height || '280px'};
  overflow: auto;
  max-height: 75vh;
  box-sizing: border-box;
  background-color: ${colors.main}02;
  @media (max-width: 767px) {
    min-height: ${(props) => props.height || '240px'};
    max-height: 65vh;
  }
`

const MobileBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  background-color: ${colors.main}02;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-end;
  padding: 16px;
  box-sizing: border-box;
  border-top: 1px solid ${colors.border};
  @media (max-width: 1280px) {
    padding: 12px 16px;
  }
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white};
  padding: 16.5px 24px;
  box-sizing: border-box;
  border-bottom: 1px solid ${colors.border};
  border-radius: 8px 8px 0 0;
  @media (max-width: 767px) {
    padding: 15px 14px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  :hover {
    opacity: 0.7;
  }
  :active {
    opacity: 0.5;
  }
`

export default BaseModal
