import React from 'react'
import styled from 'styled-components'

function returnAccessoryLink(link, ref) {
  if (ref) return `${link}?ref=${ref}`
  return link
}

export default function ShopAccessory({ accessory, license }) {
  return (
    <Container>
      <a
        href={returnAccessoryLink(accessory.link, license.konectAffiliateRef)}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          alt={accessory.name}
          src={accessory.image}
          className={accessory.className}
        />
      </a>
      <Name>{accessory.name}</Name>
      <Price
        href={returnAccessoryLink(accessory.link, license.konectAffiliateRef)}
        target="_blank"
        rel="noopener noreferrer"
      >
        View Product {'    '}|{'    '} ${accessory.price}
      </Price>
    </Container>
  )
}

const Container = styled.div`
  width: calc(100% / 3);
  padding: 46px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  @media (max-width: 1000px) {
    width: calc(100% / 2);
    padding: 12px;
    margin-bottom: 28px;
  }
`

const Name = styled.span`
  font-size: 15px;
  margin-top: 44px;
  @media (max-width: 1000px) {
    font-size: 14px;
    margin-top: 24px;
  }
`

const Price = styled.a`
  font-size: 14px;
  padding: 16px 0;
  width: 100%;
  font-weight: 600;
  background-color: #ededed;
  border: 1px solid #c3cdd3;
  color: #000;
  border-radius: 8px;
  text-align: center;
  white-space: pre;
  cursor: pointer;
  margin-top: 32px;
  @media (max-width: 1000px) {
    font-size: 13px;
    margin-top: 24px;
    padding: 12px 0;
    white-space: initial;
  }
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.8;
    transform: scale(0.98);
  }
  :visited {
    color: #000;
  }
`
