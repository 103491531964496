import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { colors } from '../core/theme'
import { BackIcon } from '../assets/icons'
import { Label } from './Typography'

const BackButton = ({ backTo, onClick, fill = colors.primary }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const history = useHistory()

  const iconSize = isMobile ? { width: 14, height: 12 } : {}

  if (onClick) {
    return (
      <BackContainer onClick={onClick}>
        <BackIcon color={fill} {...iconSize} />
        {!isMobile && <BackLabel>Back</BackLabel>}
      </BackContainer>
    )
  }

  if (!backTo) {
    return (
      <BackContainer onClick={() => history.goBack()}>
        <BackIcon color={fill} {...iconSize} />
        {!isMobile && <BackLabel>Back</BackLabel>}
      </BackContainer>
    )
  }

  return (
    <NavLink to={backTo}>
      <BackContainer>
        <BackIcon color={fill} {...iconSize} />
        {!isMobile && <BackLabel>Back</BackLabel>}
      </BackContainer>
    </NavLink>
  )
}

const BackContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.5;
  }
`

const BackLabel = styled(Label)`
  padding: 0 6px;
  font-size: 12.5px;
  font-weight: 500;
`

export default BackButton
