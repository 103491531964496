import React, { useState } from 'react'
import styled from 'styled-components'
import { SocialLink } from 'konect-react'
import { websiteAddressValidator } from '../../helpers/validators/websiteAddressValidator'
import Switch from '../Switch'
import { Label } from '../Typography'
import { cardColors, colors } from '../../core/theme'
import LinkIconEditor from '../LinkIconEditor'
import { useActions, useStore } from '../../store'
import { licenseStatus } from '../../helpers/licenseStatus'
import FloatingInput from '../FloatingInput'

export default function SocialIcon({ link, card, updateCard, section }) {
  const { showModal } = useActions().modals
  const { license } = useStore()
  const { isCardActive, isTrialActive } = licenseStatus(license, card)
  const { cardBackgroundColor = cardColors.cardBackgroundColor } = card
  const [error, setError] = useState()

  return (
    <>
      <PreviewContainer style={{ backgroundColor: cardBackgroundColor }}>
        <IconContainer>
          <SocialLink
            link={link}
            card={card}
            section={section}
            index={1}
            onSocialLinkPressed={() => {}}
          />
        </IconContainer>
      </PreviewContainer>
      <FieldsContainer>
        <FloatingInput
          label="Title"
          onChange={(e) => {
            link.title = e.target.value
            updateCard(card)
          }}
          value={link.title}
        />
        <FloatingInput
          label={`${link.label || 'Link'}`}
          onChange={(e) => {
            link.value = e.target.value
            if (!link.action) {
              if (websiteAddressValidator(link.value)) {
                setError(websiteAddressValidator(link.value))
              } else {
                setError('')
              }
            }
            updateCard(card)
          }}
          value={link.value}
          error={error}
          style={{ marginTop: 8 }}
        />
        {link.highlighted && (
          <FloatingInput
            label="Description"
            onChange={(e) => {
              link.description = e.target.value
              updateCard(card)
            }}
            value={link.description}
            style={{ marginTop: 8 }}
          />
        )}
        {section.layout !== 'minimal' && (
          <OptionWrapper>
            <Option>
              <Label weight="medium">Highlighted</Label>
              <Switch
                value={link.highlighted}
                onChange={(status) => {
                  link.highlighted = status
                  updateCard(card)
                }}
              />
            </Option>
          </OptionWrapper>
        )}
        <LinkIconEditor
          card={card}
          updateCard={updateCard}
          link={link}
          isCardActive={isCardActive}
          isTrialActive={isTrialActive}
          showModal={showModal}
        />
      </FieldsContainer>
    </>
  )
}

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 0 32px 20px 24px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 0 28px 20px 20px;
  }
`

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${colors.border};
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 14px;
  margin-bottom: 16px;
  margin-top: 8px;
`

const Option = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
`

const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding: 40px 24px;
  margin-bottom: 20px;
  box-sizing: border-box;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 366px;
`
