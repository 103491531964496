import React from 'react'
import styled from 'styled-components'
import { BUTTON_STYLE_TYPES } from '../core/constants'
import { colors } from '../core/theme'
import PremiumBadge from './PremiumBadge'

export default function ButtonTypePicker({ button, onChange, onSizeChange }) {
  const { buttonSize = 'large' } = button
  const buttonType = button.buttonType || BUTTON_STYLE_TYPES[0].value
  return (
    <Container>
      <FloatingLabel>Select a style</FloatingLabel>
      <ButtonsContainer
        style={{
          borderBottomRightRadius: buttonType === 'image-left' ? 0 : 8,
          borderBottomLeftRadius: buttonType === 'image-left' ? 0 : 8,
        }}
      >
        {BUTTON_STYLE_TYPES.map((style) => {
          if (
            (button.type === 'podcast' || button.type === 'folder') &&
            style.value === 'grid'
          )
            return null
          if (
            (button.type === 'podcast' || button.type === 'folder') &&
            style.value === 'carousel'
          )
            return null
          return (
            <ButtonTypeContainer
              selected={style.value === buttonType}
              onClick={() => {
                onChange(style)
              }}
            >
              <ButtonTypeImage src={style.icon} />
              {(style.isPremium || button.type === 'podcast') && (
                <PremiumBadgeContainer>
                  <PremiumBadge
                    type="feature"
                    direction="bottom"
                    arrowPosition="top"
                    size={14}
                  />
                </PremiumBadgeContainer>
              )}
            </ButtonTypeContainer>
          )
        })}
      </ButtonsContainer>
      {buttonType === 'image-left' && (
        <ButtonsSizeWrapper>
          <ButtonSize
            onClick={() => {
              if (buttonSize !== 'large') {
                onSizeChange('large')
              }
            }}
            isSelected={buttonSize === 'large'}
            style={{
              borderTopLeftRadius: 8,
              borderBottomLeftRadius: 8,
            }}
          >
            Large
          </ButtonSize>
          <ButtonSize
            onClick={() => {
              if (buttonSize !== 'small') {
                onSizeChange('small')
              }
            }}
            isSelected={buttonSize === 'small'}
            style={{
              borderTopRightRadius: 8,
              borderBottomRightRadius: 8,
              borderLeft: 'none',
            }}
          >
            Small
          </ButtonSize>
        </ButtonsSizeWrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 22px;
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-wrap: nowrap;
  width: 100%;
  overflow: auto;
  flex-shrink: 0;
  margin-top: 12px;
  border: 1px solid ${colors.border};
  padding: 12px 10px;
  border-radius: 8px;
  box-sizing: border-box;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 767px) {
    padding: 10px 8px;
  }
`

const ButtonsSizeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.border};
  border-top: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 16px;
`

const ButtonSize = styled.div`
  cursor: pointer;
  font-size: 13.5px;
  font-weight: 600;
  padding: 12px 0;
  box-sizing: border-box;
  width: 188px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid ${colors.border};
  box-sizing: border-box;
  background-color: ${(props) => (props.isSelected ? '#F5F5F5' : '#fff')};
  @media (max-width: 767px) {
    width: 46%;
  }
`

const FloatingLabel = styled.div`
  position: absolute;
  left: 7px;
  top: 0;
  background-color: white;
  padding: 2px 8px 2px 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3;
  z-index: 1;
  color: #444;
`

const ButtonTypeContainer = styled.div`
  display: flex;
  width: 68px;
  max-width: 68px;
  min-width: 68px;
  height: 68px;
  min-height: 68px;
  max-height: 68px;
  position: relative;
  flex: 1;
  border: 2px solid ${(props) => (props.selected ? colors.main : 'transparent')};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-items: center;
  margin: 0 5px;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;
  :hover {
    border: 2px solid
      ${(props) => (props.selected ? colors.main : colors.lightgrey)};
  }
  @media (max-width: 767px) {
    :hover {
      border: 2px solid
        ${(props) => (props.selected ? colors.main : 'transparent')};
    }
    margin: 0 3px;
    width: 58px;
    max-width: 58px;
    min-width: 58px;
    height: 58px;
    min-height: 58px;
    max-height: 58px;
  }
`

const ButtonTypeImage = styled.img`
  width: 100%;
  height: 100%;
  flex-shrink: 0;
`

const PremiumBadgeContainer = styled.div`
  position: absolute;
  right: -13px;
  top: -6px;
  z-index: 1;
`
