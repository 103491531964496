import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import FolderOptions from './FolderOptions'

const Folder = ({ folder, isSelected, setFolder }) => {
  return (
    <Container onClick={() => setFolder(folder)} isSelected={isSelected}>
      {folder.name}
      <FolderOptions folder={folder} />
    </Container>
  )
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.isSelected ? colors.mainLight : '#EDEDED'};
  border: 1px solid #c3cdd3;
  font-weight: 500;
  color: #000;
  padding: 0 8px;
  font-size: 12px;
  border-radius: 5px;
  height: 24px;
  max-height: 24px;
  margin: 4px;
`

export default Folder
