import React from 'react'
import styled from 'styled-components'
import FloatingInput from '../FloatingInput'
import MediaImageUpload from '../MediaImageUpload'

export default function Media({ section, card, updateCard }) {
  const { question, description, media = [] } = section

  const onQuestionChange = (e) => {
    section.question = e.target.value
    updateCard(card)
  }

  const onDescriptionChange = (e) => {
    section.description = e.target.value
    updateCard(card)
  }

  return (
    <EditSectionContainer>
      <FloatingInput
        onChange={onQuestionChange}
        label="Header"
        value={question}
      />
      <FloatingInput
        onChange={onDescriptionChange}
        label="Description"
        value={description}
        style={{ marginTop: 8 }}
      />
      {media.map((item) => (
        <MediaImageUpload
          key={item.id}
          card={card}
          updateCard={updateCard}
          media={item}
        />
      ))}
    </EditSectionContainer>
  )
}

const EditSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  margin-top: 32px;
`
