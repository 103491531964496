/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import styled from 'styled-components'

export default function RequiredSwitch({ value, onChange, onClick }) {
  return (
    <Container>
      <label className="required-switch-small">
        <input
          checked={value}
          type="checkbox"
          onClick={(e) => {
            if (onClick) {
              onClick(e)
            }
          }}
          onChange={() => {
            if (onChange) {
              onChange(!value)
            }
          }}
        />
        <span className="required-slider-small round" />
      </label>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`
