import React, { useState } from 'react'
import styled from 'styled-components'
import { DeleteIcon, ExportIcon, MobileMenuIcon } from '../assets/icons'
import { colors } from '../core/theme'
import { Dropdown, DropdownList } from './Dropdown'
import { usePermission } from '../hooks/usePermission'
import { useActions } from '../store'

function exportResponseToJSON(form) {
  const dataStr =
    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(form))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', form.label + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const InboxCardDetailsMenu = ({ card, style }) => {
  console.log(card.id, card) // eslint-disable-line
  const { showModal } = useActions().modals
  const { canEdit, showPermissionAlert } = usePermission(card)
  const [isMenuVisible, setMenuVisibility] = useState(false)
  const hideMenu = () => setMenuVisibility(false)
  const showMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (isMenuVisible) setMenuVisibility(false)
    else setMenuVisibility(true)
  }

  return (
    <Dropdown
      isVisible={isMenuVisible}
      placement="bottom-start"
      onClose={hideMenu}
      triggerRenderer={({ ref }) => (
        <div
          onClick={showMenu}
          ref={ref}
          style={{ cursor: 'pointer', ...style }}
        >
          <MenuButton>
            <MobileMenuIcon />
          </MenuButton>
        </div>
      )}
    >
      <DropdownList
        onClose={hideMenu}
        items={[
          {
            content: 'Export response to JSON',
            onItemSelect: () => exportResponseToJSON(card),
            icon: <ExportIcon color={colors.primary} size={18} />,
          },
          {
            content: 'Delete contact',
            color: colors.error,
            onItemSelect: () => {
              if (!canEdit) {
                showPermissionAlert()
                return
              }
              showModal({ name: 'DELETE_RESPONSE', card })
              hideMenu()
            },
            icon: <DeleteIcon color={colors.error} size={18} />,
          },
        ]}
      />
    </Dropdown>
  )
}

const MenuButton = styled.div`
  cursor: pointer;
  background-color: #ffffff;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

export default InboxCardDetailsMenu
