export function addQueryToURL(url, queryObject) {
  if (!url) return ''

  if (Array.isArray(queryObject)) {
    queryObject.forEach((key) => {
      const { name, value } = key
      if (name && value) {
        url += url.indexOf('?') > -1 ? `&` : `?`
        url += encodeURIComponent(name) + '=' + encodeURIComponent(value)
      }
    })

    return url
  }

  if (queryObject && typeof queryObject === 'object') {
    Object.keys(queryObject).forEach((key) => {
      if (queryObject[key]) {
        url += url.indexOf('?') > -1 ? `&` : `?`
        url +=
          encodeURIComponent(key) + '=' + encodeURIComponent(queryObject[key])
      }
    })
  }

  return url
}
