import React from 'react'
import Attribute from '../components/Attribute'

export function renderDataFromSections(sections) {
  const blocksData = []
  sections.forEach((section, sectionIndex) => {
    if (section.type === 'contact') {
      const fields = section.value || []
      fields.forEach((field, i) => {
        const value = field.value
        const label = field.question || field.placeholder
        if (!value) return
        if (label) {
          blocksData.push(
            <Attribute
              key={'field' + i + 'section' + sectionIndex}
              label={label}
              value={value}
              multiline
            />
          )
        }
      })
    }
  })

  return blocksData
}
