import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import styled from 'styled-components'
import {
  ArrowDown,
  ArrowUp,
  DeleteIcon,
  DraggableIcon,
  EditTextIcon,
  FolderIcon,
  FullFolderIcon,
} from '../assets/icons'
import { colors } from '../core/theme'
import { useActions } from '../store'
import Button from './Button'
import IconStylePicker from './IconStylePicker'
import MobileItemsList from './MobileItemsList'
import PremiumBadge from './PremiumBadge'
import { returnSectionIcon } from '../modals/AddSectionModal'
import { InfoTooltip } from './InfoTooltip'
import TemplateLock from './TemplateLock'

function canBeAddedToFolder(section, index, card) {
  if (section.type === 'folder') return false
  for (let i = 0; i < index; i++) {
    const previousSection = card.sections[i] || {}
    if (previousSection.type === 'folder') {
      return true
    }
  }
  return false
}

export default function MobileSectionItem({
  section,
  index,
  onClick,
  isOpen,
  card,
  updateCard,
  showSave,
  isSaving,
  saveChanges,
  isMobile,
  showAddSection,
  openSectionModal,
  templateLockProps,
  isDragDisabled,
}) {
  const { showModal } = useActions().modals
  let sectionLabel = section.title || section.question || section.label
  const label = `section-${section.id}`
  if (section.enablePopup) {
    return null
  }
  if (section.type === 'buttons') {
    sectionLabel = 'Buttons'
  }
  const hasAddButton =
    section.type === 'social-links' || section.type === 'buttons'
  const hasSettings =
    section.type !== 'buttons' && section.type !== 'social-links'
  const isFreeSection =
    section.type === 'social-links' ||
    section.type === 'buttons' ||
    section.type === 'link' ||
    section.type === 'folder' ||
    section.type === 'description'
  const hasAddToFolder = canBeAddedToFolder(section, index, card)
  const isContentBlocked = templateLockProps.isLocked('Add Content')
  const isItemBlocked = isContentBlocked || templateLockProps.isLocked(label)
  const showLock = isContentBlocked
    ? false
    : templateLockProps.showLock || (card.cardID && isItemBlocked)

  const deleteSection = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const result = window.confirm(
      'Are you sure you want to delete this content?'
    )
    if (result) {
      card.sections = card.sections.filter((b, i) => i !== index)
      updateCard(card)
    }
  }

  return (
    <>
      <Draggable
        key={section.id}
        draggableId={section.id}
        index={index}
        isDragDisabled={isDragDisabled}
      >
        {(provided, snapshot) => (
          <ItemContainer
            id={`section-${section.type}-${section.id}`}
            ref={provided.innerRef}
            onClick={() => {
              if (hasAddButton) {
                onClick()
              }
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            <ItemWrapper showLock={showLock}>
              {!isDragDisabled && (
                <IconContainer style={{ marginRight: 10 }}>
                  <DraggableIcon />
                </IconContainer>
              )}
              <SectionTitleWrapper>
                <div style={{ display: 'flex' }}>
                  {returnSectionIcon(
                    section.type || 'link',
                    42,
                    section.image,
                    section
                  )}
                </div>
                <SectionTitle>{sectionLabel}</SectionTitle>
                <IconContainer>
                  {!hasAddButton ? null : isOpen ? (
                    <ArrowUp size={20} color={colors.primary} />
                  ) : (
                    <ArrowDown size={20} color={colors.primary} />
                  )}
                </IconContainer>
              </SectionTitleWrapper>
              {!isFreeSection && (
                <PremiumBadge
                  direction="bottom"
                  arrowPosition="top"
                  size={14}
                  zIndex={1}
                />
              )}
              {!isItemBlocked && (
                <>
                  {hasAddButton && (
                    <Button
                      small
                      outline
                      id={`add-${section.type}-${section.id}`}
                      borderColor={colors.main}
                      className="add-button"
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        if (section.type === 'social-links') {
                          showModal({
                            name: 'ADD_LINK_MODAL',
                            cardSection: section,
                            card,
                            addSocialLinks: (links) => {
                              if (section.links.length >= 100) {
                                window.alert('You can add max 100 links.')
                                return
                              }
                              links.forEach((link) => {
                                section.links.push(link)
                              })

                              updateCard(card)
                            },
                          })
                        }

                        if (section.type === 'buttons') {
                          if (section.buttons.length >= 100) {
                            window.alert('You can add max 100 buttons.')
                            return
                          }
                          showAddSection(section)
                          if (!isOpen) {
                            onClick()
                          }
                        }
                      }}
                    >
                      Add
                    </Button>
                  )}
                  {hasAddToFolder && (
                    <InfoTooltip
                      hideMobile={isMobile}
                      icon={
                        <IconContainer
                          onClick={(e) => {
                            e.stopPropagation()
                            if (section.addToFolder) {
                              delete section.addToFolder
                            } else {
                              section.addToFolder = true
                            }
                            updateCard(card)
                          }}
                        >
                          {section.addToFolder ? (
                            <FullFolderIcon />
                          ) : (
                            <FolderIcon />
                          )}
                        </IconContainer>
                      }
                      description={
                        section.addToFolder
                          ? 'Remove from folder'
                          : 'Add to folder'
                      }
                      direction="bottom"
                      arrowPosition="top"
                      zIndex={1}
                    />
                  )}
                  {hasSettings && (
                    <IconContainer
                      id={`${section.type}-${section.id}`}
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        openSectionModal(section, index)
                      }}
                    >
                      <EditTextIcon size={17} />
                    </IconContainer>
                  )}
                  <IconContainer onClick={deleteSection}>
                    <DeleteIcon size={21} />
                  </IconContainer>
                </>
              )}
              {showLock && (
                <TemplateLock
                  onLock={templateLockProps.onLock}
                  label={label}
                  isLocked={templateLockProps.isLocked}
                  type="content"
                  style={{
                    right: '-14px',
                  }}
                />
              )}
            </ItemWrapper>
            {isOpen && section.type === 'social-links' && (
              <IconStylePicker
                label={label}
                section={section}
                updateCard={updateCard}
                card={card}
                isMobile={isMobile}
                templateLockProps={templateLockProps}
              />
            )}
          </ItemContainer>
        )}
      </Draggable>
      {isOpen && (
        <MobileItemsList
          isDragDisabled={isItemBlocked}
          templateLockProps={templateLockProps}
          section={section}
          card={card}
          updateCard={updateCard}
          showSave={showSave}
          saveChanges={saveChanges}
          isSaving={isSaving}
          isMobile={isMobile}
          sectionLabel={label}
          items={
            section.type === 'social-links' ? section.links : section.buttons
          }
        />
      )}
    </>
  )
}

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
})

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  background-color: #f0f0f0;
  border: 1px solid #c3cdd3;
  border-radius: 8px;
  width: 100%;
  flex-shrink: 0;
  margin: 0 0 16px 0;
`

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: ${(props) =>
    props.showLock ? '12px 12px 12px 8px' : '12px 5px 12px 8px'};
  box-sizing: border-box;
`

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  cursor: pointer;
`

const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
`

const SectionTitle = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-left: 12px;
`
