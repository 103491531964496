import React from 'react'
import { useHistory } from 'react-router-dom'
import ActionModal from './ActionModal'
import { colors } from '../core/theme'
import Button from '../components/Button'
import { logoutUser } from '../api/auth-api'
import { useActions } from '../store'

const LogoutModal = ({ onClose }) => {
  const { inbox, cards, license, profile, invitation, accessories } =
    useActions()
  const history = useHistory()

  const onLogoutClicked = () => {
    onClose()
    inbox.clear()
    cards.clear()
    license.clear()
    profile.clear()
    invitation.clear()
    accessories.clear()
    logoutUser()
    setTimeout(() => {
      if (history) history.push('/')
    }, 50)
  }

  return (
    <ActionModal
      onClose={onClose}
      actions={
        <>
          <Button
            color={colors.darkgrey}
            onClick={onClose}
            style={{ margin: '12px 6px 0 6px' }}
            outline
          >
            Wait, go back
          </Button>
          <Button
            onClick={onLogoutClicked}
            color={colors.error}
            style={{ margin: '12px 6px 0 6px' }}
          >
            Yes, logout
          </Button>
        </>
      }
      header="Logout from account?"
      description="You’re about to logout from your account. Are you sure you want to?"
    />
  )
}

export default LogoutModal
