import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import BaseModal from './BaseModal'
import { useActions, useStore } from '../store'
import { Label } from '../components/Typography'
import { updateLicenseSubscription } from '../api/billing-api'
import { generateRandomString } from '../helpers/generateRandomString'
import { ArrowRight } from '../assets/icons'
import { colors } from '../core/theme'
import { BILLING_PLANS } from '../core/constants'
import FloatingSelect from '../components/FloatingSelect'

const SLOTS = new Array(500).fill().map((v, i) => i + 1)

export default function AddSlotsModal({ onClose }) {
  const { profile, license } = useStore()
  const { showToast } = useActions().toasts
  const { getLicenseData } = useActions().license
  const [slots, setSlots] = useState(1)
  const [loading, setLoading] = useState(false)
  const plan =
    BILLING_PLANS.filter((p) => license.subscriptionName === p.name)[0] || {}
  const price = plan.price[license.subscriptionType]

  const updateSubscription = async () => {
    if (loading) return
    setLoading(true)
    try {
      const newSlots = new Array(slots).fill().map(() => ({
        id: generateRandomString(5),
        createdTimestamp: new Date().getTime(),
      }))
      const newCardSlots = [...newSlots, ...license.cardSlots]
      await updateLicenseSubscription({
        customerEmail: profile.email,
        license: profile.license,
        customerId: license.customerId,
        subscriptionName: license.subscriptionName,
        subscriptionType: license.subscriptionType,
        subscriptionId: license.subscriptionId,
        cardSlots: newCardSlots,
      })
      await getLicenseData()
      showToast({
        content: 'Subscription updated successfully.',
        variant: 'success',
      })
      onClose()
    } catch (e) {
      console.error(e)
      showToast({ content: e.message })
    }
    setLoading(false)
  }

  return (
    <BaseModal
      title="Add Premium Pages"
      maxWidth="640px"
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} outline style={{ margin: '0 8px' }}>
            Cancel
          </Button>
          <Button
            onClick={updateSubscription}
            loading={loading}
            icon={<ArrowRight size={20} color={colors.white} />}
          >
            Update Subscription
          </Button>
        </>
      }
    >
      <BodyWrapper>
        <FloatingSelect
          onChange={(e) => {
            setSlots(parseInt(e.target.value, 10))
          }}
          label="Number of premium pages to add"
          description="Pick the number of premium pages that will be added to your account."
          value={slots}
          style={{ marginTop: 8 }}
          options={SLOTS.map((i) => {
            return (
              <option key={i} value={i}>
                {i}
              </option>
            )
          })}
        />
        <Label padding="4px" lineHeight="big">
          New subscription will cost:{' '}
          <strong>
            ${(slots + license.cardSlots.length) * price} /{' '}
            {license.subscriptionType}.
          </strong>
        </Label>
        <Label padding="4px" lineHeight="big">
          You will be charged for added premium pages right away. The price will
          be lower depending on how many days are left to your billing cycle.{' '}
          {license.subscriptionType === 'monthly' ? (
            <span>
              For example if there are <strong>15 days left</strong> until the
              new billing cycle, you will be charged only <strong>50%</strong>{' '}
              for added premium pages.
            </span>
          ) : (
            <span>
              For example if there are <strong>180 days left</strong> until the
              new billing cycle, you will be charged only <strong>50%</strong>{' '}
              for added premium pages.
            </span>
          )}
        </Label>
      </BodyWrapper>
    </BaseModal>
  )
}

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 28px 16px 28px;
  box-sizing: border-box;
  @media (max-width: 767px) {
    padding: 16px;
  }
`
