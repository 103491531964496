import React, { useState } from 'react'
import styled from 'styled-components'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as LinkInBio } from '../assets/icons/link_in_bio_type.svg'
import { ReactComponent as BusinessCard } from '../assets/icons/business_card_type.svg'
import Button from './Button'
import { ArrowRight } from '../assets/icons'
import { colors } from '../core/theme'

export default function SelectPageType({ moveToDashboard, loading }) {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const [type, setType] = useState()

  return (
    <Wrapper>
      <Container>
        <PageType
          selected={type === 'Modern'}
          onClick={() => setType('Modern')}
        >
          <PageTitle>Modern</PageTitle>
          <LinkInBio
            width={!isMobile ? 275 : '100%'}
            height={!isMobile ? 445 : '100%'}
          />
        </PageType>
        <PageType
          selected={type === 'Classic'}
          onClick={() => setType('Classic')}
        >
          <PageTitle>Classic</PageTitle>
          <BusinessCard
            width={!isMobile ? 275 : '100%'}
            height={!isMobile ? 445 : '100%'}
          />
        </PageType>
      </Container>
      {type && (
        <Button
          style={{ marginTop: 12, minWidth: '160px' }}
          icon={<ArrowRight size={20} color={colors.white} />}
          onClick={() => moveToDashboard(type)}
          loading={loading}
        >
          Next
        </Button>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 16px;
  box-sizing: border-box;
`

const PageType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 8px 24px 16px 24px;
  cursor: pointer;
  transition: opacity 200ms ease-out;
  background-color: ${(props) =>
    props.selected ? colors.mainLight : 'transparent'};
  border: ${(props) =>
    props.selected ? `1px solid ${colors.main}` : '1px solid transparent'};
  border-radius: 12px;
  box-sizing: border-box;
  :hover {
    opacity: 0.9;
  }
  :active {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    padding: 6px 12px 16px 12px;
  }
`

const PageTitle = styled.span`
  font-size: 15px;
  font-weight: 600;
  margin: 8px 0 20px 0;
  text-align: center;
`
