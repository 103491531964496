export function getTodayTime() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 86400000).getTime()
}

export function getLastWeekTime() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 604800000).getTime()
}

export function getLastMonthTime() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 2592000000).getTime()
}

export function getLastQuarterTime() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 7776000000).getTime()
}

export function getLastYearTime() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 31104000000).getTime()
}

export function returnTimeZone(date = '') {
  if (date) {
    if (date.split(',')[1]) return date.split(',')[1].substring(7)
  }
  return /\((.*)\)/.exec(new Date().toString())[1]
}

export function getStartTime() {
  return 1
}

export function getTodayDate() {
  return new Date().getTime()
}

export function getYesterdayDate() {
  const todayTime = new Date().getTime()
  return new Date(todayTime - 86400000).getTime()
}

export const returnChartDateFormat = (date) => {
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  const yyyy = date.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return yyyy + '-' + mm + '-' + dd
}

export const returnFormattedDate = (date = new Date()) => {
  const day = date.getDate()
  const month = monthAsString(date.getMonth())
  const year = date.getFullYear()
  return `${month} ${day}, ${year}`
}

export const monthAsString = (monthIndex) => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ][monthIndex]
}

export function getFormattedDate(date = new Date()) {
  let dd = date.getDate()
  let mm = date.getMonth() + 1
  const yyyy = date.getFullYear()
  if (dd < 10) {
    dd = '0' + dd
  }
  if (mm < 10) {
    mm = '0' + mm
  }
  return mm + '-' + dd + '-' + yyyy
}

export function sortDatesInArray(array) {
  const length = array.length
  for (let i = 0; i < length; i++) {
    for (let j = 0; j < length - i - 1; j++) {
      const number = new Date(array[j].date).getTime()
      const number2 = new Date(array[j + 1].date).getTime()
      if (number < number2) {
        const tmp = Object.assign(array[j])
        array[j] = array[j + 1]
        array[j + 1] = tmp
      }
    }
  }
  return array
}
