const TRIAL_DAYS = 7

function daysToMilliseconds(days) {
  return days * 24 * 60 * 60 * 1000
}

export const startLicenseTrial = (license) => {
  const currentDate = new Date().getTime()
  const trialEnds = currentDate + daysToMilliseconds(TRIAL_DAYS)
  license.trialEnds = trialEnds
}
