import React from 'react'
import styled from 'styled-components'
import { colors } from '../core/theme'
import { Tooltip, TooltipContent } from './Tooltip'

const Tag = ({
  removeTag,
  style,
  color = colors.main,
  label,
  onClick,
  index,
}) => {
  if (removeTag) {
    return (
      <Tooltip
        offset={1}
        align="center"
        trigger="hover"
        zIndex={100 - index}
        content={
          <TooltipContent arrowPosition="top">
            <Remove onClick={removeTag}>Remove</Remove>
          </TooltipContent>
        }
        direction="bottom"
      >
        <TagContainer style={style} color={color}>
          {label}
        </TagContainer>
      </Tooltip>
    )
  }

  return (
    <TagContainer onClick={onClick} style={style} color={color}>
      {label}
    </TagContainer>
  )
}

const TagContainer = styled.div`
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  box-sizing: border-box;
  text-decoration: none;
  vertical-align: top;
  line-height: 1.25;
  display: inline-block;
  padding: 6px 8px;
  min-height: 28px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 4px;
  letter-spacing: 0.25px;
  transition: opacity 0.2s linear;
  background-color: ${(props) => props.color};
  color: #ffffff;
  margin: 3px;
  white-space: pre;
  ${(props) => props.style}
`

const Remove = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: ${colors.white};
  z-index: 9;
  transition: opacity 200ms ease-out;
  :hover {
    opacity: 0.8;
  }
`

export default Tag
